export const VISITS_REQUEST = 'VISITS_REQUEST';
export const VISITS_REQUEST_SUCCESS = 'VISITS_REQUEST_SUCCESS';
export const VISITS_REQUEST_FAILED = 'VISITS_REQUEST_FAILED';
export const VISITS_EXPORT_REQUEST = 'VISITS_EXPORT_REQUEST';
export const VISITS_EXPORT_REQUEST_SUCCESS = 'VISITS_EXPORT_REQUEST_SUCCESS';
export const VISITS_EXPORT_REQUEST_FAILED = 'VISITS_EXPORT_REQUEST_FAILED';
export const VISITS_EXPORT_AND_PRINT_REQUEST = 'VISITS_EXPORT_AND_PRINT_REQUEST';

export type ErrorMessage = {
  message: string;
};

export type Visit = {
  subdivision: string;
  house_num: string;
  address_1: string;
  lot_num: string;
  customer_id: number;
  firstname: string;
  lastname: string;
  visitor_status_id: string;
  visitor_type_id: string;
  cell_phone_1: string;
  modified_on: string;
  property_id: number;
  visit_status_id: string;
  platform: string;
  _id: number;
};

export type VisitsRequestSuccess = {
  items: Array<Visit>;
  total: number;
};

export type VisitsState = {
  items: Array<Visit>;
  total: number;
  loading: boolean;
  error: ErrorMessage['message'];
};
