export const clientFields = [
  'name',
  'modified_on',
  'main_contact',
  'comments',
  'contact_position',
  'created_on',
  'created_by',
  'modified_by',
  'requires_opt_in',
  'builder_id',
  'texting_active',
  'STI_active',
  'LASSO_active',
  'Novihome_active',
  'active',
  'is_rental',
  'is_webflow_code_only',
  'high_security_verification_active',
  'lock_lease_fee',
] as const;

export type ClientFields = typeof clientFields[number];

export type ClientType = Omit<Record<ClientFields, string>, 'builder_id' | 'requires_opt_in' |
'texting_active' | 'LASSO_active' | 'STI_active' | 'Novihome_active' | 'active' | 'is_rental' | 'is_webflow_code_only' | 'high_security_verification_active' | 'lock_lease_fee'> & {
  builder_id: number;
  texting_active: boolean;
  LASSO_active: boolean;
  Novihome_active: boolean;
  STI_active: boolean;
  opt_in_notice: string;
  opt_in_notice2: string;
  requires_opt_in: boolean;
  active: boolean;
  is_rental: boolean;
  is_webflow_code_only: number;
  high_security_verificatiob_active: boolean;
  lock_lease_fee: number;
  builderIntegrations: {
    integration_id: number;
    isActive: number;
  }[]
};

export type BuilderIntegration = {
  isActive: boolean,
  integration: { id: number, name: string },
  builderIntegrationAttribute: { id?: number, name: string, value: string }[]
};

export type BuilderSettings = {
  webflow_name: string
};

export class ClientClass {
  name: string = '';

  modified_on: string = '';

  main_contact: string = '';

  comments: string = '';

  contact_position: string = '';

  created_on: string = '';

  created_by: string = '';

  modified_by: string = '';

  builder_id?: number;

  texting_active: boolean = false;

  requires_opt_in: boolean = false;

  STI_active: boolean = false;

  STI_builder_id?: number;

  LASSO_active: boolean = false;

  LASSO_apikey: string = '';

  LASSO_id: string = '';

  TRIPOINTE_active: boolean = false;

  academy_active: boolean = false;

  Novihome_active: boolean = false;

  active: boolean = false;

  is_rental: boolean = false;

  is_webflow_code_only: boolean = false;

  high_security_verification_active: boolean = false;

  lock_lease_fee: number = 0;

  opt_in_notice: string = '';

  opt_in_notice_2: string = '';

  tour_new_homes_active: boolean = false;

  billing_contract_code: string = '';

  bossApi_active: boolean = false;

  allDayAccess_active: boolean = false;

  adjustableTourHours: boolean = false;

  users: { username: string }[] = [];

  builderIntegrations?: BuilderIntegration[] = [];

  builderSettings?: BuilderSettings = { webflow_name: '' };

  number_of_licenses: number = 0;

  builder_timezone: number = 0;

  company_code: string = '';
}
