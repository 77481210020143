import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  widgetsWrapper: {
    marginTop: 33,
  },
  expandRoot: {
    backgroundColor: '#fefefe',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  controlButton: {
    marginBottom: '25px',
  },
  tableHeaderText: {
    marginLeft: '23px',
  },
});
const useStyles = makeStyles(styles);

export default useStyles;
