/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import { gql } from 'apollo-boost';

export const GET_COMMUNITY = gql`
query($communityId: Float!) {
  communityById(communityId: $communityId) {
      id,
      name,
      city,
      state,
      zip,
      external_community_id,
      created_on,
      created_by,
      modified_on,
      modified_by,
      builder_id,
      lasso_community_project_id,
      novihome_integration,
      nternow_hub_backup_code,
      community_backup_code,
      trade_code,
      sales_code,
      sales_agent,
      emergency_contacts
  }
}
`;

export const ALL_COMMUNITIES = gql`
query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
  communityList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
    list {
      id,
      name,
      city,
      state,
      zip,
      external_community_id,
      created_on,
      created_by,
      modified_on,
      modified_by,
      builder {
        name
      } 
    }                    
    params {
      total
      limit
      offset
    }
  }
}
`;

export const CREATE_COMMUNITY = gql`
  mutation updateCommunity($community: UpdateCommunity!) {
    updateCommunity(community: $community) 
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation deleteCommunity($communityIds: [Int!]!) {  
    deleteCommunity(communityIds: $communityIds)   
  }  
`;
export const SEARCH_COMMUNITIES = gql`
  query($name: String!, $builder_id: Float!) {  
    communities: searchCommunities (name: $name, builder_id: $builder_id) {
      id,
      name,
      city,
      state,
      lasso_community_project_id,
      novihome_integration,
      nternow_hub_backup_code,
      community_backup_code,
      trade_code,
      sales_code
    }
  }
`;
export const GET_COMMUNITY_BY_ID = gql`
  query($id: Float!) {
    community: communityById (communityId: $id) {
      id,
      name,
    }
  }
`;

export const GET_AGENTS_BY_COMMUNITY_ID = gql`
  query($id: Float!) {
    agents: agentsByCommunityId (communityId: $id) {
      username,
      firstname,
      lastname,
      phone
    }
  }
`;
