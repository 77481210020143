/* eslint-disable import/prefer-default-export */
export const convertToUsdCurrencyFormat = (price: number): string | number => {
  const isValid = price && price > 0 && !Number.isNaN(price);

  if (!isValid) return price;

  if (price) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency', currency: 'USD', useGrouping: true, maximumFractionDigits: 2,
    }).format(price);
  }

  return '';
};
