import { Collapse, Grid } from '@material-ui/core';
import React from 'react';
import { generateLabel } from '../../../../utils/helpers';
import CustomTextField from '../../../CustomTextField';

type Props = {
  isActive: boolean;
  handleChangeClient: ({
    target: { name, value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors: any;
  loginUrl: string;
  clientId: string;
  clientSecret: string;
  redirectUri: string;
  username: string;
  password: string;
  securityToken: string;
};

const SalesforceAttributes: React.FC<Props> = props => {
  const {
    isActive,
    handleChangeClient,
    loginUrl,
    clientId,
    clientSecret,
    redirectUri,
    username,
    password,
    securityToken,
  } = props;

  return (
    <Collapse in={isActive}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            label={generateLabel('Salesforce Login Url')}
            name="Salesforce Login Url"
            fullWidth
            value={loginUrl || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            multiline
            label={generateLabel('Salesforce Client Id')}
            name="Salesforce Client Id"
            value={clientId || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            label={generateLabel('Salesforce Client Secret')}
            name="Salesforce Client Secret"
            fullWidth
            value={clientSecret || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            label={generateLabel('Salesforce Redirect Uri')}
            name="Salesforce Redirect Uri"
            value={redirectUri || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            label={generateLabel('Salesforce Username')}
            name="Salesforce Username"
            value={username || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            label={generateLabel('Salesforce Password')}
            name="Salesforce Password"
            value={password || ''}
            onChange={handleChangeClient}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            label={generateLabel('Salesforce Security Token')}
            name="Salesforce Security Token"
            value={securityToken || ''}
            onChange={handleChangeClient}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default React.memo(SalesforceAttributes);
