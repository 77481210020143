import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    '& > div': {
      marginLeft: theme.spacing(2),
    },
  },
  spacer: {
    flexGrow: 1,
  },
});
