import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomButton from '../../../components/CustomButton';
import { UserCodeKeyValue } from '../constants';

type Props = {
  onCancel: () => void;
  onConfirm: (data: any) => void;
  isOpen: boolean;
  userCode?: UserCodeKeyValue;
};

const validateUserCode = yup.object().shape({
  code: yup.string()
    .length(4, 'User code must be 4 characters long')
    .required('User code is required'),
});

const DialogEditUserCode: React.FC<Props> = props => {
  const {
    isOpen, onCancel, onConfirm, userCode,
  } = props;
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(validateUserCode),
  });

  const onSubmit = (data: any) => {
    onConfirm(data);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onCancel}
      >
        <DialogTitle disableTypography>
          {`Edit code #${userCode?.key}`}
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            inputRef={input => register(input, { required: true })}
            error={!!errors.name}
            helperText={errors?.name?.message ?? ''}
            id="name"
            defaultValue={userCode?.name}
            label="Name"
            name="name"
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            inputRef={input => register(input, { required: true })}
            type="number"
            error={!!errors.code}
            helperText={errors?.code?.message ?? ''}
            id="code"
            defaultValue={userCode?.value}
            label="User code"
            name="code"
          />
        </DialogContent>
        <DialogActions>
          <CustomButton variant="white" onClick={onCancel}>Cancel</CustomButton>
          <CustomButton variant="orange" onClick={handleSubmit(onSubmit)}>Save</CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(DialogEditUserCode);
