import { useContext, useEffect, useState } from 'react';
import StaticDataContext from '../context/StaticDataProvider';

export default function useStatuses(type: 'lock' | 'shipment' | 'property') {
  const staticData = useContext<any>(StaticDataContext);
  const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    if (staticData) {
      switch (type) {
        case 'lock': {
          setStatuses(staticData.lockStatuses);
          break;
        }
        case 'shipment': {
          setStatuses(staticData.shipmentStatuses);
          break;
        }
        case 'property': {
          setStatuses(staticData.propertyStatuses);
          break;
        }
        default: setStatuses([]);
      }
    }
  }, [staticData, type]);
  return statuses;
}
