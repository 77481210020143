/* eslint-disable @typescript-eslint/camelcase */
import React, { ChangeEvent, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { Checkbox, Collapse, FormControlLabel } from '@material-ui/core';
import InputPriceWrapper from '../InputPriceWrapper';
import useStyles from './styles';
import { Property } from '../../types/Property';
import {
  beautifyErrors, transformGraphQlErrorMessage, ValidationErrors, convertDateWithTimezone, transformValidationErrorMessage,
} from '../../utils/helpers';
import { propertyCreateSchema } from './PropertyValidation';
import LockSearch from '../LockSearch';
import Lock from '../../types/Lock';
import SmartLockSearch from '../SmartLockSearch';
import SmartLock from '../../types/SmartLock';
import CommunitySearch from '../CommunitySearch';
import { AgentsProperties } from '../../types/User';
import StatusSelect from '../StatusSelect/StatusSelect';
import BuilderSearch from '../BulderSearch/BuilderSearch';
import Builder from '../../types/Builder';
import StatesSelect from '../StatesSelect/StatesSelect';
import TimezoneSelect from '../TimezoneSelect/TimezoneSelect';
import CustomDatePicker from '../CustomDatePicker';
import Community from '../../types/Community';
import { UPDATE_PROPERTY } from '../../graphql/queries/PropertyDetails';

const getInitialPropertyState = ({
  property_id,
  builder,
  builder_id,
  house_num,
  address_1,
  grouping,
  address_2,
  subdivision,
  city,
  state,
  zipcode,
  disposition,
  latitude,
  longitude,
  is_rental,
  is_webflow_code_only,
  hub,
  price,
  bedrooms,
  halfBathrooms,
  fullBathrooms,
  squareFootage,
  isModel,
  lock,
  propertyStatusId,
  status,
  url_1,
  rental_application_url,
  modified_on,
  modified_by,
  remarks,
  timezone,
  lock_serial_number,
  smart_lock_serial_number,
  lock_assigned,
  lock_terminated,
  contract_date,
  alarm,
  alarm_company,
  armed_hours,
  disarm_code,
  emergency_notes,
  marketing_name,
  LASSO_project_id,
  novihome_integration,
  nternow_hub_backup_code,
  property_backup_code,
  temp_backup_code,
}: Property) => ({
  property_id,
  builder,
  builder_id,
  house_num,
  address_1,
  grouping,
  address_2,
  subdivision,
  city,
  lock,
  hub,
  propertyStatusId,
  status,
  url_1,
  rental_application_url,
  remarks,
  timezone,
  state,
  zipcode,
  disposition,
  latitude,
  longitude,
  is_rental,
  is_webflow_code_only,
  modified_on,
  modified_by,
  price,
  bedrooms,
  halfBathrooms,
  fullBathrooms,
  squareFootage,
  isModel,
  lock_serial_number,
  smart_lock_serial_number,
  lock_assigned,
  lock_terminated,
  contract_date,
  alarm,
  alarm_company,
  armed_hours,
  disarm_code,
  emergency_notes,
  marketing_name,
  LASSO_project_id,
  novihome_integration,
  nternow_hub_backup_code,
  property_backup_code,
  temp_backup_code,
});

type CurrentUser = {
  username: string, isPM: boolean, isAdmin: boolean
};

type Props = {
  info: Property;
  user: CurrentUser;
  assignedPM: AgentsProperties,
};

const PropertyInfoCard: React.FC<Props> = props => {
  const {
    info, user, assignedPM,
  } = props;
  const classes = useStyles();
  const [propertyState, setPropertyState] = React.useState(getInitialPropertyState(info));
  const [community, setCommunity] = useState(info?.propertyCommunity?.communities);
  const [chosenLock, chooseLock] = React.useState<Lock>();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<Property>>({});
  const { enqueueSnackbar } = useSnackbar();
  const [updateProperty] = useMutation<{ updateProperty: Property }>(
    UPDATE_PROPERTY,
  );

  useEffect(() => {
    setPropertyState(prevState => ({
      ...prevState,
      LASSO_project_id: info.LASSO_project_id,
      novihome_integration: info.novihome_integration,
    }));
  }, [info.LASSO_project_id, info.novihome_integration]);

  useEffect(() => {
    setPropertyState(prevState => ({
      ...prevState,
      nternow_hub_backup_code: info.nternow_hub_backup_code,
      property_backup_code: info.property_backup_code,
      temp_backup_code: info.temp_backup_code,
      trade_code: info.trade_code,
      resident_code: info.resident_code,
      sales_code: info.sales_code,
    }));
  }, [info.nternow_hub_backup_code, info.property_backup_code, info.temp_backup_code, info.trade_code, info.resident_code, info.sales_code]);

  useEffect(() => {
    setPropertyState(prevState => ({
      ...prevState,
      longitude: info.longitude,
      latitude: info.latitude,
    }));
  }, [info.longitude, info.latitude]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      const { address_2, ...rest } = propertyState;
      const uProperty = propertyCreateSchema.validateSync(rest, { abortEarly: false });
      setValidationErrors({});
      updateProperty({
        variables: {
          property: {
            ...rest,
            ...uProperty,
          },
          communityId: community?.id ?? 0,
        },
      })
        .then(() => {
          enqueueSnackbar('Success', { variant: 'success' });
        })
        .catch(res => {
          enqueueSnackbar(transformGraphQlErrorMessage(res.message), { variant: 'error' });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<Property>(errors));
      enqueueSnackbar(transformValidationErrorMessage(beautifyErrors<Property>(errors)), {variant: 'error'});
    }
  }

  const handleBuilderChange = ({ builder_id }: Builder) => {
    setPropertyState(prevState => ({ ...prevState, builder_id }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setPropertyState(prevState => ({ ...prevState, [name]: value }));

    if (name === 'isModel') {
      setPropertyState(prevState => ({ ...prevState, [name]: checked }));
    }

    if (name === 'alarm') {
      setPropertyState(prevState => ({
        ...prevState,
        [name]: checked,
        alarm_company: checked ? info.alarm_company : '',
        armed_hours: checked ? info.armed_hours : '',
        disarm_code: checked ? info.disarm_code : '',
        emergency_notes: checked ? info.emergency_notes : '',
      }));
    }
  };

  const handleLockChange = (lock: Lock) => {
    if (lock.serial_number !== '') {
      chooseLock(lock);
    } else chooseLock(undefined);
    setPropertyState(prevState => ({
      ...prevState,
      lock_serial_number: lock?.serial_number ?? '',
      lock_assigned: new Date().toISOString(),
    }));
  };

  const handleSmartLockChange = (smartLock: SmartLock) => {
    setPropertyState(prevState => ({
      ...prevState,
      smart_lock_serial_number: smartLock?.serial_number ?? '',
      lock_assigned: new Date().toISOString(),
    }));
  };

  const handleCommunityChange = (community: Community) => {
    if (community) {
      const { id, name, created_on } = community;
      setCommunity(prevState => ({
        ...prevState,
        id: id ?? 0,
        created_on: created_on ?? '',
        name,
      }));
      setPropertyState(prevState => ({
        ...prevState,
        subdivision: name ?? '',
      }));
    } else {
      setCommunity(undefined);
      setPropertyState(prevState => ({
        ...prevState,
        subdivision: '',
      }));
    }
  };

  const handleDateChange = (date: string, name: string) => {
    if (Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
      const UTCDateString = newDate.toISOString();
      setPropertyState(prevState => ({ ...prevState, [name]: UTCDateString }));
    }
  };

  const handleDispositionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPropertyState(prevState => ({ ...prevState, propertyStatusId: value }));
  };

  const userIsPmOrAdmin = (loginedUser: CurrentUser) => (assignedPM
    && assignedPM.agent_username === loginedUser.username) || user.isAdmin;

  return (
    <form className={classes.content} onSubmit={onSubmit} noValidate>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Property ID
          </Typography>
          <Typography>
            {propertyState.property_id}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <BuilderSearch
            onBuilderChange={handleBuilderChange}
            initialValue={propertyState.builder_id ?? undefined}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="builder"
            name="builder"
            label="Builder"
            value={propertyState.builder}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TimezoneSelect
            fullWidth
            margin="normal"
            id="timezone"
            name="timezone"
            defaultTimezone={propertyState.timezone ? Number(propertyState.timezone) : undefined}
            label="Timezone"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <StatusSelect
            label="Property disposition"
            statusType="property"
            name="disposition_id"
            id="disposition_id"
            onChange={handleDispositionChange}
            value={propertyState?.propertyStatusId ?? '0'}
          />
        </Grid>

        {userIsPmOrAdmin(user)
          && (
            <Grid item xs={12} style={{ marginTop: '16px' }}>
              <CommunitySearch
                handleChange={handleCommunityChange}
                initialValue={community?.id ?? undefined}
                builderId={propertyState?.builder_id}
              />
            </Grid>
          )
        }

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="house_num"
            name="house_num"
            label="Lot Number"
            value={propertyState.house_num}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="address_1"
            name="address_1"
            label="Full Street Address"
            value={propertyState.address_1}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="city"
            name="city"
            label="City"
            value={propertyState.city}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <StatesSelect
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="State/Province"
              value={propertyState.state}
              onChange={handleChange}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="zipcode"
            name="zipcode"
            label="Zip Code"
            value={propertyState.zipcode}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="subdivision"
            name="subdivision"
            label="Subdivision"
            value={propertyState.subdivision}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="marketing_name"
            name="marketing_name"
            label="Marketing Name"
            value={propertyState.marketing_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            margin="normal"
            id="grouping"
            name="grouping"
            label="Notes"
            value={propertyState.grouping}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            margin="normal"
            id="remarks"
            name="remarks"
            label="Installer"
            value={propertyState.remarks}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="url_1"
            name="url_1"
            label="Url"
            value={propertyState.url_1}
            onChange={handleChange}
          />
        </Grid>

        {propertyState.is_rental && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="rental_application_url"
              name="rental_application_url"
              label="Rental Application Url"
              value={propertyState.rental_application_url}
              onChange={handleChange}
            />
          </Grid>
        )}
        

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="latitude"
            name="latitude"
            label="Latitude"
            value={propertyState.latitude}
            onChange={handleChange}
            error={Boolean(validationErrors.latitude)}
            helperText={validationErrors.latitude || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="longitude"
            name="longitude"
            label="Longitude"
            value={propertyState.longitude}
            onChange={handleChange}
            error={Boolean(validationErrors.longitude)}
            helperText={validationErrors.longitude || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <InputPriceWrapper>
            <TextField
              fullWidth
              margin="normal"
              id="price"
              name="price"
              label={propertyState.is_rental ? "Rent per Month": "Price"}
              value={propertyState.price}
              onChange={handleChange}
            />
          </InputPriceWrapper>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="bedrooms"
            name="bedrooms"
            label="Bedrooms"
            value={propertyState.bedrooms}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="fullBathrooms"
            name="fullBathrooms"
            label="Full Bathrooms"
            value={propertyState.fullBathrooms}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="halfBathrooms"
            name="halfBathrooms"
            label="Half Bathrooms"
            value={propertyState.halfBathrooms}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="squareFootage"
            name="squareFootage"
            label="Square Footage"
            value={propertyState.squareFootage}
            onChange={handleChange}
          />
        </Grid>

        {propertyState.hub?.serial_number && (
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="caption" color="textSecondary">
            Device Serial Number
          </Typography>
          <Typography>
            {propertyState.hub.serial_number}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
)}

        <Grid item xs={12}>
          <LockSearch
            addLabel
            styles={{ zIndex: 2 }}
            onLockChange={handleLockChange}
            initialValue={propertyState.lock_serial_number ?? 0}
          />
        </Grid>

        <Grid item xs={12}>
          <SmartLockSearch
            addLabel
            styles={{ zIndex: 2 }}
            onSmartLockChange={handleSmartLockChange}
            initialValue={propertyState.smart_lock_serial_number ?? 0}
          />
        </Grid>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={12}>
            <CustomDatePicker
              fullWidth
              label="Install date"
              inputVariant="standard"
              value={propertyState.lock_assigned}
              placeholder="10/10/2012"
              disabled={propertyState?.lock?.lockStatusId === "LOCK_ACTIVATED"}
              // @ts-ignore
              onChange={date => handleDateChange(date, 'lock_assigned')}
              format="MM/dd/yyyy"
            />
          </Grid>

          <Grid item xs={12}>
            <CustomDatePicker
              fullWidth
              label="Lock removal date"
              inputVariant="standard"
              value={propertyState.lock_terminated}
              placeholder="10/10/2012"
              // @ts-ignore
              onChange={date => handleDateChange(date, 'lock_terminated')}
              format="MM/dd/yyyy"
            />
          </Grid>
        </MuiPickersUtilsProvider>

        {(chosenLock || propertyState.lock_serial_number) && (
          <>
            <Grid item xs={12}>
              <StatusSelect
                label="Lock status"
                statusType="lock"
                disabled
                value={chosenLock?.lockStatusId
                  ?? (propertyState?.lock?.lockStatusId ?? '0')}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Typography variant="caption" color="textSecondary">
            Modified by
          </Typography>
          <Typography>
            {propertyState.modified_by}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Modified on
          </Typography>
          <Typography>
            {convertDateWithTimezone(new Date(propertyState.modified_on))}
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Collapse in={Boolean(propertyState?.alarm)} className={classes.alarmContainer}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="alarm_company"
              name="alarm_company"
              label="Alarm Company"
              value={propertyState?.alarm_company ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="armed_hours"
              name="armed_hours"
              label="Armed Hours"
              value={propertyState?.armed_hours ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="disarm_code"
              name="disarm_code"
              label="Disarm Code"
              value={propertyState?.disarm_code ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              margin="normal"
              rows={4}
              id="emergency_notes"
              name="emergency_notes"
              label="Emergency Notes"
              value={propertyState?.emergency_notes ?? ''}
              onChange={handleChange}
            />
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                color="secondary"
                name="alarm"
                checked={Boolean(propertyState.alarm)}
                onChange={handleChange}
              />
            )}
            label="Alarm"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                color="secondary"
                name="isModel"
                checked={propertyState.isModel}
                onChange={handleChange}
              />
            )}
            label="Is Model"
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.formActions}>
            <Button type="submit" variant="contained">Save</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(PropertyInfoCard);
