import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  spacer: {
    paddingTop: 5 * theme.spacing(1),
  },
  divider: {
    marginTop: 5 * theme.spacing(1),
  },
  value: {
    color: theme.appPalette.orange,
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  actionButton: {
    margin: 15,
  },
  contentWrapper: {
    marginTop: theme.spacing(3),
  },
  card: {
    overflow: 'hidden',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  status: {
    marginRight: 5,
    height: 10,
    width: 10,
    borderRadius: '50%',
    background: '#37b361',
    border: '1.5px solid #fff',
    zIndex: 1,
    bottom: 0,
    right: 0,
    display: 'inline-block',
    boxSizing: 'border-box',
    '&.offline': {
      background: '#fff',
      border: '2px solid #666666',
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 15,
    margin: 0,
    paddingBottom: 15,
    position: 'relative',
    minHeight: 500,
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  alertContainer: {
    marginBottom: 12,
    margin: '0 auto',
  },
  alertText: {
    fontSize: 18,
  },
  form: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  item: {
    paddingLeft: 3 * theme.spacing(1),
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
