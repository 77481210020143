import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import SearchFilterElement from './SearchFilterElement';
import styles from './styles';
import { setFilter } from '../../redux/actions/filter';

const mapDispatchToProps = {
  setFilterState: setFilter,
};

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(SearchFilterElement),
);
