import { gql } from 'apollo-boost';


export const GET_SMART_LOCK = gql`
  query($serial_number: String!) {
    smartLockBySerialNumber(serial_number: $serial_number) {
      serial_number,
      device_id,
      smartLockStatusId,
      smart_lock_type_id,
      comments,
      created_on,
      created_by,
      modified_on,
      modified_by,
      builder_id,
    }
  }
`;

export const GET_SMART_LOCK_TYPES = gql`
  query {
    allSmartLockTypes {
      id,
      smart_lock_type
    }
  }
`;

export const UPDATE_SMART_LOCK = gql`
  mutation updateSmartLock($smartLock: UpdateSmartLock!) {
    updateSmartLock(smartLock: $smartLock) {
      serial_number
    }
  }
`;

export const SEARCH_SMART_LOCKS = gql`
  query($sn: String!) {
    smartLocks: searchSmartLocks (serial_number: $sn) {
      serial_number,
    }
  }
`;
