import FileSave from 'js-file-download';
import { MimeType } from '../redux/types/mime';
import axios from './axios';

type exportType =
  | 'locks'
  | 'properties'
  | 'customers'
  | 'visits'
  | 'clients'
  | 'enterprises'
  | 'users'
  | 'communities'
  | 'tickets'
  | 'shipments'
  | 'hub';

export type ExportFileType = {
  type:
  | 'application/pdf'
  | 'text/csv'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetl';
};

export type ExportData = Blob | Array<string>;

export const exportToFile = (
  exportData: ExportData,
  type: ExportFileType = {
    type: 'application/pdf',
  },
  fileName: string = 'export',
): void => {
  if (exportData instanceof Blob) {
    FileSave(exportData, fileName);
  } else {
    const blob = new Blob(exportData, type);
    FileSave(blob, fileName);
  }
};

export const loadExport = (
  selection: Array<number | string>,
  type: exportType,
  mimeType: MimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetl',
) => {
  const headers = {
    Accept: mimeType,
  };
  return axios.post(
    '/export',
    {
      selection,
      type,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadExportAll = (
  type: exportType,
  mimeType: MimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetl',
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/export/all',
    {
      type,
    },
    { headers, responseType: 'blob' },
  );
};
