export default class Socket {
  private ws: WebSocket | undefined;

  public static newInstance() {
    return new Socket();
  }

  public init(WS: string) {
    if (this.ws) {
      this.ws.close();
    }
    this.ws = new WebSocket(WS);
  }

  public close() {
    if (this.ws) {
      this.ws.close();
    }
  }

  public on(cb: any) {
    if (!this.ws) {
      return;
    }
    this.ws.onmessage = event => {
      cb(JSON.parse(event.data));
    };
  }

  public send(data: object) {
    if (!this.ws) {
      return;
    }
    this.ws.send(JSON.stringify(data));
  }

  public onConnected(cb: Function) {
    if (!this.ws) {
      return;
    }
    this.ws.addEventListener('open', () => {
      cb();
    });
  }

  public onClosed(cb: Function) {
    if (!this.ws) {
      return;
    }
    this.ws.onclose = () => { cb(); };
  }
}
