import React, {
  FC, memo, useEffect, useState,
} from 'react';
import {
  CardMedia,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { generateLabel } from '../../../utils/helpers';
import Status from '../../LockStatus';
import LockType from '../../LockType';
import { BaseProperty as Property } from '../../../types/Property';
import useStyles from './styles';
import useStatuses from '../../../hooks/useStatuses';
import AdjustableHourTours from './AdjustableTourHours';
import silverLock from '../../../assets/img/silver_lock.jpg';
import bronzeLock from '../../../assets/img/bronze_lock.jpg';
import blackLock from '../../../assets/img/black_lock.jpg';
import lockBox from '../../../assets/img/lockbox.jpg';
import hubImage from '../../../assets/img/hub_image.png';
import alarmImage from '../../../assets/img/alarm_image.png';
import kwiksetImage from '../../../assets/img/kwikset.png';
import useStates from '../../../hooks/useStates';

type PropertyInfoProps = {
  property: Property;
  handleTourHoursAlert: (alert: {
    isOutsideTourHours: boolean;
    isToursUnavailable: boolean;
  }) => void
};

const PropertyInfo: FC<PropertyInfoProps> = (props: PropertyInfoProps) => {
  const {
    property,
    handleTourHoursAlert,
  } = props;

  const classes = useStyles();
  const states = useStates();
  const statuses: { status_id: string; status: string }[] = useStatuses(
    'property',
  );

  const [image, setImage] = useState<string>('');

  const lockImage: { [key: string]: any } = {
    Lockbox: lockBox,
    Jorge: bronzeLock,
    Lockly: silverLock,
    Igloohome: blackLock,
  };

  const canadianProvinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK'];
  const propertyState = states.find(state => state.state_id === property.state);

  useEffect(() => {
    if (property.lock) {
      if (property.lock.manufacturer !== 'Igloohome' || property.lock_serial_number.slice(0, 3) !== 'IGK') {
        return setImage(lockImage[property.lock.manufacturer]);
      }
      setImage(lockImage.Lockbox);
    }

    if (property.smartLock) {
      // todo create image here
      setImage(lockImage.Lockbox);
    }

    if (property.hub) {
      switch (property.hub.hub_type.toLowerCase()) {
        case 'adc':
          setImage(alarmImage);
          break;

        case 'kwikset':
          setImage(kwiksetImage);
          break;

        default:
          setImage(hubImage);
          break;
      }
    }
  }, [property.lock, property.hub, property.lock_serial_number, lockImage, property.smartLock]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <span className={classes.label}>
            {generateLabel('Disposition')}
:
            {' '}
          </span>
          {' '}
          <Status
            value={
              statuses.find(item => item.status_id === property.propertyStatusId)?.status ?? ''
            }
          />
        </Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1">
            <span className={classes.label}>
              {generateLabel('Lock status')}
:
              {' '}
            </span>
            {' '}
            <Status value={property.lock?.lockStatus ?? ''} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1">
            <span className={classes.label}>
              {generateLabel('Lock type')}
:
              {' '}
            </span>
            {' '}
            <LockType value={property.lock?.manufacturer ?? ''} />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1">
            <span className={classes.label}>
              {generateLabel('Builder ')}
:
              {' '}
            </span>
            {' '}
            {property?.client?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography variant="body1" className={`${canadianProvinces.includes(property.state?.toUpperCase() as string) && classes.canada}`}>
            <span className={classes.label}>
              {generateLabel('State/Province ')}
:
              {' '}
            </span>
            {' '}
            {propertyState?.state_name ?? ''}
          </Typography>
        </Grid>
        {property.url_1 && (
          <Grid item xs={12} sm={12}>
            <Typography variant="body1">
              <span className={classes.label}>
                {generateLabel('Url')}
:
                {' '}
                <Link href={property.url_1} target="_blank" rel="noopener">
                  Go
                </Link>
              </span>
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Typography variant="body1">
            <span className={classes.label}>
              {generateLabel('Timezone')}
:
              {' '}
            </span>
            {' '}
            {typeof property.timezone === 'object'
              ? property.timezone?.value
              : property.timezone}
          </Typography>
        </Grid>
        {property && <AdjustableHourTours property={property} handleTourHoursAlert={handleTourHoursAlert} />
//           : (
//             <Grid>
//               <Grid>
//                 <Typography variant="body1">
//                   <span className={classes.label}>
//                     {generateLabel('Sunrise')}
// :
//                     {' '}
//                   </span>
//                   {' '}
//                   {property?.sunParams?.sunrise ?? ''}
//                 </Typography>
//               </Grid>
//               <Grid>
//                 <Typography variant="body1">
//                   <span className={classes.label}>
//                     {generateLabel('Sunset')}
// :
//                     {' '}
//                   </span>
//                   {' '}
//                   {property?.sunParams?.sunset ?? ''}
//                 </Typography>
//               </Grid>
//               {property.sunParams?.isBeforeSunrise && (
//                 <Alert text="IS BEFORE SUNRISE!" label="Sunrise is" condition={property?.sunParams?.untilSunrise} property={property} />
//               )}
//               {property.sunParams?.isDuringDaytime && (
//                 <Grid>
//                   <Typography variant="body1">
//                     <span className={classes.label}>
//                       {generateLabel('Sunset is')}
// :
//                       {' '}
//                     </span>
//                     {' '}
//                     {property?.sunParams?.untilSunset ?? ''}
//                   </Typography>
//                 </Grid>
//               )}
//               {property.sunParams?.isAfterSunset && (
//                 <Alert text="IS AFTER SUNSET!" label="Sunrise is" condition={property?.sunParams?.untilSunrise} property={property} />
//               )}
//             </Grid>
//           )
        }

      </Grid>
      {property.lock && (
      <Grid item xs={3} sm={3}>
        <>
          <CardMedia
            className={classes.lockPicture}
            image={image}
          />
        </>
      </Grid>
      )}
      {property.hub?.serial_number && (
      <Grid item xs>
        <>
          <CardMedia
            className={classes.lockPicture}
            image={image}
          />
        </>
      </Grid>
)}
    </Grid>
  );
};

export default memo(PropertyInfo);
