import React from 'react';
import {
  Drawer, List, Grid,
} from '@material-ui/core';
import clsx from 'clsx';
// commented it out, accordingly to the Barrett comment
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import { ReactComponent as Logo } from '../../assets/menu.svg';
// import { ReactComponent as DashboardIcon } from '../../assets/dashboardIcon.svg';
import { ReactComponent as ShipmentIcon } from '../../assets/shipmentTrackIcon.svg';
import { ReactComponent as CustomersIcon } from '../../assets/customersIcon.svg';
import { ReactComponent as InventoryIcon } from '../../assets/inventoryIcon.svg';
import { ReactComponent as VisitsIcon } from '../../assets/visitsSideBarIcon.svg';
import { ReactComponent as PropertiesIcon } from '../../assets/propertiesIcon.svg';
import { ReactComponent as ProfitCenterIcon } from '../../assets/profitCenterIcon.svg';
import { ReactComponent as EnterpriseIcon } from '../../assets/enterpriseIcon.svg';
import { ReactComponent as IntegrationIcon } from '../../assets/integrationsIcon.svg';
import { ReactComponent as CommunityIcon } from '../../assets/community.svg';
import { ReactComponent as DeviceIcon } from '../../assets/router-device.svg';
import { ReactComponent as HubAccountIcon } from '../../assets/hubAccount.svg';
// import { ReactComponent as InsightsIcon } from '../../assets/insightsIcon.svg';
import { ReactComponent as WorkbenchIcon } from '../../assets/workbenchIcon.svg';
// import { ReactComponent as AccountIcon } from '../../assets/accountIcon.svg';
import SmallLogoIcon from '../Icons/SmallLogoIcon';
import MenuItem from './MenuItem';
import useStyles from './styles';

type Props = {};
type SideBarMenuItem = {
  label: string;
  icon: React.ElementType;
  path: string;
};

const menuItems: SideBarMenuItem[] = [
  // { label: 'Dashboard', icon: DashboardIcon, path: '/dashboard' },
  { label: 'Customers', icon: CustomersIcon, path: '/customers' },
  { label: 'Properties', icon: PropertiesIcon, path: '/properties' },
  { label: 'Inventory', icon: InventoryIcon, path: '/inventory' },
  { label: 'Smart Inventory', icon: InventoryIcon, path: '/smart-inventory' },
  { label: 'Shipments', icon: ShipmentIcon, path: '/shipments' },
  { label: 'Clients', icon: ProfitCenterIcon, path: '/clients' },
  { label: 'Enterprises', icon: EnterpriseIcon, path: '/enterprises' },
  { label: 'Devices', icon: DeviceIcon, path: '/devices' },
  { label: 'Hub Accounts', icon: HubAccountIcon, path: '/accounts' },
  { label: 'Users', icon: CustomersIcon, path: '/users' },
  { label: 'Visits', icon: VisitsIcon, path: '/visits' },
  { label: 'Trouble Tickets', icon: IntegrationIcon, path: '/tickets' },
  { label: 'Communities', icon: CommunityIcon, path: '/communities' },
  { label: 'Util', icon: WorkbenchIcon, path: '/util' },
  { label: 'Ezlo Tool', icon: () => <DevicesOtherIcon fontSize="large" />, path: '/ezlo-tool' },
  // { label: 'Integrations', icon: IntegrationIcon, path: '/integrations' },
  // // { label: "Insights", icon: InsightsIcon, path: "/insights" },
  // { label: 'Workbench', icon: WorkbenchIcon, path: '/workbench' },
  // { label: "Account", icon: AccountIcon, path: "/account" }
];

const Sidebar: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const handleBarOpen = () => {
    setOpen(true);
  };

  const handleBarClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      variant="permanent"
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
      onMouseEnter={handleBarOpen}
      onMouseLeave={handleBarClose}
    >
      <Grid className={classes.logoWrapper} container justify="center" alignItems="center">
        <Grid item>
          {open ? (
            <Logo />
          )
            : (
              <SmallLogoIcon
                width={25}
                height={47}
                classes={{ root: classes.smallLogo }}
              />
            )
          }
        </Grid>
      </Grid>
      <List className={classes.menuItems}>
        {menuItems.map(({ label, path, icon }) => (
          <MenuItem key={path} label={label} path={path} icon={icon} />
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
