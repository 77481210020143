import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';

import SmallCard from '../../components/SmallCard';
import { PropertiesStatsQuery } from '../../redux/types/stats';
import { Query } from '../../utils/query';

type Props = {
  loadPropertiesStats: (query: PropertiesStatsQuery) => void;
  loadAllVisits: (query: Query) => void;
  loadContractorsCount: () => void;
  builderId: number;
  propertiesActiveCount: number;
  propertiesSoldCount: number;
  allVisitsCount: number;
  contractorsServed: number;
  loadingStats: boolean;
};

const Stats: React.FC<Props> = props => {
  const {
    loadPropertiesStats,
    loadAllVisits,
    builderId,
    propertiesActiveCount,
    propertiesSoldCount,
    allVisitsCount,
    contractorsServed,
    loadingStats,
    loadContractorsCount,
  } = props;

  useEffect(() => {
    if (builderId !== 0) {
      loadPropertiesStats({ status: 'active', builderId });
      loadPropertiesStats({ status: 'sold', builderId });
      loadAllVisits({ builderId });
      loadContractorsCount();
    }
  }, [loadPropertiesStats, loadAllVisits, loadContractorsCount, builderId]);

  return (
    <Zoom in={!loadingStats}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <SmallCard
            title="Inventory"
            content={`${propertiesActiveCount} active locks`}
            icon="lock_open"
            color="grey"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SmallCard
            title="Total Visits"
            content={allVisitsCount}
            icon="person"
            color="white"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SmallCard
            title="Houses Sold"
            content={propertiesSoldCount}
            icon="home"
            color="grey"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SmallCard
            title="Contractors Served"
            content={contractorsServed}
            icon="build"
            color="orange"
          />
        </Grid>
      </Grid>
    </Zoom>
  );
};

export default React.memo(Stats);
