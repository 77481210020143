import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  label: {
    fontWeight: 'bold',
  },

  canada: {
    color: 'red',
    '& > span': {
      color: '#000',
    },
  },

  iconWrapper: {
    textAlign: 'center',
    margin: '15px 0 31px',
    width: '100%',
  },
  icon: {
    height: 250,
    fontSize: 114,
    backgroundSize: 'contain',
    color: '#DB5C0E',
    display: 'block',
  },

  lockPicture: {
    display: 'block',
    height: 250,
    width: '100%',
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important',
  },

  makeStylesIcon: {
    color: '#DB5C0E',
    height: '250px',
    display: 'block',
    'font-size': '114px',
    'background-size': 'contain',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
