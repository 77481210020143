import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pointer: {
    cursor: 'pointer',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
