import { TimezoneCode } from '../utils/helpers';
import { CustomerVisit } from './Visit';

export class BaseCustomer {
  customer_id: number = 0;

  firstname: string = '';

  lastname: string = '';

  cell_phone_1: string = '';

  visitor_type_id?: number = 122;

  total_visits?: number;

  created_on: string = '';

  modified_on: string = '';
}

export type Catalog = {
  catalog_id: TimezoneCode;
  value?: string;
};


export type Status = {
  value: string;
};

export type State = {
  state_id: string;
  state_name: string;
};

export interface PartialLock {
  status?: { status_id?: string },
  lockStatus: string,
  manufacturer: string,
}

export interface PartialSmartLock {
  status?: { status_id?: string },
  lockStatus: string,
}


export class Customer extends BaseCustomer {
  last_status?: string = '';

  last_property?: number;

  customerStatus?: string = '';

  visitor_contact_status_id?: number;

  last_visit?: CustomerVisit;

  platform?: string = '';

  status: Status = { value: '' };

  catalog: Catalog = { catalog_id: 0, value: '' };
}
