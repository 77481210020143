import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  inputRoot: {
    '& .MuiFilledInput-root': {
      '&:-internal-autofill-selected ': {
        backgroundColor: theme.appPalette.orange,
      },
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: theme.appPalette.orange,
    },
    '& label.Mui-focused, label.MuiFormLabel-root': {
      color: theme.appPalette.orange,
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
