import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import Community from '../../../types/Community';
import BuilderSearch from '../../../components/BulderSearch/BuilderSearch';
import Builder from '../../../types/Builder';
import CustomButton from '../../../components/CustomButton';
import { beautifyErrors, ValidationErrors } from '../../../utils/helpers';
import { CREATE_COMMUNITY, GET_AGENTS_BY_COMMUNITY_ID } from '../../../graphql/queries/Communities';
import { navTo } from '../../../browserHistory';
import StatesSelect from '../../../components/StatesSelect';
import { UserType } from '../../../types/User';
import { MenuItem } from '@material-ui/core';

type Props = {
  community: Community;
  setCommunity: (value: Community) => void;
  communityId: string;
};

const CommunityInfoCard: React.FC<Props> = props => {
  const { community, setCommunity, communityId } = props;
  const [oldId] = useState(communityId);
  const classes = useStyles();
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Community>
  >({});
  const { enqueueSnackbar } = useSnackbar();
  const [agents, setAgents] = useState<UserType[]>([])

  const handleCommunityChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      setCommunity({
        ...community,
        [name]: name === 'id' ? Number(value) : value,
      });
    }
  };
  const editIdHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(Number(event.target.value))) {
      return event.preventDefault();
    }

    handleCommunityChange(event);
  };

  const handleBuilderChange = (builder: Builder) => {
    setCommunity({
      ...community,
      builder_id: builder.builder_id,
    });
  };

  const { data: agentsData } = useQuery<{agents: UserType[]}> (GET_AGENTS_BY_COMMUNITY_ID,
    { variables: { id: community.id }, skip: !community.builder_id }
  );

  useEffect(()  => {
    if (agentsData) {
      setAgents(agentsData.agents)
    }
  }, [agentsData])

  const [updateCommunity] = useMutation<{ updateCommunity: boolean }>(
    CREATE_COMMUNITY,
    { errorPolicy: 'all' },
  );

  const handleCommunitySave = () => {
    try {
      setValidationErrors({});
      updateCommunity({
        variables: {
          community: {
            ...community,
            oldId: Number(oldId),
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateCommunity) {
            enqueueSnackbar('Success!', { variant: 'success' });

            navTo('/communities');
          }
        })
        .catch(error => enqueueSnackbar(`An error occurred! ${error}`, { variant: 'error' }));
    } catch (errors) {
      setValidationErrors(beautifyErrors<Community>(errors));
    }
  };

  return (
    <div className={classes.content}>
      <Grid container>
        {communityId !== 'add' && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="id"
              name="id"
              label="Community Id"
              value={community?.id || ''}
              onChange={editIdHandler}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="name"
            name="name"
            label="Community Name"
            value={community.name}
            onChange={handleCommunityChange}
            error={!!validationErrors.name}
            helperText={validationErrors.name ? 'Name field is required' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="city"
            name="city"
            label="City"
            value={community.city}
            onChange={handleCommunityChange}
            error={!!validationErrors.city}
            helperText={validationErrors.city ? 'City field is required' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <StatesSelect
            margin="normal"
            fullWidth
            id="state"
            label="State/Province"
            name="state"
            select
            value={community.state}
            onChange={handleCommunityChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="zip"
            name="zip"
            label="Zip"
            value={community.zip}
            onChange={handleCommunityChange}
            error={!!validationErrors.zip}
            helperText={validationErrors.zip ? 'Zip field is required' : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="sales_agent"
            name="sales_agent"
            label="Sales Contact #"
            value={community.sales_agent}
            onChange={handleCommunityChange}
            error={!!validationErrors.sales_agent}
            helperText={validationErrors.sales_agent ? 'Sales Contact is required' : ''}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
              <TextField
                select
                required
                fullWidth
                margin="normal"
                id="emergency_contacts"
                name="emergency_contacts"
                label="Point Person to relay concerns"
                value={community?.emergency_contacts || ''}
                onChange={handleCommunityChange}
                error={Boolean(validationErrors.emergency_contacts)}
                helperText={validationErrors.emergency_contacts}
              >
                <MenuItem disabled>Available Users</MenuItem>
                {agents.map(
                  ({
                    username: agentsId,
                    firstname: FirstName,
                    lastname: LastName,
                  }) => (
                    <MenuItem key={agentsId} value={agentsId}>
                      {FirstName}
                      {' '}
                      {LastName}
                    </MenuItem>
                  ),
                )}
              </TextField>
            </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="external_community_id"
            name="external_community_id"
            label="External Community Id"
            value={community.external_community_id}
            onChange={handleCommunityChange}
            error={!!validationErrors.external_community_id}
            helperText={
              validationErrors.external_community_id
                ? 'External Community Id field is required'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '11px' }}>
          <BuilderSearch
            onBuilderChange={handleBuilderChange}
            initialValue={community.builder_id ?? undefined}
          />
        </Grid>
        <Grid container xs={12} sm={12} justify="center">
          <CustomButton
            variant="orange"
            onClick={handleCommunitySave}
            style={{ marginTop: '50px' }}
          >
            Save
          </CustomButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommunityInfoCard;
