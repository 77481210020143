import {
  AUTH_USER_ERROR,
  AUTH_USER_SUCCESS,
  AUTH_USER_REQUEST,
  AuthState,
  AUTH_USER_LOGOUT,
  GET_OAUTH_CLIENTS_REQUEST,
  GET_OAUTH_CLIENTS_ERROR,
  GET_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_SUCCESS,
  REMOVE_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  UPDATE_PROFILE_REQUEST_SUCCESS,
} from '../types/auth';
import { AuthActionsTypes } from '../actions/auth';
import * as storage from '../../services/storage';

export const initialState: AuthState = {
  token: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  builderId: 0,
  isPM: false,
  isAdmin: false,
  error: '',
  loading: false,
  oauthClients: [],
};

function authReducer(
  state = initialState,
  action: AuthActionsTypes,
): AuthState {
  switch (action.type) {
    case AUTH_USER_REQUEST:
    case CREATE_OAUTH_CLIENTS_REQUEST:
    case REMOVE_OAUTH_CLIENTS_REQUEST:
    case GET_OAUTH_CLIENTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case AUTH_USER_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        builderId: action.payload.builderId,
        isPM: action.payload.isPM,
        isAdmin: action.payload.isAdmin,
        error: '',
        loading: false,
      };
    }

    case GET_OAUTH_CLIENTS_ERROR:
    case AUTH_USER_ERROR: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case GET_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: action.payload.items,
      };
    }

    case CREATE_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: state.oauthClients.concat(action.payload),
      };
    }

    case REMOVE_OAUTH_CLIENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        oauthClients: state.oauthClients.filter(client => client.id !== action.payload),
      };
    }

    case UPDATE_PROFILE_REQUEST_SUCCESS: {
      const data = JSON.parse(storage.getItem('auth_user'));
      storage.addItem('auth_user', JSON.stringify({
        ...data,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
      }));
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        error: '',
        loading: false,
      };
    }


    case AUTH_USER_LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}

export default authReducer;
