import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  tableRoot: {
    minWidth: 1230,
    zIndex: 2,
  },
  tableContainer: {
    overflow: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    scrollBehavior: 'smooth',
  },
  rowRoot: {
    '&:hover > td': {
      color: theme.appPalette.orange,
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
