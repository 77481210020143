/* eslint-disable no-underscore-dangle */
import React, { useRef, memo } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MatTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TableHead from './TableHead';
import useStyles from './styles';
import { formatDate } from '../../../utils/date';
import { Order, isSelected } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import {
  DeviceType,
} from '../../../types/Devices';
import { HubAccountType } from '../../../types/HubAccounts';


type Props = {
  order: Order;
  orderBy: string;
  hubAccounts: HubAccountType[];
  onRowClick: (id: HubAccountType['id']) => void;
  onSelect: (event: React.SyntheticEvent, id: DeviceType['id']) => void;
  selected: Array<HubAccountType['id']>;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
};

const Table: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    hubAccounts, onChangeOrder, order, orderBy,
    onRowClick, onSelect, selected, onSelectAll,
  } = props;
  const tableContainer = useRef<HTMLDivElement>(null);

  function handleRequestSort(
    event: React.SyntheticEvent,
    lock: string,
  ) {
    event.preventDefault();
    const isDesc = orderBy === lock && order === 'desc';
    onChangeOrder(isDesc ? 'asc' : 'desc', lock);
  }

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={18} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          order={order}
          orderBy={orderBy}
          rowCount={hubAccounts?.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {hubAccounts.map((hubAccount, index) => {
            const isItemSelected: boolean = isSelected(hubAccount.id, selected);
            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={hubAccount.id}
                tabIndex={-1}
                onClick={event => { event.stopPropagation(); onRowClick(hubAccount.id); }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    // inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, hubAccount.id);
                    }}
                  />
                </TableCell>
                <TableCell align="center">{hubAccount.id}</TableCell>
                <TableCell align="center">{hubAccount.username}</TableCell>
                <TableCell align="center">{hubAccount.email}</TableCell>
                <TableCell align="center">{hubAccount.adc_group_id}</TableCell>
                <TableCell align="center">{hubAccount.created_on ? formatDate(hubAccount.created_on) : ''}</TableCell>
                <TableCell align="center">{hubAccount.created_by}</TableCell>
                <TableCell align="center">{hubAccount.modified_on ? formatDate(hubAccount.modified_on) : ''}</TableCell>
                <TableCell align="center">{hubAccount.modified_by}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>

      </MatTable>
    </TableContainer>
  );
};

export default memo(Table);
