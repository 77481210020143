import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    padding: 20,
    boxShadow: theme.shadows[3],
    borderRadius: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentText: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 55,
  },
  orange: {
    background: 'linear-gradient(65.75deg, #DB5C0E 0.06%, #FFA166 98.73%)',
    color: 'white',
  },
  grey: {
    background: '#8E8B99',
    color: 'white',
  },
  white: {
    background: '#FEFEFE',
    color: 'black',
  },
});
