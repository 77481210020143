import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Order } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
    {
      id: 'id',
      align: 'center',
      disablePadding: false,
      label: 'Lock Serial Number',
    },
    {
      id: 'cost',
      align: 'center',
      disablePadding: false,
      label: 'Cost',
    },
    {
      id: 'qty',
      align: 'center',
      disablePadding: false,
      label: 'Quantity',
    },
    {
      id: 'carrier',
      align: 'center',
      disablePadding: false,
      label: 'Carrier',
    },
    {
      id: 'date',
      align: 'center',
      disablePadding: false,
      label: 'Date',
    },
    {
      id: 'status',
      align: 'center',
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'tracking_number',
      align: 'center',
      disablePadding: false,
      label: 'Tracking number',
    },
    {
      id: 'builder.name',
      align: 'center',
      disablePadding: false,
      label: 'Builder',
    },
    {
      id: 'requested_by',
      align: 'center',
      disablePadding: false,
      label: 'Requested by',
    },
  ];

const TableHead: React.FC<Props> = props => {
  const {
    onSelectAllClick, onRequestSort, orderBy, order, numSelected, rowCount,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all shipments' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}

          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
