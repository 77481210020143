import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
  colorPrimary: {
    backgroundColor: '#F4D18C',
  },
  barColorPrimary: {
    backgroundColor: theme.appPalette.orange,
  },
});
