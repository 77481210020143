import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Button, Paper } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import Community from '../../types/Community';
import BuilderSearch from '../BulderSearch/BuilderSearch';
import Builder from '../../types/Builder';
import CustomButton from '../CustomButton';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { CREATE_COMMUNITY } from '../../graphql/queries/Communities';
import { navTo } from '../../browserHistory';
import StatesSelect from '../StatesSelect';
import { communityInputCreateSchema } from './communityValidation';

const CommunityInput = () => {
  const classes = useStyles();
  const [community, setCommunity] = useState<Community | any>({});
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Community>
  >({});
  const { enqueueSnackbar } = useSnackbar();
  const digitCodes = ['nternow_hub_backup_code', 'community_backup_code', 'sales_code', 'trade_code'];

  const handleCommunityChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      if (digitCodes.includes(name)) {
        setCommunity({
          ...community,
          [name]: value.replace(/\D/g,''),
        });
      } else {
        setCommunity({
          ...community,
          [name]: name === 'id' ? Number(value) : value,
        });
      }
    }
  };

  const handleBuilderChange = (builder: Builder) => {
    setCommunity({
      ...community,
      builder_id: builder.builder_id,
    });
  };

  const [updateCommunity] = useMutation<{ updateCommunity: boolean }>(
    CREATE_COMMUNITY,
    { errorPolicy: 'all' },
  );

  const handleCommunitySave = () => {
    try {
      const validCommunity = communityInputCreateSchema.validateSync(
        community,
        { abortEarly: false },
      );
      setValidationErrors({});
      updateCommunity({
        variables: {
          community: {
            ...validCommunity,
          },
        },
      })
        .then(({ data }) => {
          if (data?.updateCommunity) {
            enqueueSnackbar('Success!', { variant: 'success' });

            navTo('/communities');
          }
        })
        .catch(error => enqueueSnackbar(`An error occurred! ${error}`, { variant: 'error' }));
    } catch (errors) {
      setValidationErrors(beautifyErrors<Community>(errors));
    }
  };

  return (
    <>
      <div className={classes.actionsBar}>
        <Paper elevation={3}>
          <Button>
            <ArrowLeftIcon />
          </Button>
          <Button>
            <ArrowRightIcon />
          </Button>
        </Paper>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/communities"
          >
            <ArrowLeftIcon />
            Communities
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>

      <div className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              margin="normal"
              id="name"
              name="name"
              label="Community Name"
              value={community?.name ?? ''}
              onChange={handleCommunityChange}
              error={!!validationErrors.name}
              helperText={validationErrors.name ? 'Name field is required' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              margin="normal"
              id="city"
              name="city"
              label="City"
              value={community?.city ?? ''}
              onChange={handleCommunityChange}
              error={!!validationErrors.city}
              helperText={validationErrors.city ? 'City field is required' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatesSelect
              required
              margin="normal"
              fullWidth
              id="state"
              label="State/Province"
              name="state"
              select
              value={community?.state ?? ''}
              onChange={handleCommunityChange}
              error={!!validationErrors.state}
              helperText={
                validationErrors.state ? 'State field is required' : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              margin="normal"
              id="zip"
              name="zip"
              label="Zip"
              value={community?.zip ?? ''}
              onChange={handleCommunityChange}
              error={!!validationErrors.zip}
              helperText={validationErrors.zip ? 'Zip field is required' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              margin="normal"
              id="external_community_id"
              name="external_community_id"
              label="External Community Id"
              value={community?.external_community_id ?? ''}
              onChange={handleCommunityChange}
              error={!!validationErrors.external_community_id}
              helperText={
                validationErrors.external_community_id
                  ? 'External Community Id field is required'
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              required
              placeholder="example: 111-111-1111"
              value={community.sales_agent ?? ''}
              fullWidth
              label="Community phone number or OSC phone number"
              name="sales_agent"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.sales_agent)}
              helperText={validationErrors.sales_agent ? 'Community phone number field is required' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: '11px' }}>
            <BuilderSearch
              required
              onBuilderChange={handleBuilderChange}
              initialValue={community?.builder_id ?? undefined}
              error={Boolean(validationErrors.builder_id)}
              helperText={validationErrors.builder_id ? 'Community phone number field is required' : ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.lasso_community_project_id ?? ''}
              fullWidth
              multiline
              label="Lasso Community Apikey OR Project Id"
              name="lasso_community_project_id"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.lasso_community_project_id)}
              helperText={validationErrors.lasso_community_project_id}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.novihome_integration ?? ''}
              fullWidth
              label="Novihome Integration"
              name="novihome_integration"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.novihome_integration)}
              helperText={validationErrors.novihome_integration}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.nternow_hub_backup_code ?? ''}
              fullWidth
              label="NterNow Hub Backup Code"
              name="nternow_hub_backup_code"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.nternow_hub_backup_code)}
              helperText={validationErrors.nternow_hub_backup_code}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.community_backup_code ?? ''}
              fullWidth
              label="Community Master Code"
              name="community_backup_code"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.community_backup_code)}
              helperText={validationErrors.community_backup_code}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.trade_code ?? ''}
              fullWidth
              label="Trade Code"
              name="trade_code"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.trade_code)}
              helperText={validationErrors.trade_code}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              margin="normal"
              value={community.sales_code ?? ''}
              fullWidth
              label="Sales Code"
              name="sales_code"
              onChange={handleCommunityChange}
              error={Boolean(validationErrors.sales_code)}
              helperText={validationErrors.sales_code}
            />
          </Grid>
          <Grid container item xs={12} sm={12} justify="center">
            <CustomButton
              style={{ marginTop: '20px' }}
              variant="orange"
              onClick={handleCommunitySave}
            >
              Add
            </CustomButton>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CommunityInput;
