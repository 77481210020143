import React from 'react';
import {
  WithStyles,
  Card,
} from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';

type Props = {
  color?: 'white' | 'grey' | 'orange';
} & WithStyles<typeof styles>

const CustomCard: React.FC<Props> = props => {
  const {
    classes,
    color,
    children,
  } = props;

  return (
    <Card className={classNames(classes.card, classes[color || 'white'])}>
      {children}
    </Card>
  );
};

export default CustomCard;
