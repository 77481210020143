import { createStyles } from '@material-ui/core';

export default () => createStyles({
    scrollIcons:{
        display:'flex',
        justifyContent:'space-between',
        width:'100%',
        padding:'0 45px',
        position:'fixed',
        alignSelf:'center',
        marginTop: '1em',
        color: 'rgba(0, 0, 0, 0.54)',
    },
})