/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import MatTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import TableHead from './TableHead';
import styles from './VisitsTableStyles';
import { Order, isSelected } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import { CustomerVisit } from '../../../types/Visit';
import { changeVisitStatus, convertDateToTimeWithTimezone, convertDateWithTimezone } from '../../../utils/helpers';

type Props = {
  order: Order;
  orderBy: string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: CustomerVisit['customer_visit_id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
  visits: Array<CustomerVisit>;
  selected: Array<CustomerVisit['customer_visit_id']>;
  onRowClick: (id: CustomerVisit['customer_visit_id']) => void;
} & WithStyles<typeof styles>;

const VisitsTable: React.FC<Props> = props => {
  const {
    selected,
    visits,
    classes,
    order,
    orderBy,
    onSelect,
    onSelectAll,
    onChangeOrder,
    onRowClick,
  } = props;


  function handleRequestSort(
    event: React.SyntheticEvent,
    property: string,
  ) {
    event.preventDefault();
    const isDesc = orderBy === property && order === 'desc';
    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={11} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          order={order}
          orderBy={orderBy}
          rowCount={visits.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {visits.map((visit: CustomerVisit, index) => {
            const isItemSelected: boolean = isSelected(visit.customer_visit_id, selected);
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const visitStatus = changeVisitStatus(visit.customer?.status?.value);

            return (
              <TableRow
                key={visit.customer_visit_id}
                classes={{ root: classes.rowRoot }}
                hover
                tabIndex={-1}
                onClick={event => { event.stopPropagation(); onRowClick(visit.customer_visit_id); }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, visit.customer_visit_id);
                    }}
                  />
                </TableCell>
                {/* // className={statusClassName} */}
                <TableCell align="left">{visit.customer?.firstname ?? ''}</TableCell>
                <TableCell align="left">{visit.customer?.lastname ?? ''}</TableCell>
                <TableCell align="right">{visit.customer?.cell_phone_1 ?? ''}</TableCell>
                <TableCell align="center">{visit.customer?.catalog?.value ?? ''}</TableCell>
                <TableCell align="center">{visitStatus}</TableCell>
                <TableCell align="center">
                  {visit.property ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/properties/${visit.property.property_id}`}>{visit.property.property_id}</Link>
                    : ''}
                </TableCell>
                <TableCell align="right">{visit.property?.subdivision ?? ''}</TableCell>
                <TableCell align="right">{`${visit.property?.house_num ?? ''} ${visit.property?.address_1 ?? ''}`}</TableCell>
                <TableCell align="right">{visit?.platform ?? ''}</TableCell>
                <TableCell align="right">
                  {visit.created_on ? convertDateWithTimezone(
                    new Date(visit.created_on),
                    visit.property?.timezoneCatalog?.catalog_id,
                  )
                    : ''}
                  {'  '}
                  {visit.created_on ? convertDateToTimeWithTimezone(
                    new Date(visit.created_on),
                    visit.property?.timezoneCatalog?.catalog_id,
                  )
                    : ''}
                  {`  ${visit.property?.timezoneCatalog?.value}`}
                </TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(VisitsTable);
