import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    height: '50px',
    position: 'relative',
  },
  orange: {
    color: '#DB5C0E',
  },
  gray: {
    color: '#B6B6B6',
  },
  black: {
    color: '#040620',
  },
  white: {
    color: '#FFFFFF',
  },
  title: {
    fontSize: '22px',
  },
  subTitle: {
    fontSize: '16px',
    marginLeft: '26px',
  },
});
