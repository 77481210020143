import { createStyles, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';

const styles = () => createStyles({
  adornment: {
    color: red[500],
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
