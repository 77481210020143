import React from 'react';
import classNames from 'classnames/bind';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { WithStyles } from '@material-ui/core';
import CardHeader from '../CustomCardHeader';

import styles from './styles';

type Props = {
  content: string | number;
  title?: string;
  subtitle?: string;
  color?: 'white' | 'grey' | 'orange';
  icon?: string | React.ElementType;
} & WithStyles<typeof styles>;

const SmallCard: React.FC<Props> = props => {
  const {
    classes,
    title,
    subtitle,
    content,
    icon: CardIcon,
    color = 'white',
  } = props;

  const cx = classNames.bind(classes);
  const classname = cx({
    card: true,
    orange: color === 'orange',
    grey: color === 'grey',
    white: color === 'white',
  });
  // eslint-disable-next-line no-nested-ternary
  const cardIcon = !CardIcon ? null : typeof CardIcon === 'string' ? (
    <Icon className={classes.icon}>{CardIcon}</Icon>
  ) : (
      <CardIcon className={classes.icon} />
    );

  return (
    <div className={classname}>
      {(title || subtitle) && <CardHeader title={title} subtitle={subtitle} />}
      <Grid container justify="space-between" alignItems="flex-end">
        <Grid item>
          <Typography className={classes.contentText}>{content}</Typography>
        </Grid>
        {cardIcon && <Grid item>{cardIcon}</Grid>}
      </Grid>
    </div>
  );
};

export default SmallCard;
