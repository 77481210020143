import { gql } from 'apollo-boost';
import { ClientClass } from '../../types/Client';

const clientFields = Object.keys(new ClientClass()).filter(e => !['users', 'builderIntegrations', 'builderSettings'].includes(e));

export const GET_CLIENT = gql`
  query($builder_id: Int!) {
    builder(builder_id: $builder_id) {
      ${clientFields.join('\n')},
      builder_timezone,
      company_code
      users {
        username
      }
      builderIntegrations {
        id,
        isActive,
        integration {
          id,
          name
        },
        builderIntegrationAttribute {
          id,
          name, 
          value
        }
      }
       builderSettings {
        webflow_name
      }
    }
  }
`;

export const GET_INTEGRATIONS = gql`
query{
  integrations {
    id,
    name,
    isActive
  }
}
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient($builder: UpdateBuilder!,$integrations: [IntegrationInput!]!, $integrationAttributes: [IntegrationAttribute!]!, $builderSettings: BuilderSettings!) {
    updateBuilder(builder: $builder, integrations: $integrations, integrationAttributes: $integrationAttributes, builderSettings: $builderSettings) {
      builder_id
    }
  }
`;
