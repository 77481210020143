import React, {
    Fragment, useCallback, useEffect, useState,
  } from 'react';
  import { Link, RouteComponentProps } from 'react-router-dom';
  import {Grid, MenuItem} from '@material-ui/core';
  import Button from '@material-ui/core/Button';
  import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
  import { useMutation, useQuery } from '@apollo/react-hooks';
  import { Paper, TextField } from '@material-ui/core';
  import PropertySearch from '../../components/PropertySearch/PropertySearch';
  import { useSnackbar } from 'notistack';
  import { gql } from 'apollo-boost';
  import useStyles from './styles';
  import {DEVICE_BY_ID, UPDATE_DEVICE} from '../../graphql/queries/Devices'
  import CustomTextField from '../../components/CustomTextField/CustomTextField';
  import {
    beautifyErrors,
    ValidationErrors,
  } from '../../utils/helpers';
  import CustomButton from '../../components/CustomButton';
  import BuilderSearch from '../../components/BulderSearch/BuilderSearch';
  import Builder from '../../types/Builder';
  import InputDevice from '../../types/inputTypes/Device';
  import { BaseProperty as Property } from '../../types/Property';
  import useProperty from '../../hooks/useProperty';
  import { deviceCreateSchema } from '../Device/DeviceForm/DeviceValidation';
  import HubTypeSelect from '../../components/HubTypeSelect';
  import DeviceHubIcon from '@material-ui/icons/DeviceHub';
  import { goToZwaveTool } from '../../services/zwave-tool';

  type Props = {
    loading?: boolean;
  } & RouteComponentProps;

  const DeviceDetails: React.FC<Props> = props => {
    const {history} = props;
    const deviceId = history.location.pathname.split('/')[2]
    const classes = useStyles();
    const [validationErrors, setValidationErrors] = useState<ValidationErrors<InputDevice>>({});
    const [device, setDevice] = useState<InputDevice>(new InputDevice());
    const [buildersId, setBuildersId] = useState<{ name: string, id?: number }[]>([]);
    const { property } = useProperty(device.property_id);
    const [HubAccountId, setHubAccountId] = useState<{ id: string, username: string }[]>([]);

    const { enqueueSnackbar } = useSnackbar();

    const {data} = useQuery<{ getHubById: InputDevice }>(
        DEVICE_BY_ID,
        {
          variables: { serial_number: deviceId },
          fetchPolicy: 'network-only',
        },
    );

    useEffect(() => {
      if (data) {
        setDevice(data.getHubById)
      }
    }, [data])

    const [updateDevice] = useMutation<{ updateDevice: InputDevice }>(
      UPDATE_DEVICE,
      { errorPolicy: 'all' }
    );

    const GET_BUILDERIDS = gql`
    query {
      builderIds {
        builder_id,
        name
      }
    }
    `;


    const GET_HUBACCOUNT_IDS = gql`
    query {
      hubAccountIds {
        id,
        username,
      }
    }
    `;

    const { data: builderData } = useQuery<{ builderIds: Array<{ name: string, id?: number }> }>(
      GET_BUILDERIDS,
    );


    const { data: hubAccountData } = useQuery<{ hubAccountIds: Array<{ id: string, username: string }> }>(
      GET_HUBACCOUNT_IDS,
    );

    useEffect(() => {
      if (hubAccountData) {
        setHubAccountId(hubAccountData.hubAccountIds);
      }
    }, [hubAccountData, HubAccountId]);

    const handleDeviceChange = useCallback(
      ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (name) {
          setDevice((prevDevice: InputDevice) => ({
            ...prevDevice,
            [name]: value,
          }));
        }
      },
      [],
    );

    const handleDeviceSave = () => {
    try{
      const uDevice = deviceCreateSchema.validateSync(device, { abortEarly: false })
      updateDevice({
        variables: {
          device: {
            ...uDevice,
          },
    }}).then(() => {
      enqueueSnackbar('Success', { variant: 'success' });
    });
  } catch (errors) {
    enqueueSnackbar('Error', { variant: 'error' });
    setValidationErrors(beautifyErrors<InputDevice>(errors));
  }
  }

    const handleBuilderChange = (builder: Builder) => {
      setDevice((device: InputDevice) => ({
        ...device,
        property_id: 0,
        builder_id: builder.builder_id,
      }));
    }

    const handlePropertyChange = (property: Property) => {
      setDevice((device: InputDevice) => ({
        ...device,
        builder_id: property.builder_id ?? 0,
        property_id: property.property_id,
      })
      )
    }

    const handleControlHub = () => {
      history.push(`/ezlo-tool?deviceId=${deviceId}`);
    }

    const handleControlHubByZwaveInstall = () => {
      goToZwaveTool(device.hub_account_id, deviceId);
    }

    useEffect(() => {
      if (builderData) {
        setBuildersId(builderData.builderIds);
      }
    }, [builderData, buildersId]);

    const renderEzloFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderSeamFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Device Id"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderADCFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                id="adc_customer_id"
                name="adc_customer_id"
                label="ADC Customer ID"
                value={device.adc_customer_id}
                onChange={handleDeviceChange}
                helperText={validationErrors.adc_customer_id || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderKwiksetFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    return(
      <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>

          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/devices"
          >
            <ArrowLeftIcon />
            Devices
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
          <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <HubTypeSelect
              margin="normal"
              fullWidth
              id="hub_type"
              label="Hub Type"
              name="hub_type"
              select
              value={device.hub_type}
              onChange={handleDeviceChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              select
              label="Hub Account"
              name="hub_account_id"
              onChange={handleDeviceChange}
              value={device.hub_account_id}
              helperText={validationErrors.hub_account_id}
              >
                {HubAccountId.sort((a, b) => a.username.localeCompare(b.username)).map(HubAcc => (
                  <MenuItem
                    key={HubAcc.id}
                    value={HubAcc.id}
                  >
                    {HubAcc.username}
                  </MenuItem>
                ))}
             </TextField>
            </Grid>
            {(device.hub_type === 'Ezlo Secure') && renderEzloFields()}
            {(device.hub_type === 'Seam') && renderSeamFields()}
            {(device.hub_type === 'ADC') && renderADCFields()}
            {(device.hub_type === 'Kwikset') && renderKwiksetFields()}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="imei"
                name="imei"
                label="IMEI"
                value={device.imei}
                onChange={handleDeviceChange}
                helperText={validationErrors.imei || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BuilderSearch
                margin="normal"
                onBuilderChange={handleBuilderChange}
                label="Builder"
                initialValue={device.builder_id}
                helperText={validationErrors.builder_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PropertySearch
                style={{ marginTop: '0' }}
                margin="normal"
                fullWidth
                onPropertyChange={handlePropertyChange}
                helperText={validationErrors.property_id}
                //@ts-ignore
                initialValue={property?.property_id ? property : undefined}
                builderId = {device.builder_id}
             />
            </Grid>
            <Grid item xs={12} sm={6}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={4}
                      rowsMax={8}
                      label="Comments"
                      name="comments"
                      value={device.comments || ''}
                      onChange={handleDeviceChange}
                    />
              </Grid>
              <Grid item xs={12} sm={6}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={4}
                      rowsMax={8}
                      label="Descriprion"
                      name="description"
                      value={device.description || ''}
                      onChange={handleDeviceChange}
                    />
              </Grid>
            <Grid container spacing={4} justify="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid item>
                    <CustomButton variant="orange" onClick={handleDeviceSave}>
                      Update device
                    </CustomButton>
                </Grid>
              { device.hub_type === 'Ezlo Secure' && (
                  <>
                    <Grid item>
                      <CustomButton variant="orange" onClick={() => handleControlHub()}>
                        <DeviceHubIcon /> Control Hub
                      </CustomButton>
                    </Grid>
                    <Grid item>
                      <CustomButton variant="orange" onClick={() => handleControlHubByZwaveInstall()}>
                        <DeviceHubIcon /> Control Hub By Zwave Install
                      </CustomButton>
                    </Grid>
                  </>
              )}
              </Grid>
              </Grid>
          </Paper>
      </Grid>
      </div>
      </Fragment>
    )
  }

  export default React.memo(DeviceDetails);
