export const CREATE_SNACKBAR = 'CREATE_SNACKBAR';
export const DELETE_SNACKBAR = 'DELETE_SNACKBAR';

export type SnackbarType = {
  id: string | number;
  message: string;
  variant: 'success' | 'error' | 'warning' | 'info';
};

export type SnackbarsState = Array<SnackbarType>;
