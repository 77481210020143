import { gql } from 'apollo-boost';

export const ALL_DEVICES = gql`
  query ($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) { 
      hubs (limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
        list {
          id, 
          description,
          serial_number,
          adc_customer_id,
          imei,
          builder_id,
          property_id,
          comments,
          created_on,
          created_by,
          modified_on,
          modified_by,
          hub_type,
          hub_account_id,
          hub_account {
            username,
          },
          builder {
            name,
          }
        }
        params {
          total
          limit
          offset
        }
      }
    }
`;


export const UPDATE_DEVICE = gql`
mutation updateHub($device: inputHub!) {
  updateHub(hub: $device) {
    id
  }
}
`;


export const DEVICE_BY_ID = gql`
  query ($serial_number: String!){ 
    getHubById(serial_number: $serial_number) {
        id, 
        description,
        serial_number,
        adc_customer_id,
        imei,
        property_id,
        hub_type,
        hub_account_id,
        builder_id,
        comments,
  }
}
`;


export const DELETE_HUBS = gql`
  mutation ($ids: [String!]!){ 
    deleteMultipleHubs(ids: $ids)
}
`;
