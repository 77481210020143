interface Styles {
  [index: string]: any,
}

const styles: Styles = {
  active: {
    color: '#04A777',
  },
  down: {
    color: '#D72638',
  },
  other: {
    color: 'inherit',
  },
};

export default styles;
