import React, {
  Fragment, useState, useCallback, useMemo, useContext, useEffect,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useMutation, useQuery } from '@apollo/react-hooks';
import CustomButton from '../../components/CustomButton';
import SearchBar from '../../components/SearchBar';
import useDebounce from '../../hooks/useDebounce';
import Spinner from '../../components/CustomSpinner';
import useStyles from './styles';
import Community from '../../types/Community';
import CommunitiesTable from './CommunitiesTable';
import { ALL_COMMUNITIES, DELETE_COMMUNITY } from '../../graphql/queries/Communities';
import { ROWS_PER_PAGE } from '../../constants/table';
import CustomTablePagination from '../../components/CustomTablePagination';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import { Order } from '../../utils/table';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect/RowsPerPageSelect';
import { loadExport, loadExportAll } from '../../services/export';
import downloadDocument from '../../utils/files';
import ConfirmDelete from '../../components/ConfirmDelete';

type Props = {
  setCommunities: (communities: Community[]) => void
} & RouteComponentProps;

const Communities: React.FC<Props> = ({
  history, match,
}) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy, order, contextSearch, currentPage, rowsPerPageAmount,
  } = state.communities;
  const [rowsPerPage] = useState<number>(ROWS_PER_PAGE);
  const [search, setSearch] = useState<string>(contextSearch);
  const [selected, setSelected] = useState<Array<Community['id']>>([]);
  const [communityToRemove, setCommunityToRemove] = useState<boolean>(false);
  const selectedLength = selected.length;
  const debouncedSearch = useDebounce(search, 1000);
  const [cursor, setCursor] = useState<{limit: number, offset: number}>({
    limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
  });
  const [sort, setSort] = useState<{sortBy: keyof Community | string, direction: 'asc' | 'desc'}>({
    sortBy: orderBy, direction: order,
  });
  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const {
    loading, data, refetch: refetchCommunities,
  } = useQuery<{communityList: Paginated<Community> }>(
    ALL_COMMUNITIES, {
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const [deleteCommunity] = useMutation<{ deleteCommunity: number[] }>(
    DELETE_COMMUNITY,
    { errorPolicy: 'all' },
  );

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'communities')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_COMMUNITY',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const handleShowAllClick = () => {
    dispatch({ type: 'RESET_STATE' });
    setSort({
      direction: 'desc',
      sortBy: 'created_on',
    });
    setSearch('');
  };

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_COMMUNITY',
      payload: { currentPage: 0, rowsPerPageAmount: e.target.value },
    });
  }, [dispatch]);

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_COMMUNITY',
      payload: { currentPage: nPage },
    });
  }, [dispatch]);

  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof Community | string) => {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ offset: 0, limit: rowsPerPageAmount });
    dispatch({
      type: 'CHANGE_COMMUNITY',
      payload: {
        orderBy: newOrderBy,
        order: newOrder,
        currentPage: 0,
      },
    });
  };

  function handleClick(
    event: React.SyntheticEvent,
    id: Community['id'],
  ) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<Community['id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data && data.communityList.list.map(i => i.id);
      setSelected(newSelected ?? []);
      return;
    }
    setSelected([]);
  };

  const handleCommunitySelect = (id: number) => {
    history.push(`${match.url}/${id}`);
  };

  const communities = data ? data.communityList.list : [];
  const params = data ? data.communityList.params : { total: 0 };

  const selectDeleteHandle = () => {
    deleteCommunity({
      variables: {
        communityIds: selected,
      },
    }).then(async () => {
      setSelected([]);
      await refetchCommunities();
    });
  };

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'communities')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'Communities', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('communities').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Communities', response.data);
    });
  }

  function handlePrint() {
    if (selectedLength !== 0) {}
  }
  const isStateInitial = JSON.stringify({
    contextSearch: state.communities.contextSearch,
    order: state.communities.order,
    orderBy: state.communities.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.communities.contextSearch,
    order: initialState.communities.order,
    orderBy: initialState.communities.orderBy,
  });

  return (
    <Fragment>
      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add Community
          </CustomButton>
        </Grid>
        { !isStateInitial && (
          <Grid item className={classes.controlButton}>
            <CustomButton
              variant="orange"
              color="primary"
              onClick={handleShowAllClick}
            >
              Show all
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder="Search Communities"
            onChange={handleSearch}
            defaultSearchValue={search}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => { setCommunityToRemove(true); }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography className={classes.tableHeaderText} variant="h5">Communities</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <CommunitiesTable
            selected={selected}
            communities={communities}
            order={sort.direction}
            orderBy={sort.sortBy}
            rowsPerPage={rowsPerPage}
            onChangeOrder={handleChangeOrder}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onRowClick={handleCommunitySelect}
          />
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <CustomTablePagination
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsTotal={params.total}
            rowsCount={communities.length}
            rowsPerPage={cursor.limit}
            currentPage={page}
            onChangePage={n => handleChangePage(undefined, n)}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
          />
        </div>
      </div>
      <ConfirmDelete
        isOpen={communityToRemove}
        onCancel={() => { setCommunityToRemove(false); }}
        onConfirm={() => { selectDeleteHandle(); setCommunityToRemove(false); }}
      />
    </Fragment>
  );
};

export default React.memo(Communities);
