import React from 'react';
import { WithStyles } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import styles from './styles';

type Props = {
  loadingStatus: boolean;
} & WithStyles<typeof styles>;

const ProgressBar: React.FC<Props> = props => {
  const {
    classes: { barColorPrimary, colorPrimary, contentWrapper },
    loadingStatus,
  } = props;

  if (loadingStatus) {
    return (
      <div className={contentWrapper}>
        <LinearProgress classes={{ barColorPrimary, colorPrimary }} />
      </div>
    );
  }
  return null;
};

export default React.memo(ProgressBar);
