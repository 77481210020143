export interface TroubleTicketInterface {
  ticket_number: number | null;
}

export default class InputTicket implements TroubleTicketInterface {
  constructor(username: string = '') {
    this.rep_name = username;
  }

  ticket_number : number | null = null;

  client: string = '';

  customer_firstname: string = '';

  customer_lastname: string = '';

  cell_phone_1: string = '';

  agent_informer : string = '';

  open_date: Date = new Date();

  lockStatus: string | undefined = 'LOCK_CREATED';

  closed_date: Date | null = null;

  closed_diagnosis: number | null = null;

  rep_name: string = '';

  issue_diagnosis: string = '';

  resolution_notes: string = '';

  property_id: number = 0;

  lock_serial_number: string = '';
}
