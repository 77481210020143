import React, { RefObject, useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';

type Props = {
  getTableContainer:()=>RefObject<HTMLDivElement>,
  columns:number
} & WithStyles<typeof styles>;

const CustomTableScroll: React.FC<Props> = props => {
  const { classes, getTableContainer, columns } = props;
  const [tableWidth, setTableWidth] = useState<number>(0);
  const [tableContainer, setContainerRef] = useState<HTMLDivElement|null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(true);

  const handleResize = () => {
    if (tableContainer) {
      setTableWidth(tableContainer.scrollWidth);
      const overflow = tableContainer.scrollWidth - tableContainer.clientWidth;
      setCanScrollLeft((tableContainer.scrollLeft !== 0));
      setCanScrollRight(tableContainer.scrollLeft !== overflow && overflow > 0);
    }
  };
  const handleKeydown = (e:any) => {
    if (e.keyCode === 39) { scrollLeft('right'); }
    if (e.keyCode === 37) { scrollLeft('left'); }
  };
  const handleScroll = (e: any) => {
    if (tableContainer) {
      const overflow = tableWidth - tableContainer.clientWidth;
      setCanScrollLeft((tableContainer.scrollLeft !== 0));
      setCanScrollRight(tableContainer.scrollLeft !== overflow && overflow > 0);
    }
  };
  const scrollLeft = (direction:'left'|'right') => {
    if (tableContainer) {
      const delta = tableContainer.scrollWidth / columns;
      tableContainer.scrollLeft += (direction === 'right' ? delta : -delta);
    }
  };
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const direction = e.currentTarget.id as 'left'|'right';
    scrollLeft(direction);
  };

  useEffect(() => {
        tableContainer?.addEventListener('scroll', handleScroll);
        return () => {
            tableContainer?.removeEventListener('scroll', handleScroll);
        };
  });
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeydown);
    };
  });
  useEffect(() => {
    if (tableContainer) {
      setCanScrollRight(!(tableContainer.clientWidth === tableWidth));
    }
  }, [tableContainer, tableWidth]);
  useEffect(() => {
    setContainerRef(getTableContainer()?.current);
    setTableWidth(getTableContainer()?.current?.scrollWidth ?? 0);
  }, [getTableContainer]);
  return (
    <div className={classes.scrollIcons}>
      <IconButton id="left" onClick={handleClick} style={canScrollLeft ? { transform: 'none' } : { transform: 'scale(0)' }}>
        <ArrowLeftRoundedIcon fontSize="large" />
      </IconButton>
      <IconButton id="right" onClick={handleClick} style={canScrollRight ? { transform: 'none' } : { transform: 'scale(0)' }}>
        <ArrowRightRoundedIcon fontSize="large" />
      </IconButton>
    </div>
  );
};

export default CustomTableScroll;
