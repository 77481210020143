import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomButton from '../../components/CustomButton';

type Props = {
    onCancel: () => void;
    onConfirm: () => void;
    isOpen: boolean;
};
const ConfirmDelete: React.FC<Props> = (props) => {
    const {isOpen, onCancel, onConfirm}=props;
    return (
        <>
        <Dialog 
            open={isOpen}
            onClose={onCancel}
        >
            <DialogTitle disableTypography>
                <Typography variant="h5" color="secondary">
                    {'Are you sure you want to delete?'}
                </Typography>
            </DialogTitle>
            <DialogActions>
                <CustomButton variant="white" onClick={onCancel}>Cancel</CustomButton>
                <CustomButton variant="orange" onClick={onConfirm}>Remove</CustomButton>
            </DialogActions>
        </Dialog>
    </>
    )
}
export default React.memo(ConfirmDelete);