import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const customerCreateSchema = yup.object().shape({
  property_id: yup.number().nullable(),
  firstname: yup.string().required('First name is required'),
  email_1: yup.string().when('visitor_status_id', {
    is: val => val !== 133,
    then: yup.string().required('Email is required'),
  }),
  lastname: yup.string().required('Last name is required'),
  cell_phone_1: yup.string().when('visitor_status_id', {
    is: val => val !== 133,
    then: yup.string().required('Phone number is required'),
  }).transform(value => value.replace(/_*/g, '').replace(/-*(?!\d)/g, '')),
  visitor_type_id: yup.number().required('Visit type is required'),
  visitor_status_id: yup.mixed().nullable().when('property_id', {
    is: val => val >= 0,
    then: yup.number().required().notOneOf([0], 'Please provide a visitor status'),
  }),
});

export const customerTroubleTicketCreateSchema = yup.object().shape({
  property_id: yup.number().required('Please choose a property'),
  firstname: yup.string().required('First name is required'),
  email_1: yup.string().when('visitor_status_id', {
    is: val => val !== 133,
    then: yup.string().required('Email is required'),
  }),
  lastname: yup.string().required('Last name is required'),
  cell_phone_1: yup.string().when('visitor_status_id', {
    is: val => val !== 133,
    then: yup.string().required('Phone number is required'),
  }).transform(value => value.replace(/_*/g, '').replace(/-*(?!\d)/g, '')),
  visitor_type_id: yup.number().required('Visit type is required'),
  visitor_status_id: yup.mixed().nullable().when('property_id', {
    is: val => val >= 0,
    then: yup.number().required().notOneOf([0], 'Please provide a visitor status'),
  }),
});
