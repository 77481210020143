import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import { RouteComponentProps } from 'react-router';
import { useSnackbar } from 'notistack';
import Spinner from '../../components/CustomSpinner';
import SearchBar from '../../components/SearchBar';
import ConfirmDelete from '../../components/ConfirmDelete';
import CustomTablePagination from '../../components/CustomTablePagination';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import VisitsTable from './VisitsTable/VisitsTable';
import { ROWS_PER_PAGE } from '../../constants/table';
import styles from './styles';
import { Order } from '../../utils/table';
import {
  Customer, Status,
} from '../../types/Customer';
import { CustomerVisit } from '../../types/Visit';
import { BaseProperty as Property } from '../../types/Property';
import useDebounce from '../../hooks/useDebounce';
import CustomButton from '../../components/CustomButton';
import { ALL_VISITS, REMOVE_VISITS } from '../../graphql/queries/Visits';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect/RowsPerPageSelect';
import downloadDocument from '../../utils/files';
import { loadExport, loadExportAll } from '../../services/export';
import { FilterContext } from '../../App';

type Props = {
  visitsTotal: number;
  printVisits: (selection: Array<number | string>) => void;
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search visits';

const Visits: React.FC<Props> = props => {
  const {
    classes, printVisits, history, match,
  } = props;
  const { dispatch, state } = useContext(SearchSortContext);
  const date = useContext(FilterContext);
  const {
    orderBy, order, contextSearch, currentPage, rowsPerPageAmount,
  } = state.visits;
  const [search, setSearch] = useState<string>(contextSearch);
  const [cursor, setCursor] = useState<{limit: number, offset: number}>({
    limit: rowsPerPageAmount, offset: rowsPerPageAmount * currentPage,
  });
  const [rowsPerPage] = React.useState<number>(ROWS_PER_PAGE);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<Array<CustomerVisit['customer_visit_id']>>([]);
  const selectedLength = selected.length;
  const [visitToRemove, setVisitToRemove] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 1000);
  const [sort, setSort] = useState<{sortBy: string, direction: 'asc' | 'desc'}>({ sortBy: orderBy, direction: order });

  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const { loading, data } = useQuery<{ visitList: Paginated<CustomerVisit
  & {customer: Customer }
  & {property: Property}
  & {status: Status}>}>(
    ALL_VISITS, {
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
        startDate: date.state?.startDate,
        endDate: date.state?.endDate,
      },
      fetchPolicy: 'network-only',
    },
  );

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const [removeVisits] = useMutation(REMOVE_VISITS, {
    refetchQueries: [{
      query: ALL_VISITS,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  const visits = data?.visitList?.list ?? [];
  const params = data?.visitList?.params ?? { total: 0 };
  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      // eslint-disable-next-line no-underscore-dangle
      const newSelecteds = visits.map(i => i.customer_visit_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(
    event: React.SyntheticEvent,
    id: CustomerVisit['customer_visit_id'],
  ) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<CustomerVisit['customer_visit_id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'visits')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'Visits', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('visits').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Visits', response.data);
    });
  }

  const handleDelete = () => {
    if (selectedLength !== 0) {
      removeVisits({
        variables: {
          visits: selected,
        },
      }).then(() => {
        setSelected([]);
        enqueueSnackbar('Removed', { variant: 'success' });
      })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  };

  function handlePrint() {
    if (selectedLength !== 0) {
      printVisits(selected);
    }
  }

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'visits')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_VISIT',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const handleVisitSelect = (id: number) => {
    history.push(`${match.url}/${id}`);
  };

  const handleShowAllClick = () => {
    dispatch({ type: 'RESET_STATE' });
    setSort({
      direction: 'desc',
      sortBy: 'visits.modified_on',
    });
    setSearch('');
  };

  function handleChangeOrder(newOrder: Order, newOrderBy: string) {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_VISIT',
      payload: {
        orderBy: newOrderBy,
        order: newOrder,
        currentPage: 0,
      },
    });
  }

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_VISIT',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_VISIT',
      payload: {
        rowsPerPageAmount: e.target.value,
        currentPage: 0,
      },
    });
  }, [dispatch]);

  const isStateInitial = JSON.stringify({
    contextSearch: state.visits.contextSearch,
    order: state.visits.order,
    orderBy: state.visits.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.visits.contextSearch,
    order: initialState.visits.order,
    orderBy: initialState.visits.orderBy,
  });

  return (
    <Fragment>
      <Grid container justify="space-between">
        <Grid container spacing={8}>
          { !isStateInitial && (
          <Grid item className={classes.controlButton}>
            <CustomButton
              variant="orange"
              color="primary"
              onClick={handleShowAllClick}
            >
              Show all
            </CustomButton>
          </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            defaultSearchValue={search}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => { setVisitToRemove(true); }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography className={classes.tableHeaderText} variant="h5">Visits</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <VisitsTable
            selected={selected}
            visits={visits}
            order={sort.direction}
            orderBy={sort.sortBy}
            rowsPerPage={rowsPerPage}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleVisitSelect}
          />
        </div>
        {loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <CustomTablePagination
          rowsPerPageOptions={[25, 50, 100, 150]}
          rowsTotal={params.total}
          rowsCount={visits.length}
          rowsPerPage={cursor.limit}
          currentPage={page}
          onChangePage={n => handleChangePage(undefined, n)}
          onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
        />
      </div>
      <ConfirmDelete
        isOpen={visitToRemove}
        onCancel={() => { setVisitToRemove(false); }}
        onConfirm={() => { handleDelete(); setVisitToRemove(false); }}
      />
    </Fragment>
  );
};

export default React.memo(Visits);
