import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';

type Props = { width?: string } & Omit<KeyboardDatePickerProps, 'variant, inputVariant'>;

const CustomDatePicker: React.FC<Props> = props => (
  <div style={{ marginTop: '16px' }}>
    <KeyboardDatePicker
      variant="inline"
      {...props}
    />
  </div>
);

export default React.memo(CustomDatePicker);
