import * as yup from 'yup';
import { emptyStringToNull } from '../../utils/helpers';

// eslint-disable-next-line import/prefer-default-export
export const propertyCreateSchema = yup.object().shape({
  house_num: yup.string().nullable(),
  LASSO_project_id: yup.string()
    .nullable()
    .transform(emptyStringToNull),
  latitude: yup.number(),
  longitude: yup.number(),
  price: yup.number(),
  bedrooms: yup.number(),
  halfBathrooms: yup.number(),
  fullBathrooms: yup.number(),
  squareFootage: yup.number(),
  lock_serial_number: yup.string().test('also has smart_lock_serial_number', 'Cannot have Lock & Smart Lock at the same time', function (lock_serial_number: string) {
    const { smart_lock_serial_number } = this.parent;
    if (smart_lock_serial_number) {
      return !((lock_serial_number.length > 0) && (smart_lock_serial_number.length > 0));
    }
    return true;
  })
});
