import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const createTicketSchema = yup.object().shape({
  property_id: yup.number().required().notOneOf([0], 'Choose a property'),
  customer_firstname: yup.string().required('Customer first name is required'),
  customer_lastname: yup.string().required('Customer last name is required'),
  cell_phone_1: yup.string().transform(value => value.replace(/_*/g, '').replace(/-*(?!\d)/g, '')).required('Customer phone is required'),
  issue_diagnosis: yup.string().required('Issue diagnosis is required'),
  agent_informer: yup.string().required('Agents informed is required'),
  rep_name: yup.string().required('Reporter name is required'),
});
