import { gql } from 'apollo-boost';

export const ALL_SMART_LOCKS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    smartLockList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        serial_number,
        device_id,
        property {
          property_id
        },
        created_on,
        created_by,
        modified_on,
        modified_by,
        smartLockStatus,
        client{
          builder_id,
          name
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_MULTIPLE_SMART_LOCKS = gql(`
     mutation($smartLocks: [String!]!) {
        deleteMultipleSmartLocksBySN(smartLocks: $smart_locks)
    }
    `);