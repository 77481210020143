import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {
    height: '100vh',
  },
  form: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  progressButtonWrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  themeColor: {
    color: theme.appPalette.orange,
  },
  submit: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.appPalette.orange,
    },
    fontSize: '16px',
  },
});
