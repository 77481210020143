import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

type Props = {
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  rowsPerPage: number;
  rowsPerPageOptions?: Array<number>;
} & TextFieldProps;

const RowsPerPageSelect: React.FC<Props> = props => {
  const { rowsPerPage, onChangeRowsPerPage, rowsPerPageOptions } = props;
  function handleChange(event: React.ChangeEvent<{ value: unknown }>): void {
    onChangeRowsPerPage(event.target.value as number);
  }
  return (
    <div>
      Rows per page:
      {' '}
      <Select
        value={rowsPerPage}
        onChange={handleChange}
        style={{ marginRight: '15px', marginBottom: '10px' }}
      >
        {rowsPerPageOptions!.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default React.memo(RowsPerPageSelect);
