import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../redux/selectors/auth';
import { GET_STATIC_DATA } from '../graphql/queries/staticData';
import { Catalog } from '../types/Customer';
import { Status } from '../types/Status';

export type StaticData = {
  shipmentStatuses: Catalog[],
  lockStatuses: Status[],
  propertyStatuses: Status[],
  timezones: Catalog[],
  statuses: Catalog[],
  types: Catalog[],
  sources: Catalog[],
};

export default function useStaticData() {
  const [staticData, setStaticData] = useState<any>();
  const auth = useSelector(selectAuthData);
  const { loading, data } = useQuery<StaticData>(
    GET_STATIC_DATA, {
      skip: !auth.token,
    },
  );

  useEffect(() => {
    if (data) {
      setStaticData({
        shipmentStatuses: data?.shipmentStatuses,
        lockStatuses: data?.lockStatuses,
        propertyStatuses: data?.propertyStatuses,
        timezones: data?.timezones,
        statuses: data?.statuses,
        types: data?.types,
        sources: data?.sources,
      });
    }
  }, [data, loading]);
  return staticData;
}
