import React, { ReactNode, useReducer } from 'react';
import { Action } from 'redux';
// eslint-disable-next-line import/no-cycle
import { Customer } from '../types/Customer';
import { CustomerVisit } from '../types/Visit';
// eslint-disable-next-line import/no-cycle
import { User } from '../components/Users/Users';
import Builder from '../types/Builder';
// eslint-disable-next-line import/no-cycle
import { Lock, Property, Shipment } from '../types/Property';
import Community from '../types/Community';
import TroubleTicket from '../types/TroubleTickets';
import { ROWS_PER_PAGE } from '../constants/table';
import { DeviceType } from '../types/Devices';
import { HubAccountType } from '../types/HubAccounts';
import { SmartLock } from '../redux/types/smart-locks';
import { Enterprise } from '../types/Enterprise';

type Fields<T> = {
  order: 'asc' | 'desc',
  orderBy: keyof T | string,
  contextSearch: string,
  currentPage: number,
  rowsPerPageAmount: number
};

type Props = {
  children: ReactNode,
};

type initialState = {
  customers: Fields<Customer>,
  users: Fields<User>,
  clients: Fields<Builder>,
  enterprises: Fields<Enterprise>,
  locks: Fields<Lock>,
  smartLocks: Fields<SmartLock>
  visits: Fields<CustomerVisit>,
  properties: Fields<Property>,
  communities: Fields<Community>,
  tickets: Fields<TroubleTicket>,
  shipments: Fields<Shipment>,
  devices: Fields<DeviceType>,
  hubAccounts: Fields<HubAccountType>,
};

export const initialState : initialState = {
  customers: {
    order: 'desc',
    orderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  users: {
    order: 'asc',
    orderBy: 'username',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  clients: {
    order: 'desc',
    orderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  enterprises: {
    order: 'desc',
    orderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  locks: {
    order: 'desc',
    orderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  smartLocks: {
    order: 'desc',
    orderBy: 'modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  visits: {
    order: 'desc',
    orderBy: 'visits.created_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  properties: {
    order: 'desc',
    orderBy: 'property.modified_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  communities: {
    order: 'desc',
    orderBy: 'created_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  tickets: {
    order: 'desc',
    orderBy: 'open_date',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  shipments: {
    order: 'desc',
    orderBy: 'last_updated',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  devices: {
    order: 'desc',
    orderBy: 'created_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
  hubAccounts: {
    order: 'desc',
    orderBy: 'created_on',
    contextSearch: '',
    currentPage: 0,
    rowsPerPageAmount: ROWS_PER_PAGE,
  },
};

export const SearchSortContext = React.createContext<{
  state: typeof initialState;
  dispatch:(action: Action & { payload?: any }) => void;
}>({
      state: initialState,
      dispatch: () => {},
    });

export const searchSortReducer = (state:initialState = initialState, action: any) => {
  switch (action.type) {
    case 'CHANGE_CUSTOMER': {
      return {
        ...state,
        customers: { ...state.customers, ...action.payload },
      };
    }
    case 'CHANGE_USER': {
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      };
    }
    case 'CHANGE_CLIENT': {
      return {
        ...state,
        clients: { ...state.clients, ...action.payload },
      };
    }
    case 'CHANGE_LOCK': {
      return {
        ...state,
        locks: { ...state.locks, ...action.payload },
      };
    }
    case 'CHANGE_SMART_LOCK': {
      return {
        ...state,
        smartLocks: { ...state.smartLocks, ...action.payload },
      };
    }
    case 'CHANGE_SHIPMENT': {
      return {
        ...state,
        shipments: { ...state.shipments, ...action.payload },
      };
    }
    case 'CHANGE_DEVICE': {
      return {
        ...state,
        shipments: { ...state.shipments, ...action.payload },
      };
    }
    case 'CHANGE_VISIT': {
      return {
        ...state,
        visits: { ...state.visits, ...action.payload },
      };
    }
    case 'CHANGE_PROPERTY': {
      return {
        ...state,
        properties: { ...state.properties, ...action.payload },
      };
    }
    case 'CHANGE_COMMUNITY': {
      return {
        ...state,
        communities: { ...state.communities, ...action.payload },
      };
    }
    case 'CHANGE_TICKET': {
      return {
        ...state,
        tickets: { ...state.tickets, ...action.payload },
      };
    }
    case 'RESET_STATE': {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export const SearchSortProvider = ({ children } : Props) => {
  const [state, dispatch] = useReducer(searchSortReducer, initialState);
  return (
    <SearchSortContext.Provider value={{ dispatch, state }}>
      { children }
    </SearchSortContext.Provider>
  );
};
