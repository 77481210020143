import { gql } from 'apollo-boost';

export const GET_PROPERTY = gql`
  query($property_id: Float!) {
    property(property_id: $property_id) {
      property_id,
      subdivision,
      lock_serial_number,
      smart_lock_serial_number,
      lock_assigned,
      lock_terminated,
      propertyStatusId,
      builder,
      builder_id,
      state,
      city,
      latitude,
      is_rental,
      is_webflow_code_only,
      url_1,
      rental_application_url,
      longitude,
      zipcode,
      contract_date,
      house_num,
      price,
      bedrooms,
      halfBathrooms,
      fullBathrooms,
      squareFootage,
      isModel,
      modified_on,
      modified_by,
      remarks,
      timezone,
      grouping,
      LASSO_project_id,
      novihome_integration,
      nternow_hub_backup_code,
      property_backup_code,
      temp_backup_code,
      trade_code,
      sales_code,
      resident_code,
      propertyCommunity {
        communities {
          id,
          name,
        }
      },
      lock {
        lockStatusId
      },
      visits{
        customer_visit_id,
        modified_on,
        remarks,
        customer {
          firstname,
          lastname,
          cell_phone_1,
          city,
          visitor_status_id
        }
        property {
          timezoneCatalog {
            catalog_id,
            value,
          }
        }
      },
      assignedAgents: agents {
        property_id,
        agent_username,
        is_project_manager,
        user {
          firstname,
          lastname,
          email,
          phone,
        }
      },
      hub{
        serial_number
        hub_type
      },
      availableAgents {
        firstname,
        lastname,
        username,
        phone,
        email,
        isPM,
      },
      address_1,
      address_2,
      adjustableTourHours,
      tourHoursStart,
      tourHoursEnd,
      integrations {
        name,
        value,
        field,
        isActive
      }
      alarm,
      alarm_company,
      armed_hours,
      disarm_code,
      emergency_notes,
      marketing_name,
      access_notes
    }
  }
`;

export const ADD_AGENT = gql`
  mutation addAgent($agent: UpdateAgentProperty!) {
    addAgentToProperty(agent: $agent)
  }
`;

export const REMOVE_AGENT = gql`
  mutation removeAgent($username: String!, $property_id: Int!) {
    removeAgentFromProperty(username: $username, property_id: $property_id) {
      id
    }
  }
`;

export const GET_PROPERTY_STATUSES = gql(`
      query {
            statuses: getPropertyStatuses {
              status_id,
              status
            }
           }
  `);

export const UPDATE_PROPERTY = gql`
    mutation updateProperty($property: UpdateProperty!, $communityId: Int!) {
        updateProperty(property: $property, communityId: $communityId) {
            property_id
        }
    }
`;

export const UPDATE_INTEGRATIONS = gql`
    mutation updateIntegrations($propertyId: Int!, $integrations: [UpdateIntegration!]!) {
        updateIntegrations(propertyId: $propertyId, integrations: $integrations)
    }
`;

export const UPDATE_AUTOMATIONS = gql`
  mutation updateAutomations(
    $propertyId: Int!
    $nternowHubBackupCode: String!
    $propertyBackupCode: String!
    $propertyTradeCode: String!
    $propertySalesCode: String!
  ) {
    updateAutomations(
      propertyId: $propertyId
      nternowHubBackupCode: $nternowHubBackupCode
      propertyBackupCode: $propertyBackupCode
      propertyTradeCode: $propertyTradeCode
      propertySalesCode: $propertySalesCode
    )
  }
`;

export const GET_NTERNOW_CODE = gql`
    query getNternowCode($id: String!, $firstName: String!, $lastName: String!, $hubType: String!) {
      getNternowCode(id: $id, firstName: $firstName, lastName: $lastName, hubType: $hubType)
    }
`;

export const GET_NTERNOW_SMART_LOCK_CODE = gql`
    query getNternowSmartLockCode($smartLockSerialNumber: String!, $firstName: String!, $lastName: String!) {
      getNternowSmartLockCode(smartLockSerialNumber: $smartLockSerialNumber, firstName: $firstName, lastName: $lastName)
    }
`;

export const UPDATE_KWIKSET_LOCK_STATE = gql`
    query updateKwiksetLockState($id: String!, $state: String!) {
      updateKwiksetLockState(id: $id, state: $state)
    }
`;

export const GET_KWIKSET_LOCK_STATE = gql`
    query getKwiksetLockState($id: String!) {
      getKwiksetLockState(id: $id)
    }
`;

export const GENERATE_KWIKSET_ACCESS_CODE = gql`
    query generateKwiksetAccessCode($id: String!) {
      generateKwiksetAccessCode(id: $id)
    }
`;

export const ADD_DATADOC_LOGS = gql`
    query dataDocLogs(
        $propertyId: Int!,
        $customerId: Int!,
        $serialNumber: String!,
        $customerName: String!,
        $phone: String!,
        $type: String!,
        $code: String!) {
      dataDocLogs(propertyId:$propertyId,
        serialNumber: $serialNumber,
        customerName: $customerName,
        customerId: $customerId,
        phone: $phone,
        type: $type,
        code: $code)
    }
`;
