import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  tableRoot: {
    minWidth: 1230,
    zIndex:2
  },
  pointer: {
    cursor: 'pointer',
  },
  orange: {
    color: theme.appPalette.orange,
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 15,
    paddingBottom: 5,
  },
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  tableContainer:{
    overflow:'auto',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    scrollBehavior:'smooth'
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
