import {
  ALL_PROPERTIES_STATS_REQUEST,
  ALL_PROPERTIES_STATS_REQUEST_FAILED,
  ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS,
  ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS,
  PropertyStats,
  ErrorMessage,
  WEEKLY_VISITS_REQUEST,
  WEEKLY_VISITS_REQUEST_FAILED,
  WeeklyVisitStats,
  MonthlyVisitStats,
  YearlyVisitStats,
  WEEKLY_VISITS_REQUEST_SUCCESS,
  MONTHLY_VISITS_REQUEST,
  MONTHLY_VISITS_REQUEST_FAILED,
  MONTHLY_VISITS_REQUEST_SUCCESS,
  YEARLY_VISITS_REQUEST,
  ALL_VISITS_REQUEST,
  YEARLY_VISITS_REQUEST_FAILED,
  YEARLY_VISITS_REQUEST_SUCCESS,
  ALL_VISITS_REQUEST_FAILED,
  ALL_VISITS_REQUEST_SUCCESS,
  PropertiesStatsQuery,
  AllVisitStats,
  CONTRACTORS_SERVED_COUNT_REQUEST,
  CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS,
  CONTRACTORS_SERVED_COUNT_REQUEST_FAILED,
} from '../types/stats';
import { Query } from '../../utils/query';

export const propertiesStatsRequest = (query: PropertiesStatsQuery) => ({
  type: ALL_PROPERTIES_STATS_REQUEST,
  payload: query,
} as const);

export const propertiesStatsRequestFailed = (payload: ErrorMessage) => ({
  type: ALL_PROPERTIES_STATS_REQUEST_FAILED,
  payload,
} as const);

export const propertiesActiveStatsRequestSuccess = (
  payload: Array<PropertyStats>,
) => ({
  type: ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS,
  payload,
} as const);

export const propertiesSoldStatsRequestSuccess = (
  payload: Array<PropertyStats>,
) => ({
  type: ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS,
  payload,
} as const);

export type PropertiesStatsRequestActionType = ReturnType<
  typeof propertiesStatsRequest
>;

export type PropertiesStatsActionsTypes =
  | PropertiesStatsRequestActionType
  | ReturnType<typeof propertiesStatsRequestFailed>
  | ReturnType<typeof propertiesActiveStatsRequestSuccess>
  | ReturnType<typeof propertiesSoldStatsRequestSuccess>;

export const weeklyVisitsRequest = () => ({
  type: WEEKLY_VISITS_REQUEST,
} as const);

export const weeklyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: WEEKLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const weeklyVisitsRequestSuccess = (payload: Array<WeeklyVisitStats>) => ({
  type: WEEKLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export type WeeklyVisitsRequestActionType = ReturnType<
  typeof weeklyVisitsRequest
>;

export type WeeklyVisitsStatsActionsTypes =
  | WeeklyVisitsRequestActionType
  | ReturnType<typeof weeklyVisitsRequestFailed>
  | ReturnType<typeof weeklyVisitsRequestSuccess>;

export const monthlyVisitsRequest = (query: Query) => ({
  type: MONTHLY_VISITS_REQUEST,
  payload: query,
} as const);

export const monthlyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: MONTHLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const monthlyVisitsRequestSuccess = (
  payload: Array<MonthlyVisitStats>,
) => ({
  type: MONTHLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export type MonthlyVisitsRequestActionType = ReturnType<
  typeof monthlyVisitsRequest
>;

export type MonthlyVisitsStatsActionsTypes =
  | MonthlyVisitsRequestActionType
  | ReturnType<typeof monthlyVisitsRequestFailed>
  | ReturnType<typeof monthlyVisitsRequestSuccess>;

export const yearlyVisitsRequest = () => ({
  type: YEARLY_VISITS_REQUEST,
} as const);

export const yearlyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: YEARLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const yearlyVisitsRequestSuccess = (payload: Array<YearlyVisitStats>) => ({
  type: YEARLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export type YearlyVisitsRequestActionType = ReturnType<
  typeof yearlyVisitsRequest
>;

export type YearlyVisitsStatsActionsTypes =
  | YearlyVisitsRequestActionType
  | ReturnType<typeof yearlyVisitsRequestFailed>
  | ReturnType<typeof yearlyVisitsRequestSuccess>;

export const allVisitsRequest = (query: Query) => ({
  type: ALL_VISITS_REQUEST,
  payload: query,
} as const);

export const allVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: ALL_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const allVisitsRequestSuccess = (payload: Array<AllVisitStats>) => ({
  type: ALL_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export type AllVisitsRequestActionType = ReturnType<typeof allVisitsRequest>;

export type AllVisitsStatsActionsTypes =
  | AllVisitsRequestActionType
  | ReturnType<typeof allVisitsRequestFailed>
  | ReturnType<typeof allVisitsRequestSuccess>;

export const contractorsCountRequest = () => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST,
} as const);

export const contractorsCountRequestFailed = (payload: ErrorMessage) => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST_FAILED,
  payload,
} as const);

export const contractorsCountRequestSuccess = (payload: number) => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS,
  payload,
} as const);

export type ContractorsActionsTypes =
  | ReturnType<typeof contractorsCountRequest>
  | ReturnType<typeof contractorsCountRequestFailed>
  | ReturnType<typeof contractorsCountRequestSuccess>;
