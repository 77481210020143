import { useEffect, useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { State } from '../types/Customer';
import { selectAuthData } from '../redux/selectors/auth';
import { GET_STATES } from '../graphql/queries/staticData';

export default function useStates() {
  const [states, setStates] = useState<Array<State>>([]);
  const auth = useSelector(selectAuthData);
  const { loading, data } = useQuery<{ states: State[] }>(
    GET_STATES, {
      skip: !auth.token,
    },
  );

  useEffect(() => {
    if (data?.states) {
      setStates(data.states);
    }
  }, [data, loading]);
  return states;
}
