import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const propertyInputCreateSchema = yup.object().shape({
  builder_id: yup.number().required('Client is required').notOneOf([0]),
  latitude: yup.number(),
  house_num: yup.string(),
  city: yup.string(),
  state: yup.string(),
  zipcode: yup.string(),
  subdivision: yup.string(),
  timezone: yup.number().required('Timezone is required').notOneOf([0], 'Timezone is required'),
  lock_serial_number: yup.string(),
  smart_lock_serial_number: yup.string(),
  longitude: yup.number(),
  price: yup.number(),
  bedrooms: yup.number(),
  halfBathrooms: yup.number(),
  fullBathrooms: yup.number(),
  squareFootage: yup.number(),
  lock: yup.object().shape({
    lock_serial_number: yup.string(),
    lock_status: yup.number(),
  }),
  smartLock: yup.object().shape({
    smart_lock_serial_number: yup.string(),
    lock_status: yup.number(),
  }),
});
