import InputLock from './Lock';
import { /* UserType, */ AgentsProperties } from '../User';
import { DomainStatus } from '../Status';
import InputSmartLock from './SmartLock';

export interface InputPropertyInterface {
  property_id: number | null;
  timezone: number;
}

export default class InputProperty implements InputPropertyInterface {
  property_id : number | null = null;

  builder_id : number = 0;

  status : DomainStatus | null = null;

  propertyStatusId : string | null = null;

  house_num: string = '';

  address_1: string = '';

  subdivision: string = '';

  lock: InputLock = {
    serial_number: '',
    status: {
      status_id: 'LOCK_CREATED',
    },
    lockStatusId: 'LOCK_CREATED',
  };

  smartLock: InputSmartLock = {
    serial_number: '',
    status: {
      status_id: 'LOCK_CREATED',
    },
    smartLockStatusId: 'LOCK_CREATED',
  };

  agents: Array<AgentsProperties> = [];

  lock_assigned: Date = new Date();

  city: string = '';

  state: string = '';

  builder: string = '';

  zipcode: string = '';

  remarks: string = '';

  latitude: number = 0;

  longitude: number = 0;

  timezone: number = 0;

  price: number = 0;

  is_rental: boolean = false;

  is_webflow_code_only: boolean = false;

  bedrooms: number = 0;

  halfBathrooms: number = 0;

  fullBathrooms: number = 0;

  squareFootage: number = 0;

  isModel: boolean = false;

  address_2: number = 0;

  url_1: string = '';

  rental_application_url: string = '';

  disposition?: string = '';

  lock_serial_number: string = '';

  smart_lock_serial_number: string = '';

  grouping?: string = '';

  alarm?: boolean = false;

  alarm_company?: string | null = '';

  armed_hours?: string | null = '';

  disarm_code?: string | null = '';

  emergency_notes?: string | null = '';

  marketing_name?: string | null = '';

  LASSO_project_id?: string;

  novihome_integration?: string;

  nternow_hub_backup_code?: string;

  property_backup_code?: string;

  temp_backup_code?: string;

  trade_code?: string;

  sales_code?: string;

  resident_code?: string;
}
