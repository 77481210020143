import { gql } from 'apollo-boost';

export const GET_VISIT = gql`
  query($id: Int!) {
    getVisitById(id: $id) {
      customer_visit_id,
      created_on,
      platform,
      customer {
        firstname,
        lastname,
       
        catalog {
          value,
        }
        customerStatus,
      },
      property_id,
      visit_status_id,
      created_by,
      property {
        address_1,
        subdivision,
        house_num,
        timezoneCatalog {
          catalog_id,
          value
        }
      },
      remarks,
      modified_on,
      modified_by
    }
  }
`;

export const UPDATE_VISIT = gql`
  mutation updateVisit($visit: UpdateVisit!) {
    updateVisit(visit: $visit) {
      customer_visit_id
    }
  }
`;
