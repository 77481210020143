import React, { useState, useEffect } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import styles from './styles';
import Community from '../../types/Community';
import useDebounce from '../../hooks/useDebounce';
import { GET_COMMUNITY_BY_ID, SEARCH_COMMUNITIES } from '../../graphql/queries/Communities';
type Props = {
  handleChange: (community:Community) => void;
  initialValue?: number;
  builderId?: number;
} & WithStyles<typeof styles> & TextFieldProps;

const CommunitySearch: React.FC<Props> = ({
  handleChange, initialValue, builderId, classes, ...textFieldProps
}) => {
  const [search, setSearch] = useState<string>('');
  //const [initialCommunity, setInitialCommunity] = useState<Community>();
  const [communities, setCommunities] = useState<{value: Community, label: any}[]>([]);
  const debouncedSearch = useDebounce(search, 100);
  const handleSearch = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  };

  const { data, loading } = useQuery<{ communities: Community[] }>(
    SEARCH_COMMUNITIES,
    {
      variables: {
        name: debouncedSearch,
        builder_id: builderId,
      },
      fetchPolicy: 'network-only',
    },
  );
  const { data: communityData } = useQuery< { community:Community} >(GET_COMMUNITY_BY_ID,
    { variables: { id: initialValue }, skip: !initialValue });

  /* const communities = (data?.communities.map(item => ({
    value: item.id,
    label: `${item.name}`,
  }))) || []; */
  /*useEffect(() => {
    if (initialValue && communityData?.community) {
      setInitialCommunity(communityData.community);
    }
  }, [initialValue, communityData, initialCommunity]);
*/
  useEffect(() => {
    const availableCommunities = (data?.communities.map(item => ({
      value: item,
      label: `${item.name}`,
    }))) || [];
    setCommunities(availableCommunities);
  }, [data]);

  useEffect(() => {
    if (communityData && !communities.find(community => community.value.id === initialValue)) {
      communities.push({
        value: communityData.community,
        label: communityData.community.name,
      });
    }
  }, [communityData, communities, initialValue]);
  const onCommunityChange = (event: any, chosenSelect: { label: string, value: Community } | null) => {
    const initialProp = {
      id: 0,
      name: '',
      created_on:''
    };
    handleChange(chosenSelect?.value ?? initialProp);
  };
  const initial = communityData ? communityData.community : undefined;
  return (
    <div className={classes.communitySelect}>
      {initial && (
        <Autocomplete
          options={communities}
          getOptionLabel={option => option.label}
          noOptionsText="No communities names were found"
          onChange={onCommunityChange}
          loading={loading}
          defaultValue={initial ? {
            value: initial, label: initial.name,
          } : undefined}
          renderInput={params => (
            <TextField
              {...params}
              label={'Community'}
              fullWidth
              onKeyUp={handleSearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...textFieldProps}
            />
          )}
        />
      )}
         { !initial && (
          <Autocomplete
            options={communities}
            getOptionLabel={option => option.label}
            noOptionsText="No communities names were found"
            onChange={onCommunityChange}
            loading={loading}
            renderInput={params => (
              <TextField
                {...params}
                label="Community"
                fullWidth
                onKeyUp={handleSearch}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                {...textFieldProps}
              />
            )}
          />
        ) }
    </div>
  );
};

export default CommunitySearch;
