export class InputHubAccount {
  username: string = '';

  email: string = '';

  hashpw?: string = '';

  adc_group_id?: string = '';
}

export class HubServerRelay {
  hubId: string = '';

  serverRelay: string = '';

  identity: string = '';

  identitySignature: string = '';

  controllerStatus: number = 0;
}
