import {Checkbox, FormControlLabel, FormLabel, MenuItem, TextField} from '@material-ui/core';
import React, { useState } from 'react';

interface Props {
  name: string;
  label: string;
  register: any;
}

const types = [
  {
    name: 'Int',
    component: ({ register }: any) => (
      <TextField
        margin="normal"
        type="number"
        style={{ width: 'calc(100% - 100px)' }}
        inputRef={input => register(input, { required: true, valueAsNumber: true })}
        required
        hiddenLabel
        name="value"
      />
    ),
  },
  {
    name: 'Bool',
    component: ({ register }: any) => (
      <FormControlLabel
        control={(
          <Checkbox
            inputRef={input => register(input, { required: false })}
            color="secondary"
            name="value"
            defaultChecked={false}
          />
        )}
        key="value"
        label="value"
      />
    ),
  },
  {
    name: 'Float',
    component: ({ register }: any) => (
      <TextField
        margin="normal"
        type="number"
        style={{ width: 'calc(100% - 100px)' }}
        inputRef={input => register(input, { required: true, valueAsNumber: true })}
        required
        hiddenLabel
        name="value"
      />
    ),
  },
  {
    name: 'String',
    component: ({ register }: any) => (
      <TextField
        margin="normal"
        style={{ width: 'calc(100% - 100px)' }}
        inputRef={input => register(input, { required: true })}
        required
        hiddenLabel
        name="value"
      />
    ),
  },
];

const MixTypeInput = ({ name, label, register }: Props) => {
  const [type, setType] = useState<string>('Int');

  const inputType = React.useMemo(() => {
    const c = types.find(item => item.name === type);
    return c;
  }, [type]);

  return (
    <>
      { label && <FormLabel id={`list-item-input-${name}`}>{label}</FormLabel>}
      <div>
        <TextField
          id="outlined-select-currency"
          select
          margin="normal"
          hiddenLabel
          style={{ width: '100px' }}
          defaultValue={type}
          onChange={event => setType(event.target.value)}
        >
          {types.map(option => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {inputType && <inputType.component register={register} />}
      </div>
    </>
  );
};

export default MixTypeInput;
