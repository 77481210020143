import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  Link, MenuItem, Paper, TextField,
} from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
// @ts-ignore
import InputMask from 'react-input-mask';
import useTroubleTicket from '../../hooks/useTroubleTicket';
import useStyles from '../LockDetails/styles';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomButton from '../../components/CustomButton';
import { beautifyErrors, generateLabel, ValidationErrors } from '../../utils/helpers';
import CustomDatePicker from '../../components/CustomDatePicker';
import StatusSelect from '../../components/StatusSelect/StatusSelect';
import InputTicket from '../../types/inputTypes/TroubleTicket';
import PropertySearch from '../../components/PropertySearch/PropertySearch';
import { BaseProperty as Property } from '../../types/Property';
import { processAgents } from '../../utils/agents';
import useProperty from '../../hooks/useProperty';
import { createTicketSchema } from '../TroubleTickets/TroubleTicketValidation';
import useClosingDiagnosesCatalogs from './useClosingDiagnosesCatalogs';

type Props = {
  loading?: boolean;
} & RouteComponentProps & {match:{params: {ticketId: string }}, location: {state: {customer: any}}};

const TroubleTicketDetails: React.FC<Props> = ({ match, history, location }) => {
  const {
    troubleTicket, formType, setTroubleTicket, updateTicket,
  } = useTroubleTicket(Number(match.params.ticketId));
  const { property } = useProperty(troubleTicket.property_id);
  const { enqueueSnackbar } = useSnackbar();
  const diagnoses = useClosingDiagnosesCatalogs();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<InputTicket>>({});
  const classes = useStyles();
  const handleTicketChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setTroubleTicket((prevTicket: InputTicket) => ({
          ...prevTicket,
          [name]: value,
        }));
      }
    }, [setTroubleTicket],
  );
  const lockSerial = troubleTicket.lock_serial_number || property?.lock_serial_number;

  const handleStatusChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setTroubleTicket((prevTicket: InputTicket) => ({
        ...prevTicket,
        lockStatus: value,
      }));
    }, [setTroubleTicket],
  );

  useEffect(() => {
    setTroubleTicket(ticket => ({
      ...ticket,
      lockStatus: property.lock?.lockStatusId,
    }));
  }, [property, property.lock, setTroubleTicket]);

  useEffect(() => {
    if (location?.state?.customer) {
      const {
        firstName, lastName, cell_phone_1, property_id,
      } = location.state.customer;
      setTroubleTicket((prevTicket: InputTicket) => ({
        ...prevTicket,
        customer_firstname: firstName,
        customer_lastname: lastName,
        cell_phone_1,
        property_id,
      }));
    }
  }, [location, location.state, formType, setTroubleTicket]);

  useEffect(() => {
    setTroubleTicket((prevTicket: InputTicket) => ({
      ...prevTicket,
      agent_informer: processAgents(property?.agents?.map(agent => ({
        ...agent.user,
        is_project_manager: agent.is_project_manager,
      }))),
    }));
  }, [property, setTroubleTicket, troubleTicket.lockStatus]);

  useEffect(() => {
    setTroubleTicket((prevTicket: InputTicket) => ({
      ...prevTicket,
      client: property.builder,
    }));
  }, [property.builder, setTroubleTicket]);

  const handleDateChange = (date: string, name: string) => {
    if (Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
      const UTCDateString = newDate.toISOString();
      setTroubleTicket(prevState => ({ ...prevState, [name]: UTCDateString }));
    }
  };

  const handlePropertyChange = (chosenProperty: Property) => {
    setTroubleTicket(prevState => ({
      ...prevState,
      property_id: chosenProperty.property_id,
      lock_serial_number: chosenProperty?.lock_serial_number,
      lockStatus: chosenProperty?.lock?.status?.status_id,
      agent_informer: processAgents(chosenProperty?.agents?.map(agent => ({
        ...agent.user,
        is_project_manager: agent.is_project_manager,
      }))),
    }));
  };

  const handleTicketSave = () => {
    try {
      const uTroubleTicket = createTicketSchema.validateSync(troubleTicket, { abortEarly: false });
      setValidationErrors({});
      updateTicket({
        variables: {
          ticket: uTroubleTicket,
        },
      }).then(res => {
        setTroubleTicket((prevTicket: InputTicket) => ({
          ...prevTicket,
          ticket_number: res.data?.updateTroubleTicket?.ticket_number ?? null,
        }));
        enqueueSnackbar('Success!', { variant: 'success' });
      })
        .catch(() => {
          enqueueSnackbar('An error occurred!', { variant: 'error' });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<InputTicket>(errors));
    }
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
            GoBack
          </CustomButton>
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            {troubleTicket && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    { troubleTicket.ticket_number && (
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>
                          {generateLabel('Ticket number')}
                        :
                          {' '}
                        </span>
                        {' '}
                        {troubleTicket.ticket_number}
                      </Typography>
                    ) }
                    { Boolean(troubleTicket.property_id) && (
                      <>
                        <Typography variant="body1">
                          <span style={{ fontWeight: 'bold' }}>
                            {generateLabel('Lock id')}
                        :
                            {' '}
                          </span>
                          {' '}
                          <Link href={`/inventory/${lockSerial}`} target="_blank" rel="noopener">
                            {lockSerial}
                          </Link>
                        </Typography>
                        <Typography variant="body1">
                          <span style={{ fontWeight: 'bold' }}>
                            {generateLabel('Property id')}
                        :
                            {' '}
                          </span>
                          {' '}
                          <Link href={`/properties/${property?.property_id}`} target="_blank" rel="noopener">
                            {property?.property_id}
                          </Link>
                        </Typography>
                        <Typography variant="body1">
                          <span style={{ fontWeight: 'bold' }}>
                            {generateLabel('Client name')}
                        :
                            {' '}
                          </span>
                          {' '}
                          { troubleTicket.client }
                        </Typography>
                      </>
                    ) }
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('Visitor name')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {`${troubleTicket?.customer_firstname} ${troubleTicket?.customer_lastname}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Customer first name"
                      name="customer_firstname"
                      required
                      value={troubleTicket?.customer_firstname}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.customer_firstname)}
                      helperText={validationErrors.customer_firstname || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Customer last name"
                      name="customer_lastname"
                      required
                      value={troubleTicket?.customer_lastname}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.customer_lastname)}
                      helperText={validationErrors.customer_lastname || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputMask
                      mask="999-999-9999"
                      onChange={handleTicketChange}
                      value={troubleTicket?.cell_phone_1}
                    >
                      {() => (
                        <CustomTextField
                          margin="normal"
                          fullWidth
                          required
                          label="Cell phone 1"
                          name="cell_phone_1"
                          error={Boolean(validationErrors.cell_phone_1)}
                          helperText={validationErrors.cell_phone_1 || ''}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      required
                      rows={9}
                      rowsMax={9}
                      label="Issue diagnosis"
                      name="issue_diagnosis"
                      value={troubleTicket?.issue_diagnosis}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.issue_diagnosis)}
                      helperText={validationErrors.issue_diagnosis || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      required
                      rows={9}
                      rowsMax={9}
                      label="Agent contact info"
                      name="agent_informer"
                      value={troubleTicket?.agent_informer}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.agent_informer)}
                      helperText={validationErrors.agent_informer || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={9}
                      rowsMax={9}
                      label="Resolution steps"
                      name="resolution_notes"
                      value={troubleTicket?.resolution_notes}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.resolution_notes)}
                      helperText={validationErrors.resolution_notes || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Rep name"
                      name="rep_name"
                      required
                      value={troubleTicket?.rep_name}
                      onChange={handleTicketChange}
                      error={Boolean(validationErrors.rep_name)}
                      helperText={validationErrors.rep_name || ''}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={3}>
                      <CustomDatePicker
                        fullWidth
                        label="Open date"
                        value={troubleTicket?.open_date}
                        placeholder="10/10/2018"
                        inputVariant="outlined"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'open_date')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <CustomDatePicker
                        fullWidth
                        label="Close date"
                        value={troubleTicket?.closed_date}
                        placeholder="10/10/2018"
                        inputVariant="outlined"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'closed_date')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  { formType === 'add' && (
                    <Grid item xs={12} sm={3}>
                      <PropertySearch
                        // @ts-ignore
                        initialValue={property?.property_id ? property : undefined}
                        onPropertyChange={handlePropertyChange}
                        error={Boolean(validationErrors.property_id
                          || validationErrors.lockStatus)}
                        helperText={validationErrors.property_id || validationErrors.lockStatus || ''}
                        variant="outlined"
                        required
                      />
                    </Grid>
                  ) }
                  { property.lock_serial_number && troubleTicket.lockStatus && (
                    <Grid item xs={12} sm={3}>
                      <StatusSelect
                        value={troubleTicket.lockStatus}
                        onChange={handleStatusChange}
                        disabled={property.lock_serial_number === ''}
                        variant="outlined"
                        label="Lock status"
                        statusType="lock"
                      />
                    </Grid>
                  )
                  }
                  <Grid item xs={12} sm={3}>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      label="Closed diagnoses"
                      fullWidth
                      name="closed_diagnosis"
                      onChange={handleTicketChange}
                      value={troubleTicket.closed_diagnosis ?? 0}
                      select
                    >
                      <MenuItem disabled value={0}>None</MenuItem>
                      {diagnoses.map(({ catalog_id, value }) => (
                        <MenuItem
                          key={catalog_id}
                          value={catalog_id}
                        >
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={4} justify="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Grid item>
                    <CustomButton variant="orange" onClick={handleTicketSave}>
                      {formType === 'edit' ? 'Save' : 'Add'}
                    </CustomButton>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(TroubleTicketDetails);
