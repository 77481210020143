import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const communityInputCreateSchema = yup.object().shape({
  name: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  sales_agent: yup.string().required(),
  builder_id: yup.number().required(),
  lasso_community_project_id: yup.string(),
  novihome_integration: yup.string(),
  nternow_hub_backup_code: yup.string(),
  community_backup_code: yup.string(),
});
