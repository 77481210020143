import keyBy from 'lodash.keyby';
import {
  DeviceInfo,
  DeviceProperty,
  DevicePropertyName,
  DoorLockEnum,
  EzloDevice,
  EzloDeviceCategory,
  LockProperty, NetworkMobile,
  UserCodeValue,
} from './constants';

export const buildMessage = (method: string, params: object, id?: string) => ({
  method,
  params,
  id: id || String(new Date().getTime()),
});

export const extractLockDevice = (devices: EzloDevice[]): DeviceInfo | undefined => {
  const listDevice = devices
    .filter(item => item.category === EzloDeviceCategory.DoorLock)
    .map(({
      _id: id, name, reachable, info: { manufacturer, model },
    }) => ({
      id,
      name,
      reachable,
      manufacturer,
      model,
    } as DeviceInfo));

  if (listDevice.length > 0) {
    return listDevice[0];
  }

  return undefined;
};

export const extractDeviceProperties = (deviceProperties: DeviceProperty[]): LockProperty => {
  const obj = keyBy(deviceProperties, 'name');
  const userCodes = (obj[DevicePropertyName.USER_CODES]?.value || {}) as UserCodeValue;
  let maxUserCodeKey = 0;
  Object.keys(userCodes).forEach(item => {
    maxUserCodeKey = Math.max(maxUserCodeKey, Number(item));
  });
  return {
    status: obj[DevicePropertyName.DOOR_LOCK]?.value as DoorLockEnum,
    battery: obj[DevicePropertyName.BATTERY]?.value as number,
    userCodes,
    maxUserCodeKey,
  };
};

export const extractNetworkMobile = (networks: any[]): NetworkMobile => {
  const mobileNetwork = networks.find(({ _id: id }) => id === 'mobile0');
  return {
    accessTechnology: mobileNetwork.modem.accessTechnology,
    frequencyBand: mobileNetwork.modem.frequencyBand,
    operator: mobileNetwork.modem.operator,
    signalQuality: mobileNetwork.signalQuality,
  };
};
