import React, { FC } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import CustomButton from '../../CustomButton';
import SEND_APP_LINKS from '../../../graphql/queries/MobileAppLink';
import { transformGraphQlErrorMessage } from '../../../utils/helpers';

type LinkProps = {
  customerId: number,
  isDisabled: boolean,
};

const MobileAppLink: FC<LinkProps> = ({ customerId, isDisabled }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [sendCustomerLinks] = useMutation<{ sendCustomerLinks: number }>(
    SEND_APP_LINKS,
  );

  const sendCustomerInfo = async () => {
    try {
      await sendCustomerLinks({
        variables: {
          customerId,
        },
      });
      enqueueSnackbar('The links have been successfully sent!', {
        variant: 'success',
      });
    } catch (err) {
      enqueueSnackbar(
        transformGraphQlErrorMessage(err?.message) ?? 'An error occurred',
        { variant: 'error' },
      );
    }
  };

  return (
    <CustomButton
      disabled={isDisabled}
      variant="orange"
      onClick={sendCustomerInfo}
      style={{ margin: '0 5px 5px 0' }}
    >
      Send Mobile Acct Link
    </CustomButton>
  );
};

export default MobileAppLink;
