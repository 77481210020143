import {
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST_FAILED,
  CUSTOMER_SELECTED_CLEAR,
  CUSTOMERS_REQUEST,
  CUSTOMERS_REQUEST_SUCCESS,
  CUSTOMERS_REQUEST_FAILED,
  CUSTOMERS_EXPORT_REQUEST,
  CUSTOMERS_EXPORT_REQUEST_SUCCESS,
  CUSTOMERS_EXPORT_REQUEST_FAILED,
  CUSTOMERS_EXPORT_AND_PRINT_REQUEST,
  CustomersRequestSuccess,
  CustomerDetailsRequestSuccess,
  ErrorMessage,
} from '../types/customers';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';

export const customersRequest = (query?: Query) => ({
  type: CUSTOMERS_REQUEST,
  payload: query,
} as const);

export const customersRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMERS_REQUEST_FAILED,
  payload,
} as const);

export const customersRequestSuccess = (payload: CustomersRequestSuccess) => ({
  type: CUSTOMERS_REQUEST_SUCCESS,
  payload,
} as const);

export const customersExportAndPrintRequest = (selection: Array<string | number>, mimeType: MimeType = 'application/pdf') => ({
  type: CUSTOMERS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const customersExportRequest = (selection: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: CUSTOMERS_EXPORT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const customersExportRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMERS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const customersExportRequestSuccess = () => ({
  type: CUSTOMERS_EXPORT_REQUEST_SUCCESS,
} as const);

export const customerDetailsRequest = (payload: number) => ({
  type: CUSTOMER_DETAILS_REQUEST,
  payload,
} as const);

export const customerDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMER_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const customerDetailsRequestSuccess = (
  payload: CustomerDetailsRequestSuccess,
) => ({
  type: CUSTOMER_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const customerDetailsClear = () => ({
  type: CUSTOMER_SELECTED_CLEAR,
} as const);

export type CustomersRequestActionType = ReturnType<typeof customersRequest>;
export type CustomersExportRequestActionType =
  ReturnType<typeof customersExportRequest> | ReturnType<typeof customersExportAndPrintRequest>;

export type CustomersActionsTypes =
  | CustomersRequestActionType
  | ReturnType<typeof customersRequestFailed>
  | ReturnType<typeof customersRequestSuccess>
  | ReturnType<typeof customerDetailsRequest>
  | ReturnType<typeof customerDetailsRequestFailed>
  | ReturnType<typeof customerDetailsRequestSuccess>
  | ReturnType<typeof customerDetailsClear>;
