import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContent } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomButton from '../../../components/CustomButton';

type Props = {
  onCancel: () => void;
  onConfirm: (data: any) => void;
  isOpen: boolean;
  maxUserCodeKey?: number;
};

const validateUserCode = yup.object().shape({
  code: yup.string()
    .length(4, 'User code must be 4 characters long')
    .required('User code is required'),
  name: yup.string()
    .required('Name is required'),
  key: yup.string()
    .required('Key is required'),
});

const DialogAddNewUserCode: React.FC<Props> = props => {
  const {
    isOpen, onCancel, onConfirm, maxUserCodeKey,
  } = props;
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(validateUserCode),
  });

  const onSubmit = (data: any) => {
    onConfirm(data);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={onCancel}
      >
        <DialogTitle disableTypography>
          Add new code
        </DialogTitle>
        <DialogContent>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            type="number"
            inputRef={register}
            error={!!errors.key}
            helperText={errors?.key?.message ?? ''}
            id="key"
            label="Key"
            defaultValue={maxUserCodeKey ? maxUserCodeKey + 1 : 1}
            name="key"
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            inputRef={register}
            error={!!errors.name}
            helperText={errors?.name?.message ?? ''}
            id="name"
            label="Name"
            name="name"
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            inputRef={register}
            type="number"
            error={!!errors.code}
            helperText={errors?.code?.message ?? ''}
            id="code"
            label="User code"
            name="code"
          />
        </DialogContent>
        <DialogActions>
          <CustomButton variant="white" onClick={onCancel}>Cancel</CustomButton>
          <CustomButton variant="orange" onClick={handleSubmit(onSubmit)}>Save</CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(DialogAddNewUserCode);
