import { useQuery } from '@apollo/react-hooks';


export type Query = Record<string, string | number>;

export function buildQuery(
  queryObject: Query | undefined,
  prefix = '?',
): string {
  if (!queryObject) {
    return '';
  }

  const query = Object.entries(queryObject)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return `${prefix}${query}`;
}

export const useImperativeQuery = (query: any) => {
  const { refetch } = useQuery(query, { skip: true });

  const imperativelyCallQuery = (variables: any) => refetch(variables);

  return imperativelyCallQuery;
};
