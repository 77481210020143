import {
  SMART_LOCKS_REQUEST,
  SMART_LOCKS_REQUEST_SUCCESS,
  SMART_LOCKS_REQUEST_FAILED,
  SMART_LOCK_DETAILS_REQUEST,
  SMART_LOCK_DETAILS_REQUEST_SUCCESS,
  SMART_LOCK_DETAILS_REQUEST_FAILED,
  SmartLocksState,
} from '../types/smart-locks';
import { SmartLocksActionsTypes } from '../actions/smart-locks';

const initialState: SmartLocksState = {
  items: [],
  error: '',
  loading: false,
  selected: null,
};

export default function (
  state = initialState,
  action: SmartLocksActionsTypes,
): SmartLocksState {
  switch (action.type) {
    case SMART_LOCKS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case SMART_LOCKS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case SMART_LOCKS_REQUEST_FAILED:
    case SMART_LOCK_DETAILS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case SMART_LOCK_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
        selected: null,
      };
    }

    case SMART_LOCK_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        selected: action.payload,
      };
    }


    default: {
      return state;
    }
  }
}