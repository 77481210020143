import { Collapse, Grid } from '@material-ui/core';
import React from 'react';
import { generateLabel } from '../../../../utils/helpers';
import CustomTextField from '../../../CustomTextField';

type Props={
  novihomeApikey: string;
  isActive: boolean;
  handleChangeClient: ({
    target:
    { name, value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors: any;
};

const NovihomeAttributes: React.FC<Props> = props => {
  const {
    novihomeApikey, isActive, handleChangeClient, validationErrors,
  } = props;

  return (
    <Collapse in={isActive}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            fullWidth
            label={generateLabel('Novihome_apikey')}
            name="Novihome Apikey"
            value={novihomeApikey || ''}
            onChange={handleChangeClient}
            error={Boolean(validationErrors.Novihome_apikey)}
            helperText={validationErrors.Novihome_apikey || ''}
          />
        </Grid>
      </Grid>
    </Collapse>

  );
};

export default React.memo(NovihomeAttributes);
