import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '../../../components/CustomCheckbox';
import { Order } from '../../../utils/table';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (
    event: React.SyntheticEvent,
    property: string
  ) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
  {
    id: 'property_id',
    align: 'center',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    id: 'client.name',
    align: 'center',
    disablePadding: false,
    label: 'Client',
  },
  {
    id: 'community',
    align: 'center',
    disablePadding: false,
    label: 'Community',
  },
  {
    id: 'lock_serial_number',
    align: 'center',
    disablePadding: false,
    label: 'Lock#',
  },
  {
    id: 'smart_lock_serial_number',
    align: 'center',
    disablePadding: false,
    label: 'Smart Lock#',
  },
  {
    id: 'hub.serial_number',
    align: 'center',
    disablePadding: false,
    label: 'Device',
  },
  {
    id: 'lockStatus.status',
    align: 'center',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'propertyStatus.status',
    align: 'center',
    disablePadding: false,
    label: 'Disposition',
  },
  {
    id: 'address_1', align: 'right', disablePadding: false, label: 'Address',
  },
  {
    id: 'lock_assigned',
    align: 'center',
    disablePadding: false,
    label: 'Install Date',
  },
  {
    id: 'city',
    align: 'center',
    disablePadding: false,
    label: 'City',
  },
  {
    id: 'state',
    align: 'center',
    disablePadding: false,
    label: 'State/Province',
  },
  {
    id: 'LASSO_project_id',
    align: 'center',
    disablePadding: false,
    label: 'Integration',
  },
  {
    id: 'agents.agent_username',
    align: 'center',
    disablePadding: false,
    label: 'Agents',
  },
  {
    id: 'lock_terminated',
    align: 'center',
    disablePadding: false,
    label: 'Removal Date',
  },
];

const TableHead: React.FC<Props> = props => {
  const {
    onRequestSort,
    onSelectAllClick,
    orderBy,
    order,
    numSelected,
    rowCount,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    if (property !== 'agents.agent_username') {
      onRequestSort(event, property);
    }
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all Properties' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            size="small"
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}
            <TableSortLabel
              active={orderBy === id}
              direction={order}
              IconComponent={ArrowIcon}
              hideSortIcon
            />
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default TableHead;
