import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '../../../components/CustomCheckbox';
import { Order } from '../../../utils/table';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (
    event: React.SyntheticEvent,
    property: string
  ) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
  {
    id: 'ticket_number',
    align: 'left',
    disablePadding: false,
    label: 'Ticket Number',
  },
  {
    id: 'property_id',
    align: 'left',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    id: 'property.lock_serial_number',
    align: 'left',
    disablePadding: false,
    label: 'Lock SN',
  },
  {
    id: 'property.smart_lock_serial_number',
    align: 'left',
    disablePadding: false,
    label: 'Smart Lock SN',
  },
  {
    id: 'property.hub.serial_number',
    align: 'left',
    disablePadding: false,
    label: 'Device',
  },
  {
    id: 'customer_firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'customer_lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'cell_phone_1',
    align: 'left',
    disablePadding: false,
    label: 'Phone number',
  },
  {
    id: 'client',
    align: 'left',
    disablePadding: false,
    label: 'Client name',
  },
  {
    id: 'open_date',
    align: 'left',
    disablePadding: false,
    label: 'Open date',
  },
  {
    id: 'statusEntity.status',
    align: 'left',
    disablePadding: false,
    label: 'Lock status',
  },
  {
    id: 'issue_diagnosis',
    align: 'left',
    disablePadding: false,
    label: 'Issue diagnosis',
  },
  {
    id: 'resolution_notes',
    align: 'left',
    disablePadding: false,
    label: 'Resolution notes',
  },
];

const TableHead: React.FC<Props> = props => {
  const {
    onRequestSort,
    onSelectAllClick,
    orderBy,
    order,
    numSelected,
    rowCount,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all Tickets' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}
            <TableSortLabel
              active={orderBy === id}
              direction={order}
              IconComponent={ArrowIcon}
            />
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
