import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { Order } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
  {
    id: 'serial_number',
    align: 'center',
    disablePadding: false,
    label: 'Serial Number',
  },
  {
    id: 'device_id',
    align: 'center',
    disablePadding: false,
    label: 'Device Id',
  },
  {
    id: 'statusEntity.status',
    align: 'center',
    disablePadding: false,
    label: 'Smart Lock Status',
  },
  {
    id: 'client.name',
    align: 'right',
    disablePadding: false,
    label: 'Client',
  },
  {
    id: 'property.property_id',
    align: 'center',
    disablePadding: false,
    label: 'Property id',
  },
  {
    id: 'created_on',
    align: 'center',
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'created_by',
    align: 'center',
    disablePadding: false,
    label: 'Created by',
  },
  {
    id: 'modified_on',
    align: 'center',
    disablePadding: false,
    label: 'Modified on',
  },
  {
    id: 'modified_by',
    align: 'center',
    disablePadding: false,
    label: 'Modified by',
  },
];

const TableHead: React.FC<Props> = props => {
  const {
    onSelectAllClick, onRequestSort, orderBy, order, numSelected, rowCount,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all smart locks' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}
            <TableSortLabel
              active={orderBy === id}
              direction={order}
              IconComponent={ArrowIcon}
            />
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
