import { gql } from 'apollo-boost';

export const GET_USER = gql`
  query($id: String!) {
    user(id: $id) {
      builder_id
      username
      firstname
      lastname
      phone
      email
      send_sms
      send_email
      notification_email
      groups {
        GroupID
        Label
      }
      active
    }
  }
`;

export const GET_PROFILE = gql`
  query($id: String!) {
    user(id: $id) {
      username
      firstname
      lastname
      phone
      email
    }
  }
`;

export const GET_STATIC = gql`
  query {
    userGroups {
      Label
      GroupID
    }
    builders {
      builder_id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($user: UpdateUser!) {
    updateUser(user: $user) {
      username
      firstname
      lastname
      phone
      email
      notification_email
      groups {
        GroupID
        Label
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateUser($user: UpdateUser!) {
    updateProfile(user: $user) {
      username
      firstName: firstname
      lastName: lastname
      phone
      email
    }
  }
`;
