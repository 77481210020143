import React, {
  RefObject, useState, useContext, useEffect,
} from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { SvgIcon } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import styles from './styles';
import useUrlLocation from '../../hooks/useUrlLocation';
import { FilterContext } from '../../App';

type Props = {
  searchRequest?: () => void,
  setFilterState: (payload: any) => void,
  searchBarRef: RefObject<HTMLDivElement> | null
} & WithStyles<typeof styles>;

const SearchFilterElement: React.FC<Props> = props => {
  const {
    setFilterState, searchRequest, classes, searchBarRef,
  } = props;
  const path = useUrlLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = () => {
    if (searchBarRef && searchBarRef.current) {
      setAnchorEl(searchBarRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const filterContext = useContext(FilterContext);
  const filterContextState = filterContext.setState;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const handleStartDateChange = (dateInfo: Date | null) => {
    setStartDate(dateInfo);
    setFilterState({
      name: 'startDate',
      value: dateInfo,
      type: path,
    });
  };
  const handleEndDateChange = (dateInfo: Date | null) => {
    setEndDate(dateInfo);
    setFilterState({
      name: 'endDate',
      value: dateInfo,
      type: path,
    });
  };

  useEffect(() => {
    filterContextState({
      startDate,
      endDate,
    });
  }, [startDate, endDate, filterContextState]);

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <SvgIcon>
          <path d="M0.392477 0C0.239263 0 0.10112 0.090411 0.0358153 0.228539C-0.0269773 0.366667 -0.00688366 0.529909 0.0935844 0.647945L7.6086 9.43037V21.6107C7.6086 21.7438 7.67893 21.8694 7.79195 21.9397C7.90498 22.0126 8.04815 22.0201 8.16871 21.9623L14.1717 19.1345C14.3098 19.0692 14.3952 18.9285 14.3952 18.7779V9.42786L21.9077 0.645434C22.0057 0.529909 22.0283 0.366667 21.9655 0.228539C21.9027 0.090411 21.7646 0.00251142 21.6138 0H0.392477Z" fill="#DB5C0E" />
        </SvgIcon>
        <h4>Filter</h4>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Select date</InputLabel>
          <Select>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="start-date-picker-inline"
                label="Start date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="end-date-picker-inline"
                label="End date"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={searchRequest}
        >
          Apply
        </Button>
      </Menu>
    </>
  );
};

export default SearchFilterElement;
