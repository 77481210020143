import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/styles';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { authUserRequest as AuthUserRequest } from '../../redux/actions/auth';
import { isUserAuthorized } from '../../services/auth';
import { resetRequest } from '../../services/reset-password';

import styles from './styles';

type Props = {
  authUserRequest: typeof AuthUserRequest;
  loading: boolean;
} & WithStyles<typeof styles>;

const Login: React.FC<Props> = ({ authUserRequest, classes, loading }) => {
  const [formData, setFormData] = useState<{ username: string, password: string, email: string }>({
    username: '',
    password: '',
    email: '',
  });
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [requesting, setRequesting] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<null | string>(null);
  const isAuthenticated = isUserAuthorized();
  const disabled = !formData.username || !formData.password;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = formData;

    authUserRequest({ username, password });
  };

  const handleSubmitDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { username, email } = formData;
    setRequesting(true);
    resetRequest({ username, email })
      .then(() => {
        setOpen(false);
        enqueueSnackbar(`Email was sent to ${email}`, { variant: 'success' });
      })
      .catch((err: AxiosError) => {
        const { response } = err;
        if (response && response.status) {
          setErrorText('Incorrect credentials');
        }
      })
      .finally(() => {
        setRequesting(false);
      });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setFormData(prevState => ({ ...prevState, [name]: value }));
    setErrorText(null);
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(false);
    setErrorText(null);
  };

  return isAuthenticated ? (<Redirect to="/" />)
    : (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.container}
      >
        <Typography component="h1" variant="h4">
          Sign in
        </Typography>
        <form
          noValidate
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <TextField
            required
            autoFocus
            fullWidth
            margin="normal"
            variant="filled"
            id="username"
            label="Username"
            name="username"
            onChange={handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            variant="filled"
            id="password"
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
          />
          <Grid item xs={12}>
            <Grid container justify="space-between" alignItems="center">
              <Button
                onClick={handleOpen}
              >
                Forgot password?
              </Button>
              <div className={classes.progressButtonWrapper}>
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  disabled={disabled}
                >
                  Sign In
                </Button>
                {loading && <CircularProgress size={24} className={classes.progress} />}
              </div>
            </Grid>
          </Grid>
        </form>
        <Dialog
          open={open}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Forgot password?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your username and email.
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              error={Boolean(errorText)}
              margin="normal"
              id="dialog-username"
              name="username"
              label="Username"
              value={formData.username}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              error={Boolean(errorText)}
              margin="normal"
              id="dialog-email"
              name="email"
              label="Email Address"
              type="email"
              value={formData.email}
              onChange={handleChange}
              helperText={errorText}
            />
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="contained"
              onClick={handleClose}
              disabled={requesting}
            >
              Cancel
            </Button>
            <div className={classes.progressButtonWrapper}>
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmitDialog}
                disabled={!formData.username || !formData.email || requesting}
              >
                Submit
              </Button>
              {requesting && <CircularProgress size={24} className={classes.progress} />}
            </div>
          </DialogActions>
        </Dialog>
      </Grid>
    );
};

export default Login;
