import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const shipmentCreateSchema = yup.object().shape({
  cost: yup.number().required(),
  qty: yup.number().required(),
  carrier: yup.string(),
  date: yup.date().required(),
  tracking_number: yup.string(),
  status_id: yup.string().required(),
  builder_id: yup.number().required(),
  lockSerialNumber: yup.array().of(
    yup.string()).required(),
});
