import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    marginTop: '33px',
    boxShadow: theme.shadows[3],
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  controlButton: {
    marginBottom: '25px',
  },
  tableHeaderText: {
    marginLeft: '23px',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
