import { gql } from 'apollo-boost';
import { clientFields } from '../../types/Client';

export const ALL_CLIENTS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    builderList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        ${clientFields.join('\n')}
        builderIntegrations {
          integration_id
          isActive
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_CLIENT = gql`
  mutation($builder_id: Int!) {
    deleteBuilder(builder_id: $builder_id) {
      builder_id
    }
  }
`;

export const REMOVE_CLIENTS = gql`
  mutation($builders: [Int!]!) {
    deleteBuildersById(builders: $builders)
  }
`;
export const SEARCH_BUILDERS = gql`
  query($name: String!) {
    builders: searchBuilders (name: $name) {
      builder_id,
      name,
    }
  }
`;

export const GET_BUILDER_BY_ID = gql`
  query($id: Float!) {
    builder: searchBuilderById (id: $id) {
      builder_id,
      name,
    }
  }
`;
