import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import useStyles from './styles';

type Props = {
  variant: 'white' | 'orange'
  loading?: boolean;
} & Omit<ButtonProps, 'variant'>;

const CustomButton: React.FC<Props> = (
  {
    children, variant, loading, disabled, ...props
  },
) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled || loading}
      className={clsx({
        [classes.white]: variant === 'white',
        [classes.orange]: variant === 'orange',
      })}
      {...props}
    >
      { loading ? <Spinner color="inherit" size={24} /> : children}
    </Button>
  );
};

export default CustomButton;
