import React, { useState } from 'react';

import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';

interface Props {
  register: any;
}

const SCRIPTS = [
  'HUB:zigbee/scripts/start_include',
  'HUB:zigbee/scripts/stop_include',
  'HUB:zigbee/scripts/reset',
  'HUB:zigbee/scripts/device_remove',
  'HUB:zigbee/scripts/stop_fast_poll',
];

const SCRIPT_DEVICE_REMOVE = 'HUB:zigbee/scripts/device_remove';
const SCRIPT_STOP_FAST_POLL = 'HUB:zigbee/scripts/stop_fast_poll';

const RunZigbeeScriptComponent = ({ register }: Props) => {
  const [script, setScript] = useState<string>('');

  return (
    <>
      <FormControl>
        <FormLabel id="radio-buttons-group-label">script</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue=""
          name="script"
          onChange={(event, value) => setScript(value)}
        >
          {SCRIPTS.map(item => (
            <FormControlLabel value={item} control={<Radio inputRef={register} />} label={item} />
          ))}
        </RadioGroup>
      </FormControl>
      {[SCRIPT_DEVICE_REMOVE, SCRIPT_STOP_FAST_POLL].includes(script) && (
        <>
          <p>scriptParams</p>
          <TextField
            margin="normal"
            fullWidth
            inputRef={input => register(input, { required: true })}
            required
            label="deviceId"
            key="deviceId"
            name="scriptParams.deviceId"
          />
        </>
      )}
    </>
  );
};

export default RunZigbeeScriptComponent;
