import React, { useCallback, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import InputMask, { InputState } from 'react-input-mask';
import { useSnackbar } from 'notistack';
import TextField from '../CustomTextField';
import styles from './styles';
import { selectAuthData } from '../../redux/selectors/auth';
import useUser from '../../hooks/useUser';
import InputUser from '../../types/inputTypes/User';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { updateProfileSchema } from '../UserDetails/userValidation';

type Props = WithStyles<typeof styles> & {userLogout: () => void};

const EditProfile: React.FC<Props> = props => {
  const { classes, userLogout } = props;
  const { username } = useSelector(selectAuthData);
  const { user, setUser, updateUser } = useUser(username);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<InputUser>>({});
  const dispatch = useDispatch();

  const handleChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setUser((prevState: InputUser) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }, [setUser],
  );

  function handleSubmit(event: React.SyntheticEvent): void {
    event.preventDefault();
    if (Boolean(user.pass_word) && user.pass_word !== confirmPassword) {
      return setPasswordError(true);
    }
    try {
      setValidationErrors({});
      setPasswordError(false);
      updateProfileSchema.validateSync(user, { abortEarly: false });
      updateUser({
        variables: {
          user,
        },
      }).then(res => {
        enqueueSnackbar('Updated', { variant: 'success' });
        if (res.data?.updateProfile) {
          dispatch({ type: 'UPDATE_PROFILE_REQUEST_SUCCESS', payload: res.data.updateProfile });
        }
      })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<InputUser>(errors));
    }
  }

  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              disabled
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={user.username}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="firstname"
              label="First Name"
              name="firstname"
              onChange={handleChange}
              value={user.firstname}
              error={Boolean(validationErrors.firstname)}
              helperText={validationErrors.firstname || ''}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="lastname"
              label="Last Name"
              name="lastname"
              onChange={handleChange}
              value={user.lastname}
              error={Boolean(validationErrors.lastname)}
              helperText={validationErrors.lastname || ''}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <InputMask
              mask="999-999-9999"
              onChange={handleChange}
              value={user.phone}
            >
              {(inputProps: InputState) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  error={Boolean(validationErrors.phone)}
                  helperText={validationErrors.phone || ''}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              onChange={handleChange}
              value={user.email}
              error={Boolean(validationErrors.email)}
              helperText={validationErrors.email || ''}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="pass_word"
              label="New Password"
              name="pass_word"
              type="password"
              onChange={handleChange}
              value={user.pass_word ?? ''}
              error={Boolean(validationErrors.pass_word)}
              helperText={validationErrors.pass_word || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={({ target: { value } }) => setConfirmPassword(value)}
              error={passwordError}
              helperText={passwordError ? 'Passwords does not match' : ''}
            />
          </Grid>
        </Grid>
        <div className={classes.cardActions}>
          <Grid container>
            <Grid item xs>
              <Button
                type="submit"
                variant="contained"
                className={classnames(classes.button, classes.saveButton)}
              >
                Save
              </Button>
              <Button
                variant="contained"
                className={classnames(classes.button, classes.logOutButton)}
                onClick={userLogout}
              >
                Log out
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default React.memo(EditProfile);
