import React, { useRef } from 'react';
import MatTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import TableHead from './TableHead';
import { parseDateWithoutTimezone } from '../../../utils/helpers';
import styles from './PropertiesTableStyles';
import { Order, isSelected } from '../../../utils/table';
import { Property } from '../../../types/Property';
import PropertyAgents from '../PropertyAgents';
import Status from '../../../components/LockStatus';


type Props = {
  properties: Array<Property>;
  order: Order;
  orderBy: string;
  rowsPerPage: number;
  onRowClick: (id: Property['property_id']) => void;
  onSelect: (event: React.SyntheticEvent, id: Property['property_id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
  selected: Array<Property['property_id']>;
} & WithStyles<typeof styles>;

const PropertiesTable: React.FC<Props> = props => {
  const {
    properties,
    classes,
    order,
    orderBy,
    onSelect,
    onSelectAll,
    onChangeOrder,
    onRowClick,
    selected,
  } = props;

  function handleRequestSort(
    event: React.SyntheticEvent,
    property: string,
  ) {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={13} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          rowCount={properties.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {properties.map((property: Property, index) => {
            const isItemSelected: boolean = isSelected(
              property.property_id,
              selected,
            );
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const agents = property.agents?.map(agent => `${agent.agent_username} ${agent.user?.email ?? ''} ${agent.user?.phone}`);

            const renderLockStatus = () => {
              return <Status value={property.lock?.lockStatus ? property.lock?.lockStatus : ''} />
            }

            const renderSmartLockStatus = () => {
              return <Status value={property.smartLock?.smartLockStatus ? property.smartLock?.smartLockStatus : ''} />
            }
            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={property.property_id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={event => { event.stopPropagation(); onRowClick(property.property_id); }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, property.property_id);
                    }}
                  />
                </TableCell>
                <TableCell align="center" id={labelId}>
                  {property.property_id}
                </TableCell>
                <TableCell align="center">
                  {property.client?.name}
                </TableCell>
                <TableCell align="center">{property.community}</TableCell>
                <TableCell align="center">
                  {property.lock ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/inventory/${property.lock.serial_number}`}>{property.lock_terminated ? '' : property.lock.serial_number }</Link>
                    : '' }
                </TableCell>
                <TableCell align="center">
                  {property.smartLock ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/smart-inventory/${property.smartLock.serial_number}`}>{property.lock_terminated ? '' : property.smartLock.serial_number }</Link>
                    : '' }
                </TableCell>
                <TableCell align="center">
                  {property.hs ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/devices/${property.hs}`}>{property.hs }</Link>
                    : '' }
                </TableCell>
                <TableCell align="center">
                  { property.lock.lockStatus ?? renderLockStatus() }
                  { property.smartLock.smartLockStatus ?? renderSmartLockStatus() }
                </TableCell>
                <TableCell align="center">{property.disposition ?? 'N / A'}</TableCell>
                <TableCell align="center">
                  { `${property.house_num ?? ''} ${property.address_1}` }
                </TableCell>
                <TableCell align="center">
                  { property.lock_assigned !== null ? parseDateWithoutTimezone(property.lock_assigned) : ''}
                </TableCell>
                <TableCell align="center">
                  { property.city }
                </TableCell>
                <TableCell align="center">
                  { property.state }
                </TableCell>
                <TableCell align="center">
                  <Checkbox
                    color="secondary"
                    name="LASSO_project_id"
                    checked={Boolean(property.LASSO_project_id)}
                    disabled
                  />
                </TableCell>
                <TableCell
                  align="center"
                >
                  <PropertyAgents agents={agents} />
                </TableCell>
                <TableCell align="center">
                  { property.lock_assigned !== null ? parseDateWithoutTimezone(property.lock_terminated) : ''}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(PropertiesTable);
