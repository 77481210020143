import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from '../StatusSelect/styles';
import { Catalog } from '../../types/Customer';
import useTimezones from '../../hooks/useTimezones';

type Props = {defaultTimezone?: number} & TextFieldProps;

const TimezoneSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { defaultTimezone, ...textFieldProps } = props;
  const timezones: Catalog[] = useTimezones();
  return (
    <>
      <TextField
        margin="normal"
        fullWidth
        {...textFieldProps}
        value={defaultTimezone}
        select
        inputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
      >
        <MenuItem
          key={0}
          value={0}
        >
          Choose a timezone
        </MenuItem>
        {timezones.map(({ catalog_id, value }) => (
          <MenuItem
            key={catalog_id}
            value={catalog_id}
          >
            {value}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default React.memo(TimezoneSelect);
