import React from 'react';
import { InputAdornment } from '@material-ui/core';
import SecurityIcon from '@material-ui/icons/Security';
import useStyles from './styles';

const OptInAdornment: React.FC = () => {
  const classes = useStyles();
  return (
    <InputAdornment position="start">
      <SecurityIcon className={classes.adornment} />
    </InputAdornment>
  );
};
export default OptInAdornment;
