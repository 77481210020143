import { SET_FILTER_DATA } from '../types/filter';

interface SetFilterPayload {
  name: string,
  value: string | unknown,
  type: string,
}

export const setFilter = (payload: SetFilterPayload) => ({
  type: SET_FILTER_DATA,
  payload,

} as const);

export type setFilterActionType = ReturnType<typeof setFilter>;
