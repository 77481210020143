import React from 'react';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/styles';
import {
  Switch, Route, Redirect, RouteComponentProps,
} from 'react-router-dom';
import Tabs from '../../components/CustomTabs';
import EditProfile from '../../components/EditProfile';
import NotFound from '../../pages/NotFound';

import styles from './styles';
import ApiKeys from '../../components/ApiKeys';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const tabs = {
  'edit-profile': 'Edit profile',
  'test-texting': 'Test texting',
  subscriptions: 'Subscriptions',
  billing: 'Billing',
  'api-keys': 'API Keys',
} as {[key: string]: string};

const Account: React.FC<Props> = props => {
  const {
    classes, location, history, match,
  } = props;
  // const [tabIndex, setTabIndex] = useState<number>(0);

  function toggleTab(key: string): () => void {
    return () => {
      history.push(key);
    };
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Tabs value={location.pathname.split('/')[2]}>
          {Object.entries(tabs).map(([key, label]) => (
            <Tab key={key} value={key} label={label} onClick={toggleTab(key)} />
          ))}
        </Tabs>
      </div>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>{tabs[location.pathname.split('/')[2]]}</Typography>
      </div>
      <div className={classes.content}>
        <Switch>
          <Route path="/account" exact component={() => <Redirect to={`${match.path}/edit-profile`} />} />
          <Route path={`${match.path}/edit-profile`} exact component={EditProfile} />
          <Route path={`${match.path}/api-keys`} exact component={ApiKeys} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default React.memo(Account);
