// Clone from https://github.com/hivivo/ngx-json-viewer
import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import useStyles from './styles';

export interface Segment {
  key: string;
  value: any;
  type: undefined | string;
  description: string;
  expanded: boolean;
}

interface Props {
  json: any;
  expanded?: boolean;
  depth?: number;

  _currentDepth?: number;
}

const JsonViewer = ({
  json, expanded = true, depth = -1, _currentDepth = 0,
}: Props) => {
  const classes = useStyles();
  const [segments, setSegments] = useState<Segment[]>([]);

  const isExpanded = (): boolean => (
    expanded
    && !(depth > -1 && _currentDepth >= depth)
  );

  const copyToClipboard = () => {
    const container = document.createElement('textarea');

    container.innerHTML = JSON.stringify(
      json,
      null,
      '  ',
    );

    document.body.appendChild(container);
    container.select();
    document.execCommand('copy');

    document.body.removeChild(container);
  };

  const decycle = (object: any) => {
    const objects = new WeakMap();
    return (function derez(value, path) {
      let oldPath;
      let nu: any;

      if (
        typeof value === 'object'
        && value !== null
        && !(value instanceof Boolean)
        && !(value instanceof Date)
        && !(value instanceof Number)
        && !(value instanceof RegExp)
        && !(value instanceof String)
      ) {
        oldPath = objects.get(value);
        if (oldPath !== undefined) {
          return { $ref: oldPath };
        }
        objects.set(value, path);

        if (Array.isArray(value)) {
          nu = [];
          value.forEach((element, i) => {
            nu[i] = derez(element, `${path}[${i}]`);
          });
        } else {
          nu = {};
          Object.keys(value).forEach(name => {
            nu[name] = derez(
              value[name],
              `${path}[${JSON.stringify(name)}]`,
            );
          });
        }
        return nu;
      }
      return value;
    }(object, '$'));
  };

  const parseKeyValue = (key: string, value: object): Segment => {
    const segment: Segment = {
      key,
      value,
      type: undefined,
      description: `${value}`,
      expanded: isExpanded(),
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = 'number';
        break;
      }
      case 'boolean': {
        segment.type = 'boolean';
        break;
      }
      case 'function': {
        segment.type = 'function';
        break;
      }
      case 'string': {
        segment.type = 'string';
        segment.description = `"${segment.value}"`;
        break;
      }
      case 'undefined': {
        segment.type = 'undefined';
        segment.description = 'undefined';
        break;
      }
      case 'object': {
        // yea, null is object
        if (segment.value === null) {
          segment.type = 'null';
          segment.description = 'null';
        } else if (Array.isArray(segment.value)) {
          segment.type = 'array';
          segment.description = `Array[${segment.value.length}] ${JSON.stringify(segment.value)}`;
        } else if (segment.value instanceof Date) {
          segment.type = 'date';
        } else {
          segment.type = 'object';
          segment.description = `Object ${JSON.stringify(segment.value)}`;
        }
        break;
      }
      default:
        break;
    }

    return segment;
  };

  useEffect(() => {
    // remove cycles
    const dataJson = decycle(json);
    if (!dataJson) {
      return;
    }
    const seg = [];
    if (typeof dataJson === 'object') {
      Object.keys(dataJson).forEach(key => {
        seg.push(parseKeyValue(key, dataJson[key]));
      });
    } else {
      seg.push(parseKeyValue(`(${typeof dataJson})`, dataJson));
    }
    setSegments(seg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [json]);

  const isExpandable = (segment: Segment) => segment.type === 'object' || segment.type === 'array';

  const toggle = (segment: Segment, index: number) => {
    if (isExpandable(segment)) {
      setSegments(preState => [
        ...preState.slice(0, index),
        { ...segment, expanded: !segment.expanded },
        ...preState.slice(index + 1)]);
    }
  };

  return (
    <section className={classes.ngxJsonViewer}>
      { _currentDepth === 0
        && <Button className={classes.btnCopy} onClick={copyToClipboard}>Copy</Button>
      }
      {segments.map((segment, index) => (
        <section key={segment.key} className={`segment segment-type-${segment.type}`}>
          <section
            aria-hidden="true"
            onClick={() => toggle(segment, index)}
            className={`segment-main ${isExpandable(segment) ? 'expandable' : ''} ${segment.expanded ? 'expanded' : ''}`}
          >
            { isExpandable(segment) && <div className="toggler" />}
            <span className="segment-key">{ segment.key }</span>
            <span className="segment-separator">: </span>
            { (!segment.expanded || !isExpandable(segment)) && <span className="segment-value">{ segment.description }</span>}
          </section>
          {segment.expanded && isExpandable(segment) && (
          <section className="children">
            <JsonViewer
              json={segment.value}
              expanded={expanded}
              depth={depth}
              _currentDepth={_currentDepth + 1}
            />
          </section>
          )}
        </section>
      ))}
    </section>
  );
};

export default JsonViewer;
