import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getItem, clear } from './storage';


export const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_API_GRAPH_QL,
  request: operation => {
    const token = getItem('auth_token');
    operation.setContext({
      headers: {
        'x-auth-token': token,
      },
    });
  },
  cache: new InMemoryCache({
    addTypename: false,
  }),
  onError: (error: any) => {
    // Logout on 401 error
    if (error.networkError && error.networkError.statusCode === 401) {
      clear();
      window.location.reload();
    }
  },
});

export const getApolloClientWithToken = (token: string) => new ApolloClient({
  uri: process.env.REACT_APP_API_GRAPH_QL,
  request: operation => {
    operation.setContext({
      headers: {
        'x-auth-token': token,
      },
    });
  },
});
