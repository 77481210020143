export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_USER_REQUEST = 'AUTH_USER_REQUEST';

export const GET_OAUTH_CLIENTS_REQUEST = 'GET_OAUTH_CLIENTS_REQUEST';
export const GET_OAUTH_CLIENTS_SUCCESS = 'GET_OAUTH_CLIENTS_SUCCESS';
export const GET_OAUTH_CLIENTS_ERROR = 'GET_OAUTH_CLIENTS_ERROR';

export const REMOVE_OAUTH_CLIENTS_REQUEST = 'REMOVE_OAUTH_CLIENTS_REQUEST';
export const REMOVE_OAUTH_CLIENTS_SUCCESS = 'REMOVE_OAUTH_CLIENTS_SUCCESS';
export const REMOVE_OAUTH_CLIENTS_ERROR = 'REMOVE_OAUTH_CLIENTS_ERROR';

export const CREATE_OAUTH_CLIENTS_REQUEST = 'CREATE_OAUTH_CLIENTS_REQUEST';
export const CREATE_OAUTH_CLIENTS_SUCCESS = 'CREATE_OAUTH_CLIENTS_SUCCESS';
export const CREATE_OAUTH_CLIENTS_ERROR = 'CREATE_OAUTH_CLIENTS_ERROR';
export const UPDATE_PROFILE_REQUEST_SUCCESS = 'UPDATE_PROFILE_REQUEST_SUCCESS';

export type AuthRequest = {
  username: string;
  password: string;
};

export type ErrorMessage = {
  message: string;
};

export type AuthSuccess = {
  token: string;
  username: string;
  firstName: string;
  lastName: string;
  builderId: number;
  email: string;
  phone: string;
  isPM: boolean;
  isAdmin: boolean;
};

export type AuthState = {
  token: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  builderId: number;
  isPM: boolean;
  isAdmin: boolean;
  loading: boolean;
  error: ErrorMessage['message'];
  oauthClients: OauthClient[];
};

export type OauthClient = {
  id: number,
  client_secret: string,
  client_id: string,
  redirect_uri: string | null,
  user_id: string,
  created_at: string,
  updated_at: string,
};
