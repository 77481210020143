import { useContext, useEffect, useState } from 'react';
import { StaticDataContext } from '../context/StaticDataProvider';
import { Catalog } from '../types/Customer';
import { StaticData } from './useStaticData';

type StaticCatalogs = {
  sources: Catalog[],
  statuses: Catalog[],
  types: Catalog[],
};

export default function useStaticCatalogs() {
  const staticData = useContext<StaticData>(StaticDataContext);
  const [catalogs, setCatalogs] = useState<StaticCatalogs>({
    types: [],
    statuses: [],
    sources: [],
  });
  useEffect(() => {
    if (staticData) {
      setCatalogs({
        sources: staticData.sources,
        statuses: staticData.statuses,
        types: staticData.types,
      });
    }
  }, [staticData]);
  return catalogs;
}
