import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useFormType from './useFormType';
import InputUser from '../types/inputTypes/User';
import { GET_PROFILE, UPDATE_PROFILE } from '../graphql/queries/UserDetails';

export default function useUser(username? : string) {
  const [user, setUser] = useState<InputUser>(new InputUser());
  const formType = useFormType(username);
  const { data } = useQuery<{ user: InputUser }>(
    GET_PROFILE,
    {
      variables: { id: username },
      skip: formType === 'add' || !username,
      fetchPolicy: 'network-only',
    },
  );
  useEffect(() => {
    if (data?.user) {
      setUser(data.user);
    }
  }, [data]);

  const [updateUser] = useMutation<{ updateProfile: InputUser }>(
    UPDATE_PROFILE,
    { errorPolicy: 'all' },
  );

  return {
    user, formType, setUser, updateUser,
  };
}
