import { gql } from 'apollo-boost';
import { Customer } from '../../types/Customer';

const fields = Object.keys(new Customer());
const customerFields = fields.filter(e => !['last_status', 'last_property', 'last_visit', 'cell_phone_1', 'platform', 'status', 'catalog'].includes(e));
export const ALL_CUSTOMERS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    customerList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        ${customerFields.join('\n')},
        cell_phone_1,
        firstname,
        lastname,
        created_on,
        catalog {
          value
        },
        total_visits,
        customerStatus,
        status {
          value
        },
        last_visit {
          modified_on,
          property_id,
          property {
            timezone
          }
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_CUSTOMER = gql`
  mutation($customer_id: Int!) {
    deleteCustomer(customer_id: $customer_id) {
      customer_id
    }
  }
`;

export const REMOVE_CUSTOMERS = gql`
  mutation($customers: [Int!]!) {
    deleteMultipleCustomersById(customers: $customers)
  }
`;
