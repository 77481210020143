import { createStyles, makeStyles } from '@material-ui/core';
import { mapKeyValue } from '../../utils/object';


const typeColors = {
  string: '#FF6B6B',
  number: '#009688',
  boolean: '#B938A4',
  date: '#05668D',

  array: '#999',
  object: '#999',
  function: '#999',

  null: '#999',
  undefined: '#999',
};

const styles = () => createStyles({
  btnCopy: {
    float: 'right',
  },
  ngxJsonViewer: {
    padding: 8,
    backgroundColor: 'rgb(245 245 245)',
    borderRadius: 5,
    fontSize: 18,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& .segment': {
      padding: 2,
      margin: '1px 1px 1px 12px',

      '& .segment-main': {
        wordWrap: 'break-word',

        '& .toggler': {
          position: 'absolute',
          marginLeft: -14,
          marginTop: 3,
          fontSize: '.8em',
          lineHeight: '1.2em',
          verticalAlign: 'middle',
          color: '#787878',
        },

        '& .segment-key': {
          color: '#4E187C',
        },

        '& .segment-separator': {
          color: '#999',
        },

        '& .segment-value': {
          color: '#000',
        },
      },

      '& .toggler:after': {
        display: 'inline-block',
        content: '"►"',
        transition: 'transform 0.1s ease-in',
      },

      '& .children': {
        marginLeft: '12px',
      },
      ...mapKeyValue(typeColors,
        (value: any, key: string) => `&.segment-type-${key} > .segment-main > .segment-value`,
        (value: any) => ({ color: value })),
      '&.segment-type-object > .segment-main': {
        whiteSpace: 'nowrap',
      },
      '&.segment-type-array > .segment-main': {
        whiteSpace: 'nowrap',
      },
      '& .expanded > .toggler:after': {
        transform: 'rotate(90deg)',
      },
      '& .expandable': {
        cursor: 'pointer',
      },
      '& .expandable > .toggler': {
        cursor: 'pointer',
      },
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
