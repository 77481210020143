import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { useQuery } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import Spinner from '@material-ui/core/CircularProgress';
import Tabs from '../../components/CustomTabs';
import Socket from '../../services/socket';
import EzloCommandComponent from './EzloCommandComponent';
import EzloRequestResponseComponent from './EzloRequestResponseComponent';
import {
  BroadcastItem,
  BroadcastMsgSubclass,
  CommandEnum,
  ConnectStatus,
  DeviceInfo,
  DeviceProperty,
  DevicePropertyName,
  EzloResponseId,
  LockProperty,
  NetworkMobile,
  UserCodeKeyValue,
} from './constants';
import useStyles from './styles';
import { HubServerRelay } from '../../types/inputTypes/HubAccount';
import { HUB_SERVER_REPLAY_BY_ID } from '../../graphql/queries/HubAccounts';
import {
  buildMessage, extractDeviceProperties, extractLockDevice, extractNetworkMobile,
} from './helper';
import ConfirmDelete from '../../components/ConfirmDelete';
import DialogEditUserCode from './Components/DialogEditUserCode';
import DialogAddNewUserCode from './Components/DialogAddNewUserCode';
import { getQueryObject } from '../../utils/helpers';
import InputDevice from '../../types/inputTypes/Device';
import { DEVICE_BY_ID } from '../../graphql/queries/Devices';
import { setAuth } from '../../services/auth';

const initMessage = (MMSAuth: string, MMSAuthSig: string) => ({
  method: 'loginUserMios',
  id: 'loginUser',
  params: {
    MMSAuth,
    MMSAuthSig,
  },
});

const buildRegisterMessage = (id: string) => ({
  method: 'register', id: 'register', jsonrpc: '2.0', params: { serial: id },
});

type Props = RouteComponentProps;

const EzloMobile : React.FC<Props> = props => {
  const { history } = props;
  const query = getQueryObject(history.location.search);
  const queryDeviceId = query.get('deviceId');
  const token = query.get('token') as string;
  setAuth(token);

  const { enqueueSnackbar } = useSnackbar();
  const socket = useRef<Socket>(Socket.newInstance());

  useEffect(() => () => socket.current.close(), []);

  const [connectStatus, setConnectStatus] = useState<ConnectStatus>(ConnectStatus.NotConnect);
  const [broadcastMessages, setBroadcastMessages] = useState<object[]>([]);
  const [requestData, setRequestData] = useState<object>({});
  const [responseData, setResponseData] = useState<object>({});
  const [hubAccountId, setHubAccountId] = useState<string>('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [deviceId, setDeviceId] = useState<string>('');
  const [lockDevice, setLockDevices] = useState<DeviceInfo | undefined>();
  const [lockProperties, setLockProperties] = useState<LockProperty>();
  const [networkInfo, setNetworkInfo] = useState<NetworkMobile>();
  const [deviceProperties, setDeviceProperties] = useState<DeviceProperty[]>();
  const [userCodeKeyToRemove, setUserCodeKeyToRemove] = useState<string>();
  const [userCodeKeyToEdit, setUserCodeKeyToEdit] = useState<UserCodeKeyValue>();
  const [openAddNewCode, setOpenAddNewCode] = useState<boolean>(false);
  const classes = useStyles();

  const { data: hubServers } = useQuery<{ getListEzloHubServerRelay: HubServerRelay[] }>(
    HUB_SERVER_REPLAY_BY_ID,
    {
      variables: { id: hubAccountId },
      fetchPolicy: 'network-only',
      skip: !hubAccountId,
    },
  );

  const { data: dataHubDetail } = useQuery<{ getHubById: InputDevice }>(
    DEVICE_BY_ID,
    {
      variables: { serial_number: queryDeviceId },
      fetchPolicy: 'network-only',
      skip: !queryDeviceId,
    },
  );

  const handleSendCommand = (data: object) => {
    setRequestData(data);
    socket.current.send(data);
  };

  const handleSelectAccount = (id: string) => {
    setHubAccountId(id);
    // Reset all info
    socket.current.close();
    setBroadcastMessages([]);
    setRequestData({});
    setResponseData({});
    setDeviceId('');
    setConnectStatus(ConnectStatus.NotConnect);
    setLockDevices(undefined);
  };

  useEffect(() => {
    if (dataHubDetail && queryDeviceId) {
      handleSelectAccount(dataHubDetail.getHubById.hub_account_id);
      setDeviceId(queryDeviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataHubDetail]);

  const resetAll = () => {
    setBroadcastMessages([]);
    setRequestData({});
    setResponseData({});
    setConnectStatus(sts => (sts !== ConnectStatus.Connecting ? ConnectStatus.NotConnect : sts));
    setLockDevices(undefined);
  };

  const disconnect = () => {
    if (queryDeviceId) {
      history.push('/ezlo-tool');
    }
    socket.current.close();
  };

  const loadDeviceInfo = () => {
    socket.current.send(buildMessage(CommandEnum.HubDevicesList, {}, CommandEnum.HubDevicesList));
  };

  const handleReboot = () => {
    handleSendCommand(buildMessage(CommandEnum.HubReboot, {}));
  };

  const handleChangeToVerizon = () => {
    handleSendCommand(buildMessage(CommandEnum.HubNetworkReconnect, {
      interfaceId: 'mobile0',
      operator: 'Hologram/Verizon',
      initializeAt: 'AT&F1&R2%7=60',
      dialNumber: '*99#',
      apn: 'hologram',
    }));
  };

  const handleDeleteUserCode = (keyCode: string) => {
    const userCode = deviceProperties?.find(item => item.name === DevicePropertyName.USER_CODES);
    if (userCode) {
      const { _id: id } = userCode;
      handleSendCommand(buildMessage(CommandEnum.DictionaryRemove, {
        _id: id,
        key: keyCode,
      }));
    }
  };

  const handleEditUserCode = (
    userCodeValue: UserCodeKeyValue,
    newData: any,
  ) => {
    const userCode = deviceProperties?.find(item => item.name === DevicePropertyName.USER_CODES);
    if (userCode) {
      const { _id: id } = userCode;
      handleSendCommand(buildMessage(CommandEnum.DictionarySet, {
        _id: id,
        key: userCodeValue.key,
        element: {
          type: 'userCode',
          value: {
            code: newData.code,
            name: newData.name,
          },
        },
      }));
    }
  };

  const loadNetwork = () => {
    handleSendCommand(buildMessage(CommandEnum.HubNetworkGet, {}, CommandEnum.HubNetworkGet));
  };

  const handleHealthCheckValue = () => {
    handleSendCommand(buildMessage(CommandEnum.HubDeviceCheck, {
      _id: lockDevice?.id,
      check: 'value',
    }));
  };

  const handleGetNetwork = () => {
    handleSendCommand(buildMessage(CommandEnum.HubNetworkGet, {}));
  };

  const handleHealthCheckStatus = () => {
    handleSendCommand(buildMessage(CommandEnum.HubDeviceCheck, {
      _id: lockDevice?.id,
      check: 'status',
    }));
  };

  const handleAddNewCode = (data: any) => {
    const userCode = deviceProperties?.find(item => item.name === DevicePropertyName.USER_CODES);
    if (userCode) {
      const { _id: id } = userCode;
      handleSendCommand(buildMessage(CommandEnum.DictionarySet, {
        _id: id,
        key: data.key,
        element: {
          type: 'userCode',
          value: {
            code: data.code,
            name: data.name,
          },
        },
      }));
    }
  };

  const onBroadcastMessages = (data: BroadcastItem) => {
    setBroadcastMessages(currentData => [{
      timestamp: new Date(),
      data,
    }, ...currentData]);

    if (data.msg_subclass === BroadcastMsgSubclass.DictionaryUpdated) {
      loadDeviceInfo();
    }
  };

  const loadDeviceCode = (deviceIds: string[]) => {
    socket.current.send(buildMessage(CommandEnum.HubItemsList, {
      deviceIds,
    }, CommandEnum.HubItemsList));
  };

  const handleConnect = () => {
    const hubServer = hubServers?.getListEzloHubServerRelay
      .find(item => item.hubId === deviceId);
    if (!hubServer) {
      return;
    }
    setConnectStatus(ConnectStatus.Connecting);
    socket.current.init(hubServer.serverRelay);

    socket.current.on((data: any) => {
      switch (data.id) {
        case EzloResponseId.LoginUser:
          socket.current.send(buildRegisterMessage(deviceId));
          break;
        case EzloResponseId.Register:
          if (data.error !== null) {
            setConnectStatus(ConnectStatus.NotConnect);
            enqueueSnackbar(`Connect failed: ${data.error.data}`, { variant: 'error' });
            break;
          }
          setConnectStatus(ConnectStatus.Connected);
          enqueueSnackbar('Connected successfully', { variant: 'success' });
          loadDeviceInfo();
          loadNetwork();
          break;
        case EzloResponseId.UIBroadcast:
          onBroadcastMessages(data);
          break;
        case CommandEnum.HubDevicesList:
          // eslint-disable-next-line no-case-declarations
          const device = extractLockDevice(data.result.devices);
          setLockDevices(device);
          if (device) {
            loadDeviceCode([device.id]);
          }
          break;
        case CommandEnum.HubItemsList:
          setLockProperties(extractDeviceProperties(data.result.items));
          setDeviceProperties(data.result.items);
          break;
        case CommandEnum.HubNetworkGet:
          setNetworkInfo(extractNetworkMobile(data.result.interfaces));
          break;
        default:
          setResponseData(data);
          break;
      }
    });

    socket.current.onConnected(() => {
      console.log('server connected');
      socket.current.send(initMessage(hubServer.identity, hubServer.identitySignature));
    });

    socket.current.onClosed(() => {
      console.log('server closed');
      resetAll();
      enqueueSnackbar('Closed connection', { variant: 'warning' });
    });
  };

  useEffect(() => {
    if (queryDeviceId && hubServers && hubServers.getListEzloHubServerRelay.length) {
      handleConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubServers]);

  return (
    <>
      <Paper className={classes.paper}>
        { connectStatus !== ConnectStatus.Connected && queryDeviceId && (
          <div className={classes.spinnerWrapper}>
            <Spinner size={20} />
          </div>
        )}
        {!!hubAccountId && connectStatus === ConnectStatus.Connected && (
          <Grid container>
            <Grid item xs={12} sm={12}>
              Connected to hub #
              {deviceId}
              <Button onClick={disconnect}>Disconnect</Button>
              <Button onClick={handleConnect}>Reconnect</Button>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid item xs={12} sm={7}>
                <div className={classes.spacer} />
                <Tabs value={tabValue} onChange={(event, value) => setTabValue(value)} aria-label="simple tabs example">
                  <Tab label="Commands" />
                  <Tab label="Device Detail" />
                </Tabs>
              </Grid>
              {tabValue === 0 && (
                <>
                  <EzloCommandComponent onSubmit={handleSendCommand} />
                </>
              )}
              {tabValue === 1 && (
                <Grid item xs={12} sm={11}>
                  {(!lockDevice || !lockProperties) && (
                    <Typography variant="subtitle1" gutterBottom> No lock devices</Typography>
                  )}
                  {!!lockDevice && !!lockProperties && (
                    <Grid key={lockDevice.id}>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                          {lockDevice.name}
                          {' '}
                          <span className={classes.value}>
                          #
                            {lockDevice.id}
                          </span>
                        </Typography>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" component="div">
                              General info:
                            </Typography>
                            <Typography variant="body1" component="div">
                              Battery:
                              {' '}
                              <span className={classes.value}>
                                {`${lockProperties.battery} %`}
                              </span>
                            </Typography>
                            <Typography variant="body1" component="div">
                              Status:
                              {' '}
                              <span className={classes.value}>
                                {`${lockProperties.status}`}
                              </span>
                            </Typography>
                            <Typography variant="body1" component="div">
                              Reachable:
                              {' '}
                              <span className={classes.value}>{String(lockDevice.reachable)}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                              Manufacturer:
                              {' '}
                              <span className={classes.value}>{lockDevice.manufacturer}</span>
                            </Typography>
                            <Typography variant="body1" component="div">
                              Model:
                              {' '}
                              <span className={classes.value}>{lockDevice.model}</span>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            {!!networkInfo && (
                              <>
                                <Typography variant="subtitle1" component="div">
                                  Network info:
                                </Typography>
                                <Typography variant="body1" component="div">
                                  Access Technology:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.accessTechnology}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  Frequency Band:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.frequencyBand}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  Operator:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.operator}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  SignalQuality.level:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.signalQuality.level}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  SignalQuality.rsrq:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.signalQuality.rsrq}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  SignalQuality.rsrp:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.signalQuality.rsrp}
                                  </span>
                                </Typography>
                                <Typography variant="body1" component="div">
                                  SignalQuality.rssi:
                                  {' '}
                                  <span className={classes.value}>
                                    {networkInfo.signalQuality.rssi}
                                  </span>
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Typography variant="subtitle1" component="div">
                          Code info:
                        </Typography>
                        <Table aria-label="Codes">
                          <TableHead>
                            <TableRow>
                              <TableCell>Key</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell align="center">Code</TableCell>
                              <TableCell align="center">mode</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(lockProperties.userCodes).map(key => {
                              const item = lockProperties.userCodes[key];
                              return (
                                <TableRow
                                  key={key}
                                >
                                  <TableCell component="th" scope="row">
                                    {key}
                                  </TableCell>
                                  <TableCell align="left">{item.name}</TableCell>
                                  <TableCell align="center">{item.code}</TableCell>
                                  <TableCell align="center">{item.mode}</TableCell>
                                  <TableCell align="center">
                                    <Button
                                      size="medium"
                                      onClick={() => setUserCodeKeyToEdit({
                                        key,
                                        value: item.code,
                                        name: item.name,
                                      })}
                                    >
                                      Edit
                                    </Button>
                                    <Button onClick={() => setUserCodeKeyToRemove(key)} size="medium" color="secondary">Delete</Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <Button style={{ float: 'right' }} size="medium" onClick={() => setOpenAddNewCode(true)}>Add new code</Button>
                        <div />
                      </CardContent>
                      <Divider className={classes.divider} />
                      <CardActions>
                        <Button size="medium" onClick={handleChangeToVerizon}>Change to Verizon</Button>
                        <Button size="medium" onClick={handleReboot} color="secondary">Reboot</Button>
                        <Button size="medium" onClick={handleHealthCheckValue}>Health check value</Button>
                        <Button size="medium" onClick={handleGetNetwork}>Get network</Button>
                      </CardActions>
                      <CardActions>
                        <Button size="medium" disabled>Auto-relock - Schlage</Button>
                        <Button size="medium" disabled>Auto-relock - Kwikset</Button>
                        <Button size="medium" onClick={handleHealthCheckStatus}>Health check status</Button>
                      </CardActions>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={7}>
              <EzloRequestResponseComponent
                requestData={requestData}
                responseData={responseData}
                broadcastMessages={broadcastMessages}
                clearBroadcastMessages={() => setBroadcastMessages([])}
              />
            </Grid>
          </Grid>
        )}
        <ConfirmDelete
          isOpen={!!userCodeKeyToRemove}
          onCancel={() => {
            setUserCodeKeyToRemove(undefined);
          }}
          onConfirm={() => {
            if (userCodeKeyToRemove) {
              handleDeleteUserCode(userCodeKeyToRemove);
            }
            setUserCodeKeyToRemove(undefined);
          }}
        />
        <DialogEditUserCode
          isOpen={!!userCodeKeyToEdit}
          userCode={userCodeKeyToEdit}
          onCancel={() => {
            setUserCodeKeyToEdit(undefined);
          }}
          onConfirm={(data: any) => {
            if (userCodeKeyToEdit) {
              handleEditUserCode(userCodeKeyToEdit, data);
            }
            setUserCodeKeyToEdit(undefined);
          }}
        />
        <DialogAddNewUserCode
          isOpen={openAddNewCode}
          maxUserCodeKey={lockProperties?.maxUserCodeKey}
          onCancel={() => {
            setOpenAddNewCode(false);
          }}
          onConfirm={(data: any) => {
            handleAddNewCode(data);
            setOpenAddNewCode(false);
          }}
        />
      </Paper>
    </>
  );
};

export default EzloMobile;
