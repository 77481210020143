import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const clientCreateSchema = yup.object().shape({
  name: yup.string().required(),
  STI_active: yup.boolean(),
  STI_builder_id: yup.mixed().nullable(true).when('STI_active', {
    is: true,
    then: yup.number().required(),
  }),
  LASSO_active: yup.boolean(),
  LASSO_apikey: yup.mixed().nullable().when('LASSO_active', {
    is: true,
    then: yup.string().required(),
  }),
  LASSO_id: yup.mixed().nullable().when('LASSO_active', {
    is: true,
    then: yup.string().required(),
  }),
  academy_active: yup.boolean(),
  bossApi_active: yup.boolean(),
  allDayAccess_active: yup.boolean(),
  adjustableTourHours: yup.boolean(),
  Novihome_active: yup.boolean(),
  active: yup.boolean(),
  is_rental: yup.boolean(),
  is_webflow_code_only: yup.boolean(),
  high_security_verification_active: yup.boolean(),
  lock_lease_fee: yup.number(),
  number_of_licens: yup.number(),
  builder_timezone: yup.number().oneOf([51, 52, 53, 54]).required(),
});
