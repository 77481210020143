
import { connect } from 'react-redux';
import UserDetails from './UserDetails';
import { setProgressBarStatus } from '../../redux/actions/progressBar';

const mapStateToProps = null;

const mapDispatchToProps = {
  setProgressBarStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
