import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  tableRoot: {
    minWidth: 1230,
   zIndex:2
  },
  tableContainer:{
    overflow:'auto',
    position:'relative',
    display:'flex',
    flexDirection:'column',
    scrollBehavior:'smooth'
  },
  rowRoot: {
    '&:hover > td': {
      color: theme.appPalette.orange,
    },
  },
  accessed: {
    color: '#04A777',
  },
  link: {
    color: theme.appPalette.orange,
  },
});
