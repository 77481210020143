import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from '../StatusSelect/styles';
import CustomTextField from '../CustomTextField/CustomTextField';

type Props = {
  customTextField?: boolean
} & TextFieldProps;

const HubTypeSelect: React.FC<Props> = props => {
  const hubTypes = [
    { hubName: 'Ezlo Secure' },
    { hubName: 'ADC' },
    { hubName: 'Kwikset'},
    { hubName: 'Seam'}
  ]
  const { customTextField, ...restProps } = props;
  const renderStatesItems = () => hubTypes.map(({ hubName }) => (
    <MenuItem
      key={hubName}
      value={hubName}
    >
      {hubName}
    </MenuItem>
  ));
  const classes = useStyles();
  return (
    <>
      { customTextField && (
        <>
          <CustomTextField
            margin="normal"
            fullWidth
            {...restProps}
            select
            inputProps={{
              classes: {
                disabled: classes.disabled,
              },
            }}
          >
            <MenuItem disabled value={1}>N / A</MenuItem>
            {renderStatesItems()}
          </CustomTextField>
        </>
      )
}
      { !customTextField && (
        <>
          <TextField
            margin="normal"
            fullWidth
            {...restProps}
            select
            inputProps={{
              classes: {
                disabled: classes.disabled,
              },
            }}
          >
            <MenuItem disabled value={1}>N / A</MenuItem>
            {renderStatesItems()}
          </TextField>
        </>
      )
  }
    </>
  );
};

export default React.memo(HubTypeSelect);