import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pointer: {
    cursor: 'pointer',
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  avatar: {
    color: 'white',
  },
  switcherLabel: {
    marginTop: 5,
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
