import React, {
    Fragment, FC, useCallback, useContext, useEffect, useMemo, useState, memo
} from 'react';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/react-hooks';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RouteComponentProps } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import SearchBar from '../../components/SearchBar';
import useStyles from './styles';
import { PaginatedShipment } from '../../types/Property';
import Spinner from '../../components/CustomSpinner';
import ConfirmDelete from '../../components/ConfirmDelete';
import useDebounce from '../../hooks/useDebounce';
import CustomTablePagination from '../../components/CustomTablePagination';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import { ROWS_PER_PAGE } from '../../constants/table';
import { Order } from '../../utils/table';
import CustomButton from '../../components/CustomButton';
import { ALL_SHIPMENTS, REMOVE_SHIPMENTS } from '../../graphql/queries/Shipments';
import { SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect/RowsPerPageSelect';
import downloadDocument from '../../utils/files';
import { loadExport, loadExportAll } from '../../services/export';
import Table from './ShipmentTable/Table'


type Props = {
    history: any
    match: any
} & RouteComponentProps;

const Shipments: FC<Props> = ({ history, match }) => {

    const [shipments, setShipments] = useState<any>([]);
    const classes = useStyles();
    const { dispatch, state } = useContext(SearchSortContext);
    const {
        orderBy, order, contextSearch, rowsPerPageAmount, currentPage,
    } = state.shipments;
    const [search, setSearch] = useState<string>(contextSearch);
    const [cursor, setCursor] = useState<{ limit: number, offset: number }>({
        limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
    });
    const [sort, setSort] = useState<{ sortBy: string, direction: 'asc' | 'desc' }>({
        sortBy: orderBy, direction: order,
    });
    const debouncedSearch = useDebounce(search, 1000);
    const [rowsPerPage] = useState<number>(ROWS_PER_PAGE);
    const { enqueueSnackbar } = useSnackbar();
    const [shipmentToRemove, setShipmentToRemove] = useState<boolean>(false);
    const [selected, setSelected] = useState<Array<PaginatedShipment['id']>>([]);
    const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);
    const selectedLength = selected.length;

    const { loading, data } = useQuery<{
        shipmentsList: Paginated<PaginatedShipment>
    }>(ALL_SHIPMENTS, {
        variables: {
            limit: cursor.limit,
            offset: cursor.offset,
            search: debouncedSearch,
            sortBy: sort.sortBy,
            direction: sort.direction,
        },
        fetchPolicy: 'network-only',
    });

    const [removeShipments] = useMutation(REMOVE_SHIPMENTS, {
        refetchQueries: [{
            query: ALL_SHIPMENTS,
            variables: {
                limit: cursor.limit,
                offset: cursor.offset,
                search: debouncedSearch,
                sortBy: sort.sortBy,
                direction: sort.direction,
            },
        }],
    });

    useEffect(() => {
        if (data && data.shipmentsList) {
            setShipments(data.shipmentsList.list)
        }
    }, [loading, data])

    const params = data?.shipmentsList?.params ?? { total: 0 };
    const page = useMemo(
        () => Math.ceil(cursor.offset / cursor.limit),
        [cursor.limit, cursor.offset],
    );

    const handleShipmentSelect = (id: number) => {
        history.push(`${match.url}/${id}`);
    }

    const handleSearch = useCallback((value: string) => {
        dispatch({
            type: 'CHANGE_SHIPMENT',
            payload: { contextSearch: value, currentPage: 0 },
        });
        setSearch(value);
        setCursor({ limit: rowsPerPageAmount, offset: 0 });
    }, [dispatch, rowsPerPageAmount]);


    const handleChangePage = useCallback((_e: unknown, nPage: number) => {
        setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
        dispatch({
            type: 'CHANGE_SHIPMENT',
            payload: {
                currentPage: nPage,
            },
        });
    }, [dispatch]);

    const handleChangeRowsPerPage = useCallback(e => {
        setCursor({ offset: 0, limit: e.target.value });
        dispatch({
            type: 'CHANGE_SHIPMENT',
            payload: {
                rowsPerPageAmount: e.target.value,
                currentPage: 0,
            },
        });
    }, [dispatch]);

    function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const newSelecteds = shipments.map((shipment: any) => shipment.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(
        event: React.SyntheticEvent,
        id: PaginatedShipment['id'],
    ) {
        event.preventDefault();

        const selectedIndex = selected.indexOf(id);
        let newSelected: Array<PaginatedShipment['id']> = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }

    function handleChangeOrder(newOrder: Order, newOrderBy: string) {
        setSort({
            direction: newOrder,
            sortBy: newOrderBy,
        });
        setCursor({ limit: rowsPerPageAmount, offset: 0 });
        dispatch({
            type: 'CHANGE_SHIPMENT',
            payload: {
                orderBy: newOrderBy,
                order: newOrder,
                currentPage: 0,
            },
        });
    }

    function handleExport() {
        if (selectedLength !== 0) {
            loadExport(selected, 'shipments')
                .then(response => {
                    const contentType = response.headers['Content-Type'];
                    downloadDocument(contentType, 'Shipments', response.data);
                });
        }
    }


    function handleExportAll() {
        setExportAllLoading(true);
        loadExportAll('shipments').then(response => {
            setExportAllLoading(false);
            const contentType = response.headers['Content-Type'];
            downloadDocument(contentType, 'Shipments', response.data);
        });
    }

    function handleDelete() {
        if (selectedLength !== 0) {
            removeShipments({
                variables: {
                    shipments: selected,
                },
            }).then(() => {
                setSelected([]);
                enqueueSnackbar('Removed', { variant: 'success' });
            })
                .catch(() => {
                    enqueueSnackbar('An error occurred', { variant: 'error' });
                });
        }
    }

    return (
        <Fragment>
            <Grid item className={classes.controlButton}>
                <CustomButton
                    variant="orange"
                    color="primary"
                    onClick={() => history.push(`${match.path}/add`)}
                >
                    Add
          </CustomButton>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <SearchBar
                        placeholder={'Search shipment'}
                        defaultSearchValue={search}
                        onChange={handleSearch}
                    />
                </Grid>
            </Grid>

            <div className={classes.contentWrapper}>
                <SelectedActionsBar
                    onDelete={() => { setShipmentToRemove(true); }}
                    onExport={handleExport}
                    onExportAll={handleExportAll}
                    onPrint={() => { }}
                    selectedLength={selectedLength}
                    exportAllLoading={exportAllLoading}
                />
                <Typography className={classes.tableHeaderText} variant="h5">Shipments</Typography>
                <Grid container alignContent="center" justify="flex-end">
                    <RowsPerPageSelect
                        rowsPerPageOptions={[25, 50, 100, 150]}
                        rowsPerPage={cursor.limit}
                        onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
                    />
                </Grid>
                <div className={classes.tableWrapper}>
                    <Table
                        selected={selected}
                        shipments={shipments}
                        order={sort.direction}
                        orderBy={sort.sortBy}
                        rowsPerPage={rowsPerPage}
                        onChangeOrder={handleChangeOrder}
                        onSelect={handleClick}
                        onSelectAll={handleSelectAllClick}
                        onRowClick={handleShipmentSelect}
                    />
                </div>
                {loading && (
                    <div className={classes.spinnerWrapper}>
                        <Spinner />
                    </div>
                )}
                <CustomTablePagination
                    rowsPerPageOptions={[25, 50, 100, 150]}
                    rowsTotal={params.total}
                    rowsCount={shipments.length}
                    rowsPerPage={cursor.limit}
                    currentPage={page}
                    onChangePage={n => handleChangePage(undefined, n)}
                    onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
                />
            </div>
            <ConfirmDelete
                isOpen={shipmentToRemove}
                onCancel={() => { setShipmentToRemove(false); }}
                onConfirm={() => { handleDelete(); setShipmentToRemove(false); }}
            />
        </Fragment>
    )
}

export default memo(Shipments)
