import { gql } from 'apollo-boost';

export const ALL_SHIPMENTS = gql`
query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
  shipmentsList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
    list {
      lockSerialNumber,
      cost,
      id,
      qty,
      carrier,
      date,
      status,
      status_id,
      tracking_number,
      username,
      builder_id,
      builder,
      last_updated,
      notes
    }                    
    params {
      total
      limit
      offset
    }
  }
}
`;

export const SHIPMENT_BY_ID = gql`
query ($shipment_id: Int!) {
  shipmentbyId(shipment_id: $shipment_id) {
      cost,
      id,
      qty,
      carrier,
      date,
      status,
      status_id,
      tracking_number,
      username,
      lockSerialNumber,
      builder,
      builder_id,  
      notes
    }
  }
`;

export const UPSERT_SHIPMENT = gql`
mutation upsertShipment($shipment: UpdateShipment!) {
  upsertShipment(shipment: $shipment)
}
`

export const REMOVE_SHIPMENTS = gql(`
     mutation($shipments: [Float!]!) {
        deleteMultipleShipments(shipments: $shipments)
    }
    `);