import {
  Theme, createStyles, makeStyles,
} from '@material-ui/core';


const drawerWidth = 240;

const styles = (theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  smallLogo: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    fontSize: 'initial',
  },
  menuItems: {
    marginTop: theme.spacing(2),
  },
  logoWrapper: {
    paddingTop: 40,
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
