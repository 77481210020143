import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import EditProfile from './EditProfile';
import styles from './styles';
import { authUserLogout } from '../../redux/actions/auth';

const mapStateToProps = null;

const mapDispatchToProps = {
  userLogout: authUserLogout,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
