import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import useFormType from './useFormType';
import InputProperty from '../types/inputTypes/Property';
import { GET_PROPERTY } from '../graphql/queries/Properties';

export default function useProperty(propertyId? : number) {
  const [property, setProperty] = useState<InputProperty>(new InputProperty());
  const formType = useFormType(propertyId);
  const { data } = useQuery<{ property: InputProperty }>(
    GET_PROPERTY,
    {
      variables: { property_id: propertyId },
      skip: formType === 'add' || !propertyId || propertyId === 0,
      fetchPolicy: 'network-only',
    },
  );
  useEffect(() => {
    if (data?.property) {
      setProperty(data.property);
    }
  }, [data]);

  useEffect(() => {
    if (propertyId === 0) {
      setProperty(new InputProperty());
    }
  }, [setProperty, propertyId]);

  return {
    property, formType, setProperty,
  };
}
