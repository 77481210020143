/* eslint-disable import/prefer-default-export */
import { UserType } from '../types/User';

export const processAgents = (agents: Array<UserType> | undefined) => {
  if (!agents?.length) {
    return '';
  }

  return agents.reduce((acc: string, user : UserType) => {
    if (!user || !user.username) {
      return acc;
    }

    const agentString = `${user.is_project_manager ? 'PM' : 'A'} - ${user.username}\n    - ${user?.phone}\n    - ${user?.email}\n\n`;
    if (!acc.length) {
      return agentString;
    }
    return `${acc} ${agentString}`;
  }, '');
};
