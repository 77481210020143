import { gql } from 'apollo-boost';

export const GET_STATES = gql`
  query{
    states {
      state_id,
      state_name
    },
  }
`;

export const GET_STATIC_DATA = gql(`
      query{
        shipmentStatuses {
         status_id,
         status
        }
        lockStatuses {
         status_id,
         status
        }
        propertyStatuses {
         status_id,
         status
        }
        timezones {
          catalog_id,
          value
        }
        statuses:  visitorStatuses {
          catalog_id,
          value
        }
        types: visitorTypes {
          catalog_id,
          value
        },
        sources: verificationSources{
          catalog_id,
          value
        }
      }
  `);
