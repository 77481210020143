import React, { FC, memo, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  generateLabel,
} from '../../../utils/helpers';
import { BaseProperty as Property } from '../../../types/Property';
import useStyles from './styles';
import Alert from './Alert';
import { Catalog } from '../../../types/Customer';

const moment = require('moment-timezone');

type AdjustableHourToursProps = {
  property: Property;
  tourHoursStart?: string;
  tourHoursEnd?: string;
  isOutsideTourHours?: boolean;
  handleTourHoursAlert: (alert: {
    isOutsideTourHours: boolean;
    isToursUnavailable: boolean;
  }) => void;
};

const AdjustableHourTours: FC<AdjustableHourToursProps> = ({
  property,
  handleTourHoursAlert,
}) => {
  const classes = useStyles();

  const defaultTourHoursStart = '7:00 AM';
  const defaultTourHoursEnd = '9:00 PM';
  const isToursAvailable = property?.propertyTourHours?.isToursAvailable
    ?? property?.propertyCommunity?.communities?.communityTourHours
      ?.isToursAvailable;
  const propertyTourHoursStart = property?.propertyTourHours?.tourHoursStart;
  const propertyTourHoursEnd = property?.propertyTourHours?.tourHoursEnd;
  const propertySecondWindowStart = property?.propertyTourHours?.secondWindowStart;
  const propertySecondWindowEnd = property.propertyTourHours?.secondWindowEnd;
  const communityTourHoursStart = property?.propertyCommunity?.communities?.communityTourHours
      ?.tourHoursStart;
  const communityTourHoursEnd = property?.propertyCommunity?.communities?.communityTourHours?.tourHoursEnd;
  const communitySecondWindowStart = property?.propertyCommunity?.communities?.communityTourHours?.secondWindowStart;
  const communitySecondWindowEnd = property.propertyCommunity?.communities?.communityTourHours?.secondWindowEnd;
  const tourHoursStart = propertyTourHoursStart ?? communityTourHoursStart ?? defaultTourHoursStart;
  const tourHoursEnd = propertyTourHoursEnd ?? communityTourHoursEnd ?? defaultTourHoursEnd;
  const secondWindowStart = propertySecondWindowStart ?? communitySecondWindowStart;
  const secondWindowEnd = propertySecondWindowEnd ?? communitySecondWindowEnd;
  const isSecondWindowEnabled = propertyTourHoursStart
    ? property.propertyTourHours?.isSecondWindowEnabled
    : property.propertyCommunity?.communities?.communityTourHours
        ?.isSecondWindowEnabled ?? false;

  let timezoneValue = 51; // default to Denver, lowest catalog value

  if ((property.timezone as Catalog)?.catalog_id) {
    timezoneValue = (property.timezone as Catalog).catalog_id;
  }

  let timezoneString = 'America/Denver';

  switch (timezoneValue) {
    case 52:
      timezoneString = 'America/New_York';
      break;
    case 53:
      timezoneString = 'America/Chicago';
      break;
    case 54:
      timezoneString = 'America/Los_Angeles';
      break;
    default:
      break;
  }

  const dateInLAString = moment().tz('America/Los_Angeles').format('YYYY/MM/DD'); // we need the date in Los Angeles as it's the most western timezone and we need to make sure that it doesn't skip days after UTC passes over a day over PST

  const momentStart = moment.tz(`${dateInLAString} ${tourHoursStart}`, 'YYYY/MM/DD HH:mm A', timezoneString);
  const momentEnd = moment.tz(`${dateInLAString} ${tourHoursEnd}`, 'YYYY/MM/DD HH:mm A', timezoneString);
  const momentSecondWindowStart = moment.tz(`${dateInLAString} ${secondWindowStart}`, 'YYYY/MM/DD HH:mm A', timezoneString);
  const momentSecondWindowEnd = moment.tz(`${dateInLAString} ${secondWindowEnd}`, 'YYYY/MM/DD HH:mm A', timezoneString);
  const nowMoment = moment();

  const hoursUntilTourStart = moment.duration(momentStart.diff(nowMoment)).asHours();
  const hoursAfterTourEnd = moment.duration(nowMoment.diff(momentEnd)).asHours();
  const hoursUntilSecondWindowStart = moment.duration(momentSecondWindowStart.diff(nowMoment)).asHours();
  const hoursAfterSecondWindowEnd = moment.duration(nowMoment.diff(momentSecondWindowEnd)).asHours();
  const isOutsideFirstWindow = (hoursUntilTourStart > 0) || (hoursAfterTourEnd > 0);
  const isOutsideSecondWindow = (hoursUntilSecondWindowStart > 0) || (hoursAfterSecondWindowEnd > 0);
  const isOutsideTourHours = isOutsideFirstWindow && isOutsideSecondWindow;

  useEffect(() => {
    handleTourHoursAlert({
      isOutsideTourHours,
      isToursUnavailable: !JSON.parse(isToursAvailable ?? 'true'),
    });
  }, [isOutsideTourHours, handleTourHoursAlert, isToursAvailable]);

  return (
    <Grid>
      <Grid>
        {isOutsideTourHours && <Alert text={`Outside the tour hours. Time is ${moment().tz(timezoneString).format('HH:mm A')} at property.`} />}
        <Typography variant="body1">
          <span className={classes.label}>
            {generateLabel('Tour hours')}
:
            {' '}
          </span>
          {' '}
          {JSON.parse(isToursAvailable ?? 'true')
            ? (
              <span>
                {tourHoursStart}
                {' '}
-
                {' '}
                {tourHoursEnd}
              </span>
            )
            : <span>No Tours Today</span>}
          {isSecondWindowEnabled && (
            <>
,
              <br />
              <span>
                {secondWindowStart}
                {' '}
  -
                {' '}
                {secondWindowEnd}
              </span>
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default memo(AdjustableHourTours);
