import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  VISITS_REQUEST,
  VISITS_EXPORT_REQUEST,
  VISITS_EXPORT_AND_PRINT_REQUEST,
} from '../types/visits';

import { loadVisits, loadVisitsExport } from '../../services/visits';
import {
  visitsRequestSuccess,
  visitsRequestFailed,
  VisitsRequestActionType,
  VisitsExportRequestActionType,
  visitsExportSuccess,
  visitsExportFailed,
} from '../actions/visits';
import { openPrintDialog } from '../../services/print';
import { exportToFile } from '../../services/export';

function* loadVisitsSaga(
  action: VisitsRequestActionType,
) {
  try {
    const { data } = yield call(loadVisits, action.payload);
    yield put(visitsRequestSuccess(data));
  } catch (err) {
    yield put(visitsRequestFailed(err));
  }
}

function* loadVisitsExportSaga(
  action: VisitsExportRequestActionType,
) {
  try {
    const { selected, mimeType } = action.payload;
    const { data } = yield call(loadVisitsExport, selected, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === VISITS_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'visits-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(visitsExportSuccess());
  } catch (err) {
    yield put(visitsExportFailed(err));
  }
}

export default all([
  takeLatest<any>(VISITS_REQUEST, loadVisitsSaga),
  takeLatest<any>(
    [VISITS_EXPORT_REQUEST, VISITS_EXPORT_AND_PRINT_REQUEST],
    loadVisitsExportSaga,
  ),
]);
