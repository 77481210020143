import React, { FC, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import { generateLabel } from '../../../utils/helpers';
import { BaseProperty as Property } from '../../../types/Property';

type Props = {
    text: string;
    label?: string;
    property?: Property;
    condition?: string;
}

const Alert: FC<Props> = (props: Props) => {
  const classes = useStyles();

    return (
        <Fragment>
                  <Grid>
                    <Typography variant="body1">
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: 'red',
                          animationName: 'flashRed',
                          animationDuration: '1s',
                          animationIterationCount: 'infinite',
                        }}
                      >
                        {props.text}
                      </span>
                    </Typography>
                  </Grid>
                  {props.label && (
                    <Grid>
                    <Typography variant="body1">
                      <span className={classes.label}>
                        {generateLabel(props.label)}
:
                        {' '}
                      </span>
                      {' '}
                      {props.condition ?? ''}
                    </Typography>
                  </Grid>
                  )}
                  
                </Fragment>
    )
};

export default Alert;
