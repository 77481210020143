import React, { useRef } from 'react';
import MatTable from '@material-ui/core/Table';
import { TableBody, TableContainer, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import TableHead from './TableHead';
import styles from './CustomersTableStyles';
import { Order, isSelected } from '../../../utils/table';
import {
  Customer, Catalog, Status,
} from '../../../types/Customer';
import { CustomerVisit } from '../../../types/Visit';
import { convertDateWithTimezone, convertDateToTimeWithTimezone, TimezoneCode } from '../../../utils/helpers';

type Props = {
  customers: Array<Customer & {catalog: Catalog} & {status : Status} & {visits: CustomerVisit[]}>;
  order: Order;
  orderBy: keyof Customer | string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: Customer['customer_id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: keyof Customer) => void;
  selected: Array<Customer['customer_id']>;
  onRowClick: (customerId: number) => void;
} & WithStyles<typeof styles>;

const CustomersTable: React.FC<Props> = props => {
  const {
    order,
    customers,
    orderBy,
    onSelect,
    onRowClick,
    onSelectAll,
    onChangeOrder,
    selected,
    classes,
  } = props;

  function handleRequestSort(
    event: React.SyntheticEvent,
    property: keyof Customer,
  ) {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={11} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          rowCount={customers.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {customers.map((customer, index) => {
            const isItemSelected: boolean = isSelected(
              customer.customer_id,
              selected,
            );
            const status = customer?.customerStatus?.toLowerCase().replace('-', '') as
            | 'contact'
            | 'nocontact';
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const lastVisitDate = customer.modified_on
              ? new Date(customer.modified_on) : null;
            const timezoneCode = customer.last_visit?.property?.timezone as TimezoneCode;
            const modificationDate = lastVisitDate ? convertDateWithTimezone(lastVisitDate, timezoneCode) : 'No visits';
            const modificationTime = lastVisitDate ? convertDateToTimeWithTimezone(lastVisitDate, timezoneCode) : '';
            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={customer.customer_id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={() => onRowClick(customer.customer_id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, customer.customer_id);
                    }}
                  />
                </TableCell>
                <TableCell align="center" id={labelId}>
                  {customer.firstname}
                </TableCell>
                <TableCell align="center">{customer.lastname}</TableCell>
                <TableCell align="center" classes={{ root: classes[status] }}>{customer.customerStatus ?? 'No status'}</TableCell>
                <TableCell align="center">{customer.cell_phone_1}</TableCell>
                <TableCell align="center">
                  {customer.catalog?.value ?? 'None'}
                </TableCell>
                <TableCell align="center">{customer?.total_visits ?? 0}</TableCell>
                <TableCell align="center">
                  <Typography className={classes.date}>{modificationDate}</Typography>
                  {modificationTime
                  && <Typography className={classes.date}>{modificationTime}</Typography>}
                </TableCell>
                <TableCell align="center">
                  {customer.last_visit?.property_id
                    ? (
                      <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/properties/${customer.last_visit?.property_id}`}>
                        { customer.last_visit?.property_id}
                      </Link>
)
                    : 'No visits' }
                </TableCell>
                <TableCell align="center">{customer.status?.value ?? 'No status'}</TableCell>
                <TableCell align="center">{customer.created_on ? new Date(customer.created_on).toLocaleDateString() : 'No visits' }</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(CustomersTable);
