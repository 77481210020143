import { gql } from 'apollo-boost';

export const ALL_TROUBLE_TICKETS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    troubleTicketList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        customer_lastname,
        ticket_number,
        property_id,
        customer_firstname,
        cell_phone_1,
        lockStatus,
        agent_informer,
        open_date,
        closed_date,
        lock_serial_number,
        client,
        property {
          property_id,
          lock_serial_number,
          lock {
            serial_number,
            lockStatus,
          },
          hub {
            serial_number
          }
        },
        rep_name,
        diagnosis {
          value
        },
        resolution_notes,
        issue_diagnosis,
        closed_diagnosis,
      },
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation($ticket: UpdateTroubleTicket!) {
    updateTroubleTicket(ticket: $ticket) {
      ticket_number
    }
  }
`;

export const GET_TROUBLE_TICKET = gql`
  query($ticketNumber: Float!) {
    troubleTicket(ticketNumber: $ticketNumber) {
      ticket_number,
      client,
      property_id,
      lockStatus,
      customer_firstname,
      customer_lastname,
      cell_phone_1,
      issue_diagnosis,
      agent_informer,
      rep_name,
      resolution_notes,
      open_date,
      closed_date,
      closed_diagnosis,
      lock_serial_number,
    }
  }
`;

export const GET_CLOSED_DIAGNOSIS_CATALOGS = gql`
  query {
    closingDiagnoses {
      catalog_id,
      value
    }
  }
`;

export const REMOVE_TICKETS = gql`
  mutation($tickets: [Int!]!) {
    deleteMultipleTroubleTicketsByTicketNumbers(tickets: $tickets)
  }
`;
