/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import { UserType as User } from '../types/User';


export function SetNewAgentHook() {
  const [newAgent, setNewAgent] = useState<User>({
    firstname: '',
    lastname: '',
    username: '',
    is_project_manager: false,
  });

  return { newAgent, setNewAgent };
}

export function GetAvailableAgents() {

}
