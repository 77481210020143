import { all } from 'redux-saga/effects';
import authSaga from './auth';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import locks from './locks';
import stats from './stats';

export default function* rootSaga() {
  yield all([authSaga, properties, customers, visits, locks, stats]);
}
