import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import SearchBar from '../../components/SearchBar';
import ConfirmDelete from '../../components/ConfirmDelete';
import TroubleTicketsTable from './TroubleTicketsTable/TroubleTicketsTable';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE } from '../../constants/table';
import CustomTablePagination from '../../components/CustomTablePagination';
import Spinner from '../../components/CustomSpinner';
import useStyles from './styles';
import useDebounce from '../../hooks/useDebounce';
import CustomButton from '../../components/CustomButton';
import TroubleTicket from '../../types/TroubleTickets';
import { Property } from '../../types/Property';
import { ALL_TROUBLE_TICKETS, REMOVE_TICKETS } from '../../graphql/queries/TroubleTickets';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect/RowsPerPageSelect';
import downloadDocument from '../../utils/files';
import { loadExport, loadExportAll } from '../../services/export';
import SelectedActionsBar from '../../components/SelectedActionsBar';


type Props = {
  troubleTickets: Array<TroubleTicket>;
  troubleTicketsTotal: number;
  loading: boolean;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all Trouble Tickets';

const TroubleTickets: React.FC<Props> = ({ history, match }) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy, order, contextSearch, currentPage, rowsPerPageAmount,
  } = state.tickets;
  const [rowsPerPage] = useState<number>(ROWS_PER_PAGE);
  const [selected, setSelected] = useState<Array<TroubleTicket['ticket_number']>>([]);
  const [cursor, setCursor] = useState<{limit: number, offset: number}>({
    limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
  });
  const [search, setSearch] = useState<string>(contextSearch);
  const [sort, setSort] = useState<{sortBy: string, direction: 'asc' | 'desc'}>({ sortBy: orderBy, direction: order });
  const selectedLength = selected.length;
  const [ticketToRemove, setTicketToRemove] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 1000);
  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const { loading, data } = useQuery<{troubleTicketList: Paginated<TroubleTicket
  & { property: Property }>}>(ALL_TROUBLE_TICKETS, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const troubleTicketsData = data?.troubleTicketList?.list ?? [];
  const params = data?.troubleTicketList?.params ?? { total: 0 };

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'tickets')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_TICKET',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const handleShowAllClick = () => {
    dispatch({ type: 'RESET_STATE' });
    setSort({
      direction: 'desc',
      sortBy: 'open_date',
    });
    setSearch('');
  };

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);
  function handleChangeOrder(newOrder: Order, newOrderBy: string) {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_TICKET',
      payload: {
        orderBy: newOrderBy,
        order: newOrder,
        currentPage: 0,
      },
    });
  }
  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_TICKET',
      payload: {
        currentPage: 0,
        rowsPerPageAmount: e.target.value,
      },
    });
  }, [dispatch]);

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_TICKET',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelected = troubleTicketsData.map(i => i.ticket_number);
      setSelected(newSelected || []);
      return;
    }
    setSelected([]);
  }

  function handleClick(
    event: React.SyntheticEvent,
    id: TroubleTicket['ticket_number'],
  ) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<TroubleTicket['ticket_number']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleTroubleTicketSelect(ticketNumber: number) {
    history.push(`${match.url}/${ticketNumber}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'tickets')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'TroubleTickets', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('tickets').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'TroubleTickets', response.data);
    });
  }

  const [removeTickets] = useMutation(REMOVE_TICKETS, {
    refetchQueries: [{
      query: ALL_TROUBLE_TICKETS,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  const handleDelete = () => {
    if (selectedLength !== 0) {
      removeTickets({
        variables: {
          tickets: selected,
        },
      }).then(() => setSelected([]));
    }
  };


  function handlePrint() {
  }

  const isStateInitial = JSON.stringify({
    contextSearch: state.tickets.contextSearch,
    order: state.tickets.order,
    orderBy: state.tickets.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.tickets.contextSearch,
    order: initialState.tickets.order,
    orderBy: initialState.tickets.orderBy,
  });

  return (
    <Fragment>
      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add TroubleTicket
          </CustomButton>
        </Grid>
        { !isStateInitial && (
          <Grid item className={classes.controlButton}>
            <CustomButton
              variant="orange"
              color="primary"
              onClick={handleShowAllClick}
            >
              Show all
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            defaultSearchValue={search}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => { setTicketToRemove(true); }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography className={classes.tableHeaderText} variant="h5">Trouble Tickets</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <TroubleTicketsTable
            selected={selected}
            troubleTickets={troubleTicketsData}
            order={sort.direction}
            orderBy={sort.sortBy}
            rowsPerPage={rowsPerPage}
            onChangeOrder={handleChangeOrder}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onRowClick={handleTroubleTicketSelect}
          />
        </div>
        {loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <CustomTablePagination
          rowsPerPageOptions={[25, 50, 100, 150]}
          rowsTotal={params.total}
          rowsCount={troubleTicketsData.length}
          rowsPerPage={cursor.limit}
          currentPage={page}
          onChangePage={n => handleChangePage(undefined, n)}
          onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
        />
      </div>
      <ConfirmDelete
        isOpen={ticketToRemove}
        onCancel={() => { setTicketToRemove(false); }}
        onConfirm={() => { handleDelete(); setTicketToRemove(false); }}
      />
    </Fragment>
  );
};

export default React.memo(TroubleTickets);
