import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {
    height: '100%',
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    '& > div': {
      paddingLeft: 43,
      paddingRight: 43,
    },
  },
  header: {
    paddingTop: 25,
    backgroundColor: '#E9ECF2',
  },
  titleWrapper: {
    margin: '32px 0',
  },
  title: {
    fontSize: 28,
  },
  content: {},
});
