import { gql } from 'apollo-boost';

export const ALL_ENTERPRISES = gql`
  query(
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $direction: String
  ) {
    enterpriseList(
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      direction: $direction
    ) {
      list {
        id
        name
        created_by
        created_on
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const GET_ENTERPRISE = gql`
  query($id: String!) {
    enterprise(id: $id) {
      id
      name
      builderEnterprise {
        builder_id
        enterprise_id
        builder {
          name
        }
      }
    }
  }
`;

export const CREATE_ENTERPRISE = gql`
  mutation CreateEnterprise($enterprise: CreateEnterprise!) {
    createEnterprise(enterprise: $enterprise) {
      name
    }
  }
`;

export const UPDATE_ENTERPRISE = gql`
  mutation UpdateEnterprise($enterprise: UpdateEnterprise!) {
    updateEnterprise(enterprise: $enterprise) {
      name
    }
  }
`;

export const REMOVE_ENTERPRISE = gql`
  mutation($id: Int!) {
    deleteEnterprise(id: $id) {
      id
    }
  }
`;

export const REMOVE_ENTERPRISES = gql`
  mutation($enterprises: [Int!]!) {
    deleteEnterprisesById(enterprises: $enterprises)
  }
`;
