import { gql } from 'apollo-boost';

export const ALL_USERS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    userList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        username
        firstname
        lastname
        phone
        email
        builder {
          name
        }
        groups {
          Label
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation($username: String!) {
    deleteUser(username: $username) {
      username
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation($password: String!) {
    updatePassword(password: $password)
  }
`;

export const REMOVE_USERS = gql`
  mutation($usernames: [String!]!) {
    deleteUsersByUsernames(usernames: $usernames)
  }
`;
