import { connect } from 'react-redux';
import PropertyInfoCard from './PropertyInfoCard';
import { AppState } from '../../redux/store';
import { selectUserIsPMorAdmin } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  user: selectUserIsPMorAdmin(state),
});

export default connect(mapStateToProps)(PropertyInfoCard);
