import { Collapse, Grid } from '@material-ui/core';
import React from 'react';
import { generateLabel } from '../../../../utils/helpers';
import CustomTextField from '../../../CustomTextField';

type Props = {
  stiBuilderId: number | undefined;
  isActive: boolean;
  handleChangeClient: ({
    target:
    { name, value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors: any;
};

const StiAttributes: React.FC<Props> = props => {
  const {
    stiBuilderId, isActive, handleChangeClient, validationErrors,
  } = props;

  return (
    <Collapse in={isActive}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            margin="normal"
            label={generateLabel('STI_builder_id')}
            name="STI Builder Id"
            fullWidth
            type="number"
            value={stiBuilderId || ''}
            onChange={handleChangeClient}
            error={Boolean(validationErrors.STI_builder_id)}
            helperText={validationErrors.STI_builder_id || ''}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default React.memo(StiAttributes);
