/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MatTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import TableHead from './TableHead';
import { formatDate } from '../../../utils/date';
import { convertDateWithTimezone, convertDateToTimeWithTimezone } from '../../../utils/helpers';
import styles from './InventoryTableStyles';
import { Order, isSelected } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import { Lock } from '../../../types/Property';
import Status from '../../../components/LockStatus';

type Props = {
  order: Order;
  orderBy: string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: Lock['serial_number']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
  onRowClick: (id: Lock['serial_number']) => void;
  locks: Array<Lock>;
  selected: Array<Lock['serial_number']>;
} & WithStyles<typeof styles>;

const InventoryTable: React.FC<Props> = props => {
  const {
    selected,
    locks,
    classes,
    order,
    orderBy,
    onSelect,
    onSelectAll,
    onChangeOrder,
    onRowClick,
  } = props;

  function handleRequestSort(
    event: React.SyntheticEvent,
    lock: string,
  ) {
    event.preventDefault();
    const isDesc = orderBy === lock && order === 'desc';
    onChangeOrder(isDesc ? 'asc' : 'desc', lock);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={18} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          order={order}
          orderBy={orderBy}
          rowCount={locks.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {locks.map((lock: Lock, index) => {
            const isItemSelected: boolean = isSelected(lock.serial_number, selected);
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const formatedModificationDate = lock.modified_on
              ? `${convertDateWithTimezone(new Date(lock.modified_on))}
          ${convertDateToTimeWithTimezone(new Date(lock.modified_on))}` : '';
            return (
              <TableRow
                key={lock.serial_number}
                classes={{ root: classes.rowRoot }}
                hover
                tabIndex={-1}
                onClick={event => { event.stopPropagation(); onRowClick(lock.serial_number); }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, lock.serial_number);
                    }}
                  />
                </TableCell>
                <TableCell align="center">{lock.serial_number}</TableCell>
                <TableCell align="center">
                  <Status value={lock?.lockStatus ?? ''} />
                </TableCell>
                <TableCell align="center">{lock.version ?? ''}</TableCell>
                <TableCell align="center">{lock.manufacturer ?? ''}</TableCell>
                <TableCell align="center">{lock.purchase ? formatDate(lock.purchase) : ''}</TableCell>
                <TableCell align="center">{lock.license_start_date ? formatDate(lock.license_start_date) : ''}</TableCell>
                <TableCell align="center">{lock.client?.name ?? ''}</TableCell>
                <TableCell align="center">
                  {lock.property ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/properties/${lock.property.property_id}`}>{ lock.property.property_id}</Link>
                    : '' }
                </TableCell>
                <TableCell align="center">{lock.additional_info_1 ?? ''}</TableCell>
                <TableCell align="center">{lock.created_on ? formatDate(lock.created_on) : ''}</TableCell>
                <TableCell align="center">{lock.created_by ?? ''}</TableCell>
                <TableCell align="center">
                  {' '}
                  { formatedModificationDate }
                </TableCell>
                <TableCell align="center">{lock.modified_by ?? ''}</TableCell>
                <TableCell align="center">{lock.year ?? ''}</TableCell>
                <TableCell align="center">{lock.batch ?? ''}</TableCell>
                <TableCell align="center">{lock.hardware_version ?? ''}</TableCell>
                <TableCell align="center">{lock.software_version ?? ''}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(InventoryTable);
