import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Grid, Paper,
} from '@material-ui/core';
import { useLazyQuery } from '@apollo/react-hooks';
import { Alert } from '@material-ui/lab';
import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { GET_CODES_BY_PROPERTY_ID } from '../../graphql/queries/CodeGenerator';
import useStyles from './styles';
import { transformGraphQlErrorMessage } from '../../utils/helpers';
import { LockCodeWithOffset } from '../../types/LockCode';
import CodesTable from './CodesTable/CodesTable';

type FormData = {
  propertyId: string,
  serialNumber: string
};

const Util : React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    propertyId: '',
    serialNumber: '',
  });
  const [codes, setCodes] = useState<LockCodeWithOffset[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const classes = useStyles();
  const handleFormDataChange = useCallback(
    ({
      target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setFormData(prevData => ({
          ...prevData,
          [name]: value,
        }));
      }
    },
    [],
  );

  const [loadCodes, { data, error }] = useLazyQuery<{ codes: LockCodeWithOffset[] }>(
    GET_CODES_BY_PROPERTY_ID,
  );

  useEffect(() => {
    if (data && data.codes) {
      setCodes(data.codes);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setCodes([]);
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    if (errorMessage) {
      setIsError(true);
    }
  }, [errorMessage]);

  const clearErrorMessage = () => {
    if (error || errorMessage) {
      setErrorMessage('');
      setIsError(false);
    }
  };

  const handleSubmitClick = () => {
    if (formData.propertyId === '' && formData.serialNumber === '') {
      setIsError(true);
      setErrorMessage('Please fill in at least one of the fields');
      return;
    }
    loadCodes({
      variables: {
        propertyId: parseInt(formData.propertyId, 10),
        serialNumber: formData.serialNumber,
      },
    });
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            justify="center"
          >
            <div>
              <CustomTextField
                id="property_id"
                label="Property id"
                onFocus={clearErrorMessage}
                onChange={handleFormDataChange}
                name="propertyId"
                className={classes.actionButton}
                value={formData.propertyId}
                type="text"
                error={!!errorMessage}
              />

              <CustomTextField
                id="serial_number"
                label="Serial number"
                onFocus={clearErrorMessage}
                onChange={handleFormDataChange}
                className={classes.actionButton}
                name="serialNumber"
                value={formData.serialNumber}
                type="text"
                error={!!errorMessage}
              />
              { (error || errorMessage) && isError && (
                <div>
                  <Grid>
                    <Grid
                      container
                      className={classes.alertContainer}
                      justify="center"
                    >
                      <Grid item xs={12} lg={12} md={12}>
                        <Alert variant="outlined" className={classes.alertText} severity="error">
                          { errorMessage !== '' ? errorMessage : error ? transformGraphQlErrorMessage(error.message) : ''}
                        </Alert>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <CustomButton
            variant="orange"
            onClick={handleSubmitClick}
          >
            Submit
          </CustomButton>
        </Grid>
        { codes.length > 0 && (
          <Grid container>
            <CodesTable
              items={codes}
            />
          </Grid>
        ) }
      </Paper>
    </>
  );
};

export default Util;
