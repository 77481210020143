import React, { FC, memo } from 'react';
import { Grid } from '@material-ui/core';
import { SurveyResult } from '../../types/CustomerSurveys';
import SurveyInfo from './SurveyInfo';
import useStyles from '../CustomerDetails/styles';

type Props = {
  surveyResults: SurveyResult[]
};

const SurveyTab: FC<Props> = ({ surveyResults }) => {
  const classes = useStyles();
  return (
    <Grid container xs={12} sm={12}>
      <Grid item xs={12} sm={12}>
        <div className={classes.innerPaper}>
          {surveyResults.map(result => (
            <Grid item xs={12} sm={12}>
              <SurveyInfo survey={result} />
            </Grid>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(SurveyTab);
