/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { BaseSmartLock } from '../../types/Property';
import {
  GET_SMART_LOCK,
  GET_SMART_LOCK_TYPES,
  UPDATE_SMART_LOCK,
} from '../../graphql/queries/SmartLockDetails';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { smartLockCreateSchema } from './SmartLockValidation';
import {
  beautifyErrors,
  transformGraphQlErrorMessage,
  ValidationErrors,
  getSmartLockTypeValue,
} from '../../utils/helpers';
import CustomButton from '../../components/CustomButton';
import BuilderSearch from '../../components/BulderSearch/BuilderSearch';
import Builder from '../../types/Builder';
import StatusSelect from '../../components/StatusSelect/StatusSelect';
import useFormType from '../../hooks/useFormType';
import Timestamps from '../../components/Timestamps/Timestamps';
import SmartLockTypeSelect from '../../components/SmartLockTypeSelect';

interface SmartLock extends BaseSmartLock {
  old_serial_number: string | null;
  builder_id: number | null;
  smart_lock_type_id: number;
}

type Props = {
  loading?: boolean;
} & RouteComponentProps<{ smartLockSerial: string }>;

const SmartLockDetails: React.FC<Props> = props => {
  const classes = useStyles();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<SmartLock>>({});
  const { match, location, history } = props;
  const [smartLockType, setSmartLockType] = useState('');

  const [smartLock, setSmartLock] = useState<SmartLock>({
    serial_number: '',
    device_id: '',
    status: {
      status_id: 'LOCK_CREATED',
    },
    smartLockStatusId: 'LOCK_CREATED',
    smart_lock_type_id: 1,
    comments: '',
    created_on: '',
    created_by: '',
    modified_on: '',
    modified_by: '',
    old_serial_number: null,
    builder_id: null,
  });

  const { enqueueSnackbar } = useSnackbar();
  const formType = useFormType(match.params.smartLockSerial);

  const handleSmartLockChange = useCallback(
    ({
      target: { name, value, checked },
    }: React.ChangeEvent<HTMLInputElement>) => {
      const booleanFields: (keyof SmartLock)[] = [];
      if (name) {
        setSmartLock((prevSmartLock: SmartLock) => ({
          ...prevSmartLock,
          [name]: booleanFields.includes(name as keyof SmartLock) ? checked : value,
        }));
      }
    },
    [],
  );

  const handleSmartLockStatusChange = useCallback(
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setSmartLock((prevSmartLock: SmartLock) => ({
        ...prevSmartLock,
        status: { status_id: value },
        smartLockStatusId: value,
      }));
    },
    [],
  );

  const smartLockTypes = useQuery<{
    allSmartLockTypes: [{ id: number, smart_lock_type: string }]
  }>(GET_SMART_LOCK_TYPES, { fetchPolicy: 'network-only' });

  useEffect(() => {
    setSmartLockType(getSmartLockTypeValue(smartLock.smart_lock_type_id, smartLockTypes));
    console.log(smartLockType);
  }, [smartLock, smartLockTypes, smartLockType]);

  const handleSmartLockTypeChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const smartLockType = smartLockTypes?.data?.allSmartLockTypes.find((smartLockT: any) => smartLockT.smart_lock_type === value);
    console.log(smartLockType);
    setSmartLock((prevSmartLock: SmartLock) => ({
      ...prevSmartLock,
      smart_lock_type_id: smartLockType ? smartLockType.id : 1
    }));
  };

  const [updateSmartLock] = useMutation<{ updateSmartLock: SmartLock }>(UPDATE_SMART_LOCK, {
    errorPolicy: 'all',
  });

  // const handleDateChange = (date: string, name: string) => {
  //   if (Date.parse(date)) {
  //     const newDate = new Date(date);
  //     newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  //     const UTCDateString = newDate.toISOString();
  //     setSmartLock(prevState => ({ ...prevState, [name]: UTCDateString }));
  //   }
  // };

  const { loading, data } = useQuery<{ smartLockBySerialNumber: SmartLock }>(GET_SMART_LOCK, {
    variables: { serial_number: match.params.smartLockSerial },
    skip: formType === 'add' || !match.params.smartLockSerial,
    fetchPolicy: 'network-only',
  });

  const handleBuilderChange = (builder: Builder) => {
    setSmartLock((prevSmartLock: SmartLock) => ({
      ...prevSmartLock,
      builder_id: builder.builder_id,
    }));
  };


  const handleSmartLockSave = () => {
    try {
      const { ...requestSmartLock } = smartLock;
      const uSmartLock = smartLockCreateSchema.validateSync(smartLock, { abortEarly: false });
      // @ts-ignore
      const { ...validatedSmartLock } = uSmartLock;
      setValidationErrors({});
      updateSmartLock({
        variables: {
          smartLock: {
            ...requestSmartLock,
            ...validatedSmartLock,
          },
        },
      })
        .then(async result => {
          const sn = result.data ? result.data.updateSmartLock.serial_number : 'add';
          location.pathname = location.pathname.replace(
            match.params.smartLockSerial,
            sn,
          );
          enqueueSnackbar('Success', { variant: 'success' });
        })
        .catch(err => {
          enqueueSnackbar(transformGraphQlErrorMessage(err.message), {
            variant: 'error',
          });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<SmartLock>(errors));
    }
  };

  useEffect(() => {
    console.log(match.params.smartLockSerial);
    if (data) {
      if (data.smartLockBySerialNumber === null) {
        history.push('/smart-inventory/add');
      } else {
        data.smartLockBySerialNumber.old_serial_number = match.params.smartLockSerial || '';
        setSmartLock(data.smartLockBySerialNumber);
        setSmartLock((prevSmartLock: SmartLock) => ({
          ...prevSmartLock,
        }));
      }
    }
  }, [loading, data, history, match.params.smartLockSerial]);

  return (
    <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/smart-inventory"
          >
            <ArrowLeftIcon />
            Smart Inventory
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            {smartLock && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Timestamps timestamps={{
                      modifiedBy: smartLock.modified_by,
                      modifiedOn: smartLock.modified_on,
                      createdBy: smartLock.created_by,
                      createdOn: smartLock.created_on,
                    }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      autoFocus
                      required
                      label="Serial number"
                      name="serial_number"
                      value={smartLock.serial_number || ''}
                      onChange={handleSmartLockChange}
                      error={Boolean(validationErrors.serial_number)}
                      helperText={validationErrors.serial_number || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Device Id"
                      name="device_id"
                      value={smartLock.device_id || ''}
                      onChange={handleSmartLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SmartLockTypeSelect
                      label="Smart Lock Type"
                      variant="outlined"
                      value={getSmartLockTypeValue(smartLock.smart_lock_type_id, smartLockTypes)}
                      smartLockTypeId={smartLock.smart_lock_type_id}
                      smartLockTypes={smartLockTypes}
                      onChange={handleSmartLockTypeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '11px' }}>
                    <BuilderSearch
                      onBuilderChange={handleBuilderChange}
                      initialValue={smartLock.builder_id ?? undefined}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatusSelect
                      defaultValue=""
                      label="Smart Lock status"
                      variant="outlined"
                      statusType="lock"
                      onChange={handleSmartLockStatusChange}
                      value={smartLock.smartLockStatusId ?? ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={8}
                      rowsMax={8}
                      label="Comments"
                      name="comments"
                      value={smartLock.comments || ''}
                      onChange={handleSmartLockChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              spacing={4}
              justify="center"
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >

              <Grid item>
                <CustomButton variant="orange" onClick={handleSmartLockSave}>
                  {formType === 'edit' ? 'Save' : 'Add'}
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(SmartLockDetails);
