import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  visitsRequest,
  visitsExportRequest,
  visitsExportAndPrintRequest,
} from '../../redux/actions/visits';
import {
  selectTotalVisits,
  selectVisits,
  selectVisitsRequestStatus,
} from '../../redux/selectors/visits';
import Visits from './Visits';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  visits: selectVisits(state),
  visitsTotal: selectTotalVisits(state),
  loading: selectVisitsRequestStatus(state),
});

const mapDispatchToProps = {
  loadVisits: visitsRequest,
  exportVisits: visitsExportRequest,
  printVisits: visitsExportAndPrintRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Visits),
);
