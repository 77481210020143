import {
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST_FAILED,
  CUSTOMER_SELECTED_CLEAR,
  CUSTOMERS_REQUEST,
  CUSTOMERS_REQUEST_SUCCESS,
  CUSTOMERS_REQUEST_FAILED,
  CustomersState,
} from '../types/customers';
import { CustomersActionsTypes } from '../actions/customers';

const initialState: CustomersState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  selected: null,
};

export default function (
  state = initialState,
  action: CustomersActionsTypes,
): CustomersState {
  switch (action.type) {
    case CUSTOMERS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case CUSTOMERS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case CUSTOMERS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case CUSTOMER_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case CUSTOMER_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        selected: action.payload,
        error: '',
        loading: false,
      };
    }

    case CUSTOMER_DETAILS_REQUEST_FAILED: {
      return {
        ...state,
        selected: null,
        error: action.payload.message,
        loading: false,
      };
    }

    case CUSTOMER_SELECTED_CLEAR: {
      return {
        ...state,
        selected: null,
        error: '',
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
