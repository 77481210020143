import * as yup from 'yup';

export const userCreateSchema = yup.object().shape({
  username: yup.string().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  pass_word: yup.string().min(4).required(),
});

export const userUpdateSchema = yup.object().shape({
  username: yup.string().required(),
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  phone: yup.string(),
  email: yup.string().email(),
  pass_word: yup.string().min(4),
});

export const updateProfileSchema = yup.object().shape({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  phone: yup.string().required('Phone number is required'),
  email: yup.string().email().required('Email is required'),
  pass_word: yup.string().min(4, 'Password should be at least 4 characters long'),
});
