import { Query } from '../../utils/query';

export const WEEKLY_VISITS_REQUEST = 'WEEKLY_VISITS_REQUEST';
export const WEEKLY_VISITS_REQUEST_SUCCESS = 'WEEKLY_VISITS_REQUEST_SUCCESS';
export const WEEKLY_VISITS_REQUEST_FAILED = 'WEEKLY_VISITS_REQUEST_FAILED';
export const MONTHLY_VISITS_REQUEST = 'MONTHLY_VISITS_REQUEST';
export const MONTHLY_VISITS_REQUEST_SUCCESS = 'MONTHLY_VISITS_REQUEST_SUCCESS';
export const MONTHLY_VISITS_REQUEST_FAILED = 'MONTHLY_VISITS_REQUEST_FAILED';
export const YEARLY_VISITS_REQUEST = 'YEARLY_VISITS_REQUEST';
export const YEARLY_VISITS_REQUEST_SUCCESS = 'YEARLY_VISITS_REQUEST_SUCCESS';
export const YEARLY_VISITS_REQUEST_FAILED = 'YEARLY_VISITS_REQUEST_FAILED';
export const ALL_VISITS_REQUEST = 'ALL_VISITS_REQUEST';
export const ALL_VISITS_REQUEST_SUCCESS = 'ALL_VISITS_REQUEST_SUCCESS';
export const ALL_VISITS_REQUEST_FAILED = 'ALL_VISITS_REQUEST_FAILED';
export const ALL_PROPERTIES_STATS_REQUEST = 'ALL_PROPERTIES_STATS_REQUEST';
export const ALL_PROPERTIES_STATS_REQUEST_FAILED = 'ALL_PROPERTIES_STATS_REQUEST_FAILED';
export const ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS = 'ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS';
export const ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS = 'ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS';
export const CONTRACTORS_SERVED_COUNT_REQUEST = 'CONTRACTORS_SERVED_COUNT_REQUEST';
export const CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS = 'CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS';
export const CONTRACTORS_SERVED_COUNT_REQUEST_FAILED = 'CONTRACTORS_SERVED_COUNT_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type PropertyStats = {
  LASSO_custom_apikey?: string;
  LASSO_project_id?: string;
  LASSO_rpi: string;
  address_1?: string;
  address_2?: string;
  agent_2_old?: string;
  agent_3_old?: string;
  agent_4_old?: string;
  agent_old?: string;
  builder?: string;
  builder_id?: number;
  city?: string;
  closing_date?: string;
  contracted_by?: number;
  created_by?: string;
  created_on?: string;
  disposition_id?: number;
  grouping?: string;
  house_num?: string;
  latitude: number;
  lock_assigned?: string;
  lock_serial_number: string;
  smart_lock_serial_number: string;
  lock_terminated?: string;
  longitude: number;
  is_rental: boolean;
  is_webflow_code_only: boolean;
  lot_num?: string;
  modified_by?: string;
  modified_on?: string;
  non_cak?: number;
  phonedummy?: string;
  price: number;
  bedrooms: number;
  halfBathrooms: number;
  fullBathrooms: number;
  squareFootage: number;
  isModel: boolean;
  property_id: number;
  remarks?: string;
  state?: string;
  subdivision?: string;
  texting_active: number;
  texting_text: string;
  timezone?: number;
  under_contract?: number;
  under_contract_by?: string;
  under_contract_date?: string;
  url_1?: string;
  rental_application_url?: string;
  url_image?: string;
  zipcode?: string;
};

export type PropertiesStatsQuery = Query & { status: 'active' | 'sold' };

export type PropertiesStatsState = {
  propertiesActive: Array<PropertyStats>;
  propertiesSold: Array<PropertyStats>;
  loading: boolean;
  error: ErrorMessage['message'];
};

export type AllVisitStats = {
  customer_visit_id: number;
};

export type WeeklyVisitStats = {
  visit_count: number;
  date: string;
  day_of_week: number;
  name_of_day: string;
};

export type MonthlyVisitStats = {
  visit_count: number;
  date: string;
  day_of_week: number;
  name_of_day: string;
};

export type YearlyVisitStats = {
  visit_count: number;
  month_of_year: number;
  name_of_month: string;
};

export type WeeklyVisitsState = {
  items: Array<WeeklyVisitStats>;
  loading: boolean;
  error: ErrorMessage['message'];
};

export type MonthlyVisitsState = {
  items: Array<MonthlyVisitStats>;
  loading: boolean;
  error: ErrorMessage['message'];
};

export type YearlyVisitsState = {
  items: Array<YearlyVisitStats>;
  loading: boolean;
  error: ErrorMessage['message'];
};

export type AllVisitsState = {
  items: Array<AllVisitStats>;
  loading: boolean;
  error: ErrorMessage['message'];
};

export type StatsState = {
  properties: PropertiesStatsState;
  weeklyVisits: WeeklyVisitsState;
  monthlyVisits: MonthlyVisitsState;
  yearlyVisits: YearlyVisitsState;
  allVisits: AllVisitsState;
};

export type ContractorsState = {
  served: number;
  error: string;
  loading: boolean;
};
