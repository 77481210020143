import React, { useContext } from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from '../StatusSelect/styles';
import { StatesContext } from '../../context/StatesProvider';
import { State } from '../../types/Customer';
import CustomTextField from '../CustomTextField/CustomTextField';

type Props = {
  customTextField?: boolean
} & TextFieldProps;

const StatesSelect: React.FC<Props> = props => {
  const states = useContext<State[]>(StatesContext);
  const { customTextField, ...restProps } = props;
  const renderStatesItems = () => states.map(({ state_id, state_name }) => (
    <MenuItem
      key={state_id}
      value={state_id}
    >
      {state_name}
    </MenuItem>
  ));
  const classes = useStyles();
  return (
    <>
      { customTextField && (
        <>
          <CustomTextField
            margin="normal"
            fullWidth
            {...restProps}
            select
            inputProps={{
              classes: {
                disabled: classes.disabled,
              },
            }}
          >
            <MenuItem disabled value={1}>N / A</MenuItem>
            {renderStatesItems()}
          </CustomTextField>
        </>
      )
}
      { !customTextField && (
        <>
          <TextField
            margin="normal"
            fullWidth
            {...restProps}
            select
            inputProps={{
              classes: {
                disabled: classes.disabled,
              },
            }}
          >
            <MenuItem disabled value={1}>N / A</MenuItem>
            {renderStatesItems()}
          </TextField>
        </>
      )
  }
    </>
  );
};

export default React.memo(StatesSelect);
