import { combineReducers } from 'redux';
import auth from './auth';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import locks from './locks';
import smartLocks from './smart-locks';
import progressBarStatus from './progressBarStatus';
import stats from './stats';
import snackbars from './snackbars';
export default combineReducers({
  auth,
  locks,
  smartLocks,
  visits,
  customers,
  properties,
  progressBarStatus,
  stats,
  snackbars,
});
