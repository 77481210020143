import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Grid from '@material-ui/core/Grid';
import { SingleSelect } from 'react-select-material-ui';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import { generateLabel } from '../../utils/helpers';
import { AgentsProperties, AvailableAgentsType as Agent, UserType as User } from '../../types/User';
import useStyles from './styles';

type Props = {
  availableAgents: Array<Agent>;
  assignedAgents: Array<AgentsProperties>;
  handleInputChange: (value: User) => void;
  onAddAgent: () => void;
  newAgent: User;
  onRemoveAgent: (agent: AgentsProperties) => void;
};

const AvailableAgents: React.FC<Props> = props => {
  const {
    availableAgents, handleInputChange,
    assignedAgents, onAddAgent, onRemoveAgent, newAgent,
  } = props;
  const classes = useStyles();

  availableAgents.sort((a: Agent, b: Agent) => -(Number(a.value.isPM) - Number(b.value.isPM)));

  return (
    <List component="ul">
      <>
        <ListItem>
          <ListItemAvatar><div /></ListItemAvatar>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <SingleSelect
                label={generateLabel('Available agents')}
                options={availableAgents}
                style={{ zIndex: 2 }}
                onChange={handleInputChange}
                value={0}
                SelectProps={{
                  isCreatable: false,
                  msgNoOptionsMatchFilter: 'No agents were found',
                }}
              />
            </Grid>
            <Grid item container xs={12} sm={4} direction="row" alignContent="flex-end">
              <Grid item xs={12} sm={12}>
                <IconButton edge="end" disabled={newAgent.username === ''} onClick={onAddAgent}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </>
      {assignedAgents
        .map(propertyAgent => {
          if (!propertyAgent.user) {
            return '';
          }
          const agentRole = propertyAgent.is_project_manager ? 'PM' : 'AG';
          const listItemTextContent = `${agentRole}: ${propertyAgent.user.firstname} ${propertyAgent.user.lastname}`;
          const handleClick = () => onRemoveAgent(propertyAgent);
          const { email, phone } = propertyAgent.user;
          return (
            <ListItem component="li" key={listItemTextContent} className={classes.mainList}>
              <ListItemAvatar>
                <Avatar component="div" className={classes.icon}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <List>
                <ListItemText>{listItemTextContent}</ListItemText>
                {email && <ListItemText>{`Email: ${email}`}</ListItemText>}
                {phone && <ListItemText>{`Phone: ${phone}`}</ListItemText>}
              </List>
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={handleClick}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
    </List>
  );
};

export default AvailableAgents;
