import { gql } from 'apollo-boost';

export const ALL_PROPERTIES = gql`
  query(
    $limit: Int
    $offset: Int
    $search: String
    $sortBy: String
    $direction: String
  ) {
    newProperties(
      limit: $limit
      offset: $offset
      search: $search
      sortBy: $sortBy
      direction: $direction
    ) {
      list {
        property_id
        subdivision
        community
        house_num
        client {
          name
        }
        agents {
          agent_username
          is_project_manager
          user {
            email
            phone
            isPM
          }
        }
        city
        state
        LASSO_project_id
        lock {
          serial_number
          lockStatus
        }
        smartLock {
          serial_number
          smartLockStatus
        }
        hs
        disposition
        lock_assigned
        lock_terminated
        address_1
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_PROPERTY = gql(`
    mutation($id: Int!) {
        deletePropertyById(id:$id) {
        property_id,
        }
      }
    `);

export const REMOVE_PROPERTIES = gql`
  mutation($properties: [Int!]!) {
    deleteMultiplePropertiesById(properties: $properties)
  }
`;

export const CREATE_PROPERTY = gql`
  mutation updateProperty($property: UpdateProperty!, $communityId: Int!) {
    updateProperty(property: $property, communityId: $communityId) {
      property_id
    }
  }
`;

export const GET_AGENTS = gql`
  query getUsersByBuilderId($id: Float!) {
    getUsersByBuilderId(builder_id: $id) {
      username
      firstname
      lastname
      email
      phone
      isPM
    }
  }
`;

export const ADD_AGENTS = gql`
  mutation savePropertyAgents($propertyAgents: [UpdateAgentProperty!]!) {
    savePropertyAgents(propertyAgents: $propertyAgents)
  }
`;

export const PROPERTY_SUN_PARAMS = gql`
  query($property_id: Float!) {
    propertySunParams: propertySunParams(property_id: $property_id) {
      sunrise
      sunset
      untilSunrise
      untilSunset
      isBeforeSunrise
      isDuringDaytime
      isAfterSunset
    }
  }
`;

export const SEARCH_PROPERTIES_BY_ID = gql`
  query($property_id: Float!, $builder_id: Float!) {
    properties: searchProperties(
      property_id: $property_id
      builder_id: $builder_id
    ) {
      property_id
      propertyStatusId
      address_1
      builder
      builder_id
      url_1
      state
      emergency_contact {
        username
        firstname
        lastname
        phone
      }
      client {
        requires_opt_in
        name
      }
      timezone: timezoneCatalog {
        catalog_id
        value
      }
      lock {
        lockStatus
        lockStatusId
        manufacturer
      }
      agents {
        is_project_manager
        user {
          username
          email
          phone
        }
      }
      hub {
        serial_number
        hub_type
      }
      subdivision
      house_num
      lock_serial_number
      smart_lock_serial_number
      grouping
      adjustableTourHours
      tourHoursStart
      tourHoursEnd
      propertyTourHours {
        dayOfWeek
        tourHoursStart
        tourHoursEnd
        isToursAvailable
        secondWindowStart
        secondWindowEnd
        isSecondWindowEnabled
      }
      propertyCommunity {
        communities {
          communityTourHours {
            dayOfWeek
            tourHoursStart
            tourHoursEnd
            isToursAvailable
            secondWindowStart
            secondWindowEnd
            isSecondWindowEnabled
          }
          community_backup_code
          nternow_hub_backup_code
          sales_code
          trade_code
          sales_agent
          emergency_contacts
        }
      }
      alarm
      alarm_company
      armed_hours
      disarm_code
      emergency_notes
      novihome_integration
      nternow_hub_backup_code
      property_backup_code
      temp_backup_code
      access_notes
    }
  }
`;

export const GET_PROPERTY = gql`
  query($property_id: Float!) {
    property(property_id: $property_id) {
      property_id
      address_1
      house_num
      builder
      lock_serial_number
      smart_lock_serial_number
      lock {
        lockStatus
        lockStatusId
      }
      agents {
        is_project_manager
        user {
          username
          email
          phone
        }
      }
    }
  }
`;

export const GET_COORDINATES = gql`
  mutation(
    $house_num: Int!
    $address: String!
    $city: String!
    $state: String!
  ) {
    coordinates(
      house_num: $house_num
      address: $address
      city: $city
      state: $state
    ) {
      lng
      lat
    }
  }
`;
