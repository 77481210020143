import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Paper } from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { RouteComponentProps } from 'react-router-dom';
import { beautifyErrors, generateLabel, ValidationErrors } from '../../utils/helpers';
import useStyles from '../LockDetails/styles';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomButton from '../../components/CustomButton';
import LockCode from '../../components/LockCode/LockCode';
import { serialNumber } from './installGeneratorValidation';
import { GET_SERVER_TIME } from '../../graphql/queries/CodeGenerator';

type Props = {
  loading?: boolean;
} & RouteComponentProps;


const InstallGenerator: React.FC<Props> = ({ history }) => {
  const [serial, setSerial] = useState<string>('');
  const [lockSerial, setLockSerial] = useState<string>('0');
  const [
    validationErrors, setValidationErrors,
  ] = useState<ValidationErrors<{ serial : string }>>({});
  const [timeStamp, setTimestamp] = useState<number>(new Date().getMinutes());
  const classes = useStyles();
  const [serverTime, setServerTime] = useState({
    day: '',
    year: '',
    minute: '',
    hour: '',
    month: '',
  });

  const { data, loading } = useQuery<{ getServerTime: {
    day: number, year: number, minute: number, hour: number, month: number
  } }>(
    GET_SERVER_TIME,
    {
      variables: {
        minutes: timeStamp,
      },
      fetchPolicy: 'network-only',
    },
  );

  const handleSerialChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSerial(value);
  };

  const transformDate = (date: number) => {
    const stringDate = date.toString().substr(0, 2);
    return stringDate.padStart(2, '0') ?? '';
  };


  const handleLockSend = () => {
    try {
      serialNumber.validateSync({ serial }, { abortEarly: false });
      setValidationErrors({});
      setLockSerial(serial);
    } catch (errors) {
      setValidationErrors(beautifyErrors<{ serial: string }>(errors));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      setTimestamp(date.getMinutes());
    }, 10000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (data) {
      const minute = transformDate(data?.getServerTime?.minute);
      const month = transformDate(data?.getServerTime?.month);
      const day = transformDate(data?.getServerTime?.day);
      const year = transformDate(data?.getServerTime?.year);
      const hour = transformDate(data?.getServerTime?.hour);
      setServerTime({
        minute,
        month,
        day,
        hour,
        year,
      });
    }
  }, [data, loading]);


  return (
    <>
      <Grid>
        <CustomButton variant="white" onClick={() => history.goBack()}>
          <ArrowLeft />
          Go Back
        </CustomButton>
      </Grid>
      <div className={classes.contentWrapper} style={{ fontSize: '20px' }}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid container item xs={12} sm={12} style={{ marginTop: '20px', marginBottom: '15px' }} justify="center">
              <span style={{ fontWeight: 'bold' }}>
                NterNow Code Generator (auto refresh for every minute)
              </span>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              alignItems="center"
              justify="center"
              style={{ marginTop: '15px' }}
            >
              <CustomTextField
                id="sn"
                label="Serial number"
                value={serial || ''}
                onChange={handleSerialChange}
                helperText={validationErrors.serial ? 'Serial number must be a number' : ''}
              />
              <CustomButton variant="orange" onClick={handleLockSend} style={{ marginLeft: '15px' }}>
                    Submit
              </CustomButton>
              <Grid item xs={12} sm={12} style={{ marginTop: '25px', marginBottom: '15px' }}>
                <LockCode serialNumber={lockSerial} 
                setCode = {() => {}}/>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: '25px' }}>
          <Grid container>
            <>
              <Grid item xs={12} sm={1}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('Program time')}
                </span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('Code')}
                </span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('year')}
                </span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('month')}
                </span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('day ')}
                </span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('hour')}
                </span>
              </Grid>
              <Grid item xs={12} sm={1}>
                <span style={{ fontWeight: 'bold' }}>
                  {generateLabel('minute ')}
                </span>
              </Grid>
            </>
            <>
              <Grid item xs={12} sm={1}>
                <span>User switch</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span>1</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span>{serverTime.year}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span>{serverTime.month}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span>{serverTime.day}</span>
              </Grid>
              <Grid item xs={12} sm={2}>
                <span>{serverTime.hour}</span>
              </Grid>
              <Grid item xs={12} sm={1}>
                <span>{serverTime.minute}</span>
              </Grid>
            </>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

export default InstallGenerator;
