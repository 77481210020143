
import { connect } from 'react-redux';
import ApiKeys from './ApiKeys';
import { getOauthClientsRequest, removeOauthClientsRequest, createOauthClientsRequest } from '../../redux/actions/auth';
import { AppState } from '../../redux/store';
import { selectOauthClients } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  oauthClients: selectOauthClients(state),
});

const mapDispatchToProps = {
  getOauthClientsRequest,
  removeOauthClientsRequest,
  createOauthClientsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
