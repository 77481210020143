import React from 'react';
import SnackBar from '../../components/SnackBar';

import { SnackbarType } from '../../redux/types/snackbars';

type Props = {
  snackbars: Array<SnackbarType>;
  removeSnackBar: (id: string | number) => void;
};

const SnackBars: React.FC<Props> = props => {
  const { snackbars, removeSnackBar } = props;

  return (
    <>
      {
        snackbars
          .reverse()
          .map((snackbar, index) => (
            <SnackBar
              open
              key={snackbar.id}
              index={index}
              snackbar={snackbar}
              onClose={removeSnackBar}
            />
          ))
      }
    </>
  );
};

export default React.memo(SnackBars);
