import { gql } from 'apollo-boost';

export const GET_SERVER_TIME = gql`
    query {
      getServerTime {
        day,
        year,
        minute,
        hour,
        month
      }
    }
  `;

export const GET_CODES_BY_PROPERTY_ID = gql`
  query($propertyId: Int, $serialNumber: String) {
    codes(propertyId: $propertyId, serialNumber: $serialNumber) {
      offset,
      code,
    }
  }
`;

export const GET_LOCK_CODE = gql`
        query ($sn: String!, $minutes: Int!) {
            code(sn: $sn, minutes: $minutes)
        }
    `;
