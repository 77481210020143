import React from 'react';
import {
  Button, Chip, Divider, Grid,
} from '@material-ui/core';
import useStyles from './styles';
import JsonViewer from '../../components/JsonViewer';
import { convertDateToFullTimeWithTimezone } from '../../utils/helpers';

type Props = {
  requestData: Object;
  responseData: Object;
  broadcastMessages: Object[];
  clearBroadcastMessages: () => void;
};

const EzloRequestResponseComponent : React.FC<Props> = props => {
  const classes = useStyles();
  const {
    requestData, responseData, broadcastMessages, clearBroadcastMessages,
  } = props;

  return (
    <>
      <Grid item xs={12} sm={12}>
        <div className={classes.spacer} />
        <h2>Responses</h2>
        <p>Request, response and broadcast messages</p>
      </Grid>
      <Grid item xs={12} sm={12}>
        <p>Request</p>
        <JsonViewer
          json={requestData}
          depth={0}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <p>Response</p>
        <JsonViewer
          json={responseData}
          depth={0}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <div>
          <p>
            Broadcast messages
            (
            {broadcastMessages.length}
            )
            <Button style={{ float: 'right' }} onClick={clearBroadcastMessages}>
              Clear
            </Button>
          </p>
        </div>
        <Divider />
        <div style={{ maxHeight: 600, overflow: 'auto' }}>
          {broadcastMessages.map((item: any) => (
            <div key={item} style={{ margin: 10 }}>
              <div style={{ marginBottom: 10 }}>
                {/* eslint-disable-next-line no-underscore-dangle */}
                { item.data.result._id && <Chip size="small" color="primary" label={`_ID: ${item.data.result._id}`} />}
                <div style={{ float: 'right' }}>{convertDateToFullTimeWithTimezone(item.timestamp)}</div>
              </div>
              <JsonViewer
                json={item.data}
                depth={1}
              />
              <Divider />
            </div>
          ))}
        </div>
      </Grid>
    </>
  );
};

export default EzloRequestResponseComponent;
