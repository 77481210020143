import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-boost';
import { useSnackbar } from 'notistack';
import Community from '../../types/Community';
import { navTo } from '../../browserHistory';
import { GET_COMMUNITY } from '../../graphql/queries/Communities';

const defaultCommunity: Community = {
  id: 0,
  state: '',
  city: '',
  zip: '',
  external_community_id: '',
  sales_agent: '',
  builder_id: 0,
  name: '',
  created_on: '',
  created_by: '',
  modified_on: '',
  modified_by: '',
  oldId: 0,
};

const useCommunityDetails = (id : number | string) => {
  const load: boolean = id !== 'add';
  const [dataLoading, setLoading] = useState<boolean>(load);
  const [community, setCommunity] = useState<Community>(defaultCommunity);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery<{ communityById: Community }>(
    GET_COMMUNITY, {
      variables: {
        communityId: Number(id),
      },
      skip: id === 'add',
      fetchPolicy: 'network-only',
      onError: (error: ApolloError) => {
        enqueueSnackbar(`Error: ${error}`, { variant: 'error' });
        navTo('/communities');
      },
    },
  );

  useEffect(() => {
    if (data?.communityById) {
      setCommunity(data.communityById);
      setLoading(loading);
    }
  }, [data, loading]);

  return { community, loading: dataLoading, setCommunity };
};

export default useCommunityDetails;
