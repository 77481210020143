import jwt from 'jsonwebtoken';
import axios from './axios';
import * as storage from './storage';
import { AuthRequest, AuthState } from '../redux/types/auth';
import { AUTH_HEADER, AUTH_TOKEN, AUTH_USER } from '../constants/auth';

export const login = async ({ username, password }: AuthRequest) => {
  const url = '/auth/users';
  const requestData = { username, password };
  const requestConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await axios.post(url, requestData, requestConfig);
  const { data } = response;

  storage.addItem(AUTH_TOKEN, data.token);
  storage.addItem(AUTH_USER, JSON.stringify(data));
  axios.defaults.headers.common[AUTH_HEADER] = data.token;

  return response;
};

export const logOut = () => {
  storage.removeItem(AUTH_TOKEN);
  storage.removeItem(AUTH_USER);
  delete axios.defaults.headers.common[AUTH_HEADER];
};

export const setAuth = (token: string) => {
  storage.addItem(AUTH_TOKEN, token);
  axios.defaults.headers.common[AUTH_HEADER] = token;
};

export const getAuthUser = () => storage.getItem(AUTH_USER);
export const getAuthToken = () => storage.getItem(AUTH_TOKEN);

export const isUserAuthorized = (): boolean => {
  const authToken = storage.getItem(AUTH_TOKEN);

  return Boolean(authToken);
};

export const decodeToken = (token = '') => jwt.decode(token);

type TokenPayload =
  | Pick<AuthState, 'username' | 'builderId'>
  | string
  | null
  | { [key: string]: any };

export const getLocalAuthState = (initialState: AuthState): AuthState => {
  const token = storage.getItem(AUTH_TOKEN);
  const authUser = getAuthUser() || '';

  if (!token || !authUser) {
    return initialState;
  }

  const decoded: TokenPayload = decodeToken(token);
  const parsedUser = JSON.parse(authUser);

  if (
    decoded
    && typeof decoded !== 'string'
    && parsedUser.username === decoded.username
  ) {
    return {
      ...initialState,
      ...parsedUser,
      token,
    };
  }

  return initialState;
};

export const getOauthClients = async () => {
  const response = await axios.get('/auth/oauth_clients');
  return response;
};

export const createOauthClient = async (
  request : { client_secret?: string, client_id?: string, redirect_uri?: string},
) => {
  const response = await axios.post('/auth/oauth_clients', request);
  return response;
};

export const removeOauthClient = async (id: number) => {
  const response = await axios.delete(`/auth/oauth_clients/${id}`);
  return response;
};
