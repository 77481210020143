import React, {
  useState, useEffect,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import Lock from '../../types/Lock';
import useDebounce from '../../hooks/useDebounce';
import { SEARCH_SHIPMENTS_LOCKS } from '../../graphql/queries/LockDetails';

type Props = {
  onLockChange: (event: any) => void,
  initialValue?: string[];
  styles?: object;
  addLabel?: boolean;
  formType?: string;
} & TextFieldProps;

const ShipmentLockSearch: React.FC<Props> = ({
  onLockChange, initialValue, styles, formType, addLabel, ...textFieldProps
}) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 100);
  const handleSearch = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  };
  const [value, setValue] = useState<any>([]);
  const [availableLocks, setAvailableLocks] = useState<any>([]);

  const { data, loading } = useQuery<{ availableLocks: Lock[] }>(
    SEARCH_SHIPMENTS_LOCKS,
    {
      variables: {
        sn: debouncedSearch,
      },
      fetchPolicy: 'network-only',
    },
  );

  const handleChange = (event: any, chosenSelect: any | null) => {
    if (value.find((selected: any) => selected.label === event.target.innerText)) {
      return;
    }

    if (!chosenSelect.length) {
      return;
    }
    setAvailableLocks(availableLocks.filter((lockList: any) => !chosenSelect.some((selected: any) => selected.label === lockList.label)));
    const initialProp = [{
      serial_number: '',
    }];

    onLockChange(chosenSelect ?? initialProp);
  };

  useEffect(() => {
    setAvailableLocks(data?.availableLocks.map(item => ({
      value: item,
      label: `${item.serial_number}`,
    })));
  }, [data]);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue.map((iValue: string) => ({ label: iValue, value: { serial_number: iValue } })));
    }
  }, [initialValue]);

  if (formType && formType === 'edit' && !initialValue) {
    return null;
  }

  if (formType && formType === 'edit' && !value.length) {
    return null;
  }

  return (
    <>
      <div style={{ marginTop: '15px' }}>
        <Autocomplete
          multiple

          options={availableLocks}
          id="multiple-limit-tags"
          getOptionLabel={option => option.label}
          noOptionsText="No locks were found"
          onChange={handleChange}
          loading={loading}
          value={value ?? undefined}
          renderInput={params => (
            <TextField
              {...params}
              label={addLabel && 'Lock Serial Number'}
              fullWidth
              onKeyUp={handleSearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...textFieldProps}
            />
          )}
        />
      </div>
    </>
  );
};

export default ShipmentLockSearch;
