import { gql } from 'apollo-boost';
import { Customer } from '../../components/CustomerDetails/CustomerDetails';

const fields = Object.keys(new Customer());
const customerFields = fields.filter(e => !['remarks', 'property_id', 'survey_answers', 'emergency_contacts'].includes(e));

export const GET_CUSTOMER = gql`
query($customer_id: Float!) {
  customer(id: $customer_id) {
    ${customerFields.join('\n')},
    phoneNumber,
    sales_agent,
    emergency_contacts {
      username,
      firstname,
      lastname,
      phone
    }
  }
  surveyAnswers(customerId: $customer_id) {
    surveyId,
    results {
      question,
      answer
    }
  }
}
`;

export const GET_SURVEY_QUESTIONS = gql`
  query($builder_id: Int!) {
    surveyQuestions(builderId: $builder_id) {
      question_id,
      text,
      questiontype {
        questiontype_id,
        question_type
      }
      choices {
        choice_id,
        choice_number,
        text,
      }
    }
  }
`;


export const GET_VISITS = gql`
  query($customer_id: Int!) {
    visits: getCustomerVisits (customerId: $customer_id) {
      customer_visit_id,
      created_on,
      property{
        address_1,
        builder,
        timezoneCatalog {
          catalog_id,
          value
        }
        house_num,
      },
      remarks,
      modified_by
    },
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer($customer: UpdateCustomer!) {
    updateCustomer(customer: $customer) {
      customer_id
    }
  }
`;
