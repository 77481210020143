import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_CLOSED_DIAGNOSIS_CATALOGS } from '../../graphql/queries/TroubleTickets';
import { Catalog } from '../../types/Customer';

export default function useClosingDiagnosesCatalogs() {
  const [statuses, setStatuses] = useState<Catalog[]>([]);
  const { data } = useQuery<{ closingDiagnoses: Catalog[] }>(
    GET_CLOSED_DIAGNOSIS_CATALOGS,
  );
  useEffect(() => {
    if (data?.closingDiagnoses) {
      setStatuses(data.closingDiagnoses);
    }
  }, [data]);
  return statuses;
}
