import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  actionButton: {
    margin: 15,
  },
  contentWrapper: {
    marginTop: theme.spacing(3),
  },
  card: {
    overflow: 'hidden',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 30,
    margin: 30,
    paddingBottom: 15,
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  alertContainer: {
    marginBottom: 12,
    margin: '0 auto',
  },
  alertText: {
    fontSize: 18,
  },
  form: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
