/* eslint-disable lines-between-class-members */
import React, { useEffect, useState, useCallback } from 'react';
// @ts-ignore
import InputMask from 'react-input-mask';
import {
  Paper, Typography, Grid, MenuItem, Link,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField';
import {
  beautifyErrors,
  ValidationErrors,
  generateLabel,
} from '../../utils/helpers';
import { customerCreateSchema } from './CustomerValidation';
import { BaseCustomer, Catalog } from '../../types/Customer';
import Spinner from '../CustomSpinner';

import { BaseProperty as Property } from '../../types/Property';
import { SurveyQuestions } from '../../types/CustomerSurveys';

import LockCode from '../LockCode/LockCode';
import PropertySearch from '../PropertySearch';
import StatesSelect from '../StatesSelect/StatesSelect';
import PropertyInfo from './PropertyInfo/PropertyInfo';
import MobileAppLink from './MobileAppLink';
import Timestamps from '../Timestamps/Timestamps';
import {
  GET_NTERNOW_CODE,
  UPDATE_KWIKSET_LOCK_STATE,
  GET_KWIKSET_LOCK_STATE,
  ADD_DATADOC_LOGS,
  GENERATE_KWIKSET_ACCESS_CODE,
  GET_NTERNOW_SMART_LOCK_CODE,
} from '../../graphql/queries/PropertyDetails';
import useStatuses from '../../hooks/useStatuses';
import { warningStatuses } from '../LockStatus/LockStatus';

export class Customer extends BaseCustomer {
  street_address?: string = '';
  city?: string = '';
  zipcode?: string = '';
  verification_source_id?: number = 0;
  modified_by?: string = '';
  created_by?: string = '';
  visitor_status_id?: number = 0;
  state?: string = '';
  spouse_firstname?: string = '';
  spouse_lastname?: string = '';
  secret_question?: string = '';
  secret_answer?: string = '';
  property_id?: number | null;
  house_phone_1?: string = '';
  survey_answers?: {
    question_id: number;
    answer_choiceid: number;
  }[];
  cell_phone_2?: string = '';
  office_phone_1?: string = '';
  visitor_contact_status_id?: number = 156;
  email_1?: string = '';
  email_2?: string = '';
  remarks?: string = '';
  emergency_contacts?: {
    username: string;
    firstname: string;
    lastname: string;
    phone: string;
  } = {
    username: '',
    firstname: '',
    lastname: '',
    phone: ''
  };
  sales_agent?: string = '';
}

class UpdateCustomer extends Customer {
  pin_code?: string = '';
  serial_number?: string = '';
}

type Props = {
  history?: any;
  customerId: number;
  formType: string;
  visitorTypes: Catalog[];
  visitorStatuses: Catalog[];
  verificationSources: Catalog[];
  surveyQuestions: SurveyQuestions[];
  handleSave: (
    formCustomer: UpdateCustomer,
    validationCustomer: any,
    property: Property
  ) => Promise<any>;
  initialCustomer: Customer;
  setProgressBarStatus: (payload: boolean) => void;
  handleChooseProperty: (property: Property) => void;
};

const CustomerDetails: React.FC<Props> = ({
  formType,
  initialCustomer,
  visitorStatuses,
  visitorTypes,
  verificationSources,
  handleSave,
  surveyQuestions,
  history,
  handleChooseProperty,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [customer, setCustomer] = useState<Customer>(initialCustomer);
  const [verificationSourcesState, setVerificationSourcesState] = useState<
  Catalog[]
  >(
    verificationSources.filter(
      source => source.value
        && ![
          'License Verification',
          'Google Agency',
          'Staff Verification',
          'Dashboard Verification',
        ].includes(source.value),
    ),
  );
  const [surveyAnswers, setSurveyAnswers] = useState<any>([]);
  const gridLength = formType === 'add' ? 4 : 3;
  const [property, setProperty] = useState<Property>({
    property_id: 0,
    address_1: '',
    builder: '',
    subdivision: '',
    hub: {
      serial_number: '',
      hub_type: '',
    },
    timezone: { value: '', catalog_id: 0 },
    lock_serial_number: '',
    smart_lock_serial_number: '',
    house_num: '',
    url_1: '',
    rental_application_url: '',
    grouping: '',
    adjustableTourHours: 0,
    access_notes: '',
  });
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Customer>
  >({});
  const [hubCode, setHubCode] = useState<string>('');
  const [smartLockCode, setSmartLockCode] = useState<string>('');
  const [kwiksetLockState, setKwiksetLockState] = useState<string>('Unknown');
  const [kwiksetLockCode, setKwiksetLockCode] = useState<string>('');
  const [hubSerialNumber, setHubSerialNumber] = useState<string>('');
  const [smartLockSerialNumber, setSmartLockSerialNumber] = useState<string>(
    '',
  );
  const [hubType, setHubType] = useState<string>('');
  const [isCode, setIsCode] = useState<boolean>(false);
  const [isSmartLockCode, setIsSmartLockCode] = useState<boolean>(false);
  const [lockCode, setLockCode] = useState<string>('');
  const [isOutsideTourHours, setIsOutsideTourHours] = useState(false);
  const [isToursUnavailable, setIsToursUnavailable] = useState(false);

  const hubsWithCodes = ['Ezlo Secure', 'ADC', 'Seam'];

  useEffect(() => {
    setCustomer(initialCustomer);
  }, [initialCustomer]);

  useEffect(() => {
    setSurveyAnswers(
      surveyQuestions.map((question: SurveyQuestions) => ({
        question_id: question.question_id,
        choice: 0,
        answer_text: '',
      })),
    );
  }, [surveyQuestions]);

  const [getNNCode, { data: getNternowCode, loading: codeLoading, error: codeError }] = useLazyQuery(GET_NTERNOW_CODE, { fetchPolicy: 'no-cache' });
  const [getNNSmartLockCode, { data: getNternowSmartLockCode, loading: smartLockCodeLoading, error: smartLockCodeError }] = useLazyQuery(GET_NTERNOW_SMART_LOCK_CODE, { fetchPolicy: 'no-cache' });
  const [updateKwikset, { data: updateKwiksetLockState, loading: isLoadingUpdateKwikset, error: updateKwiksetError }] = useLazyQuery(UPDATE_KWIKSET_LOCK_STATE, { fetchPolicy: 'no-cache' });
  const [getKwikset, { data: getKwiksetLockState, loading: isLoadingGetKwikset, error: getKwiksetError }] = useLazyQuery(GET_KWIKSET_LOCK_STATE, { fetchPolicy: 'no-cache' });
  const [getKwikAccessCode, { data: getKwiksetAccessCode, loading: isLoadingGetKwiksetAccessCode, error: getKwiksetAccessCodeError }] = useLazyQuery(GENERATE_KWIKSET_ACCESS_CODE, { fetchPolicy: 'no-cache' });

  const statuses: { status_id: string; status: string }[] = useStatuses(
    'property',
  );
  const status = statuses.find(item => item.status_id === property.propertyStatusId)
      ?.status ?? '';
  const warningTourHours = isToursUnavailable || isOutsideTourHours;
  const warningStatus = warningStatuses.includes(status);
  const isDeny = customer.visitor_status_id === 155;
  const showWarning = warningStatus || isOutsideTourHours || isToursUnavailable || isDeny;
  function handleCode(code: string) {
    setLockCode(code);
  }
  const handleTourHoursAlert = (alert: {
    isOutsideTourHours: boolean;
    isToursUnavailable: boolean;
  }) => {
    setIsOutsideTourHours(alert.isOutsideTourHours);
    setIsToursUnavailable(alert.isToursUnavailable);
  };
  useEffect(() => {
    if (getNternowCode) {
      setHubCode(getNternowCode.getNternowCode);
    }
  }, [hubSerialNumber, getNternowCode, hubCode]);

  useEffect(() => {
    if (getNternowSmartLockCode) {
      setSmartLockCode(getNternowSmartLockCode.getNternowSmartLockCode);
    }
  }, [smartLockSerialNumber, getNternowSmartLockCode, smartLockCode]);

  useEffect(() => {
    if (getKwiksetLockState) {
      setKwiksetLockState(getKwiksetLockState.getKwiksetLockState);
      console.log(getKwiksetLockState);
    }
  }, [getKwiksetLockState]);

  useEffect(() => {
    if (getKwiksetAccessCode) {
      setKwiksetLockCode(getKwiksetAccessCode.generateKwiksetAccessCode);
    }
  }, [getKwiksetAccessCode]);

  useEffect(() => {
    if (updateKwiksetLockState) {
      setKwiksetLockState(updateKwiksetLockState.updateKwiksetLockState);
    }
  }, [updateKwiksetLockState]);

  useQuery(ADD_DATADOC_LOGS, {
    variables: {
      propertyId: property.property_id,
      serialNumber: property.hub?.serial_number ?? property.lock_serial_number ?? property.smart_lock_serial_number,
      customerName: `${customer.firstname} ${customer.lastname}`,
      customerId: customer.customer_id,
      phone: customer.cell_phone_1,
      type: property.hub?.hub_type ?? property.lock?.manufacturer,
      code: hubCode ? hubCode.toString() : smartLockCode ? smartLockCode.toString() : lockCode,
    },
    fetchPolicy: 'network-only',
    skip: !hubCode && !lockCode && !smartLockCode,
  });

  useEffect(() => {
    if (customer.visitor_type_id === 125) {
      setVerificationSourcesState(
        verificationSources.filter(
          source => source.value
            && !['License Verification', 'Google Agency'].includes(source.value),
        ),
      );
    } else if (customer.visitor_type_id === 123) {
      setVerificationSourcesState(
        verificationSources.filter(
          source => source.value
            && !['Staff Verification', 'Dashboard Verification'].includes(
              source.value,
            ),
        ),
      );
    } else {
      setVerificationSourcesState(
        verificationSources.filter(
          source => source.value
            && ![
              'License Verification',
              'Google Agency',
              'Staff Verification',
              'Dashboard Verification',
            ].includes(source.value),
        ),
      );
    }
  }, [customer.visitor_type_id, verificationSources]);

  const createMenuItemArray = (items: Array<Catalog>) => items.map(item => (
    <MenuItem key={item.value} value={item.catalog_id}>
      {item.value}
    </MenuItem>
  ));

  const handleCustomerChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setCustomer((prevCustomer: Customer) => ({
          ...prevCustomer,
          [name]: value,
        }));
      }
    },
    [],
  );

  const handlePropertyChange = useCallback((chosenProperty: Property) => {
    const firstName = customer.firstname;
    const lastName = customer.lastname;
    setHubCode('');
    setLockCode('');
    setSmartLockCode('');
    setCustomer((prevUser: Customer) => ({
      ...prevUser,
      property_id: chosenProperty.property_id,
    }));
    setProperty(() => ({
      ...chosenProperty,
    }));
    handleChooseProperty(chosenProperty);

    if (chosenProperty.hub) {
      setHubSerialNumber(chosenProperty.hub?.serial_number.toString());
      setHubType(chosenProperty.hub?.hub_type);
      if (hubsWithCodes.includes(chosenProperty.hub.hub_type)) {
        getNNCode({
          variables: {
            id: chosenProperty.hub.serial_number, firstName, lastName, hubType: chosenProperty.hub.hub_type,
          },
        });
      }
      if (chosenProperty.hub.hub_type === 'Kwikset') {
        getKwikset({
          variables: {
            id: chosenProperty.hub.serial_number,
          },
        });
        getKwikAccessCode({
          variables: {
            id: chosenProperty.hub.serial_number,
          },
        });
      }
      setIsCode(true);
      setIsSmartLockCode(false);
    } else if (chosenProperty.smart_lock_serial_number?.length > 0) {
      setSmartLockSerialNumber(chosenProperty.smart_lock_serial_number.toString());
      getNNSmartLockCode({
        variables: {
          smartLockSerialNumber: chosenProperty.smart_lock_serial_number, firstName, lastName,
        },
      });
      setIsCode(false);
      setIsSmartLockCode(true);
    } else {
      setHubSerialNumber('');
      setHubType('');
      setHubCode('');
      setIsSmartLockCode(false);
      setIsCode(false);
    }
  }, [customer, getNNCode, getNNSmartLockCode, getKwikset, getKwikAccessCode, hubsWithCodes, handleChooseProperty]);

  const handleRetryCode = () => {
    getNNCode({
      variables: {
        id: hubSerialNumber, firstName: customer.firstname, lastName: customer.lastname, hubType,
      },
    });
  };

  const handleRetrySmartLockCode = () => {
    getNNSmartLockCode({
      variables: {
        smartLockSerialNumber, firstName: customer.firstname, lastName: customer.lastname,
      },
    });
  };

  const handleLockKwiksetHub = () => {
    updateKwikset({
      variables: {
        id: hubSerialNumber, state: 'Lock',
      },
    });
  };

  const handleUnlockKwiksetHub = () => {
    updateKwikset({
      variables: {
        id: hubSerialNumber, state: 'Unlock',
      },
    });
  };

  const handleSurveyAnswersChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    question: SurveyQuestions,
  ) => {
    const surveyAnswer = e.target;
    const questionWithChoices = question.questiontype?.questiontype_id === 1
      || question.questiontype?.questiontype_id === 2;
    const arr = surveyAnswers.slice();
    const index = surveyAnswers.findIndex((it: any) => Object.values(it)[0] === Number(surveyAnswer.name));
    const answer = question?.choices?.find(
      (choice: any) => choice.choice_id === surveyAnswer.value,
    );
    arr[index] = {
      question_id: Number(surveyAnswer.name),
      choice: questionWithChoices ? surveyAnswer.value : 0,
      answer_text: questionWithChoices && answer ? answer.text : surveyAnswer.value,
    };
    setSurveyAnswers(arr);
    setCustomer((prevUser: Customer) => ({
      ...prevUser,
      survey_answers: arr,
    }));
  };

  const handleCustomerSave = async () => {
    try {
      const uCustomer = customerCreateSchema.validateSync(customer, {
        abortEarly: false,
      });
      setValidationErrors({});
      return handleSave({
        ...customer,
        pin_code: hubCode ? hubCode.toString() : smartLockCode ? smartLockCode.toString() : lockCode,
        serial_number: property.hub?.serial_number ?? property.lock_serial_number ?? property.smart_lock_serial_number,
      }, uCustomer, property);
    } catch (errors) {
      setValidationErrors(beautifyErrors<Customer>(errors));
    }
  };

  const handleTicketCreate = async () => {
    try {
      await handleCustomerSave();
      history.push({
        pathname: '/tickets/add',
        state: {
          customer: {
            firstName: customer.firstname,
            lastName: customer.lastname,
            cell_phone_1: customer.cell_phone_1,
            property_id: property.property_id,
          },
        },
      });
    } catch (e) {
      enqueueSnackbar('An error occurred', { variant: 'error' });
    }
  };

  const renderVisitorInfo = () => {
    if (formType === 'add') {
      return (
        <Grid
          container
          item
          xs={12}
          sm={3}
          direction="column"
          alignContent="flex-end"
          alignItems="flex-end"
        >
          <InputMask
            mask="999-999-9999"
            onChange={handleCustomerChange}
            value={customer.cell_phone_1}
          >
            {() => (
              <CustomTextField
                margin="normal"
                fullWidth
                required
                label="Cell phone 1"
                name="cell_phone_1"
                error={Boolean(validationErrors.cell_phone_1)}
                helperText={validationErrors.cell_phone_1 || ''}
              />
            )}
          </InputMask>
          <CustomTextField
            fullWidth
            id="visitor_type_id"
            label="Visitor Type"
            name="visitor_type_id"
            value={customer.visitor_type_id}
            select
            required
            onChange={handleCustomerChange}
          >
            <MenuItem disabled value={0}>
              None
            </MenuItem>
            {createMenuItemArray(visitorTypes)}
          </CustomTextField>
        </Grid>
      );
    }
    return (
      <Grid
        container
        item
        xs={12}
        sm={4}
        direction="column"
        alignContent="flex-end"
        alignItems="flex-end"
      >
        <CustomTextField
          fullWidth
          margin="normal"
          id="visitor_contact_status_id"
          required
          label="Contact status"
          name="visitor_contact_status_id"
          value={customer.visitor_contact_status_id}
          select
          onChange={handleCustomerChange}
          error={Boolean(validationErrors.visitor_contact_status_id)}
          helperText={validationErrors.visitor_contact_status_id || ''}
        >
          <MenuItem disabled value={0}>
            None
          </MenuItem>
          <MenuItem value={156}>Contact</MenuItem>
          <MenuItem value={157}>No-contact</MenuItem>
        </CustomTextField>
        <CustomTextField
          fullWidth
          id="visitor_type_id"
          label="Visitor Type"
          name="visitor_type_id"
          value={customer.visitor_type_id}
          select
          required
          onChange={handleCustomerChange}
        >
          <MenuItem disabled value={0}>
            None
          </MenuItem>
          {createMenuItemArray(visitorTypes)}
        </CustomTextField>
        <CustomTextField
          margin="dense"
          fullWidth
          id="visitor_status_id"
          required
          label="Visit status"
          name="visitor_status_id"
          value={customer.visitor_status_id}
          select
          onChange={handleCustomerChange}
          error={Boolean(validationErrors.visitor_status_id)}
          helperText={validationErrors.visitor_status_id || ''}
        >
          <MenuItem disabled value={0}>
            None
          </MenuItem>
          {createMenuItemArray(visitorStatuses)}
        </CustomTextField>
      </Grid>
    );
  };

  const renderCustomerAddress = () => (
    <>
      <Grid item xs={12} sm={formType === 'edit' ? gridLength : 3}>
        <CustomTextField
          margin="normal"
          fullWidth
          label="Street Address"
          name="street_address"
          value={customer.street_address}
          onChange={handleCustomerChange}
        />
      </Grid>
      <Grid item xs={12} sm={formType === 'edit' ? 4 : 3}>
        <CustomTextField
          margin="normal"
          fullWidth
          label="City"
          name="city"
          value={customer.city}
          onChange={handleCustomerChange}
        />
      </Grid>
      <Grid item xs={12} sm={formType === 'edit' ? 4 : 3}>
        <StatesSelect
          fullWidth
          customTextField
          id="state"
          label="State"
          name="state"
          value={customer.state}
          onChange={handleCustomerChange}
        />
      </Grid>
      <Grid item xs={12} sm={formType === 'edit' ? 4 : 3}>
        <CustomTextField
          margin="normal"
          fullWidth
          label="Zip code"
          name="zipcode"
          value={customer.zipcode}
          onChange={handleCustomerChange}
        />
      </Grid>
    </>
  );
  const renderVisitInfo = () => (
    <>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          margin="normal"
          fullWidth
          id="visitor_status_id"
          required
          label="Visit status"
          name="visitor_status_id"
          value={customer.visitor_status_id}
          select
          onChange={handleCustomerChange}
          error={Boolean(validationErrors.visitor_status_id)}
          helperText={validationErrors.visitor_status_id || ''}
        >
          <MenuItem disabled value={0}>
            None
          </MenuItem>
          {createMenuItemArray(visitorStatuses)}
        </CustomTextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomTextField
          fullWidth
          margin="normal"
          id="visitor_contact_status_id"
          required
          label="Contact status"
          name="visitor_contact_status_id"
          value={customer.visitor_contact_status_id}
          select
          onChange={handleCustomerChange}
          error={Boolean(validationErrors.visitor_contact_status_id)}
          helperText={validationErrors.visitor_contact_status_id || ''}
        >
          <MenuItem disabled value={0}>
            None
          </MenuItem>
          <MenuItem value={156}>Contact</MenuItem>
          <MenuItem value={157}>No-contact</MenuItem>
        </CustomTextField>
      </Grid>
    </>
  );
  const renderAnswerQuestion = () => (
    <>
      <Grid item xs={3} sm={6}>
        <CustomTextField
          margin="normal"
          fullWidth
          label="Secret question"
          name="secret_question"
          value={customer.secret_question ?? ''}
          onChange={handleCustomerChange}
        />
      </Grid>
      <Grid item xs={3} sm={6}>
        <CustomTextField
          margin="normal"
          fullWidth
          label="Secret answer"
          name="secret_answer"
          value={customer.secret_answer ?? ''}
          onChange={handleCustomerChange}
        />
      </Grid>
    </>
  );

  const renderSurveyQuestion = () => (
    <>
      <Grid
        container
        className={classes.surveyQuestionsContainer}
        alignContent="center"
        xs={12}
        sm={12}
      >
        {surveyQuestions.map(question => {
          const index = surveyAnswers.find(
            (it: any) => Object.keys(it)[0] === question.question_id?.toString(),
          );
          const value = index && Object.values(index)[0];
          const questionsWithChoices = question.questiontype?.questiontype_id === 1
            || question.questiontype?.questiontype_id === 2;

          return (
            <>
              <Grid container className={classes.surveyQuestions} xs>
                <Typography variant="body1" className={classes.surveyAnswers}>
                  {question.text}
                </Typography>
                <Grid container item xs={12} sm={3}>
                  {questionsWithChoices && (
                    <CustomTextField
                      id="survey_answer"
                      select
                      fullWidth
                      label="Answer"
                      name={question.question_id?.toString()}
                      value={value}
                      className={classes.surveyAnswerDropdown}
                      onChange={e => handleSurveyAnswersChange(e, question)}
                    >
                      <MenuItem
                        disabled
                        value={0}
                        className={classes.surveyAnswerDropdown}
                      >
                        None
                      </MenuItem>
                      {questionsWithChoices
                        && question.choices
                        && question.choices.map(choice => (
                          <MenuItem
                            className={classes.surveyAnswerDropdown}
                            value={choice.choice_id}
                          >
                            {choice.text}
                          </MenuItem>
                        ))}
                    </CustomTextField>
                  )}
                </Grid>
                <Grid container item xs={12} sm={3}>
                  {question.questiontype?.questiontype_id === 3 && (
                    <CustomTextField
                      id="survey_answer"
                      select
                      fullWidth
                      label="Answer"
                      name={question.question_id?.toString()}
                      value={value}
                      className={classes.surveyAnswerDropdown}
                      onChange={e => handleSurveyAnswersChange(e, question)}
                    >
                      <MenuItem
                        disabled
                        value={0}
                        className={classes.surveyAnswerDropdown}
                      >
                        None
                      </MenuItem>
                      <MenuItem
                        className={classes.surveyAnswerDropdown}
                        value="Yes"
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        className={classes.surveyAnswerDropdown}
                        value="No"
                      >
                        No
                      </MenuItem>
                    </CustomTextField>
                  )}
                </Grid>
                {question.questiontype?.questiontype_id === 4 && (
                  <CustomTextField
                    placeholder="Text Stream (Limit 30 characters)"
                    name={question.question_id?.toString()}
                    onChange={e => handleSurveyAnswersChange(e, question)}
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                {question.questiontype?.questiontype_id === 5 && (
                  <CustomTextField
                    type="number"
                    placeholder="Enter Number"
                    name={question.question_id?.toString()}
                    onChange={e => handleSurveyAnswersChange(e, question)}
                  />
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.paper}>
          {customer && (
            <>
              <Grid container item xs={12} sm={12}>
                <Grid container justify="flex-end">
                  <MobileAppLink
                    customerId={customer.customer_id}
                    isDisabled={formType === 'add'}
                  />
                  <Grid item>
                    <CustomButton
                      variant="orange"
                      onClick={handleCustomerSave}
                      style={{ marginBottom: '5px' }}
                    >
                      {formType === 'edit' ? 'Save' : 'Add'}
                    </CustomButton>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={4}>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      variant="h3"
                      gutterBottom
                      className={classes.customerName}
                    >
                      {customer.firstname}
                      {' '}
                      {customer.lastname}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10}>
                    <PropertySearch
                      fullWidth
                      onPropertyChange={handlePropertyChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={6} sm={8} spacing={1}>
                  {renderVisitorInfo()}
                  <Grid item container sm={8} xs={6}>
                    <Grid item xs={12} sm={12}>
                      {property?.property_id !== 0 && (
                        <PropertyInfo
                          property={property}
                          handleTourHoursAlert={handleTourHoursAlert}
                        />
                      )}
                    </Grid>
                    {!!property.property_id && isCode && hubsWithCodes.includes(property.hub!.hub_type) && (
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              {generateLabel('Hub Code: ')}
                            </span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '24px',
                                letterSpacing: '14px',
                                marginLeft: '5px',
                              }}
                            >
                              {codeLoading ? <Spinner size={20} /> : hubCode}
                            </span>
                            {codeError && (
                            <span
                              style={{ color: 'red', fontWeight: 600 }}
                            >
                              {codeError.message.replace('GraphQL error: ', '')}
                            </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {codeError && (
                          <CustomButton variant="orange" onClick={handleRetryCode}>
                            Retry
                          </CustomButton>
                          )}

                        </Grid>
                      </Grid>
                    )}
                    {!!property.property_id && isSmartLockCode && (
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              {generateLabel('Smart Lock Code: ')}
                            </span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                fontSize: '24px',
                                letterSpacing: '14px',
                                marginLeft: '5px',
                              }}
                            >
                              {smartLockCodeLoading ? <Spinner size={20} /> : smartLockCode}
                            </span>
                            {smartLockCodeError && (
                            <span
                              style={{ color: 'red', fontWeight: 600 }}
                            >
                              {smartLockCodeError.message.replace('GraphQL error: ', '')}
                            </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {smartLockCodeError && (
                          <CustomButton variant="orange" onClick={handleRetrySmartLockCode}>
                            Retry
                          </CustomButton>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    {!!property.property_id && isCode && (property.hub!.hub_type === 'Kwikset') && (
                      <Grid item xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              {generateLabel('Kwikset code: ')}
                            </span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                marginBottom: '20px',
                                marginTop: '20px',
                              }}
                            >
                              {(isLoadingGetKwikset || isLoadingGetKwiksetAccessCode) ? <Spinner size={20} /> : kwiksetLockCode}
                            </span>
                            {getKwiksetAccessCodeError && (
                            <span
                              style={{ color: 'red', fontWeight: 600 }}
                            >
                              {getKwiksetAccessCodeError.message.replace('GraphQL error: ', '')}
                            </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              {generateLabel('Kwikset lock status: ')}
                            </span>
                            <span
                              style={{
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                marginBottom: '20px',
                                marginTop: '20px',
                              }}
                            >
                              {(isLoadingGetKwikset || isLoadingUpdateKwikset) ? <Spinner size={20} /> : kwiksetLockState}
                            </span>
                            {getKwiksetError && (
                            <span
                              style={{ color: 'red', fontWeight: 600 }}
                            >
                              {getKwiksetError.message.replace('GraphQL error: ', '')}
                            </span>
                            )}
                            {updateKwiksetError && (
                            <span
                              style={{ color: 'red', fontWeight: 600 }}
                            >
                              {updateKwiksetError.message.replace('GraphQL error: ', '')}
                            </span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <br />
                          <CustomButton variant="orange" onClick={handleUnlockKwiksetHub}>
                            Unlock
                          </CustomButton>
                          <span>{'   '}</span>
                          <CustomButton variant="orange" onClick={handleLockKwiksetHub}>
                            Lock
                          </CustomButton>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      {!!property.property_id && !isCode && (
                        <LockCode
                          access_notes={property.access_notes || ''}
                          serialNumber={property.lock_serial_number}
                          setCode={handleCode}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  className={classes.essentialNotesContainer}
                >
                  {showWarning && (
                    <Grid
                      className={classes.statusWarning}
                      direction="column"
                      container
                      item
                      xs={12}
                      sm={5}
                    >
                      {warningStatus && (
                        <div className={classes.marginNote}>
                          <span className={classes.notesHeader}>
                            Property Disposition Alert:
                          </span>
                          <span
                            className={classes.notesMessage}
                            style={{ color: 'red' }}
                          >
                            {status}
                          </span>
                        </div>
                      )}
                      {warningTourHours && (
                        <div className={classes.marginNote}>
                          <span className={classes.notesHeader}>
                            Tour Hours Alert:
                          </span>
                          <span
                            className={classes.notesMessage}
                            style={{ color: 'red' }}
                          >
                            {isOutsideTourHours && 'Outside Tour Hours'}
                            {isToursUnavailable && 'No Tours Today'}
                          </span>
                        </div>
                      )}
                      {isDeny && (
                        <div className={classes.marginNote}>
                          <span className={classes.notesHeader}>
                            Visitor:
                          </span>
                          <span
                            className={classes.notesMessage}
                            style={{ color: 'red' }}
                          >
                            Deny
                          </span>
                        </div>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    required
                    label="First name"
                    name="firstname"
                    value={customer.firstname}
                    onChange={handleCustomerChange}
                    error={Boolean(validationErrors.firstname)}
                    helperText={validationErrors.firstname || ''}
                  />
                </Grid>
                <Grid item xs={3} sm={3}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    required
                    label="Last name"
                    name="lastname"
                    value={customer.lastname}
                    onChange={handleCustomerChange}
                    error={Boolean(validationErrors.lastname)}
                    helperText={validationErrors.lastname || ''}
                  />
                </Grid>
                {formType === 'edit' && renderAnswerQuestion()}
                <Grid item xs={3} sm={formType === 'edit' ? gridLength : 3}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    required
                    label="Email"
                    name="email_1"
                    value={customer.email_1}
                    onChange={handleCustomerChange}
                    error={Boolean(validationErrors.email_1)}
                    helperText={validationErrors.email_1 || ''}
                  />
                </Grid>
                {formType === 'edit' && (
                  <Grid item xs={3} sm={gridLength}>
                    <InputMask
                      mask="999-999-9999"
                      onChange={handleCustomerChange}
                      value={customer.cell_phone_1}
                    >
                      {() => (
                        <CustomTextField
                          margin="normal"
                          fullWidth
                          required
                          label="Cell phone 1"
                          name="cell_phone_1"
                          error={Boolean(validationErrors.cell_phone_1)}
                          helperText={validationErrors.cell_phone_1 || ''}
                        />
                      )}
                    </InputMask>
                  </Grid>
                )}
                <Grid item xs={3} sm={formType === 'edit' ? gridLength : 3}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    id="verification_source_id"
                    label="Verification Source"
                    name="verification_source_id"
                    value={customer.verification_source_id ?? ''}
                    select
                    onChange={handleCustomerChange}
                  >
                    <MenuItem disabled value={0}>
                      None
                    </MenuItem>
                    {createMenuItemArray(verificationSourcesState)}
                  </CustomTextField>
                </Grid>
                {formType === 'edit' && renderCustomerAddress()}
                {formType === 'add' && renderVisitInfo()}
                {formType === 'add'
                  && !!property.property_id
                  && renderSurveyQuestion()}
                {formType === 'add' && renderAnswerQuestion()}
                {formType === 'add' && renderCustomerAddress()}
                <Grid item xs={12} sm={12}>
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    multiline
                    rows={8}
                    rowsMax={8}
                    label="Call center visit remarks"
                    name="remarks"
                    value={customer.remarks}
                    onChange={handleCustomerChange}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="Spouse First Name"
                name="spouse_firstname"
                value={customer.spouse_firstname ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="Spouse Last Name"
                name="spouse_lastname"
                value={customer.spouse_lastname ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="House Phone"
                name="house_phone_1"
                value={customer.house_phone_1 ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="Cell phone 2"
                name="cell_phone_2"
                value={customer.cell_phone_2 ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="Office phone 1"
                name="office_phone_1"
                value={customer.office_phone_1 ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CustomTextField
                margin="normal"
                fullWidth
                label="Email 2"
                name="email_2"
                value={customer.email_2 ?? ''}
                onChange={handleCustomerChange}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={2}
              direction="column"
              justify="center"
            >
              <CustomButton variant="orange" onClick={handleTicketCreate}>
                Create trouble ticket
              </CustomButton>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Timestamps
                  timestamps={{
                    createdOn: customer.created_on,
                    createdBy: customer.created_by,
                    modifiedOn: customer.modified_on,
                    modifiedBy: customer.modified_by,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {property.property_id !== 0 && (
                  <>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('Property id ')}
:
                        {' '}
                      </span>
                      {' '}
                      <Link
                        href={`/properties/${property.property_id}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {property.property_id}
                      </Link>
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('Lock Serial Number')}
:
                        {' '}
                      </span>
                      {' '}
                      <Link
                        href={`/inventory/${property.lock_serial_number}`}
                        target="_blank"
                        rel="noopener"
                      >
                        {property.lock_serial_number}
                      </Link>
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};
export default CustomerDetails;
