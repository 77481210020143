import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from './styles';

type Props = {width?: string} & Omit<TextFieldProps, 'variant'>;

const CustomTextField: React.FC<Props> = props => {
  const textFieldProps = props;
  const classes = useStyles();

  return (
    <TextField
      variant="filled"
      {...textFieldProps}
      classes={{ root: classes.inputRoot }}
    />
  );
};

export default React.memo(CustomTextField);
