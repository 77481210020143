import React, {
  useState,
} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';

  type Props = {
    agents?: string[];
  };
const PropertyAgents:React.FC<Props> = ({ agents }) => {
  const [showAgents, setShow] = useState<boolean>(false);
  const classes = useStyles();
  const [iconStyle, setIcon] = useState({ transform: 'rotate(180%)', transitionDuration: '1s', transitionProperty: 'transform' });
  const handleClick = (event: any) => {
    event.stopPropagation();
    setShow(!showAgents);

    setIcon(!showAgents ? { transform: 'rotate(180deg)', transitionDuration: '1s', transitionProperty: 'transform' } : { transform: 'rotate(0deg)', transitionDuration: '1s', transitionProperty: 'transform' });
  };

  return (
    <>
      { (agents && agents.length)
        ? (
          <ExpansionPanel
            className={classes.root}
            expanded={showAgents}
            onClick={handleClick}
          >
            <ExpansionPanelSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography variant="inherit">Agents</Typography>
              <ExpandMoreIcon style={iconStyle} fontSize="small" color="action" />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ cursor: 'default' }} onClick={event => { event.stopPropagation(); }}>
              <div>
                {agents.map(agent => (
                  <Typography key={agent}>
                    {agent}
                  </Typography>
                ))}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (<Typography variant="inherit">No Agents</Typography>)
}
    </>
  );
};

export default PropertyAgents;
