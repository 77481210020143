import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    width: '85%',
    margin: '0 auto',
  },
  content: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px 30px',
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  button: {
    color: theme.appPalette.orange,
  },
  formActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > button': {
      marginRight: 8,
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  disabled: {
    color: '#000000',
  },
  progressWrapper: {
    position: 'relative',
  },
  progress: {
    color: theme.appPalette.orange,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  actionsBar: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 15,
    paddingBottom: 5,
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
