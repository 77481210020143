import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Order } from '../../utils/table';
import SearchBar from '../../components/SearchBar';
import CustomTablePagination from '../../components/CustomTablePagination';
import useStyles from './styles';
import Table from './Table';
import Spinner from '../../components/CustomSpinner';
import CustomButton from '../../components/CustomButton';
import { SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import useDebounce from '../../hooks/useDebounce';
import ConfirmDelete from '../../components/ConfirmDelete';
import {
  ALL_HUB_ACCOUNTS,
  DELETE_HUB_ACCOUNTS,
} from '../../graphql/queries/HubAccounts';
import { HubAccountType } from '../../types/HubAccounts';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
} & RouteComponentProps;

const HubAccounts: React.FC<Props> = props => {
  const { history, match } = props;

  const { state } = useContext(SearchSortContext);

  const {
    orderBy,
    order,
    contextSearch,
    rowsPerPageAmount,
    currentPage,
  } = state.hubAccounts;

  const [search, setSearch] = useState<string>(contextSearch);
  const [hubAccountToRemove, setHubAccountToRemove] = useState<boolean>(false);
  const [hubAccounts, setHubAccounts] = useState<HubAccountType[]>([]);
  const debouncedSearch = useDebounce(search, 1000);
  const [selected, setSelected] = useState<Array<HubAccountType['id']>>([]);
  const selectedLength = selected.length;
  const { enqueueSnackbar } = useSnackbar();

  function handleClick(event: React.SyntheticEvent, id: HubAccountType['id']) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<HubAccountType['id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  }

  const [cursor, setCursor] = useState<{ limit: number; offset: number }>({
    limit: rowsPerPageAmount,
    offset: currentPage * rowsPerPageAmount,
  });

  const [sort, setSort] = useState<{
    sortBy: string;
    direction: 'asc' | 'desc';
  }>({
    sortBy: orderBy,
    direction: order,
  });

  const classes = useStyles();

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({
      ...prevCursor,
      offset: prevCursor.limit * nPage,
    }));
  }, []);

  const [removeHubAccounts] = useMutation(DELETE_HUB_ACCOUNTS, {
    refetchQueries: [
      {
        query: ALL_HUB_ACCOUNTS,
        variables: {
          limit: cursor.limit,
          offset: cursor.offset,
          search: debouncedSearch,
          sortBy: sort.sortBy,
          direction: sort.direction,
        },
      },
    ],
  });

  function handleDelete() {
    if (selectedLength !== 0) {
      removeHubAccounts({
        variables: {
          ids: selected,
        },
      })
        .then(() => {
          setSelected([]);
          enqueueSnackbar('Removed', { variant: 'success' });
        })
        .catch(e => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  }

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      setCursor({ limit: rowsPerPageAmount, offset: 0 });
    },
    [rowsPerPageAmount],
  );

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
  }, []);

  const { loading, data } = useQuery<{
    hubAccounts: Paginated<HubAccountType>;
  }>(ALL_HUB_ACCOUNTS, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setHubAccounts(data.hubAccounts.list);
    }
  }, [data, loading]);

  const params = data?.hubAccounts?.params ?? { total: 0 };
  const page = useMemo(() => Math.ceil(cursor.offset / cursor.limit), [
    cursor.limit,
    cursor.offset,
  ]);

  const handleDeviceSelect = (hubAccountId: string) => {
    history.push(`${match.url}/${hubAccountId}`);
  };

  function handleChangeOrder(newOrder: Order, newOrderBy: string) {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = hubAccounts.map((device: any) => device.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add hub account
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder="Search hub account"
            defaultSearchValue={search}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => setHubAccountToRemove(true)}
          selectedLength={selected.length}
        />
        <Typography className={classes.tableHeaderText} variant="h5">
          Hub accounts
        </Typography>
        <Grid container alignContent="center" justify="flex-end">
          <RowsPerPageSelect
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsPerPage={cursor.limit}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })
            }
          />
        </Grid>
        <div className={classes.tableWrapper}>
          <Table
            order={sort.direction}
            orderBy={sort.sortBy}
            selected={selected}
            hubAccounts={hubAccounts}
            onRowClick={handleDeviceSelect}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onChangeOrder={handleChangeOrder}
          />
        </div>
        {loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <CustomTablePagination
          rowsPerPageOptions={[25, 50, 100, 150]}
          rowsTotal={params.total}
          rowsCount={hubAccounts.length}
          rowsPerPage={cursor.limit}
          currentPage={page}
          onChangePage={n => handleChangePage(undefined, n)}
          onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })
          }
        />
      </div>
      <ConfirmDelete
        isOpen={hubAccountToRemove}
        onCancel={() => {
          setHubAccountToRemove(false);
        }}
        onConfirm={() => {
          handleDelete();
          setHubAccountToRemove(false);
        }}
      />
    </Fragment>
  );
};

export default React.memo(HubAccounts);
