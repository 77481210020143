import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  button: {
    padding: '10px 16px',
    color: theme.appPalette.orange,
    textTransform: 'capitalize',
    fontSize: 16,
    lineHeight: '20px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  saveButton: {
    backgroundColor: theme.appPalette.orange,
    padding: '10px 40px',
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  logOutButton: {
    float: 'right',
    padding: '10px 40px',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  cardActions: {
    marginTop: 45,
  },
});
