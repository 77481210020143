/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { gql } from 'apollo-boost';
import useStyles from '../styles';
import { Shipment } from '../../../types/Property';
import { convertToUsdCurrencyFormat } from '../../../utils/price';
import { shipmentCreateSchema } from '../ShipmentValidation';

import {
  SHIPMENT_BY_ID,
  UPSERT_SHIPMENT,
} from '../../../graphql/queries/Shipments';
import CustomTextField from '../../../components/CustomTextField/CustomTextField';
import {
  beautifyErrors,

  ValidationErrors,
} from '../../../utils/helpers';
import CustomButton from '../../../components/CustomButton';
import BuilderSearch from '../../../components/BulderSearch/BuilderSearch';
import Builder from '../../../types/Builder';
import StatusSelect from '../../../components/StatusSelect/StatusSelect';
import CustomDatePicker from '../../../components/CustomDatePicker';
import useFormType from '../../../hooks/useFormType';

import ShipmentLockSearch from '../../../components/LockSearch/ShipmentLockSearch';

type Props = {
  username: string;
  loading?: boolean;
} & RouteComponentProps<{ shipment_id: string }>;

const ShipmentDetails: React.FC<Props> = props => {
  const classes = useStyles();
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrors<Shipment>
  >({});
  const { match, history, username } = props;
  const [buildersId, setBuildersId] = useState<{ name: string, id?: number }[]>([]);

  const [shipment, setShipment] = useState<Shipment>({
    id: undefined,
    lockSerialNumber: [],
    cost: 0,
    qty: undefined,
    carrier: '',
    date: new Date(),
    tracking_number: '',
    builder_id: undefined,
    username,
    number: undefined,
    requested_by: '',
    status_id: '',
    notes: '',
  });

  const { enqueueSnackbar } = useSnackbar();
  const formType = useFormType(match.params.shipment_id);

  const handleShipmentChange = useCallback(
    ({
      target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setShipment((prevShipment: Shipment) => ({
          ...prevShipment,
          [name]: value,
        }));
      }
    },
    [],
  );

  const [updateShipment] = useMutation<{ updateShipment: Shipment }>(UPSERT_SHIPMENT, {
    errorPolicy: 'all',
  });

  const GET_BUILDERIDS = gql`
    query {
      builderIds {
        builder_id,
        name
      }
    }
   `;

  const { data: builderData } = useQuery<{ builderIds: Array<{ name: string, id?: number }> }>(
    GET_BUILDERIDS,
  );

  const handleDateChange = (date: string, name: string) => {
    if (Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
      const UTCDateString = newDate.toISOString();
      setShipment((shipment: Shipment) => ({
        ...shipment,
        [name]: UTCDateString,
      }));
    }
  };


  const handleBuilderChange = (builder: Builder) => {
    setShipment((shipment: Shipment) => ({
      ...shipment,
      builder_id: builder.builder_id,
    }));
  };

  const handleSaveShipments = () => {
    try {
      const validatedShipment = shipmentCreateSchema.validateSync(shipment, { abortEarly: false });
      setValidationErrors({});
      updateShipment({
        variables: {
          shipment: validatedShipment,
        },
      }).then(res => {
        enqueueSnackbar('Success', { variant: 'success' });
      });
    } catch (errors) {
      enqueueSnackbar('Error', { variant: 'error' });
      setValidationErrors(beautifyErrors<Shipment>(errors));
    }
  };

  const handleCostChange = (value: string) => {
    const splitCostRegex = /[$.,]/;
    const parsedCost = parseInt(value.split(splitCostRegex).join(''), 10) / 100;

    setShipment(shipment => ({ ...shipment, cost: parsedCost }));
  };

  useEffect(() => {
    if (builderData) {
      setBuildersId(builderData.builderIds);
    }
  }, [builderData, buildersId]);

  const { loading: shipmentLoading, data: shipmentData } = useQuery<{ shipmentbyId: Shipment }>(SHIPMENT_BY_ID, {
    variables: { shipment_id: parseInt(match.params.shipment_id, 10) },
    skip: formType === 'add' || !match.params.shipment_id,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (shipmentData && shipmentData.shipmentbyId) {
      setShipment(shipmentData.shipmentbyId);
    }
  }, [shipmentLoading, shipmentData, match.params.shipment_id, history]);

  return (
    <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/shipments"
          >
            <ArrowLeftIcon />
              Shipments
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>


          <Paper className={classes.paper}>
            {shipment && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <ShipmentLockSearch
                      formType={formType}
                      label="Lock id"
                      required
                      className={classes.lockId}
                      initialValue={shipment.lockSerialNumber}
                      onLockChange={event => {
                        const arr: string[] = [];
                        event.map((e: any) => arr.push(e.label));
                        setShipment((shipment: Shipment) => ({
                          ...shipment,
                          lockSerialNumber: arr,
                          qty: arr.length,
                        }));
                      }}
                      error={!shipment.lockSerialNumber && Boolean(validationErrors.lockSerialNumber)}
                      helperText={!shipment.lockSerialNumber ? validationErrors.lockSerialNumber : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Shipment cost"
                      name="cost"
                      value={convertToUsdCurrencyFormat(shipment.cost)}
                      onChange={event => handleCostChange(event.target.value)}
                      error={!shipment.cost && Boolean(validationErrors.cost)}
                      helperText={!shipment.cost ? validationErrors.cost : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Quantity"
                      name="qty"
                      value={shipment?.qty ?? 0}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Carrier"
                      name="carrier"
                      value={shipment.carrier}
                      onChange={handleShipmentChange}
                      error={!shipment.carrier && Boolean(validationErrors.carrier)}
                      helperText={!shipment.carrier ? validationErrors.carrier : ''}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={4}>
                      <CustomDatePicker
                        label="Ship date"
                        clearable
                        fullWidth
                        inputVariant="outlined"
                        value={shipment.date || new Date()}
                        placeholder="10/10/2018"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'date')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item xs={12} sm={4} style={{ marginTop: '11px' }}>
                    <StatusSelect
                      label="Shipment status"
                      statusType="shipment"
                      fullWidth
                      multiline
                      name="status_id"
                      className={classes.shipmentStatus}
                      value={shipment.status_id}
                      onChange={handleShipmentChange}
                      error={!shipment.status_id && Boolean(validationErrors.status_id)}
                      helperText={!shipment.status_id ? validationErrors.status_id : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Tracking number"
                      name="tracking_number"
                      value={shipment.tracking_number}
                      onChange={handleShipmentChange}
                      error={!shipment.tracking_number && Boolean(validationErrors.tracking_number)}
                      helperText={!shipment.tracking_number ? validationErrors.tracking_number : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <BuilderSearch
                      onBuilderChange={handleBuilderChange}
                      label="Builder"
                      className={classes.builder}
                      initialValue={shipment.builder_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="username"
                      name="username"
                      value={username}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={9}
                      rowsMax={9}
                      label="Notes"
                      name="notes"
                      value={shipment.notes ?? ''}
                      onChange={handleShipmentChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              className={classes.saveButton}

              container
              spacing={0}
              justify="center"
            >
              <CustomButton
                variant="orange"
                color="primary"
                onClick={handleSaveShipments}
              >
                {formType === 'edit' ? 'Save shipment' : 'Add shipment'}
              </CustomButton>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(ShipmentDetails);
