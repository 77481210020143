/* eslint-disable no-underscore-dangle */
import React, { useRef, memo } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import MatTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TableHead from './TableHead';
import useStyles from './styles';
import { formatDate } from '../../../utils/date';
import { PaginatedShipment } from '../../../types/Property';
import { convertDateWithTimezone, convertDateToTimeWithTimezone } from '../../../utils/helpers';
import { Order, isSelected } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import { convertToUsdCurrencyFormat } from '../../../utils/price';

import Status from '../../../components/LockStatus';


type Props = {
  order: Order;
  orderBy: string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: PaginatedShipment['id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
  onRowClick: (id: PaginatedShipment['id']) => void;
  shipments: any;
  selected: Array<PaginatedShipment['id']>;
}

const Table: React.FC<Props> = ({ order, orderBy, shipments, selected, onSelect, onSelectAll, onChangeOrder, onRowClick }) => {
  const classes = useStyles();

  function handleRequestSort(
    event: React.SyntheticEvent,
    lock: string,
  ) {
    event.preventDefault();
    const isDesc = orderBy === lock && order === 'desc';
    onChangeOrder(isDesc ? 'asc' : 'desc', lock);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={18} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          order={order}
          orderBy={orderBy}
          rowCount={shipments?.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {shipments.map((shipment: any, index: any) => {
            const isItemSelected: boolean = isSelected(shipment.id, selected);
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const formatedModificationDate = shipment.modified_on
              ? `${convertDateWithTimezone(new Date(shipment.modified_on))}
          ${convertDateToTimeWithTimezone(new Date(shipment.modified_on))}` : '';
            return (
              <TableRow
                key={shipment.id}
                classes={{ root: classes.rowRoot }}
                hover
                tabIndex={-1}
                onClick={event => { event.stopPropagation(); onRowClick(shipment.id); }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, shipment.id);
                    }}
                  />
                </TableCell>
                <TableCell align="center">
                  {shipment.lockSerialNumber.map((number: string) => <p>{number}</p> ?? '')}
                </TableCell>
                <TableCell align="center">{convertToUsdCurrencyFormat(shipment.cost) || ''}</TableCell>
                <TableCell align="center">{shipment.qty ?? ''}</TableCell>
                <TableCell align="center">{shipment.carrier ?? ''}</TableCell>
                <TableCell align="center">{shipment.date ? formatDate(shipment.date) : ''}</TableCell>
                <TableCell align="center">
                  <Status value={shipment.status ?? ''} />
                </TableCell>
                <TableCell align="center">{shipment.tracking_number ?? ''}</TableCell>
                <TableCell align="center">{shipment.builder ?? ''}</TableCell>
                <TableCell align="center">{shipment.username ?? ''}</TableCell>
                <TableCell align="center">{shipment.requested_by ?? ''}</TableCell>
                {/* <TableCell align="center">
                  {lock.property ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/properties/${lock.property.property_id}`}>{ lock.property.property_id}</Link>
                    : '' }
                </TableCell> */}
                <TableCell align="center">
                  {' '}
                  {formatedModificationDate}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default memo(Table);
