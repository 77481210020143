import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  menuItem: {
    marginTop: theme.spacing(3),
  },
  menuItemText: {
    fontSize: '18px',
    color: '#8E8B99',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  selectedLink: {
    color: '#DB5C0E',
  },
  selectedSvgLink: {
    '& path': {
      fill: '#DB5C0E',
    },
  },
});
