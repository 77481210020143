import { createSelector } from 'reselect';
import { AppState } from '../store';
import { AuthState } from '../types/auth';

export type UserData = Pick<
AuthState,
'builderId' | 'firstName' | 'lastName' | 'username'
>;

export const selectAuthData = (state: AppState) => state.auth;

export const selectUserData = createSelector(
  selectAuthData,
  ({
    builderId, firstName, lastName, username,
  }): UserData => ({
    builderId,
    firstName,
    lastName,
    username,
  }),
);

export const selectUserName = createSelector(
  selectAuthData,
  ({
    username,
  }): string => username,
);

export const selectUserIsPMorAdmin = createSelector(
  selectAuthData,
  ({
    username,
    isPM,
    isAdmin,
  }): {username: string, isPM: boolean, isAdmin: boolean} => ({ username, isPM, isAdmin }),
);

export const selectOauthClients = createSelector(selectAuthData, auth => auth.oauthClients);
