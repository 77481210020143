import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isUserAuthorized } from '../../services/auth';

type Props = {
  component: React.ElementType;
  layout: React.ElementType;
} & RouteProps;

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const isAuthenticated = isUserAuthorized();

  return (
    <Route
      {...rest}
      render={props => (isAuthenticated ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ))
      }
    />
  );
};

export default React.memo(PrivateRoute);
