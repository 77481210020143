export const PROPERTIES_REQUEST = 'PROPERTIES_REQUEST';
export const PROPERTIES_REQUEST_SUCCESS = 'PROPERTIES_REQUEST_SUCCESS';
export const PROPERTIES_REQUEST_FAILED = 'PROPERTIES_REQUEST_FAILED';
export const PROPERTIES_EXPORT_REQUEST = 'PROPERTIES_EXPORT_REQUEST';
export const PROPERTIES_EXPORT_REQUEST_SUCCESS = 'PROPERTIES_EXPORT_REQUEST_SUCCESS';
export const PROPERTIES_EXPORT_REQUEST_FAILED = 'PROPERTIES_EXPORT_REQUEST_FAILED';
export const PROPERTIES_EXPORT_AND_PRINT_REQUEST = 'PROPERTIES_EXPORT_AND_PRINT_REQUEST';
export const PROPERTY_DETAILS_REQUEST = 'PROPERTY_DETAILS_REQUEST';
export const PROPERTY_DETAILS_REQUEST_SUCCESS = 'PROPERTY_DETAILS_REQUEST_SUCCESS';
export const PROPERTY_DETAILS_REQUEST_FAILED = 'PROPERTY_DETAILS_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type Property = {
  property_id: number;
  builder_id: number;
  builder: string;
  subdivision: string;
  lock_status: string;
  lock_serial_number: string;
  smart_lock_serial_number: string;
  lock_assigned: string;
  lock_terminated: string;
  house_num: string;
  address_1: string;
  city: string;
  state: string;
  price: number;
  bedrooms: number;
  halfBathrooms: number;
  fullBathrooms: number;
  squareFootage: number;
  isModel: boolean;
  disposition_id: string;
  url_1: string;
  rental_application_url: string;
  client: string;
};

export type PropertiesRequestSuccess = {
  items: Array<Property>;
  total: number;
};

export type PropertyDetailsVisit = {
  cell_phone_1: string;
  city: string;
  customer_id: number;
  customer_visit_id: number;
  firstname: string;
  lastname: string;
  modified_on: string;
  remarks: string;
  visit_status_id: string;
  visitor_status_id: number;
  visitor_status_id_value: string;
};

export type PropertyDetailsAgent = {
  agent_username: string;
  is_project_manager: number;
  firstname: string;
  lastname: string;
};

export type PropertyDetailsInfo = {
  address_1: string;
  address_2: string;
  builder: string;
  builder_id: number;
  city: string;
  disposition_id: number;
  disposition_id_value: string;
  house_num: string;
  latitude: number;
  lock_assigned: string;
  lock_serial_number: string;
  smart_lock_serial_number: string;
  lock_status: number;
  lock_status_value: string;
  lock_terminated: string;
  longitude: number;
  is_rental: boolean;
  is_webflow_code_only: boolean;
  lot_num: string;
  price: number;
  bedrooms: number;
  halfBathrooms: number;
  fullBathrooms: number;
  squareFootage: number;
  isModel: boolean;
  property_id: number;
  remarks: string;
  state: string;
  subdivision: string;
  zipcode: string;
  modified_on: string;
  url_1: string;
  rental_application_url: string;
  url_image: string;
  contracted_by: number;
};

export type PropertyDetails = {
  info: PropertyDetailsInfo;
  agents: Array<PropertyDetailsAgent>;
  visits: Array<PropertyDetailsVisit>;
};

export type PropertiesState = {
  items: Array<Property>;
  loading: boolean;
  total: number;
  error: ErrorMessage['message'];
  selected: PropertyDetails | null;
};
