import Dashboard from '../containers/Dashboard';
import Inventory from '../containers/Inventory';
import LockDetails from '../containers/LockDetails';
import PropertyDetails from '../containers/PropertyDetails';
import Customers from '../containers/Customers';
import CustomerDetails from '../containers/CustomerDetails';
import Visits from '../containers/Visits';
import Account from '../containers/Account';
import Users from '../components/Users';
import UserDetails from '../components/UserDetails';
import Clients from '../components/Clients';
import ClientDetails from '../components/ClientDetails';
import Properties from '../containers/Properties/Properties';
import TroubleTickets from '../containers/TroubleTickets/TroubleTickets';
import VisitDetails from '../components/VisitDetails/VisitDetails';
import Shipments from '../containers/Shipments';
import PropertyInput from '../components/PropertyInput/PropertyInput';
import AddShipment from '../containers/Shipments/ShipmentDetails';
import InstallGenerator from '../containers/InstallGenerator';
import Communities from '../containers/Communities';
import CommunitiesDetails from '../containers/CommunitiesDetails';
import TroubleTicketDetails from '../containers/TroubleTicketDetails/TroubleTicketDetails';
import Util from '../containers/Util';
import EzloTool from '../containers/EzloTool';
import Device from '../containers/Device';
import DeviceDetails from '../containers/DeviceDetails';
import DeviceForm from '../containers/Device/DeviceForm';
import HubAccounts from '../containers/HubAccounts';
import HubAccountForm from '../containers/HubAccounts/HubAccountForm';
import CommunityInput from '../components/CommunityInput';
import SmartInventory from '../containers/SmartInventory';
import SmartLockDetails from '../containers/SmartLockDetails';
import Enterprises from '../components/Enterprises';
import EnterpriseDetails from '../components/EnterpriseDetails';

const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  {
    path: '/users', exact: true, name: 'Users', component: Users,
  },
  {
    path: '/users/:username', exact: true, name: 'UserDetails', component: UserDetails,
  },
  {
    path: '/users/add', exact: true, name: 'AddUser', component: UserDetails,
  },
  {
    path: '/clients', exact: true, name: 'Clients', component: Clients,
  },
  {
    path: '/enterprises', exact: true, name: 'Enterprises', component: Enterprises,
  },
  {
    path: '/enterprises/:enterprise_id', exact: true, name: 'EnterpriseDetails', component: EnterpriseDetails,
  },
  {
    path: '/enterprises/add', exact: true, name: 'AddEnterprise', component: EnterpriseDetails,
  },
  {
    path: '/generator', exact: true, name: 'InstallGenerator', component: InstallGenerator,
  },
  {
    path: '/clients/:builder_id', exact: true, name: 'ClientDetails', component: ClientDetails,
  },
  {
    path: '/client/add', exact: true, name: 'AddClient', component: UserDetails,
  },
  { path: '/inventory/:lockSerial', name: 'LockDetails', component: LockDetails },
  { path: '/inventory', name: 'Inventory', component: Inventory },
  { path: '/smart-inventory/:smartLockSerial', name: 'SmartLockDetails', component: SmartLockDetails },
  { path: '/smart-inventory', name: 'SmartInventory', component: SmartInventory },
  { path: '/shipments/add', name: 'AddShipment', component: AddShipment },
  { path: '/shipments/:shipment_id', name: 'AddShipment', component: AddShipment },
  { path: '/visits/:visit_id', name: 'VisitDetails', component: VisitDetails },
  {
    path: '/shipments', exact: true, name: 'Shipments', component: Shipments,
  },
  { path: '/visits', name: 'Visits', component: Visits },
  { path: '/properties/add', name: 'PropertyInput', component: PropertyInput },
  { path: '/properties/:propertyId', name: 'PropertyDetails', component: PropertyDetails },
  { path: '/properties', name: 'Properties', component: Properties },
  { path: '/customers/:customer_id', name: 'CustomerDetails', component: CustomerDetails },
  { path: '/customers', name: 'Customers', component: Customers },
  { path: '/account', name: 'Account', component: Account },
  { path: '/tickets/:ticketId', name: 'TroubleTicketDetails', component: TroubleTicketDetails },
  { path: '/tickets', name: 'Trouble Tickets', component: TroubleTickets },
  { path: '/communities/add', name: 'CommunityInput', component: CommunityInput },
  { path: '/communities/:id', name: 'Communities', component: CommunitiesDetails },

  { path: '/communities', name: 'Communities', component: Communities },
  { path: '/devices/add', name: 'Add devices', component: DeviceForm },
  { path: '/devices/:id', name: 'Device details', component: DeviceDetails },
  { path: '/accounts/add', name: 'Add hub account', component: HubAccountForm },
  { path: '/accounts/:id', name: 'Hub account', component: HubAccountForm },

  { path: '/devices', name: 'Devices', component: Device },
  { path: '/accounts', name: 'Hub accounts', component: HubAccounts },
  {
    path: '/util', name: 'Util', component: Util, exact: true,
  },
  {
    path: '/ezlo-tool', name: 'Ezlo Tool', component: EzloTool, exact: true,
  },
];

export default routes;
