export const SMART_LOCKS_REQUEST = 'SMART_LOCKS_REQUEST';
export const SMART_LOCKS_REQUEST_SUCCESS = 'SMART_LOCKS_REQUEST_SUCCESS';
export const SMART_LOCKS_REQUEST_FAILED = 'SMART_LOCKS_REQUEST_FAILED';
export const SMART_LOCK_DETAILS_REQUEST = 'SMART_LOCK_DETAILS_REQUEST';
export const SMART_LOCK_DETAILS_REQUEST_SUCCESS = 'SMART_LOCK_DETAILS_REQUEST_SUCCESS';
export const SMART_LOCK_DETAILS_REQUEST_FAILED = 'SMART_LOCK_DETAILS_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type SmartLock = {
  lock_status_value: string;
  serial_number: string;
  device_id: string;
  comments: string;
  lock_status: number;
  smart_lock_type_id: number;
  created_on: string;
  modified_on: string;
};

export type SmartLocksRequestSuccess = Array<SmartLock>;
export type SmartLockDetailsRequestSuccess = SmartLock;

export type SmartLocksState = {
  items: Array<SmartLock>;
  loading: boolean;
  error: ErrorMessage['message'];
  selected: SmartLock | null;
};