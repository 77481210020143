import React, { createContext, Fragment, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { SnackbarProvider } from 'notistack';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './containers/MainLayout';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import routes from './routes';
import SnackBars from './containers/SnackBars';
import { StatesProvider } from './context/StatesProvider';
import { StaticDataProvider } from './context/StaticDataProvider';
import { SearchSortProvider } from './context/SearchSortContext';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import EzloMobile from './pages/EzloMobile';
import './App.css';

export const FilterContext = createContext<any>({});

const withFilterContext = (Composed: any) => (props: any) => {
  const [state, setState] = useState();
  const value = {
    state,
    setState,
  };

  return (
    <FilterContext.Provider value={value}>
      <Composed {...props} />
    </FilterContext.Provider>
  );
};

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: `${process.env.REACT_APP_PUBLIC_DSN}` });
}
type Props = {};
const App: React.FC<Props> = () => (
  <Fragment>
    <StatesProvider>
      <StaticDataProvider>
        <SearchSortProvider>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={4000}
            preventDuplicate
          >
            <CssBaseline />
            <SnackBars />
            <Switch>
              {routes.map(({ path, component: Component, exact }) => (
                <PrivateRoute
                  key={path}
                  exact={exact}
                  path={path}
                  component={Component}
                  layout={MainLayout}
                />
              ))}
              <Route path="/login" component={Login} />
              <Route path="/ezlo-mobile" component={EzloMobile} />
              <Route path="/reset_password" component={PasswordReset} />
              <Redirect exact from="/" to="/users" />
              <Route component={NotFound} />
            </Switch>
          </SnackbarProvider>
        </SearchSortProvider>
      </StaticDataProvider>
    </StatesProvider>
  </Fragment>
);

export default React.memo(withFilterContext(App));
