/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/camelcase */
import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Box, Paper, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import useStyles from './styles';
import { BaseLock, Shipments } from '../../types/Property';
import {
  GET_LOCK,
  GET_LOCK_TYPES,
  UPDATE_LOCK,
  VERIFY_IGLOO_LOCKS,
} from '../../graphql/queries/LockDetails';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { lockCreateSchema } from './LockValidation';
import {
  beautifyErrors,
  transformGraphQlErrorMessage,
  ValidationErrors,
  onlyNumberEnter,
} from '../../utils/helpers';
import CustomButton from '../../components/CustomButton';
import BuilderSearch from '../../components/BulderSearch/BuilderSearch';
import Builder from '../../types/Builder';
import StatusSelect from '../../components/StatusSelect/StatusSelect';
import CustomDatePicker from '../../components/CustomDatePicker';
import useFormType from '../../hooks/useFormType';
import Timestamps from '../../components/Timestamps/Timestamps';
import LockTypeSelect from '../../components/LockTypeSelect/LockTypeSelect';

interface Lock extends BaseLock {
  old_serial_number: string | null;
  lockShipment: Array<Shipments>;
  builder_id: number | null;
  secret_id: string;
  year?: number | null;
  batch?: string;
  hardware_version?: string;
  software_version?: string;
  initial_code?: string;
  email_1?: string;
  email_2?: string;
  shipmentStatus?: string;
  lock_type_id: number;
  lock_type: string;
}

type Props = {
  loading?: boolean;
} & RouteComponentProps<{ lockSerial: string }>;

const LockDetails: React.FC<Props> = props => {
  const classes = useStyles();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<Lock>>({});
  const { match, location, history } = props;

  const [lock, setLock] = useState<Lock>({
    serial_number: '',
    version: '',
    status: {
      status_id: 'LOCK_CREATED',
    },
    lockStatusId: 'LOCK_CREATED',
    lock_type_id: 1,
    secret_id: '',
    license_start_date: new Date(),
    licensee: '',
    manufacturer: '',
    lockShipment: [],
    purchase: new Date(),
    additional_info_1: '',
    battery_change: new Date(),
    comments: '',
    created_on: '',
    created_by: '',
    modified_on: '',
    modified_by: '',
    old_serial_number: null,
    builder_id: null,
    shipmentStatus: '',
    year: null,
    batch: '',
    hardware_version: '',
    software_version: '',
    initial_code: '',
    email_2: '',
    lock_type: '',
  });

  const { enqueueSnackbar } = useSnackbar();
  const formType = useFormType(match.params.lockSerial);

  const handleLockChange = useCallback(
    ({
      target: { name, value, checked },
    }: React.ChangeEvent<HTMLInputElement>) => {
      const booleanFields: (keyof Lock)[] = [];
      if (name) {
        setLock((prevLock: Lock) => ({
          ...prevLock,
          [name]: booleanFields.includes(name as keyof Lock) ? checked : value,
        }));
      }
    },
    [],
  );

  const handleLockStatusChange = useCallback(
    ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setLock((prevLock: Lock) => ({
        ...prevLock,
        status: { status_id: value },
        lockStatusId: value,
      }));
    },
    [],
  );

  const lockTypes = useQuery<{
    allLockTypes: [{ id: number, lock_type: string }]
  }>(GET_LOCK_TYPES, { fetchPolicy: 'network-only' });

  const handleLockTypeChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const lockType = lockTypes?.data?.allLockTypes.find((lockT: any) => lockT.lock_type === value);
    setLock((prevLock: Lock) => ({
      ...prevLock,
      lock_type_id: lockType ? lockType.id : 1,
      lock_type: value,
    }));
  };

  const [updateLock] = useMutation<{ updateLock: Lock }>(UPDATE_LOCK, {
    errorPolicy: 'all',
  });

  const handleDateChange = (date: string, name: string) => {
    if (Date.parse(date)) {
      const newDate = new Date(date);
      newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
      const UTCDateString = newDate.toISOString();
      setLock(prevState => ({ ...prevState, [name]: UTCDateString }));
    }
  };

  const { loading, data } = useQuery<{ lockBySerialNumber: Lock }>(GET_LOCK, {
    variables: { serial_number: match.params.lockSerial },
    skip: formType === 'add' || !match.params.lockSerial,
    fetchPolicy: 'network-only',
  });

  const { data: iglooLocks } = useQuery(VERIFY_IGLOO_LOCKS, {
    variables: { serial_number: match.params.lockSerial },
    skip: formType === 'add' || !match.params.lockSerial,
    fetchPolicy: 'network-only',
  });

  const handleBuilderChange = (builder: Builder) => {
    setLock((prevLock: Lock) => ({
      ...prevLock,
      builder_id: builder.builder_id,
    }));
  };

  const handleYearChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setLock((prevLock: Lock) => ({
        ...prevLock,
        year: onlyNumberEnter(value),
      }));
    },
    [],
  );

  const handleLockSave = () => {
    try {
      const { lockShipment, shipmentStatus, ...requestLock } = lock;
      const uLock = lockCreateSchema.validateSync(lock, { abortEarly: false });
      // @ts-ignore
      const { lockShipment: shipments, shipmentStatus: shipmentStatusValid, ...validatedLock } = uLock;
      setValidationErrors({});
      updateLock({
        variables: {
          lock: {
            ...requestLock,
            ...validatedLock,
          },
        },
      })
        .then(async result => {
          const sn = result.data ? result.data.updateLock.serial_number : 'add';
          location.pathname = location.pathname.replace(
            match.params.lockSerial,
            sn,
          );
          enqueueSnackbar('Success', { variant: 'success' });
        })
        .catch(err => {
          enqueueSnackbar(transformGraphQlErrorMessage(err.message), {
            variant: 'error',
          });
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<Lock>(errors));
    }
  };

  useEffect(() => {
    if (data) {
      if (data.lockBySerialNumber === null) {
        history.push('/inventory/add');
      } else {
        data.lockBySerialNumber.old_serial_number = match.params.lockSerial || '';
        const lockType = lockTypes?.data?.allLockTypes.find((lockT: any) => lockT.id === data?.lockBySerialNumber.lock_type_id);
        setLock(data.lockBySerialNumber);
        setLock((prevLock: Lock) => ({
          ...prevLock,
          lock_type: lockType?.lock_type || '',
        }));
      }
    }
  }, [loading, data, history, match.params.lockSerial, lockTypes]);

  return (
    <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/inventory"
          >
            <ArrowLeftIcon />
            Inventory
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            {lock && (
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <Timestamps timestamps={{
                      modifiedBy: lock.modified_by,
                      modifiedOn: lock.modified_on,
                      createdBy: lock.created_by,
                      createdOn: lock.created_on,
                    }}
                    />
                  </Grid>
                  {(formType === 'edit' && lock.manufacturer.toLowerCase() === ('Igloohome').toLowerCase()) && (
                    <Grid item xs={12} sm={6}>
                      <Box className={classes.lockVerification}>
                        <FontAwesomeIcon icon={faCheckCircle} className={iglooLocks?.verifyIglooLock ? classes.activeIcon : classes.disableIcon} />
                        <Typography variant="body1">Paired to NterNow</Typography>
                      </Box>

                      <Box className={classes.lockVerification}>
                        <FontAwesomeIcon icon={faCheckCircle} className={iglooLocks?.verifyIglooLock ? classes.disableIcon : classes.activeIcon} />
                        <Typography variant="body1">Paired to Igloo</Typography>
                      </Box>

                      <Box className={classes.lockVerification}>
                        <FontAwesomeIcon icon={faCheckCircle} className={lock?.secret_id?.length > 94 ? classes.activeIcon : classes.disableIcon} />
                        <Typography variant="body1">Has secret_ID in NterNow</Typography>
                      </Box>
                    </Grid>
                  )
                  }
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      autoFocus
                      required
                      label="Serial number"
                      name="serial_number"
                      value={lock.serial_number || ''}
                      onChange={handleLockChange}
                      error={Boolean(validationErrors.serial_number)}
                      helperText={validationErrors.serial_number || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Manufacturer"
                      name="manufacturer"
                      value={lock.manufacturer || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <LockTypeSelect
                      label="Lock Type"
                      variant="outlined"
                      value={lock.lock_type || ''}
                      lockTypeId={lock.lock_type_id}
                      lockTypes={lockTypes}
                      onChange={handleLockTypeChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Lock version"
                      name="version"
                      value={lock.version || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Licensee"
                      name="licensee"
                      value={lock.licensee || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Additional info 1"
                      name="additional_info_1"
                      value={lock.additional_info_1 || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      disabled
                      label="Shipment status"
                      value={lock.shipmentStatus || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} style={{ marginTop: '11px' }}>
                    <BuilderSearch
                      onBuilderChange={handleBuilderChange}
                      initialValue={lock.builder_id ?? undefined}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StatusSelect
                      defaultValue=""
                      label="Lock status"
                      variant="outlined"
                      statusType="lock"
                      onChange={handleLockStatusChange}
                      value={lock.lockStatusId ?? ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Year"
                      name="year"
                      value={lock?.year || ''}
                      onChange={handleYearChange}
                      error={Boolean(validationErrors.year)}
                      helperText={validationErrors.year || ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Batch"
                      name="batch"
                      value={lock?.batch || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Annual Lic Fee Date"
                      name="email_1"
                      value={lock?.email_1 || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Return Lock(email2)"
                      name="email_2"
                      value={lock?.email_2 || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Hardware Version"
                      name="hardware_version"
                      value={lock?.hardware_version || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Software Version"
                      name="software_version"
                      value={lock?.software_version || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      label="Initial Code"
                      name="initial_code"
                      value={lock?.initial_code || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} sm={4}>
                      <CustomDatePicker
                        fullWidth
                        label="Purchase date"
                        inputVariant="outlined"
                        value={lock.purchase || new Date()}
                        placeholder="10/10/2018"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'purchase')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomDatePicker
                        fullWidth
                        label="Battery change date"
                        inputVariant="outlined"
                        value={lock.battery_change || new Date()}
                        placeholder="10/10/2018"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'battery_change')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomDatePicker
                        fullWidth
                        inputVariant="outlined"
                        label="License start date"
                        value={lock.license_start_date || new Date()}
                        placeholder="10/10/2018"
                        // @ts-ignore
                        onChange={date => handleDateChange(date, 'license_start_date')}
                        format="MM/dd/yyyy"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={8}
                      rowsMax={8}
                      label="Comments"
                      name="comments"
                      value={lock.comments || ''}
                      onChange={handleLockChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid
              container
              spacing={4}
              justify="center"
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >

              <Grid item>
                <CustomButton variant="orange" onClick={handleLockSave}>
                  {formType === 'edit' ? 'Save' : 'Add'}
                </CustomButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(LockDetails);
