import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useUrlLocation() {
  const [path, setPath] = useState<string>('');

  const location = useLocation();
  useEffect(() => {
    const currentPath = location.pathname.replace(/\//, '');
    setPath(currentPath);
  }, [location]);

  return path;
}
