import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const deviceCreateSchema = yup.object().shape({
  serial_number: yup.string().required('Serial number field is require'),
  // adc_customer_id: yup.string().required('ADC Customer id required'),
  builder_id: yup.number().required('Builder field is require'),
  property_id: yup.number().required('Property field is require'),
  hub_account_id: yup.string().required('Hub account field is require'),
  hub_type: yup.string().required('Hub type field is require'),
});
