export default class InputDevice{

    id?: string = '';
    
    serial_number: string = '';

    adc_customer_id: string = '';

    imei: string = '';
  
    hub_type: string = '';
    
    builder_id: number = 0;
  
    property_id: number = 0;
  
    hub_account_id: string = '';

    description?: string = '';
  
    comments?: string = '';
}
