import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from './styles';

type Props = {
  value: string,
  lockTypeId: number,
  lockTypes: any
} & TextFieldProps;

const LockTypeSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { lockTypeId, value, lockTypes, ...textFieldProps } = props;

  return (
    <>
      <TextField
        margin="normal"
        value={value}
        fullWidth
        {...textFieldProps}
        select
        label="Lock type"
        inputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
      >
        <MenuItem disabled value={0}>N / A</MenuItem>
        { lockTypes?.data?.allLockTypes.map((lockData: any) => (
          <MenuItem key={lockData.id} value={lockData.lock_type}>
            {lockData.lock_type}
          </MenuItem>
        ))
          }
      </TextField>
    </>
  );
};

export default LockTypeSelect;
