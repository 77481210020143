import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import SearchBar from '../../components/SearchBar';
import ConfirmDelete from '../../components/ConfirmDelete';
import CustomersTable from './CustomersTable/CustomersTable';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE } from '../../constants/table';
import CustomTablePagination from '../../components/CustomTablePagination';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import Spinner from '../../components/CustomSpinner';
import useStyles from './styles';
import useDebounce from '../../hooks/useDebounce';
import CustomButton from '../../components/CustomButton';
import { ALL_CUSTOMERS, REMOVE_CUSTOMERS } from '../../graphql/queries/Customers';
import {
  Customer, Catalog, Status,
} from '../../types/Customer';
import { CustomerVisit } from '../../types/Visit';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect/RowsPerPageSelect';
import downloadDocument from '../../utils/files';
import { loadExport, loadExportAll } from '../../services/export';

type Props = {
  customers: Array<Customer>;
  customersTotal: number;
  loading: boolean;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search customers';

const Customers: React.FC<Props> = props => {
  const classes = useStyles();
  const {
    history,
    match,
  } = props;
  const [rowsPerPage] = useState<number>(ROWS_PER_PAGE);
  const [selected, setSelected] = useState<Array<Customer['customer_id']>>([]);
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy, order, contextSearch, rowsPerPageAmount, currentPage,
  } = state.customers;
  const [cursor, setCursor] = useState<{ limit: number, offset: number }>({
    limit: rowsPerPageAmount,
    offset: rowsPerPageAmount * currentPage,
  });
  const [search, setSearch] = useState<string>(contextSearch);
  const [customerToRemove, setCustomerToRemove] = useState<boolean>(false);
  const [sort, setSort] = useState<{sortBy: keyof Customer | string, direction: 'asc' | 'desc'}>({
    sortBy: orderBy, direction: order,
  });
  const selectedLength = selected.length;
  const debouncedSearch = useDebounce(search, 1000);
  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );
  function getValueToSort(value: string) {
    switch (value) {
      case 'last_status': return 'status.value';
      case 'last_property': return 'visits.property_id';
      default: return value;
    }
  }

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_CUSTOMER',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const { loading, data } = useQuery<{
    customerList: Paginated<Customer & { catalog: Catalog }
    & { visits: CustomerVisit[] } & { status: Status }>
  }>(ALL_CUSTOMERS, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: getValueToSort(sort.sortBy),
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const [removeCustomers] = useMutation(REMOVE_CUSTOMERS, {
    refetchQueries: [{
      query: ALL_CUSTOMERS,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Customer) {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ offset: 0, limit: rowsPerPageAmount });
    dispatch({
      type: 'CHANGE_CUSTOMER',
      payload: {
        orderBy: newOrderBy,
        order: newOrder,
        currentPage: 0,
      },
    });
  }

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'customers')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_CUSTOMER',
      payload: {
        currentPage: 0,
        rowsPerPageAmount: e.target.value,
      },
    });
  }, [dispatch]);

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_CUSTOMER',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelected = data && data.customerList.list.map(i => i.customer_id);
      setSelected(newSelected ?? []);
      return;
    }
    setSelected([]);
  }

  function handleClick(
    event: React.SyntheticEvent,
    id: Customer['customer_id'],
  ) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<Customer['customer_id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleCustomerSelect(customerId: number) {
    history.push(`${match.url}/${customerId}`);
  }

  function handleCustomersExportAll() {
    setExportAllLoading(true);
    loadExportAll('customers').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Customers', response.data);
    });
  }

  const handleShowAllClick = () => {
    dispatch({ type: 'RESET_STATE' });
    setSort({
      direction: 'desc',
      sortBy: 'modified_on',
    });
    setSearch('');
  };

  const handleDelete = () => {
    if (selectedLength !== 0) {
      removeCustomers({
        variables: {
          customers: selected,
        },
      }).then(() => setSelected([]));
    }
  };

  function handleCustomersPrint() {
  }
  const customers = data ? data.customerList.list : [];
  const params = data ? data.customerList.params : { total: 0 };

  function handleCustomersExport() {
    const customersList = selectedLength !== 0 ? selected
      : customers.map(customer => customer.customer_id);
    if (customersList.length !== 0) {
      loadExport(customersList, 'customers').then(response => {
        const contentType = response.headers['Content-Type'];
        downloadDocument(contentType, 'Customers', response.data);
      });
    }
  }

  const isStateInitial = JSON.stringify({
    contextSearch: state.customers.contextSearch,
    order: state.customers.order,
    orderBy: state.customers.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.customers.contextSearch,
    order: initialState.customers.order,
    orderBy: initialState.customers.orderBy,
  });
  return (
    <Fragment>
      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add Customer
          </CustomButton>
        </Grid>
        { !isStateInitial && (
          <Grid item className={classes.controlButton}>
            <CustomButton
              variant="orange"
              color="primary"
              onClick={handleShowAllClick}
            >
              Show all
            </CustomButton>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder={searchBarPlaceholder}
            defaultSearchValue={search}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => { setCustomerToRemove(true); }}
          onExport={handleCustomersExport}
          onExportAll={handleCustomersExportAll}
          onPrint={handleCustomersPrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography className={classes.tableHeaderText} variant="h5">Customers</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <CustomersTable
            selected={selected}
            customers={customers}
            order={sort.direction}
            orderBy={sort.sortBy}
            rowsPerPage={rowsPerPage}
            onChangeOrder={handleChangeOrder}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onRowClick={handleCustomerSelect}
          />
        </div>
        {loading && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <CustomTablePagination
          rowsPerPageOptions={[25, 50, 100, 150]}
          rowsTotal={params.total}
          rowsCount={customers.length}
          rowsPerPage={cursor.limit}
          currentPage={page}
          onChangePage={n => handleChangePage(undefined, n)}
          onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
        />
      </div>
      <ConfirmDelete
        isOpen={customerToRemove}
        onCancel={() => { setCustomerToRemove(false); }}
        onConfirm={() => { handleDelete(); setCustomerToRemove(false); }}
      />
    </Fragment>
  );
};

export default React.memo(Customers);
