import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const SmallLogoIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 25 42" {...props}>
    <path d="M0 2C0 0.895431 0.89543 0 2 0H20.5462C22.7553 0 24.5462 1.79086 24.5462 4V37.9661C24.5462 40.1752 22.7553 41.9661 20.5462 41.9661H4C1.79086 41.9661 0 40.1752 0 37.9661V2Z" fill="#040620" />
    <path d="M2.14954 38.8611L14.8999 33.4066V7.34793L2.14954 1.89346V38.8611Z" fill="#CD6A2D" />
    <path d="M12.2534 20.377C12.2534 20.9035 12.6776 21.3299 13.2001 21.3299C13.7232 21.3299 14.1473 20.9035 14.1473 20.377C14.1473 19.851 13.7232 19.4241 13.2001 19.4241C12.6776 19.4241 12.2534 19.851 12.2534 20.377Z" fill="#040620" />
  </SvgIcon>
);

export default React.memo(SmallLogoIcon);
