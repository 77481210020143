import React, { useState } from 'react';

import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';

interface Props {
  register: any;
}

const SCRIPTS = [
  'HUB:2gig/scripts/add_device',
  'HUB:2gig/scripts/start_device_discovering',
  'HUB:2gig/scripts/stop_device_discovering',
  'HUB:2gig/scripts/reset',
];

const SCRIPT_ADD_DEVICE = 'HUB:2gig/scripts/add_device';

const Run2GIGScriptComponent = ({ register }: Props) => {
  const [script, setScript] = useState<string>('');

  return (
    <>
      <FormControl>
        <FormLabel id="radio-buttons-group-label">script</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue=""
          name="script"
          onChange={(event, value) => setScript(value)}
        >
          {SCRIPTS.map(item => (
            <FormControlLabel value={item} control={<Radio inputRef={register} />} label={item} />
          ))}
        </RadioGroup>
      </FormControl>
      {SCRIPT_ADD_DEVICE === script && (
        <>
          <p>scriptParams</p>
          <TextField
            margin="normal"
            fullWidth
            inputRef={register}
            defaultValue=""
            label="model"
            key="model"
            name="scriptParams.model"
          />
          <TextField
            margin="normal"
            fullWidth
            inputRef={input => register(input, { required: true, valueAsNumber: true })}
            required
            type="number"
            label="serial"
            key="serial"
            name="scriptParams.serial"
          />
          <TextField
            margin="normal"
            fullWidth
            inputRef={register}
            type="number"
            label="device_type"
            key="device_type"
            name="scriptParams.device_type"
          />
        </>
      )}
    </>
  );
};

export default Run2GIGScriptComponent;
