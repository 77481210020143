import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Box, Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { ArrowLeft } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField/CustomTextField';
import {
  BaseCustomer, Catalog,
} from '../../types/Customer';
import { BaseProperty as Property } from '../../types/Property';
import { CustomerVisit } from '../../types/Visit';
import { convertDateToTimeWithTimezone, convertDateWithTimezone, generateLabel } from '../../utils/helpers';
import useStyles from '../../containers/LockDetails/styles';
import Spinner from '../CustomSpinner';
import { GET_VISIT, UPDATE_VISIT } from '../../graphql/queries/VisitDetails';

type Props = {
  loading?: boolean;
} & RouteComponentProps & { match: { params: { visit_id: string } } };


const VisitDetails: React.FC<Props> = ({ match, history }) => {
  const classes = useStyles();
  interface Customer extends BaseCustomer {
    platform: '',
  }
  const { enqueueSnackbar } = useSnackbar();
  const [visit, setVisit] = useState<CustomerVisit>({
    customer_visit_id: 0,
    customer_id: 0,
    created_by: '',
    modified_by: '',
    created_on: '',
    platform: '',
    customer: {
      status: {
        value: '',
      },
      customer_id: 0,
      firstname: '',
      lastname: '',
      cell_phone_1: '',
      created_on: '',
      modified_on: '',
      catalog: {
        value: '',
        catalog_id: 0,
      },
    },
    property_id: 0,
    entry: 0,
    tour: 0,
    property: {
      address_1: '',
      property_id: 0,
      builder: '',
      timezone: 0,
      subdivision: '',
      lock_serial_number: '',
      smart_lock_serial_number: '',
      house_num: '',
      adjustableTourHours: 0,
    },
    remarks: '',
    modified_on: '',
  });
  const visitId = parseInt(match.params.visit_id, 10);

  const { loading, data } = useQuery<{
    getVisitById: CustomerVisit & { catalog: Catalog } & { property: Property }
  }>(
    GET_VISIT,
    {
      variables: { id: visitId },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    if (data) {
      setVisit(data.getVisitById);
    }
  }, [loading, data]);

  const status = visit.customer?.customerStatus?.toLowerCase().replace('-', '') as
    | 'contact'
    | 'nocontact';

  const handleVisitChange = useCallback(
    ({ target: { name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const booleanFields: (keyof CustomerVisit)[] = [];
      if (name) {
        setVisit((prevVisit: CustomerVisit) => ({
          ...prevVisit,
          [name]: booleanFields.includes(name as keyof CustomerVisit) ? checked : value,
        }));
      }
    },
    [],
  );

  const [updateVisit] = useMutation<{ updateVisit: CustomerVisit }>(
    UPDATE_VISIT,
    { errorPolicy: 'all' },
  );
  const handleVisitSave = () => {
    const {
      customer, property, platform, ...queryVisit
    } = visit;
    updateVisit({
      variables: {
        visit: {
          ...queryVisit,
        },
      },
    }).then(() => {
      enqueueSnackbar('Success!', { variant: 'success' });
    });
  };
  return (
    <Fragment>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
                GoBack
          </CustomButton>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          {visit && (
            <Paper className={classes.paper}>
              <>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h3" gutterBottom>
                      {[visit?.customer?.firstname, visit?.customer?.lastname].join(' ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('created_on')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.created_on ? convertDateWithTimezone(
                        new Date(visit.created_on),
                        visit.property?.timezoneCatalog?.catalog_id,
                      )
                        : ''}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('visit_time')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.created_on ? convertDateToTimeWithTimezone(
                        new Date(visit.created_on),
                        visit.property?.timezoneCatalog?.catalog_id,
                      ) : ''}
                      {' '}
                      {visit.property?.timezoneCatalog?.value}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('created_by')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.created_by}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('modified_on')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.modified_on ? new Date(visit.modified_on).toLocaleDateString() : ''}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('modified_by')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.modified_by}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('Visitor type')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.customer?.catalog?.value}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('platform')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit?.platform}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('property')}
                        :
                        {' '}
                      </span>
                      {' '}
                      <Link to={`/properties/${visit.property_id}`}>{visit.property_id}</Link>
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('agent')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.created_by}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('address')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.property?.address_1}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('client_subdivision')}
                        :
                        {' '}
                      </span>
                      {' '}
                      {visit.property?.subdivision}
                    </Typography>
                    <Typography variant="body1">
                      <span style={{ fontWeight: 'bold' }}>
                        {generateLabel('contact_status')}
                      :
                        {' '}
                      </span>
                      {' '}
                      <Box component="div" className={classes[status]} display="inline">
                        {' '}
                        {visit.customer?.customerStatus}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      multiline
                      rows={9}
                      rowsMax={9}
                      label="Call center visit remarks"
                      name="remarks"
                      value={visit.remarks ?? ''}
                      onChange={handleVisitChange}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Grid item>
                    <CustomButton variant="orange" onClick={handleVisitSave}>
                      Save
                    </CustomButton>
                  </Grid>
                </Grid>
              </>
            </Paper>
          )}
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(VisitDetails);
