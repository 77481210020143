import React, {
  useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import Lock from '../../types/Lock';
import useDebounce from '../../hooks/useDebounce';
import { SEARCH_LOCKS } from '../../graphql/queries/LockDetails';

type Props = {
  onLockChange: (lock: Lock) => void,
  initialValue?: string;
  styles?: object;
  addLabel?: boolean;
} & TextFieldProps;

const LockSearch: React.FC<Props> = ({
  onLockChange, initialValue, styles, addLabel, ...textFieldProps
}) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 100);
  const handleSearch = (event : React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  };

  const { data, loading } = useQuery<{ locks: Lock[] }>(
    SEARCH_LOCKS,
    {
      variables: {
        sn: debouncedSearch,
      },
      fetchPolicy: 'network-only',
    },
  );

  const handleChange = (event: any, chosenSelect: { label: string, value: Lock } | null) => {
    const initialProp = {
      serial_number: '',
    };
    onLockChange(chosenSelect?.value ?? initialProp);
  };

  const availableLocks = (data?.locks.map(item => ({
    value: item,
    label: `${item.serial_number}`,
  }))) || [];

  if (initialValue && !availableLocks.find(lock => lock.value.serial_number === initialValue)) {
    availableLocks.push({
      value: {
        serial_number: initialValue,
      },
      label: initialValue,
    });
  }

  return (
    <>
      <div style={{ marginTop: '15px' }}>
        <Autocomplete
          options={availableLocks}
          getOptionLabel={option => option.label}
          noOptionsText="No locks were found"
          onChange={handleChange}
          loading={loading}
          defaultValue={initialValue
            ? { label: initialValue, value: { serial_number: initialValue } } : undefined}
          renderInput={params => (
            <TextField
              {...params}
              label={addLabel && 'Lock Serial Number'}
              fullWidth
              onKeyUp={handleSearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              {...textFieldProps}
            />
          )}
        />
      </div>
    </>
  );
};

export default LockSearch;
