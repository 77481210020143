import {
  all, takeLatest, takeEvery, put, call,
} from 'redux-saga/effects';
import {
  ALL_PROPERTIES_STATS_REQUEST,
  ALL_VISITS_REQUEST,
  CONTRACTORS_SERVED_COUNT_REQUEST,
  MONTHLY_VISITS_REQUEST,
  WEEKLY_VISITS_REQUEST,
  YEARLY_VISITS_REQUEST,
} from '../types/stats';

import {
  loadAllProperties,
  loadAllVisits,
  loadMonthlyVisits,
  loadWeeklyVisits,
  loadYearlyVisits,
} from '../../services/stats';
import {
  propertiesActiveStatsRequestSuccess,
  propertiesSoldStatsRequestSuccess,
  propertiesStatsRequestFailed,
  PropertiesStatsRequestActionType,
  weeklyVisitsRequestSuccess,
  weeklyVisitsRequestFailed,
  MonthlyVisitsRequestActionType,
  AllVisitsRequestActionType,
  monthlyVisitsRequestSuccess,
  monthlyVisitsRequestFailed,
  yearlyVisitsRequestSuccess,
  yearlyVisitsRequestFailed,
  allVisitsRequestSuccess,
  allVisitsRequestFailed, contractorsCountRequestSuccess, contractorsCountRequestFailed,
} from '../actions/stats';
import { loadVisits } from '../../services/visits';
import { Visit } from '../types/visits';

function* loadAllPropertiesStatsSaga(
  action: PropertiesStatsRequestActionType,
) {
  const { status } = action.payload;
  try {
    const { data } = yield call(loadAllProperties, action.payload);

    if (status === 'sold') {
      yield put(propertiesSoldStatsRequestSuccess(data));
    } else {
      yield put(propertiesActiveStatsRequestSuccess(data));
    }
  } catch (err) {
    yield put(propertiesStatsRequestFailed(err));
  }
}

function* loadWeeklyVisitsSaga() {
  try {
    const { data } = yield call(loadWeeklyVisits);

    yield put(weeklyVisitsRequestSuccess(data));
  } catch (err) {
    yield put(weeklyVisitsRequestFailed(err));
  }
}

function* loadMonthlyVisitsSaga(
  action: MonthlyVisitsRequestActionType,
) {
  try {
    const { data } = yield call(loadMonthlyVisits, action.payload);

    yield put(monthlyVisitsRequestSuccess(data));
  } catch (err) {
    yield put(monthlyVisitsRequestFailed(err));
  }
}

function* loadYearlyVisitsSaga() {
  try {
    const { data } = yield call(loadYearlyVisits);

    yield put(yearlyVisitsRequestSuccess(data));
  } catch (err) {
    yield put(yearlyVisitsRequestFailed(err));
  }
}

function* loadAllVisitsSaga(
  action: AllVisitsRequestActionType,
) {
  try {
    const { data } = yield call(loadAllVisits, action.payload);

    yield put(allVisitsRequestSuccess(data));
  } catch (err) {
    yield put(allVisitsRequestFailed(err));
  }
}

function* loadContractorsServedCountSaga(
) {
  try {
    const { data } = yield call(loadVisits);

    const countTCVisits = (counter: number, visit: Visit) => {
      if (visit.visitor_type_id === 'TC') {
        return counter + 1;
      }
      return counter;
    };

    const count = data.items.reduce(countTCVisits, 0);

    yield put(contractorsCountRequestSuccess(count));
  } catch (err) {
    yield put(contractorsCountRequestFailed(err));
  }
}

export default all([
  takeEvery<any>(ALL_PROPERTIES_STATS_REQUEST, loadAllPropertiesStatsSaga),
  takeLatest<any>(WEEKLY_VISITS_REQUEST, loadWeeklyVisitsSaga),
  takeLatest<any>(MONTHLY_VISITS_REQUEST, loadMonthlyVisitsSaga),
  takeLatest<any>(YEARLY_VISITS_REQUEST, loadYearlyVisitsSaga),
  takeLatest<any>(ALL_VISITS_REQUEST, loadAllVisitsSaga),
  takeLatest<any>(CONTRACTORS_SERVED_COUNT_REQUEST, loadContractorsServedCountSaga),
]);
