import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { setProgressBarStatus as SetProgressBarStatus } from '../../redux/actions/progressBar';
import useStyles from './styles';
import useDebounce from '../../hooks/useDebounce';
import CustomTablePagination from '../CustomTablePagination';
import ConfirmDelete from '../ConfirmDelete';
import CustomButton from '../CustomButton';
import TableHeadCell from '../TableHeadCell';
import SelectedActionsBar from '../SelectedActionsBar';
import SearchBar from '../SearchBar';
import Checkbox from '../CustomCheckbox';
import CustomTableScroll from '../CustomTableScroll';
import { isSelected } from '../../utils/table';
import {
  initialState,
  SearchSortContext,
} from '../../context/SearchSortContext';
import RowsPerPageSelect from '../RowsPerPageSelect/RowsPerPageSelect';
import { loadExport, loadExportAll } from '../../services/export';
import downloadDocument from '../../utils/files';
import { ALL_ENTERPRISES, REMOVE_ENTERPRISES } from '../../graphql/queries/Enterprises';
import { Enterprise } from '../../types/Enterprise';

const searchBarPlaceholder: string = 'Search enterprises';

type Props = {
  setProgressBarStatus: typeof SetProgressBarStatus;
} & RouteComponentProps;

const Enterprises: React.FC<Props> = ({
  history,
  match,
  setProgressBarStatus,
}) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy,
    order,
    contextSearch,
    rowsPerPageAmount,
    currentPage,
  } = state.enterprises;
  const [cursor, setCursor] = useState<{ limit: number; offset: number }>({
    limit: rowsPerPageAmount,
    offset: currentPage * rowsPerPageAmount,
  });
  const [search, setSearch] = useState<string>(contextSearch);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<Array<Enterprise['id']>>([]);
  const selectedLength = selected.length;
  const [sort, setSort] = useState<{
    sortBy: string;
    direction: 'asc' | 'desc';
  }>({ sortBy: orderBy, direction: order });
  const [enterpriseToRemove, setEnterpriseToRemove] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 1000);

  const { loading, error, data } = useQuery<{
    enterpriseList: Paginated<Enterprise>;
  }>(ALL_ENTERPRISES, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const handleShowAllClick = () => {
    setSort({
      direction: 'desc',
      sortBy: 'modified_on',
    });
    setSearch('');
    dispatch({ type: 'RESET_STATE' });
  };
  const [removeEnterprises] = useMutation(REMOVE_ENTERPRISES, {
    refetchQueries: [
      {
        query: ALL_ENTERPRISES,
        variables: {
          limit: cursor.limit,
          offset: cursor.offset,
          search: debouncedSearch,
          sortBy: sort.sortBy,
          direction: sort.direction,
        },
      },
    ],
  });

  useEffect(() => {
    setProgressBarStatus(loading);
  }, [loading, error, setProgressBarStatus]);

  const handleChangePage = useCallback(
    (_e: unknown, nPage: number) => {
      setCursor(prevCursor => ({
        ...prevCursor,
        offset: prevCursor.limit * nPage,
      }));
      dispatch({
        type: 'CHANGE_ENTERPRISE',
        payload: {
          currentPage: nPage,
        },
      });
    },
    [dispatch],
  );

  const handleChangeRowsPerPage = useCallback(
    e => {
      setCursor({ offset: 0, limit: e.target.value });
      dispatch({
        type: 'CHANGE_ENTERPRISE',
        payload: {
          rowsPerPageAmount: e.target.value,
          currentPage: 0,
        },
      });
    },
    [dispatch],
  );

  const page = useMemo(() => Math.ceil(cursor.offset / cursor.limit), [
    cursor.limit,
    cursor.offset,
  ]);

  const handleClick = (event: React.SyntheticEvent, id: Enterprise['id']) => {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<Enterprise['id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data ? data.enterpriseList.list.map(i => i.id) : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'enterprises').then(response => {
        const contentType = response.headers['Content-Type'];
        downloadDocument(contentType, 'Enterprises', response.data);
      });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('enterprises').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Enterprises', response.data);
    });
  }

  const handlePrint = () => {
    if (selectedLength !== 0) {
    }
  };
  const handleClientDelete = () => {
    if (selectedLength !== 0) {
      removeEnterprises({
        variables: {
          enterprises: selected,
        },
      })
        .then(() => {
          setSelected([]);
          enqueueSnackbar('Removed', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  };

  const handleSortBy = (property: string) => {
    const isDesc = sort.sortBy === property && sort.direction === 'desc';
    const direction = isDesc ? 'asc' : 'desc';
    setSort({
      direction,
      sortBy: property,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_ENTERPRISE',
      payload: {
        orderBy: property,
        order: direction,
        currentPage: 0,
      },
    });
  };

  useEffect(
    () => () => {
      const pageNameIndex = 1;
      const pathElements = history.location.pathname.split('/');
      const pathname = pathElements[pageNameIndex];
      if (pathElements.length === 3) {
        return;
      }
      if (pathname !== 'enterprises') {
        dispatch({
          type: 'RESET_STATE',
        });
      }
    },
    [dispatch, history.location.pathname],
  );

  const handleSearch = useCallback(
    (value: string) => {
      dispatch({
        type: 'CHANGE_ENTERPRISE',
        payload: { contextSearch: value, currentPage: 0 },
      });
      setSearch(value);
      setCursor({ limit: rowsPerPageAmount, offset: 0 });
    },
    [dispatch, rowsPerPageAmount],
  );

  const isStateInitial = JSON.stringify({
    contextSearch: state.enterprises.contextSearch,
    order: state.enterprises.order,
    orderBy: state.enterprises.orderBy,
  })
    === JSON.stringify({
      contextSearch: initialState.enterprises.contextSearch,
      order: initialState.enterprises.order,
      orderBy: initialState.enterprises.orderBy,
    });

  const tableContainer = useRef<HTMLDivElement>(null);

  if (!data) return null;
  if (error) {
    return (
      <Typography variant="h5">
Error :( -
        {error}
      </Typography>
    );
  }

  return (
    <div>
      <Grid container justify="space-between">
        <Grid container spacing={8}>
          <Grid item>
            <CustomButton
              variant="orange"
              color="primary"
              style={{ marginBottom: 25 }}
              onClick={() => history.push(`${match.path}/add`)}
            >
              {' '}
              Add Enterprise
            </CustomButton>
          </Grid>
          {!isStateInitial && (
            <Grid item>
              <CustomButton
                variant="orange"
                color="primary"
                style={{ marginBottom: 25 }}
                onClick={handleShowAllClick}
              >
                {' '}
                Show all
                {' '}
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        <SearchBar
          placeholder={searchBarPlaceholder}
          defaultSearchValue={search}
          onChange={handleSearch}
        />
      </Grid>
      <Paper className={classes.paper} style={{ marginTop: 33 }}>
        <SelectedActionsBar
          onDelete={() => {
            setEnterpriseToRemove(true);
          }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography style={{ marginLeft: '23px' }} variant="h5">
          Enterprises
        </Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })
              }
            />
          </Grid>
          <TableContainer
            ref={tableContainer}
            className={classes.tableContainer}
          >
            <CustomTableScroll
              getTableContainer={() => tableContainer}
              columns={9}
            />
            <Table className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedLength > 0
                        && selectedLength < data.enterpriseList.list.length
                      }
                      checked={
                        selectedLength === data.enterpriseList.list.length
                      }
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'Select all locks' }}
                    />
                  </TableCell>
                  <TableHeadCell
                    sortBy={sort.sortBy}
                    sortDirection={sort.direction}
                    value="name"
                    label="Enterprise Name"
                    onSortClick={handleSortBy}
                  />
                  <TableHeadCell
                    sortBy={sort.sortBy}
                    sortDirection={sort.direction}
                    value="created_by"
                    onSortClick={handleSortBy}
                  />
                  <TableHeadCell
                    sortBy={sort.sortBy}
                    sortDirection={sort.direction}
                    value="created_on"
                    onSortClick={handleSortBy}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.enterpriseList.list.map((enterprise, index) => {
                  const isItemSelected: boolean = isSelected(
                    enterprise?.id,
                    selected,
                  );
                  const labelId: string = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={enterprise?.id?.toString()}
                      className={classes.pointer}
                      onClick={() => history.push(`${match.path}/${enterprise?.id}`)
                      }
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event => {
                            event.stopPropagation();
                            handleClick(event, enterprise.id);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.orange}
                        component="th"
                        scope="row"
                      >
                        {enterprise.name}
                      </TableCell>
                      <TableCell>{enterprise.created_by}</TableCell>
                      <TableCell>
                        {enterprise.created_on
                          ? new Date(enterprise.created_on).toLocaleDateString()
                          : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsTotal={data!.enterpriseList.params.total}
            rowsCount={data!.enterpriseList.list.length}
            rowsPerPage={cursor.limit}
            currentPage={page}
            onChangePage={n => handleChangePage(undefined, n)}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })
            }
          />
        </div>
      </Paper>
      <ConfirmDelete
        isOpen={enterpriseToRemove}
        onCancel={() => {
          setEnterpriseToRemove(false);
        }}
        onConfirm={() => {
          handleClientDelete();
          setEnterpriseToRemove(false);
        }}
      />
    </div>
  );
};

export default React.memo(Enterprises);
