import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  icon: {
    transform: 'translateY(7px)',
  },

  mainList: {
    alignItems: 'baseline',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
