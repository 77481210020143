import * as yup from 'yup';

export const enterpriseSchema = yup.object().shape({
  name: yup.string().required('Enter enterprise name'),
  clients: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        builder_id: yup.number(),
      }),
    )
    .required('Please select clients'),
});
