import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStyles from './styles';

type Props = {
  value: string,
  smartLockTypeId: number,
  smartLockTypes: any
} & TextFieldProps;

const SmartLockTypeSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { smartLockTypeId, value, smartLockTypes, ...textFieldProps } = props;

  return (
    <>
      <TextField
        margin="normal"
        value={value}
        fullWidth
        {...textFieldProps}
        select
        label="Smart Lock type"
        inputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
      >
        <MenuItem disabled value={0}>N / A</MenuItem>
        { smartLockTypes?.data?.allSmartLockTypes.map((smartLockData: any) => (
          <MenuItem key={smartLockData.id} value={smartLockData.smart_lock_type}>
            {smartLockData.smart_lock_type}
          </MenuItem>
        ))
          }
      </TextField>
    </>
  );
};

export default SmartLockTypeSelect;