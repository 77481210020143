/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import history from './browserHistory';
import theme from './theme';
import store from './redux/store';
import App from './App';
import { apolloClient } from './services/graphql';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <Router history={history}>
          <App />
        </Router>
      </ApolloProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
