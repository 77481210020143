import { Button, FormLabel, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  register: any;
}

const MIN_ITEMS = 1;

const ListTextInput = ({
  name, label, placeholder, register,
}: Props) => {
  const { control } = useForm();
  const {
    fields, append, remove,
  } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name, // unique name for your Field Array
  });

  useEffect(() => {
    append({ [name]: '' });
  }, [name, append]);

  return (
    <>
      { label && <FormLabel id={`list-item-input-${name}`}>{label}</FormLabel>}
      {fields.map((field, index) => (
        <TextField
          aria-labelledby={`list-item-input-${name}`}
          key={field.id} // important to include key with field's id
          margin="normal"
          fullWidth
          placeholder={placeholder}
          inputRef={input => register(input, { required: true })}
          required
          hiddenLabel
          name={`${name}.${index}`}
          InputProps={{
            endAdornment: <Button disabled={fields.length === MIN_ITEMS} onClick={() => remove(index)}><DeleteIcon color="inherit" /></Button>,
          }}
        />
      ))}
      <Button
        onClick={() => append({ [name]: '' })}
      >
        Add
      </Button>
    </>
  );
};

export default ListTextInput;
