import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  spacer: {
    flexGrow: 1,
  },
  spinner: {
    alignSelf: 'flex-end',
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
      color: theme.appPalette.orange,
      textTransform: 'capitalize',
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
