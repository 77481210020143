import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Builder from '../../types/Builder';
import useDebounce from '../../hooks/useDebounce';
import { SEARCH_BUILDERS, GET_BUILDER_BY_ID } from '../../graphql/queries/Clients';

type Props = {
  onBuilderChange: (builder: Builder) => void,
  initialValue?: number;
}& TextFieldProps;

const BuilderSearch: React.FC<Props> = ({ onBuilderChange, initialValue, ...textFieldProps }) => {
  const [search, setSearch] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [builders, setBuilders] = useState<{value: Builder, label: string}[]>([]);
  const debouncedSearch = useDebounce(search, 100);
  const handleSearch = (event : React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  };

  const { data, loading } = useQuery<{ builders: Builder[] }>(
    SEARCH_BUILDERS,
    {
      variables: {
        name: debouncedSearch,
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: builderData } = useQuery< { builder: Builder } >(GET_BUILDER_BY_ID,
    { variables: { id: initialValue }, skip: !initialValue });

  useEffect(() => {
    const availableBuilders = (data?.builders.map(item => ({
      value: item,
      label: `${item.name}`,
    }))) || [];
    setBuilders(availableBuilders);
  }, [data]);

  useEffect(() => {
    if (builderData
      && !builders.find(builder => builder.value.builder_id === initialValue)) {
      if (!builderData.builder) {
        enqueueSnackbar('Builder was not found', { variant: 'warning' });
        return;
      }
      const { name, builder_id } = builderData.builder;
      builders.push({
        value: { builder_id, name },
        label: name,
      });
    }
  }, [builderData, builders, initialValue, enqueueSnackbar]);

  const handleChange = (event: any, chosenSelect: { label: string, value: Builder } | null) => {
    const initialProp = {
      builder_id: 0,
      name: '',
    };
    onBuilderChange(chosenSelect?.value ?? initialProp);
  };
  const initial = builderData ? builderData.builder : undefined;
  return (
    <>
      {initial && (
      <Autocomplete
        options={builders}
        getOptionLabel={option => option.label}
        noOptionsText="No client was found"
        onChange={handleChange}
        loading={loading}
        defaultValue={initial ? {
          value: initial, label: initial.name,
        } : undefined}
        renderInput={params => (
          <TextField
            {...params}
            label="Client"
            fullWidth
            onKeyUp={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            {...textFieldProps}
          />
        )}
      />
      )}
      { !initial && (
      <Autocomplete
        options={builders}
        getOptionLabel={option => option.label}
        noOptionsText="No client was found"
        onChange={handleChange}
        loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            label="Client"
            fullWidth
            onKeyUp={handleSearch}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            {...textFieldProps}
          />
        )}
      />
      ) }
    </>
  );
};

export default BuilderSearch;
