import React, {
  FC, useState, useEffect, memo,
} from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { IIntegration } from './Integration/Integration';
import { UPDATE_INTEGRATIONS } from '../../../graphql/queries/PropertyDetails';

type IntegrationTabProps = {
  propertyId: number,
  integrations: IIntegration[]
  handleIntegrationsChange: (integration: {
    [key: string]: string,
  }) => void
};


const IntegrationTab: FC<IntegrationTabProps> = ({ integrations, propertyId, handleIntegrationsChange }) => {
  const [integrationsList, setIntegrationsList] = useState<IIntegration[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const isIntegrationsReadonly = integrations.filter(i => i.field !== null).length === 0;

  useEffect(() => {
    if (integrations) {
      setIntegrationsList(integrations);
    }
  }, [integrations]);

  const [updateIntegrations] = useMutation(
    UPDATE_INTEGRATIONS,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    const changedIntegrations = integrationsList.map(int => {
      if (int.field === name) {
        return { ...int, value };
      }

      return int;
    });

    if (name === 'LASSO_project_id') {
      const isProjectIdValid = !Number.isNaN(Number(value));

      if (!isProjectIdValid && value.length > 0) {
        return;
      }
    }

    handleIntegrationsChange({ [name]: value });
    setIntegrationsList(changedIntegrations);
  };

  const handleIntegrationSave = async () => {
    try {
      await updateIntegrations({
        variables: {
          propertyId,
          integrations: integrationsList,
        },
      });
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error occurred', { variant: 'error' });
    }
  };

  return (
    <>
      <Grid container direction="column" spacing={3}>
        { integrationsList.map(integration => {
          const isReadOnlyIntegration = integration.field === null;

          return (
            <Grid item key={integration.name}>
              <Typography variant="h5">
                { integration.name }
              </Typography>
              {isReadOnlyIntegration ? (
                <Typography>
        This integration does not have any property level fields to configure
                </Typography>
              ) : (
                <TextField
                  margin="normal"
                  value={integration.value}
                  name={integration.field}
                  onChange={handleChange}
                  label={integration.name}
                />
              )}
              <div />
            </Grid>
          );
        }) }
        { !isIntegrationsReadonly && (
        <Grid item sm={3} md={3} lg={3}>
          <Button
            type="submit"
            variant="contained"
            onClick={handleIntegrationSave}
          >
             Save
          </Button>
        </Grid>
        ) }
      </Grid>
    </>
  );
};

export default memo(IntegrationTab);
