import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { Order } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
  {
    id: 'customer.firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'customer.lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'customer.cell_phone_1',
    align: 'right',
    disablePadding: false,
    label: 'Cell',
  },
  {
    id: 'catalogs.value',
    align: 'center',
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'status.value',
    align: 'center',
    disablePadding: true,
    label: 'Status',
  },
  {
    id: 'property.property_id',
    align: 'right',
    disablePadding: false,
    label: 'Property id',
  },
  {
    id: 'property.subdivision',
    align: 'right',
    disablePadding: false,
    label: 'Subdivision',
  },
  {
    id: 'property.address_1',
    align: 'center',
    disablePadding: true,
    label: 'Address',
  },
  {
    id: 'visits.platform',
    align: 'right',
    disablePadding: true,
    label: 'Platform',
  },
  {
    id: 'visits.created_on',
    align: 'right',
    disablePadding: true,
    label: 'Visit Date',
  },
];

const TableHead: React.FC<Props> = props => {
  const {
    onSelectAllClick, onRequestSort, orderBy, order, numSelected, rowCount,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  const dummy = () => {};

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all Properties' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => {
          const isPlatform = 'visits.platform';
          return (
            <TableCell
              key={id}
              align={align}
              padding={disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === id ? order : false}
              onClick={id === isPlatform ? dummy : createSortHandler(id)}
            >
              {label}
              {id !== isPlatform && (
              <TableSortLabel
                active={orderBy === id}
                direction={order}
                IconComponent={ArrowIcon}
              />
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
