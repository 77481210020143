import { gql } from 'apollo-boost';

export const ALL_VISITS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String, $startDate: String, $endDate: String) {
    visitList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction, startDate: $startDate, endDate: $endDate) {
      list {
        customer_visit_id,
        visit_status_id,
        created_on,
        platform,
        customer {
          firstname,
          lastname,
          cell_phone_1,
          modified_by,
          status {
            value
          },
          catalog{
            value
          }
        },
        property {
          property_id,
          address_1,
          subdivision,
          house_num,
          timezoneCatalog {
            catalog_id,
            value
          }
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_VISIT = gql`
  mutation($id: Int!) {
    deleteVisitById( id : $id) {
      customer_visit_id
    }
  }
`;

export const REMOVE_VISITS = gql`
  mutation($visits: [Int!]!) {
    deleteMultipleVisitsById(visits: $visits)
  }
`;
