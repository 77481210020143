import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  root: {
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
      