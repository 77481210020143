import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { AxiosResponse, AxiosError } from 'axios';
import {
  AUTH_USER_REQUEST,
  AUTH_USER_LOGOUT,
  AuthRequest,
  OauthClient,
  GET_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  CREATE_OAUTH_CLIENTS_REQUEST,
} from '../types/auth';
import { navTo } from '../../browserHistory';

import {
  login,
  logOut,
  getOauthClients,
  removeOauthClient,
  createOauthClient,
} from '../../services/auth';
import {
  authUserError,
  authUserSuccess,
  getOauthClientsSuccess,
  removeOauthClientsSuccess,
  getOauthClientsError,
  removeOauthClientsError,
  removeOauthClientsRequest,
  createOauthClientsSuccess,
  createOauthClientsError,
} from '../actions/auth';
import { createErrorSnackBar } from '../actions/snackbars';

const getErrorMessage = ({ response }: AxiosError): string => {
  if (!response) {
    return 'Something went wrong';
  }

  switch (response.status) {
    case 404:
      return 'User not found';
    case 403:
      return 'Invalid usersname or password';
    default: return response.data;
  }
};

function* loginSaga(loginData: AuthRequest) {
  try {
    const { data } = yield call(login, loginData);

    yield put(authUserSuccess(data));
    yield call(navTo, '/');
  } catch (err) {
    if (err.isAxiosError) {
      yield put(createErrorSnackBar(getErrorMessage(err)));
    }

    yield put(authUserError(err));
  }
}

function* logOutSaga(): IterableIterator<any> {
  yield call(logOut);
  yield call(navTo, '/');
}

function* getOauthClientsSaga() {
  try {
    const { data }: AxiosResponse<ApiResponse<OauthClient>> = yield call(
      getOauthClients,
    );
    yield put(getOauthClientsSuccess(data));
  } catch (err) {
    yield put(getOauthClientsError(err));
  }
}

function* removeOauthClientsSaga({
  payload,
}: ReturnType<typeof removeOauthClientsRequest>) {
  try {
    yield call(removeOauthClient, payload);
    yield put(removeOauthClientsSuccess(payload));
  } catch (err) {
    yield put(removeOauthClientsError(err));
  }
}

function* createOauthClientsSaga() {
  try {
    const { data }: AxiosResponse<OauthClient> = yield call(
      createOauthClient,
      {},
    );
    yield put(createOauthClientsSuccess(data));
  } catch (err) {
    yield put(createOauthClientsError(err));
  }
}

const authSaga = all([
  takeEvery<any>(AUTH_USER_REQUEST, loginSaga),
  takeEvery<any>(AUTH_USER_LOGOUT, logOutSaga),
  takeEvery<any>(GET_OAUTH_CLIENTS_REQUEST, getOauthClientsSaga),
  takeEvery<any>(REMOVE_OAUTH_CLIENTS_REQUEST, removeOauthClientsSaga),
  takeEvery<any>(CREATE_OAUTH_CLIENTS_REQUEST, createOauthClientsSaga),
]);

export default authSaga;
