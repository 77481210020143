import React, { ReactNode } from 'react';
import { State } from '../types/Customer';
import useStates from '../hooks/useStates';

type Props = {
  children: ReactNode,
  states?: State[],
};

export const StatesContext = React.createContext<State[]>([]);
export const StatesProvider = ({ children } : Props) => {
  const states = useStates();
  return (
    <StatesContext.Provider value={states}>
      { children }
    </StatesContext.Provider>
  );
};
