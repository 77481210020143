import { gql } from 'apollo-boost';

export const ALL_LOCKS = gql`
  query($limit: Int, $offset: Int, $search: String, $sortBy: String, $direction: String) {
    lockList(limit: $limit, offset: $offset, search: $search, sortBy: $sortBy, direction: $direction) {
      list {
        serial_number,
        manufacturer,
        version,
        purchase,
        license_start_date,
        licensee,
        additional_info_1,
        property {
          property_id
        },
        created_on,
        created_by,
        modified_on,
        modified_by,
        lockStatus,
        year,
        batch,
        hardware_version,
        software_version,
        client{
          builder_id,
          name
        }
      }
      params {
        total
        limit
        offset
      }
    }
  }
`;

export const REMOVE_MULTIPLE_LOCKS = gql(`
     mutation($locks: [String!]!) {
        deleteMultipleLocksBySN(locks: $locks)
    }
    `);
