import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Link, RouteComponentProps } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@material-ui/lab';
import { refreshTokenRequest } from '../../services/reset-password';
import { UPDATE_USER_PASSWORD } from '../../graphql/queries/Users';
import useStyles from './styles';
import resetPasswordValidation from './resetPasswordValidation';
import { getApolloClientWithToken } from '../../services/graphql';


type Props = RouteComponentProps;

const PasswordReset: React.FC<Props> = (
  { location },
) => {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordValidation),
  });

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(false);
  const [token, setToken] = useState(new URLSearchParams(location.search).get('token'));
  const [refreshToken] = useState(new URLSearchParams(location.search).get('refreshToken'));
  const [client, setClient] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (token) {
      setClient(getApolloClientWithToken(token));
    } else setDisplayErrorMessage(true);
  }, [token]);

  const [updatePassword, { loading }] = useMutation<{ updatePassword: Boolean }>(
    UPDATE_USER_PASSWORD,
    {
      client,
    },
  );

  const renderErrorMessage = () => (
    <div className={classes.form}>
      <Grid>
        <Grid container justify="flex-end" alignItems="center" className={classes.alertContainer}>
          <Grid item xs={12}>
            <Alert variant="outlined" className={classes.alertText} severity="error">
              Link is damaged. Try to re-submit the reset password form
            </Alert>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItems="center">
          <Button
            size="large"
            variant="contained"
            component={Link}
            to="/login"
          >
                Back to login page
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const onSubmit = (data: any) => {
    if (Boolean(data.password) && data.password !== data.confirm) {
      return setErrorMessage('Passwords doesn\t match');
    }
    setErrorMessage('');
    if (token) {
      updatePassword({
        variables: { password: data.password },
      })
        .then(() => {
          setOpen(true);
        })
        .catch(() => {
          if (refreshToken) {
            refreshTokenRequest(refreshToken).then(res => {
              setToken(res.data);
              updatePassword({
                variables: { password: data.password },
              }).then(() => {
                setOpen(true);
              });
            })
              .catch(() => setDisplayErrorMessage(true));
          }
        });
    } else {
      return setDisplayErrorMessage(true);
    }
  };

  const renderForm = () => (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      className={classes.form}
    >
      <Typography align="center" variant="h4">
        Enter new password
      </Typography>
      <TextField
        variant="filled"
        margin="normal"
        required
        inputRef={register}
        fullWidth
        id="password"
        label="Password"
        name="password"
        type="password"
        error={!!errors.password}
        helperText={errors?.password?.message ?? ''}
      />
      <TextField
        variant="filled"
        margin="normal"
        required
        inputRef={register}
        fullWidth
        id="confirm"
        label="Confirm password"
        name="confirm"
        type="password"
        error={!!errorMessage}
        helperText={errorMessage ?? ''}
      />
      <Grid container justify="flex-end" alignItems="center">
        <div className={classes.progressButtonWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              className={classnames(classes.progress, classes.progressButton)}
            />
          )}
        </div>
      </Grid>
    </form>
  );

  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="row"
        className={classes.container}
      >
        { displayErrorMessage
          ? renderErrorMessage()
          : renderForm()
        }
        <Dialog
          open={open}
          disableBackdropClick
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Your password changed successfully, please login to continue.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button component={Link} to="/login">
            Sign In.
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default React.memo(PasswordReset);
