import React, { FC, memo } from 'react';
import { Typography } from '@material-ui/core';
import { generateLabel } from '../../utils/helpers';
import useStyles from '../CustomerDetails/PropertyInfo/styles';

type Timestamps = {
  createdOn?: string,
  createdBy?: string,
  modifiedOn?: string,
  modifiedBy?: string,
};

type TimestampProps = {
  timestamps: Timestamps,
};

const Timestamps: FC<TimestampProps> = ({ timestamps }) => {
  const classes = useStyles();
  const {
    createdOn,
    createdBy,
    modifiedOn,
    modifiedBy,
  } = timestamps;
  return (
    <>
      <Typography variant="body1">
        <span className={classes.label}>
          {generateLabel('created_on')}
                    :
          {' '}
        </span>
        {' '}
        {createdOn
          ? new Date(createdOn).toLocaleString()
          : ''}
      </Typography>
      <Typography variant="body1">
        <span className={classes.label}>
          {generateLabel('created_by')}
                    :
          {' '}
        </span>
        {' '}
        {createdBy}
      </Typography>
      <Typography variant="body1">
        <span className={classes.label}>
          {generateLabel('modified_on')}
                    :
          {' '}
        </span>
        {' '}
        {modifiedOn
          ? new Date(modifiedOn).toLocaleString()
          : ''}
      </Typography>
      <Typography variant="body1">
        <span className={classes.label}>
          {generateLabel('modified_by')}
                    :
          {' '}
        </span>
        {' '}
        {modifiedBy}
      </Typography>
    </>
  );
};

export default memo(Timestamps);
