import React, { FC, memo } from 'react';
import {
  Grid, Table, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import { SurveyResult } from '../../../types/CustomerSurveys';

type Props = {
  survey: SurveyResult
};

const SurveyInfo: FC<Props> = ({ survey }) => (
  <>
    <Grid container alignItems="center" xs={12} sm={12}>
      <Grid item xs={12} sm={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Question</TableCell>
              <TableCell align="center">Answer</TableCell>
            </TableRow>
          </TableHead>
          { survey.results.map(result => (
            <TableRow key={result.question}>
              <TableCell align="center">{result.question}</TableCell>
              <TableCell align="center">{result.answer}</TableCell>
            </TableRow>
          )) }
        </Table>
      </Grid>
    </Grid>
  </>
);

export default memo(SurveyInfo);
