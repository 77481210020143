import {
  LOCKS_REQUEST,
  LOCKS_REQUEST_SUCCESS,
  LOCKS_REQUEST_FAILED,
  LOCK_DETAILS_REQUEST,
  LOCK_DETAILS_REQUEST_SUCCESS,
  LOCK_DETAILS_REQUEST_FAILED,
  LocksRequestSuccess,
  LockDetailsRequestSuccess,
  ErrorMessage,
} from '../types/locks';
import { Query } from '../../utils/query';

export const locksRequest = (query?: Query) => ({
  type: LOCKS_REQUEST,
  payload: query,
} as const);

export const locksRequestFailed = (payload: ErrorMessage) => ({
  type: LOCKS_REQUEST_FAILED,
  payload,
} as const);

export const locksRequestSuccess = (payload: LocksRequestSuccess) => ({
  type: LOCKS_REQUEST_SUCCESS,
  payload,
} as const);

export const lockDetailsRequest = (lockSerial: string | number) => ({
  type: LOCK_DETAILS_REQUEST,
  payload: lockSerial,
} as const);

export const lockDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: LOCK_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const lockDetailsRequestSuccess = (payload: LockDetailsRequestSuccess) => ({
  type: LOCK_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export type LocksRequestActionType = ReturnType<typeof locksRequest>;
export type LockDetailsRequestActionType = ReturnType<typeof lockDetailsRequest>;

export type LocksActionsTypes =
  | LocksRequestActionType
  | ReturnType<typeof locksRequestFailed>
  | ReturnType<typeof locksRequestSuccess>
  | LockDetailsRequestActionType
  | ReturnType<typeof lockDetailsRequestFailed>
  | ReturnType<typeof lockDetailsRequestSuccess>;
