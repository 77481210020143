import React, {
  Fragment, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { Order } from '../../utils/table';
import SearchBar from '../../components/SearchBar';
import CustomTablePagination from '../../components/CustomTablePagination';
import useStyles from './styles';
import Table from './Table';
import Spinner from '../../components/CustomSpinner';
import { DeviceType } from '../../types/Devices';
import { loadExport, loadExportAll } from '../../services/export';
import { ALL_DEVICES, DELETE_HUBS } from '../../graphql/queries/Devices';
import CustomButton from '../../components/CustomButton';
import { SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../../components/RowsPerPageSelect';
import SelectedActionsBar from '../../components/SelectedActionsBar';
import useDebounce from '../../hooks/useDebounce';
import ConfirmDelete from '../../components/ConfirmDelete';
import downloadDocument from '../../utils/files';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
} & RouteComponentProps;

const Device: React.FC<Props> = props => {
  const {
    history,
    match,
  } = props;

  const { dispatch, state } = useContext(SearchSortContext);

  const {
    orderBy, order, contextSearch, rowsPerPageAmount, currentPage,
  } = state.devices;

  const [search, setSearch] = useState<string>(contextSearch);
  const [deviceToRemove, setDeviceToRemove] = useState<boolean>(false);
  const [devices, setDevices] = useState<DeviceType[]>([]);
  const debouncedSearch = useDebounce(search, 1000);
  const [selected, setSelected] = useState<Array<DeviceType['id']>>([]);
  const selectedLength = selected.length;
  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();


  function handleClick(
    event: React.SyntheticEvent,
    id: DeviceType['id'],
  ) {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<DeviceType['id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  }

  const [cursor, setCursor] = useState<{ limit: number, offset: number }>({
    limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
  });

  const [sort, setSort] = useState<{ sortBy: string, direction: 'asc' | 'desc' }>({
    sortBy: orderBy, direction: order,
  });

  const classes = useStyles();

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_DEVICE',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  const [removeDevices] = useMutation(DELETE_HUBS, {
    refetchQueries: [{
      query: ALL_DEVICES,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  function handleDelete() {
    if (selectedLength !== 0) {
      removeDevices({
        variables: {
          ids: selected,
        },
      }).then(() => {
        setSelected([]);
        enqueueSnackbar('Removed', { variant: 'success' });
      })
        .catch(e => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  }

  const handleSearch = useCallback((value: string) => {
    dispatch({
      type: 'CHANGE_SHIPMENT',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_DEVICE',
      payload: {
        rowsPerPageAmount: e.target.value,
        currentPage: 0,
      },
    });
  }, [dispatch]);

  const { loading, data } = useQuery<{
    hubs: Paginated<DeviceType>
  }>(ALL_DEVICES, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setDevices(data.hubs.list);
    }
  }, [data, loading]);

  const params = data?.hubs?.params ?? { total: 0 };
  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const handleDeviceSelect = (deviceId: number) => {
    history.push(`${match.url}/${deviceId}`);
  };

  function handleChangeOrder(newOrder: Order, newOrderBy: string) {
    setSort({
      direction: newOrder,
      sortBy: newOrderBy,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_DEVICE',
      payload: {
        orderBy: newOrderBy,
        order: newOrder,
        currentPage: 0,
      },
    });
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = devices.map((device: any) => device.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'hub')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'Devices', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('hub').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Devices', response.data);
    });
  }


  function handlePrint() {
    if (selectedLength !== 0) {}
  }

  return (
    <Fragment>
      <Grid container spacing={5}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add device
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            placeholder="Search device"
            defaultSearchValue={search}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <SelectedActionsBar
          onDelete={() => setDeviceToRemove(true)}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selected.length}
          exportAllLoading={exportAllLoading}
        />
        <Typography className={classes.tableHeaderText} variant="h5">Devices</Typography>
        <Grid container alignContent="center" justify="flex-end">
          <RowsPerPageSelect
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsPerPage={cursor.limit}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
          />
        </Grid>
        <div className={classes.tableWrapper}>
          <Table
            order={sort.direction}
            orderBy={sort.sortBy}
            selected={selected}
            devices={devices}
            onRowClick={handleDeviceSelect}
            onSelect={handleClick}
            onSelectAll={handleSelectAllClick}
            onChangeOrder={handleChangeOrder}
          />
        </div>
        {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
        )}
        <CustomTablePagination
          rowsPerPageOptions={[25, 50, 100, 150]}
          rowsTotal={params.total}
          rowsCount={devices.length}
          rowsPerPage={cursor.limit}
          currentPage={page}
          onChangePage={n => handleChangePage(undefined, n)}
          onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
        />
      </div>
      <ConfirmDelete
        isOpen={deviceToRemove}
        onCancel={() => { setDeviceToRemove(false); }}
        onConfirm={() => { handleDelete(); setDeviceToRemove(false); }}
      />
    </Fragment>
  );
};

export default React.memo(Device);
