import { useContext, useEffect, useState } from 'react';
import StaticDataContext from '../context/StaticDataProvider';

export default function useTimezones() {
  const staticData = useContext<any>(StaticDataContext);
  const [timezones, setTimezones] = useState([]);
  useEffect(() => {
    if (staticData) {
      setTimezones(staticData.timezones);
    }
  }, [staticData]);
  return timezones;
}
