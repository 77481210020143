/* eslint-disable linebreak-style */
import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pointer: {
    cursor: 'pointer',
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
    marginBottom: 10,
  },
  innerPaper: {
    padding: 25,
    marginBottom: 10,
  },
  customerName: {
    wordWrap: 'break-word',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  container: {
    marginBottom: 25,
  },
  avatar: {
    color: 'white',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  timestamp: {
    color: theme.appPalette.orange,
  },
  btn: {
    minWidth: '0',
  },
  essentialNotes: {
    margin: '10px 20px',
    marginLeft: 'auto',
    border: '1px solid rgba(142, 139, 153, 0.2)',
    borderRadius: '10px',
  },
  notesHeader: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: '0 5px 0 0',
  },
  newNotesHeader: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: '0px',
  },
  contactHeader: {
    fontSize: 17,
    fontWeight: 'bold',
    margin: '0 5px 0 0',
  },
  notesMessage: {
    margin: 0,
    padding: '3px',
    fontSize: '17px',
  },
  contactPhone: {
    margin: '0 0 0 0',
    padding: '3px',
    fontSize: '17px',
  },
  contactName: {
    margin: 0,
    padding: '3px',
    fontSize: '17px',
  },
  alarmMessage: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.57,
    color: '#040620',
  },
  dot: {
    width: '4px',
    height: '4px',
    margin: '0 16px',
    borderRadius: '50%',
    backgroundColor: '#8E8B99',
  },
  marginNote: {
    padding: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  marginContacts: {
    padding: '10px',
    marginTop: 'auto',
    marginBottom: 'auto',
    border: '1px solid rgb(195, 195, 195)',
  },
  newMarginNote: {
    padding: '10px',
    paddingBottom: '0px',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  newBox: {
    paddingTop: '33px',

  },
  pointPerson: {
    padding: '5px 0 10px',
    '& p': {
      padding: 0,
      fontWeight: '500'
    },
  },
  inputRoot: {
    '& .MuiFilledInput-root': {
      '&:-internal-autofill-selected ': {
        backgroundColor: theme.appPalette.orange,
      },
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: theme.appPalette.orange,
    },
    '& label.Mui-focused, label.MuiFormLabel-root': {
      color: theme.appPalette.orange,
    },
  },
  surveyQuestions: {
    borderRadius: 8,
    background: 'rgb(232, 232, 232)',
    border: '1px solid rgb(195, 195, 195)',
    justifyContent: 'center',
    margin: 4,
    alignItems: 'center',
  },

  surveyAnswers: {
    marginRight: 20,
  },

  statusWarning: {
    minHeight: '122px',
    margin: '10px 20px',
    borderLeft: '6px solid #FF0000',
    borderRadius: '10px',
    backgroundColor: '#FF000011',
  },

  essentialNotesContainer: {
    justifyContent: 'space-between',
  },

  surveyQuestionsContainer: {
    flexWrap: 'wrap',
  },
  surveyAnswerDropdown: {
    margin: '7px 0px',
    '& > .MuiFilledInput-root': {
      background: 'rgb(232, 232, 232)',
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
