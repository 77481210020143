import { Collapse, Grid } from '@material-ui/core';
import React from 'react';
import { generateLabel } from '../../../../utils/helpers';
import CustomTextField from '../../../CustomTextField';

type Props = {
  lassoId: string;
  isActive: boolean;
  handleChangeClient: ({
    target:
    { name, value, checked },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors: any;
  lassoApikey: string;
  lassoPrimarySource: string;
  lassoSecondarySource: string;
};

const LassoAttributes: React.FC<Props> = props => {
  const {
    lassoId, lassoApikey, lassoPrimarySource, lassoSecondarySource, isActive, handleChangeClient, validationErrors,
  } = props;

  return (
    <Collapse in={isActive}>
      <Grid container spacing={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="normal"
              label={generateLabel('LASSO_id')}
              name="Lasso Id"
              fullWidth
              value={lassoId || ''}
              onChange={handleChangeClient}
              error={Boolean(validationErrors.LASSO_id)}
              helperText={validationErrors.LASSO_id || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="normal"
              fullWidth
              multiline
              label={generateLabel('LASSO_apikey')}
              name="Lasso Apikey"
              value={lassoApikey || ''}
              onChange={handleChangeClient}
              error={Boolean(validationErrors.LASSO_apikey)}
              helperText={validationErrors.LASSO_apikey || ''}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="normal"
              label={generateLabel('LASSO_primary_source')}
              name="Lasso Primary Source"
              fullWidth
              value={lassoPrimarySource || ''}
              onChange={handleChangeClient}
              error={Boolean(validationErrors.LASSO_primary_source)}
              helperText={validationErrors.LASSO_primary_source || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="normal"
              fullWidth
              label={generateLabel('LASSO_secondary_source')}
              name="Lasso Secondary Source"
              value={lassoSecondarySource || ''}
              onChange={handleChangeClient}
              error={Boolean(validationErrors.LASSO_secondary_source)}
              helperText={validationErrors.LASSO_secondary_source || ''}
            />
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default React.memo(LassoAttributes);
