import { connect } from 'react-redux';
import {
  propertiesStatsRequest,
  allVisitsRequest,
  contractorsCountRequest,
} from '../../redux/actions/stats';
import { selectUserData } from '../../redux/selectors/auth';
import {
  selectPropertiesStatsState,
  selectAllVisitsState,
  selectContractors,
} from '../../redux/selectors/stats';
import Stats from './Stats';
import { AppState } from '../../redux/store';
import {
  selectVisitsRequestStatus,
} from '../../redux/selectors/visits';

const mapStateToProps = (state: AppState) => ({
  builderId: selectUserData(state).builderId,
  propertiesActiveCount: selectPropertiesStatsState(state).propertiesActive
    .length,
  propertiesSoldCount: selectPropertiesStatsState(state).propertiesSold.length,
  allVisitsCount: selectAllVisitsState(state).items.length,
  contractorsServed: selectContractors(state).served,
  loadingStats:
    selectPropertiesStatsState(state).loading
    || selectAllVisitsState(state).loading
    || selectVisitsRequestStatus(state),
});

const mapDispatchToProps = {
  loadPropertiesStats: propertiesStatsRequest,
  loadAllVisits: allVisitsRequest,
  loadContractorsCount: contractorsCountRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Stats);
