import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  widgetsWrapper: {
    marginTop: 33,
  },
  expandRoot: {
    backgroundColor: '#fefefe',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  controlButton: {
    marginBottom: '25px',
  },
  tableHeaderText: {
    paddingTop: '20px',
    marginLeft: '23px',
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  contact: {
    color: '#04A777',
  },
  nocontact: {
    color: '#E60D09',
  },
  card: {
    overflow: 'hidden',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 30,
    paddingBottom: 15,
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: '#E9ECF2',
  },
  cardContent: {
    backgroundColor: '#fefefe',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  shipmentStatus: {
    marginTop: '8px',
  },
  lockId: {
    marginTop: '8px',
  },
  builder: {
    marginTop: '24px',
  },
  saveButton: {
    marginTop: '60px'
  }
});
const useStyles = makeStyles(styles);

export default useStyles;
