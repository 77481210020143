import ShipmentDetails from './ShipmentDetails';
import { selectUserName } from '../../../redux/selectors/auth';
import { connect } from 'react-redux';

import { AppState } from '../../../redux/store'
const mapStateToProps = (state: AppState) => ({
    username: selectUserName(state),
});

export default connect(mapStateToProps)(ShipmentDetails);
