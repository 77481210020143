import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import useFormType from './useFormType';
import InputTicket from '../types/inputTypes/TroubleTicket';
import { GET_TROUBLE_TICKET, UPDATE_TICKET } from '../graphql/queries/TroubleTickets';
import useProperty from './useProperty';
import { selectUserName } from '../redux/selectors/auth';

export default function useTroubleTicket(ticketNumber? : number) {
  const username = useSelector(selectUserName);
  const [troubleTicket, setTroubleTicket] = useState<InputTicket>(new InputTicket(username));
  const { property, setProperty } = useProperty(troubleTicket.property_id);
  const formType = useFormType(ticketNumber);
  const { data } = useQuery<{ troubleTicket: InputTicket }>(
    GET_TROUBLE_TICKET,
    {
      variables: { ticketNumber },
      skip: formType === 'add' || !ticketNumber,
      fetchPolicy: 'network-only',
    },
  );
  useEffect(() => {
    if (data?.troubleTicket) {
      const ticket: InputTicket = {
        ...data.troubleTicket,
      };
      setTroubleTicket(ticket);
    }
  }, [data]);

  const [updateTicket] = useMutation<{ updateTroubleTicket: InputTicket }>(
    UPDATE_TICKET,
    { errorPolicy: 'all' },
  );

  return {
    troubleTicket, formType, setTroubleTicket, updateTicket, property, setProperty,
  };
}
