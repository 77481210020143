import { CREATE_SNACKBAR, DELETE_SNACKBAR } from '../types/snackbars';

export const createSuccessSnackBar = (message: string) => ({
  type: CREATE_SNACKBAR, payload: { message, variant: 'success' },
}) as const;

export const createInfoSnackBar = (message: string) => ({
  type: CREATE_SNACKBAR, payload: { message, variant: 'info' },
}) as const;

export const createWarningSnackBar = (message: string) => ({
  type: CREATE_SNACKBAR, payload: { message, variant: 'warning' },
}) as const;

export const createErrorSnackBar = (message: string) => ({
  type: CREATE_SNACKBAR, payload: { message, variant: 'error' },
}) as const;

export const deleteSnackBar = (id: string | number) => ({
  type: DELETE_SNACKBAR, payload: id,
}) as const;

export type SnackbarActions = ReturnType<typeof createSuccessSnackBar>
| ReturnType<typeof createInfoSnackBar>
| ReturnType<typeof createWarningSnackBar>
| ReturnType<typeof createErrorSnackBar>
| ReturnType<typeof deleteSnackBar>;
