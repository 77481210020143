import React, { ReactNode, createContext } from 'react';
import useStaticData from '../hooks/useStaticData';

type Props = {
  children: ReactNode,
};
export const StaticDataContext = createContext<any>({});
export const StaticDataProvider = ({ children } : Props) => {
  const statuses = useStaticData();
  return (
    <StaticDataContext.Provider value={statuses}>
      { children }
    </StaticDataContext.Provider>
  );
};
export default StaticDataContext;
