export interface UserInterface {
  username: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  pass_word: string;
}

export default class InputUser implements UserInterface {
  username: string = '';

  firstname: string = '';

  lastname: string = '';

  phone: string = '';

  email: string = '';

  pass_word: string = '';
}
