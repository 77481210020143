import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { BaseProperty as Property } from '../../types/Property';
import useDebounce from '../../hooks/useDebounce';
import { SEARCH_PROPERTIES_BY_ID, PROPERTY_SUN_PARAMS } from '../../graphql/queries/Properties';
import OptInAdornment from '../OptInAdornment';
import { useImperativeQuery } from '../../utils/query';


type Props = {
  onPropertyChange: (value: Property) => void,
  initialValue?: Property;
  builderId?: number;
} & TextFieldProps;


const PropertySearch: React.FC<Props> = ({
  builderId, onPropertyChange, initialValue, ...textFieldProps
}) => {
  const [search, setSearch] = useState<number>(0);
  const [isOptInRequired, setIsOptInRequired] = useState<boolean>(false);
  const [properties, setProperties] = useState<{ value: Property, label: string }[]>([]);
  const debouncedSearch = useDebounce(search, 100);
  const handleSearch = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value ? parseInt(target.value, 10) : 0);
  };
  const sunParamsQuery = useImperativeQuery(PROPERTY_SUN_PARAMS);

  const handleChange = async (event: any, chosenSelect: { label: string, value: Property } | null) => {
    const initialProp: Property = {
      property_id: 0,
      address_1: '',
      builder: '',
      state: '',
      client: {
        requires_opt_in: false,
      },
      timezone: 0,
      subdivision: '',
      house_num: '',
      lock: {
        status: { status_id: '' },
        lockStatus: '',
        manufacturer: '',
      },
      lock_serial_number: '',
      smart_lock_serial_number: '',
      grouping: '',
      url_1: '',
      rental_application_url: '',
      adjustableTourHours: 0,
    };

    if (!chosenSelect) {
      return onPropertyChange(initialProp);
    }

    setIsOptInRequired(chosenSelect?.value?.client?.requires_opt_in ?? false);

    const { data } = await sunParamsQuery({ property_id: chosenSelect?.value.property_id });

    onPropertyChange({
      ...chosenSelect.value,
      sunParams: data.propertySunParams,
    });
  };

  const { data, loading } = useQuery<{ properties: Property[] }>(
    SEARCH_PROPERTIES_BY_ID,
    {
      variables: {
        property_id: debouncedSearch,
        builder_id: builderId ?? 0,
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const availableProperties = (data?.properties.map(item => ({
      value: item,
      label: `${item.property_id} , ${item.house_num} ${item.address_1}`,
    }))) || [];
    setProperties(availableProperties);
  }, [data]);

  return (
    <>
      <div style={{ marginTop: '15px' }}>
        {initialValue && initialValue.property_id && (
          <Autocomplete
            options={properties}
            getOptionLabel={option => option.label}
            noOptionsText="No properties were found"
            onChange={handleChange}
            loading={loading}
            defaultValue={initialValue ? {
              value: initialValue, label: `${initialValue.property_id} , ${initialValue.house_num} ${initialValue.address_1}`,
            } : undefined}
            renderOption={option => (
              <>
                {option.value.client?.requires_opt_in && (
                  <>
                    <OptInAdornment />
                  </>
                )}
                {`${option.value.property_id} , ${option.value.house_num} ${option.value.address_1}`}
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Property"
                fullWidth
                onKeyUp={handleSearch}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {isOptInRequired && (
                        <OptInAdornment />
                      )}
                    </>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                {...textFieldProps}
              />
            )}
          />
        )}
        {!initialValue && (
          <Autocomplete
            options={properties}
            getOptionLabel={option => option.label}
            noOptionsText="No properties were found"
            onChange={handleChange}
            loading={loading}
            renderOption={option => (
              <>
                {option.value.client?.requires_opt_in && (
                  <>
                    <OptInAdornment />
                  </>
                )}
                {`${option.value.property_id} , ${option.value.house_num} ${option.value.address_1}`}
              </>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Property"
                fullWidth
                multiline
                onKeyUp={handleSearch}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {isOptInRequired && (
                        <OptInAdornment />
                      )}
                    </>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                {...textFieldProps}
              />
            )}
          />
        )}
      </div>
    </>
  );
};

export default PropertySearch;
