import React, {
  useEffect,
  useState,
} from 'react';
import {
  Typography,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { generateLabel, transformLockCode } from '../../utils/helpers';
import { GET_LOCK_CODE } from '../../graphql/queries/CodeGenerator';

type Props = {
  access_notes?: string;
  serialNumber?: string;
  setCode: (code:string) => void
};

const LockCode: React.FC<Props> = ({ serialNumber, setCode, access_notes}) => {
  const [timeStamp, setTimestamp] = useState<number>(new Date().getMinutes());

  // eslint-disable-next-line no-param-reassign
  if (serialNumber === undefined || serialNumber === '') { serialNumber = '0'; }
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      setTimestamp(date.getMinutes());
    }, 30000);
    return () => clearInterval(interval);
  });

  const { data } = useQuery<{ code: string }>(
    GET_LOCK_CODE,
    {
      variables: {
        sn: serialNumber,
        minutes: timeStamp,
      },
      fetchPolicy: 'network-only',
    },
  );

  useEffect(()=> {
    if(data?.code){
      setCode(transformLockCode(data?.code))
    }
  }, [data, setCode])

  const responseCode = transformLockCode(data?.code);
  return (
    <>
      {responseCode.length > 4
        ? (
          <>
            <Typography variant="body1" align="left">
              <span style={{ fontWeight: 'bold' }}>
                {generateLabel('unlock_code')}
                :
                {' '}
              </span>
            </Typography>
            <Typography variant="body1" align="left">
              {' '}
              <span style={{
                fontWeight: 'bold', fontSize: '24px', letterSpacing: '14px',
              }}
              >
                {responseCode}
              </span>
            </Typography>
            <Typography variant="body1" align="left" style={{marginTop: '20px'}}>
              {' '}
              <span style={{
                fontWeight: 'bold', fontSize: '24px', letterSpacing: '2px'
              }}
              >
                {access_notes}
              </span>
            </Typography>
          </>
        )
        : (
          <>
            <Typography variant="body1" align="left">
              <span style={{ fontWeight: 'bold' }}>
                {generateLabel('unlock_code')}
          :
                {' '}
              </span>
              {' '}
              <span style={{
                fontWeight: 'bold', fontSize: '24px', letterSpacing: '14px', marginLeft: '5px',
              }}
              >
                {responseCode}
              </span>
            </Typography>
          </>
        )
      }
    </>
  );
};

export default LockCode;
