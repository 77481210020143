import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import useStyles from './styles';

type Props = {
  onDelete: () => void;
  onExport?: () => void;
  onExportAll?: () => void;
  onPrint?: () => void;
  selectedLength: number;
  exportAllLoading?: boolean
};
const SelectedActionsBar: React.FC<Props> = props => {
  const {
    onDelete, onExport, onExportAll, onPrint, selectedLength, exportAllLoading,
  } = props;
  const classes = useStyles();
  return (
    <Toolbar>
      {exportAllLoading ? (
        <>
          <div className={classes.spacer} />
          <Spinner size={20} className={classes.spinner} />
        </>
      ) : (
        <>
          {selectedLength > 0 && (
            <>
              <div>
                <Typography variant="h5">
                  {`${selectedLength} selected.`}
                </Typography>
              </div>
              <div className={classes.spacer} />
              <div className={classes.toolbarActions}>
                <span>Selected actions: </span>
                <Button
                  size="small"
                  onClick={onDelete}
                >
                  Delete
                </Button>
                {onExport && (
                <Button
                  size="small"
                  disabled={selectedLength === 0}
                  onClick={onExport}
                >
                  Export
                </Button>
                )}
                {onPrint && (
                <Button
                  size="small"
                  disabled={selectedLength === 0}
                  onClick={onPrint}
                >
                  Print
                </Button>
                )}
              </div>
            </>
          )}
          {selectedLength === 0 && onExportAll && (
            <>
              <div className={classes.spacer} />
              <div className={classes.toolbarActions}>
                <span>Actions: </span>
                <Button
                  size="small"
                  onClick={onExportAll}
                >
                  Export All
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </Toolbar>
  );
};
export default React.memo(SelectedActionsBar);
