import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Order } from '../../../utils/table';
import Checkbox from '../../../components/CustomCheckbox';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  numSelected: number;
  onRequestSort: (event: React.SyntheticEvent, property: string) => void;
};

type HeadRow = {
  id: string;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: Array<HeadRow> = [
  {
    id: 'id',
    align: 'center',
    disablePadding: false,
    label: 'Id',
  },
  {
    id: 'username',
    align: 'center',
    disablePadding: false,
    label: 'Username',
  },
  {
    id: 'email',
    align: 'center',
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'adc_group_id',
    align: 'center',
    disablePadding: false,
    label: 'ADC group id',
  },
  {
    id: 'created_on',
    align: 'center',
    disablePadding: false,
    label: 'Created on',
  },
  {
    id: 'created_by',
    align: 'center',
    disablePadding: false,
    label: 'Created by',
  },
  {
    id: 'modified_on',
    align: 'center',
    disablePadding: false,
    label: 'Modified on',
  },
  {
    id: 'modified_by',
    align: 'center',
    disablePadding: false,
    label: 'Modified by',
  },
];

const TableHead: React.FC<Props> = props => {
  const {
    onSelectAllClick, numSelected, rowCount, onRequestSort,
  } = props;

  const createSortHandler = (property: string) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all devices' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            onClick={createSortHandler(id)}
            padding={disablePadding ? 'none' : 'default'}
          >
            {label}
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
