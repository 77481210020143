import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Typography,
} from '@material-ui/core';
import { transformLockCode } from '../../../utils/helpers';
import { LockCodeWithOffset } from '../../../types/LockCode';

type Props = {
  items: LockCodeWithOffset[],
};

const CodesTable: React.FC<Props> = ({ items }) => (
  <TableContainer>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1" display="inline" align="center">
              Offset
            </Typography>
          </TableCell>
          {items.map(item => (
            <TableCell>
              <Typography variant="body1" display="inline" align="center">
                {`${item.offset} m`}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableCell>
          <Typography variant="body1" display="inline" align="center">
            Code
          </Typography>
        </TableCell>
        {items.map(item => (
          <TableCell>
            <Typography variant="body1" display="inline" align="center">
              { transformLockCode(item.code) }
            </Typography>
          </TableCell>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default React.memo(CodesTable);
