import {
  VISITS_REQUEST,
  VISITS_REQUEST_SUCCESS,
  VISITS_REQUEST_FAILED,
  VISITS_EXPORT_REQUEST,
  VISITS_EXPORT_AND_PRINT_REQUEST,
  VISITS_EXPORT_REQUEST_SUCCESS,
  VISITS_EXPORT_REQUEST_FAILED,
  VisitsRequestSuccess,
  ErrorMessage,
} from '../types/visits';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';

export const visitsRequest = (query?: Query) => ({
  type: VISITS_REQUEST,
  payload: query,
} as const);

export const visitsRequestFailed = (payload: ErrorMessage) => ({
  type: VISITS_REQUEST_FAILED,
  payload,
} as const);

export const visitsRequestSuccess = (payload: VisitsRequestSuccess) => ({
  type: VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const visitsExportAndPrintRequest = (selected: Array<string | number>, mimeType: MimeType = 'application/pdf') => ({
  type: VISITS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const visitsExportRequest = (selected: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: VISITS_EXPORT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const visitsExportFailed = (payload: ErrorMessage) => ({
  type: VISITS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const visitsExportSuccess = () => ({
  type: VISITS_EXPORT_REQUEST_SUCCESS,
} as const);

export type VisitsRequestActionType = ReturnType<typeof visitsRequest>;
export type VisitsExportRequestActionType =
  | ReturnType<typeof visitsExportRequest>
  | ReturnType<typeof visitsExportAndPrintRequest>;

export type VisitsActionsTypes =
  | VisitsRequestActionType
  | VisitsExportRequestActionType
  | ReturnType<typeof visitsRequestFailed>
  | ReturnType<typeof visitsRequestSuccess>
  | ReturnType<typeof visitsExportFailed>
  | ReturnType<typeof visitsExportSuccess>;
