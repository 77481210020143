import { createBrowserHistory, History } from 'history';

const history: History = createBrowserHistory({
  basename: process.env.PUBLIC_URL ? new URL(process.env.PUBLIC_URL).pathname : '/',
});


export const navTo = (location: string = '/', state?: any): void => {
  history.push(location, state);
};

export default history;
