import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import useStatuses from '../../hooks/useStatuses';
import useStyles from './styles';
import { Status } from '../../types/Status';

type Props = { statusType: 'lock' | 'shipment' | 'property' } & TextFieldProps;

const StatusSelect: React.FC<Props> = props => {
  const classes = useStyles();
  const { statusType, ...textFieldProps } = props;
  const statuses: Status[] = useStatuses(statusType);
  return (
    <>
      <TextField
        margin="normal"
        fullWidth
        {...textFieldProps}
        select
        inputProps={{
          classes: {
            disabled: classes.disabled,
          },
        }}
      >
        <MenuItem disabled value={0}>N / A</MenuItem>
        {statuses.map(status => (
          <MenuItem
            key={status.status_id}
            value={status.status_id}
          >
            {status.status}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default React.memo(StatusSelect);
