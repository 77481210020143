import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  Paper, Typography, Grid, Chip, Avatar, MenuItem, FormControlLabel, Checkbox, Select, InputLabel, FormControl
} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { setProgressBarStatus as SetProgressBarStatus } from '../../redux/actions/progressBar';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import CustomTextField from '../CustomTextField';
import { userUpdateSchema, userCreateSchema } from './userValidation';
import { beautifyErrors, ValidationErrors, transformGraphQlErrorMessage } from '../../utils/helpers';
import { GET_USER, GET_STATIC, UPDATE_USER } from '../../graphql/queries/UserDetails';
import { Builder, Group } from '../../types/User';
import useFormType from '../../hooks/useFormType';

type User = {
  username: string,
  oldUsername: string | null,
  firstname: string,
  lastname: string,
  phone: string,
  email: string,
  notification_email: string,
  groups: Group[]
  pass_word?: '',
  builder?: Builder,
  builder_id: number[],
  send_sms: boolean;
  active: boolean;
  send_email: boolean;
};

const initialUser: User = {
  username: '',
  oldUsername: null,
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  groups: [],
  notification_email: '',
  pass_word: '',
  builder_id: [],
  send_sms: false,
  send_email: false,
  active: false,
};

type Props = {
  setProgressBarStatus: typeof SetProgressBarStatus,
} & RouteComponentProps & { match: { params: { username: string } } };

const UserDetails: React.FC<Props> = ({ history, match, setProgressBarStatus }) => {
  const formType = useFormType(match.params.username);
  const {
    loading,
    error,
    data,
  } = useQuery<{ user: User }>(
    GET_USER,
    {
      variables: { id: match.params.username },
      skip: formType === 'add',
      fetchPolicy: 'network-only',
    },
  );

  const {
    loading: staticLoading,
    data: staticData,
  } = useQuery<{ userGroups: Group[], builders: Builder[] }>(GET_STATIC);

  const [updateUser, { loading: updating }] = useMutation<{ updateUser: User }>(
    UPDATE_USER
  );

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<User>(initialUser);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<User>>({});


  useEffect(() => {
    if (formType === 'edit') {
      setUser(prevUser => ({
        ...prevUser,
        oldUsername: prevUser.username,
      }));
    }
  }, [formType, user.oldUsername]);

  const updateOldUserName = (value: string) => {
    setUser(prevUser => ({
      ...prevUser,
      oldUsername: value,
    }));
  };


  const handleUpdateUser = useCallback((type: 'remove' | 'add', group: Group) => () => {
    if (type === 'add') {
      setUser(prevUser => ({ ...prevUser, groups: prevUser.groups.concat(group) }));
    }
    if (type === 'remove') {
      setUser(prevUser => ({
        ...prevUser,
        groups: prevUser.groups.filter(g => g.GroupID !== group.GroupID),
      }));
    }
  }, [setUser]);


  const userGroups = (staticData && staticData.userGroups) || [];
  const clients = useMemo(() => (staticData && staticData.builders) || [], [staticData]);

  const handleChangeUser = useCallback(
    ({ target: { name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
      const booleanFields = ['send_sms', 'send_email', 'active'];
      if (name) {
        setUser(prevState => ({
          ...prevState,
          [name]: value,
        }));
        setUser(prevUser => ({
          ...prevUser,
          [name]: booleanFields.includes(name) ? checked : value,
        }));
      }
    },
    [],
  );

  const handleChangeBuilder = (event: any) => {
    setUser(prevState => ({
      ...prevState,
      builder_id: event.target.value,
    }))
  }
  useEffect(() => {
    if (data && data.user) {
      setUser(data.user);
    }
  }, [loading, data]);

  useEffect(() => {
    setProgressBarStatus(loading || updating || staticLoading);
  }, [loading, error, setProgressBarStatus, updating, staticLoading]);


  // eslint-disable-next-line consistent-return
  const handleSaveUser = () => {
    if (Boolean(user.pass_word) && user.pass_word !== confirmPassword) {
      return setPasswordError(true);
    }

    try {
      if (formType === 'edit') {
        userUpdateSchema.validateSync(user, { abortEarly: false });
      } else {
        userCreateSchema.validateSync(user, { abortEarly: false });
      }
      setValidationErrors({});
      setPasswordError(false);
      // eslint-disable-next-line @typescript-eslint/camelcase
      const { pass_word, ...restUser } = user;
      // eslint-disable-next-line @typescript-eslint/camelcase
      updateUser({ variables: { user: pass_word ? { ...restUser, pass_word } : restUser } })
        .then(res => {
          enqueueSnackbar('Success', { variant: 'success' });
          if (res?.data?.updateUser) {
            updateOldUserName(res.data.updateUser.username);
          }
        })
        .catch(err => {
          if (err) {
            const errorMessage = transformGraphQlErrorMessage(err.message);
            enqueueSnackbar(errorMessage, { variant: 'error' });
          } else {
            enqueueSnackbar('An error occurred', { variant: 'error' });
          }
        });
    } catch (errors) {
      setValidationErrors(beautifyErrors<User>(errors));
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const onlyMissingGroups = (group: Group) => !user!.groups
    .map(({ GroupID }) => GroupID)
    .includes(group.GroupID);

  return (
    <div>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
            {' GoBack'}
          </CustomButton>
        </Grid>
        <Grid>
          <CustomButton variant="orange" onClick={handleSaveUser}>
            {formType === 'edit' ? 'Save' : 'Add'}
          </CustomButton>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        {user && (
          <>
            <Typography variant="h3" gutterBottom>
              {`${user.firstname} ${user.lastname}`}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  value={user.username}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.username)}
                  helperText={validationErrors.username || ''}
                />
              </Grid>
              {user.groups.find(group => group.GroupID === 6) ? (
                <Grid item xs={12} sm={6} >
                  <FormControl variant="filled" className={classes.clientsSelect} fullWidth>
                    <InputLabel id="demo-mutiple-name-label">Clients</InputLabel>
                    <Select
                      multiple
                      fullWidth
                      required
                      labelId="demo-mutiple-name-label"
                      id="demo-mutiple-name"
                      name="builder_id"
                      value={Array.isArray(user.builder_id) ? user.builder_id : [user.builder_id]}
                      onChange={handleChangeBuilder}
                      error={Boolean(validationErrors.builder_id)}
                    >
                      <MenuItem disabled value={[]}>None</MenuItem>
                      {clients.map(client => (
                        <MenuItem key={client.builder_id} value={client.builder_id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )
                :
                (
                  <Grid item xs={12} sm={6}>
                    <CustomTextField
                      margin="normal"
                      required
                      fullWidth
                      id="builder"
                      label="Client"
                      name="builder_id"
                      value={user.builder_id ?? ''}
                      onChange={handleChangeUser}
                      select
                      error={Boolean(validationErrors.builder_id)}
                      helperText={validationErrors.builder_id ?? ''}
                    >
                      <MenuItem disabled value={0}>None</MenuItem>
                      {clients.map(client => (
                        <MenuItem key={client.builder_id} value={client.builder_id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>

                )
              }

            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstname"
                  value={user.firstname}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.firstname)}
                  helperText={validationErrors.firstname ?? ''}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastname"
                  value={user.lastname}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.lastname)}
                  helperText={validationErrors.lastname ?? ''}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  value={user.phone}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.phone)}
                  helperText={validationErrors.phone ?? ''}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="send_sms"
                      checked={user.send_sms}
                      onChange={handleChangeUser}
                    />
                  )}
                  label="Send SMS"
                  labelPlacement="end"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  value={user.email}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.email)}
                  helperText={validationErrors.email ?? ''}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="send_email"
                      checked={user.send_email}
                      onChange={handleChangeUser}
                    />
                  )}
                  label="Send Email"
                  labelPlacement="end"
                />
                {user.send_email && (
                  <CustomTextField
                    margin="normal"
                    required
                    fullWidth
                    id="notification_email"
                    label="Notification email"
                    name="notification_email"
                    value={user.notification_email || user.email}
                    onChange={handleChangeUser}
                    error={Boolean(validationErrors.notification_email)}
                    helperText={validationErrors.notification_email ?? ''}
                  />)}
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  name="pass_word"
                  type="password"
                  value={user.pass_word ?? ''}
                  onChange={handleChangeUser}
                  error={Boolean(validationErrors.pass_word) || passwordError}
                  helperText={validationErrors.pass_word ?? ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  value={confirmPassword}
                  onChange={({ target: { value } }) => setConfirmPassword(value)}
                  error={passwordError}
                  helperText={passwordError ? 'Passwords does not match' : ''}
                />
              </Grid>
            </Grid>
            {/* <Divider /> */}
            <Grid container>
              <Grid item>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="secondary"
                      name="active"
                      checked={user.active}
                      onChange={handleChangeUser}
                    />
                  )}
                  label="Active"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
            <Grid container justify="space-around">
              <Grid item sm={2}>
                <Typography gutterBottom variant="h5">User Groups:</Typography>
                {user.groups.map(group => (
                  <Chip
                    className={classes.chip}
                    key={group.GroupID}
                    onClick={handleUpdateUser('remove', group)}
                    label={group.Label}
                    avatar={<Avatar className={classes.avatar}>-</Avatar>}
                  />
                ))}
              </Grid>
              <Grid item sm={2}>
                <Typography gutterBottom variant="h5">All Groups:</Typography>
                {userGroups
                  .filter(onlyMissingGroups)
                  .map(group => (
                    <Chip
                      key={group.GroupID}
                      onClick={handleUpdateUser('add', group)}
                      label={group.Label}
                      className={classes.chip}
                      avatar={<Avatar className={classes.avatar}>+</Avatar>}
                    />
                  ))}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
};

export default React.memo(UserDetails);
