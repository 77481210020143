import {
  Checkbox,
  Grid,
  List,
  ListItem,
  Paper,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import { ArrowLeft } from '@material-ui/icons';
import CustomTextField from '../CustomTextField';
import CustomButton from '../CustomButton';
import useStyles from './styles';
import { GET_STATIC } from '../../graphql/queries/UserDetails';
import { Builder } from '../../types/User';
import {
  CREATE_ENTERPRISE,
  GET_ENTERPRISE,
  UPDATE_ENTERPRISE,
} from '../../graphql/queries/Enterprises';
import {
  ValidationErrors,
  beautifyErrors,
  transformGraphQlErrorMessage,
} from '../../utils/helpers';
import { Enterprise } from '../../types/Enterprise';
import { setProgressBarStatus } from '../../redux/actions/progressBar';
import useFormType from '../../hooks/useFormType';
import { enterpriseSchema } from './enterpriseValidation';

interface EnterpriseForm {
  name: string;
  clients: Builder[];
}

type Props = {
  setProgressBarStatus: typeof setProgressBarStatus;
} & RouteComponentProps & { match: { params: { enterprise_id: string } } };

const EnterpriseDetails: React.FC<Props> = ({
  history,
  match,
}) => {
  const formType = useFormType(match.params.enterprise_id);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data } = useQuery<{ enterprise: Enterprise }>(
    GET_ENTERPRISE,
    {
      variables: { id: match.params.enterprise_id },
      skip: formType === 'add',
      fetchPolicy: 'network-only',
    },
  );

  const { data: staticData } = useQuery<{
    builders: Builder[];
  }>(GET_STATIC);

  const [updateEnterprise] = useMutation<{
    updateEnterprise: EnterpriseForm;
  }>(UPDATE_ENTERPRISE);
  const [createEnterprise] = useMutation<{
    createEnterprise: EnterpriseForm;
  }>(CREATE_ENTERPRISE);

  const [enterpriseForm, setEnterpriseForm] = useState<EnterpriseForm>({
    name: '',
    clients: [],
  });
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<EnterpriseForm>
  >({});

  useEffect(() => {
    if (data && data.enterprise) {
      const builders = data.enterprise.builderEnterprise?.map(builder => ({
        builder_id: builder.builder_id,
        name: builder?.builder?.name || '',
      }));
      const enterprise = {
        name: data.enterprise.name,
        clients: builders ?? [],
      };

      setEnterpriseForm(enterprise);
    }
  }, [loading, data]);

  const clients = useMemo(() => (staticData && staticData.builders) || [], [
    staticData,
  ]);

  const handleEnterpriseFormChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = e.target;
    setEnterpriseForm(prevState => ({ ...prevState, name: value }));
  };

  const handleSaveEnterprise = () => {
    try {
      enterpriseSchema.validateSync(enterpriseForm, {
        abortEarly: false,
      });
      setValidationErrors({});

      const clientsIds = enterpriseForm.clients.map(
        builder => builder.builder_id,
      );
      if (formType === 'edit') {
        updateEnterprise({
          variables: {
            enterprise: {
              ...enterpriseForm,
              id: Number(match.params.enterprise_id),
              clients: clientsIds,
            },
          },
        })
          .then(res => {
            enqueueSnackbar('Success', { variant: 'success' });
          })
          .catch(err => {
            if (err) {
              const errorMessage = transformGraphQlErrorMessage(err.message);
              enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
              enqueueSnackbar('An error occurred', { variant: 'error' });
            }
          });
      } else {
        createEnterprise({
          variables: { enterprise: { ...enterpriseForm, clients: clientsIds } },
        })
          .then(res => {
            enqueueSnackbar('Success', { variant: 'success' });
          })
          .catch(err => {
            if (err) {
              const errorMessage = transformGraphQlErrorMessage(err.message);
              enqueueSnackbar(errorMessage, { variant: 'error' });
            } else {
              enqueueSnackbar('An error occurred', { variant: 'error' });
            }
          });
      }
    } catch (errors) {
      setValidationErrors(beautifyErrors<Enterprise>(errors));
    }
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <CustomButton variant="white" onClick={() => history.goBack()}>
            <ArrowLeft />
            {' GoBack'}
          </CustomButton>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              required
              fullWidth
              margin="normal"
              id="name"
              label="Enterprise Name"
              onChange={handleEnterpriseFormChange}
              name="name"
              value={enterpriseForm.name}
              type="text"
              error={Boolean(validationErrors.name)}
              helperText={validationErrors.name || ''}
            />
          </Grid>

          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={clients}
              disableCloseOnSelect
              disableClearable
              getOptionLabel={client => client.name}
              getOptionSelected={(option: any, value) => value.name === option.name
              }
              value={enterpriseForm.clients}
              renderTags={() => null}
              noOptionsText="No record exist!"
              onChange={(e, builders) => {
                if (enterpriseForm.clients.length > builders.length) {
                  const agentToDelete = enterpriseForm.clients.find(
                    deletedClient => !builders.some(
                      client => deletedClient.name === client.name,
                    ),
                  );

                  if (agentToDelete) {
                    setEnterpriseForm(prevState => ({
                      ...prevState,
                      clients: prevState.clients.filter(
                        ({ name }) => name !== agentToDelete.name,
                      ),
                    }));
                  }
                }

                if (enterpriseForm.clients.length < builders.length) {
                  const clientToAdd = builders.find(
                    deletedClient => !enterpriseForm.clients.some(
                      client => deletedClient.name === client.name,
                    ),
                  );

                  if (clientToAdd) {
                    setEnterpriseForm(prevState => ({
                      ...prevState,
                      clients: [...prevState.clients, clientToAdd],
                    }));
                  }
                }
              }}
              renderOption={(client, { selected }) => (
                <>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} />
                  <>
                    <span>{`${client.name}`}</span>
                  </>
                </>
              )}
              renderInput={params => (
                <CustomTextField
                  {...params}
                  margin="normal"
                  style={{ width: '100%' }}
                  label="Search/Add Client*"
                  placeholder="Search client"
                  error={Boolean(validationErrors.clients)}
                  helperText={validationErrors.clients}
                />
              )}
            />
          </Grid>
        </Grid>
        <div
          className={`${classes.clientsContainer} ${clients.length < 1
            && classes.hidden}`}
        >
          <p className={classes.label}>Selected Clients</p>
          <List className={classes.clientsList}>
            <Grid container>
              {enterpriseForm.clients.map(client => (
                <Grid item style={{ width: '152px' }} key={client.name}>
                  <ListItem
                    className={classes.clientsItem}
                    onClick={() => {
                      setEnterpriseForm(prevState => ({
                        ...prevState,
                        clients: prevState.clients.filter(
                          ({ name }) => name !== client.name,
                        ),
                      }));
                    }}
                  >
                    <>
                      <span>{`${client.name}`}</span>
                    </>
                    <ClearSharpIcon
                      style={{
                        width: 18,
                        height: 18,
                        marginLeft: 'auto',
                      }}
                    />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          </List>
        </div>
        <Grid container justify="center" alignItems="center">
          <CustomButton variant="orange" onClick={handleSaveEnterprise}>
            Submit
          </CustomButton>
        </Grid>
      </Paper>
    </>
  );
};

export default EnterpriseDetails;
