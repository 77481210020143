import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { MimeType } from '../redux/types/mime';

export const loadCustomers = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/customers${query}`);
};

export const loadCustomerById = (id: number) => axios.get(`/customers/${id}`);

export const loadCustomersExport = (
  selection: Array<number | string>,
  mimeType: MimeType,
) => {
  const headers = {
    Accept: mimeType,
  };

  return axios.post(
    '/customers/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};
