export const CUSTOMER_DETAILS_REQUEST = 'CUSTOMER_DETAILS_REQUEST';
export const CUSTOMER_DETAILS_REQUEST_SUCCESS = 'CUSTOMER_DETAILS_REQUEST_SUCCESS';
export const CUSTOMER_DETAILS_REQUEST_FAILED = 'CUSTOMER_DETAILS_REQUEST_FAILED';
export const CUSTOMER_SELECTED_CLEAR = 'CUSTOMER_SELECTED_CLEAR';
export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST';
export const CUSTOMERS_REQUEST_SUCCESS = 'CUSTOMERS_REQUEST_SUCCESS';
export const CUSTOMERS_REQUEST_FAILED = 'CUSTOMERS_REQUEST_FAILED';
export const CUSTOMERS_EXPORT_REQUEST = 'CUSTOMERS_EXPORT_REQUEST';
export const CUSTOMERS_EXPORT_REQUEST_SUCCESS = 'CUSTOMERS_EXPORT_REQUEST_SUCCESS';
export const CUSTOMERS_EXPORT_REQUEST_FAILED = 'CUSTOMERS_EXPORT_REQUEST_FAILED';
export const CUSTOMERS_EXPORT_AND_PRINT_REQUEST = 'CUSTOMERS_EXPORT_AND_PRINT_REQUEST';

export type ErrorMessage = {
  message: string;
};

export type CustomerVisitedProperties = {
  property_id: number;
  count: number
}

export type Customer = {
  customer_id: number;
  firstname: string;
  lastname: string;
  email_1?: string;
  visitor_status_id: string;
  visitor_type_id: string;
  cell_phone_1: string;
  created_on: string;
  modified_on: string;
  visited_properties: CustomerVisitedProperties[];
  platform: string;
  _id: number;
  visit_counter: number;
  last_use: string;
  first_use: string;
};

export type CustomerVisits = {
  property_id: number;
  remarks: string;
  modified_on: string;
  agent_username: string;
  lot_num: string;
  house_num: string;
  address_1: string;
  city: string;
  state: string;
  customer_visit_id: number;
};

export type CustomerInfo = {
  customer_id: number;
  firstname: string;
  lastname: string;
  email_1: string;
  visitor_status_id: string;
  visitor_type_id: string;
  cell_phone_1: string;
  street_address: string;
  city: string;
  state: string;
  zipcode: string;
};

export type CustomerDetails = {
  info: CustomerInfo;
  visits: Array<CustomerVisits>;
};

export type CustomerDetailsRequestSuccess = CustomerDetails;
export type CustomersRequestSuccess = {
  items: Array<Customer>;
  total: number;
};

export type CustomersState = {
  items: Array<Customer>;
  total: number;
  loading: boolean;
  error: ErrorMessage['message'];
  selected: CustomerDetails | null;
};
