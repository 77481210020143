import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles';

type Props = {
  value: string,
};

export const warningStatuses = [
  'Non-Accessible',
  'Awaiting Activation',
  'Sold',
  'Removed',
  'Created',
  'Staff Access',
  'Smart Lock Maintenance',
];

const Status: React.FC<Props> = props => {
  const { value } = props;
  const statusColor: string = value.toLowerCase();
  let lockStatus: string;
  if (styles[statusColor]) {
    lockStatus = statusColor;
  } else {
    lockStatus = 'other';
  }

  const blink = warningStatuses.includes(value);

  return (
    <Box component="span" color={styles[lockStatus].color} style={blink ? styles.blink : {}}>
      {value}
    </Box>
  );
};

export default Status;
