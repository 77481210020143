import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  controlButton: {
    marginBottom: '25px',
  },
  tableHeaderText: {
    paddingTop: '20px',
    marginLeft: '23px',
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 30,
    paddingBottom: 15,
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
