import React, {
  FC, useState, useEffect, memo,
} from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { UPDATE_AUTOMATIONS } from '../../../graphql/queries/PropertyDetails';

type AutomationTabProps = {
  isRental: boolean;
  propertyId: number;
  tempBackupCode?: string | null;
  nternowHubBackupCode?: string | null;
  propertyBackupCode?: string | null;
  propertyTradeCode?: string | null;
  propertySalesCode?: string | null;
  propertyResidentCode?: string | null;
  handleAutomationsChange: (automation: { [key: string]: string }) => void;
};

const AutomationTab: FC<AutomationTabProps> = ({
  isRental,
  propertyId,
  tempBackupCode,
  nternowHubBackupCode,
  propertyBackupCode,
  propertyTradeCode,
  propertySalesCode,
  propertyResidentCode,
  handleAutomationsChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [nternowCode, setNternowCode] = useState('');
  const [propertyCode, setPropertyCode] = useState('');
  const [tradeCode, setTradeCode] = useState('');
  const [salesCode, setSalesCode] = useState('');
  const [residentCode, setResidentCode] = useState('');

  const [updateAutomations] = useMutation(UPDATE_AUTOMATIONS);

  useEffect(() => {
    setNternowCode(nternowHubBackupCode ?? '');
    setPropertyCode(propertyBackupCode ?? '');
    setTradeCode(propertyTradeCode ?? '');
    setSalesCode(propertySalesCode ?? '');
    setResidentCode(propertyResidentCode ?? '');
  }, [
    nternowHubBackupCode,
    propertyBackupCode,
    propertyTradeCode,
    propertySalesCode,
    propertyResidentCode,
  ]);

  const handleAutomationsSave = async () => {
    try {
      await updateAutomations({
        variables: {
          propertyId,
          nternowHubBackupCode: nternowCode,
          propertyBackupCode: propertyCode,
          propertyTradeCode: tradeCode,
          propertySalesCode: salesCode,
        },
      });
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('An error occurred', { variant: 'error' });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    handleAutomationsChange({ [name]: value });

    if (name === 'nternow_hub_backup_code') {
      setNternowCode(value);
      return;
    }

    if (name === 'property_backup_code') {
      setPropertyCode(value);
    }
  };

  return (
    <>
      <Grid container spacing={4}>
      <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            margin="normal"
            id="temp_backup_code"
            name="temp_backup_code"
            label="Temp Backup Code"
            value={tempBackupCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            id="nternow_hub_backup_code"
            name="nternow_hub_backup_code"
            label="NterNow Private Backup Code"
            value={nternowCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            id="property_backup_code"
            name="property_backup_code"
            label="Property Master Code"
            value={propertyCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            id="trade_code"
            name="trade_code"
            label="Trade Code"
            value={tradeCode}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            id="sales_code"
            name="sales_code"
            label="Sales Code"
            value={salesCode}
            onChange={handleChange}
          />
        </Grid>
        {isRental && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              margin="normal"
              id="resident_code"
              name="resident_code"
              label="Resident Code"
              value={residentCode}
              onChange={handleChange}
            />
          </Grid>
        )}
        
      </Grid>
      <Grid item sm={3} md={3} lg={3}>
        <Button
          type="submit"
          variant="contained"
          onClick={handleAutomationsSave}
          style={{ marginTop: 20 }}
        >
          Save
        </Button>
      </Grid>
    </>
  );
};

export default memo(AutomationTab);
