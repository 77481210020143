export const LOCKS_REQUEST = 'LOCKS_REQUEST';
export const LOCKS_REQUEST_SUCCESS = 'LOCKS_REQUEST_SUCCESS';
export const LOCKS_REQUEST_FAILED = 'LOCKS_REQUEST_FAILED';
export const LOCK_DETAILS_REQUEST = 'LOCK_DETAILS_REQUEST';
export const LOCK_DETAILS_REQUEST_SUCCESS = 'LOCK_DETAILS_REQUEST_SUCCESS';
export const LOCK_DETAILS_REQUEST_FAILED = 'LOCK_DETAILS_REQUEST_FAILED';

export type ErrorMessage = {
  message: string;
};

export type Lock = {
  lock_status_value: string;
  serial_number: string;
  battery_change: string | null;
  purchase: string;
  comments: string;
  lock_status: number;
  additional_info_1: string;
  additional_info_2: string;
  version: string;
  licensee: string;
  manufacturer: string;
  license_start_date: string | null;
  email_1: string;
  created_on: string;
  modified_on: string;
};

export type LocksRequestSuccess = Array<Lock>;
export type LockDetailsRequestSuccess = Lock;

export type LocksState = {
  items: Array<Lock>;
  loading: boolean;
  error: ErrorMessage['message'];
  selected: Lock | null;
};
