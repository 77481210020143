import React, { useRef } from 'react';
import MatTable from '@material-ui/core/Table';
import { TableBody, Typography, TableContainer } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import Community from '../../../types/Community';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import TableHead from './TableHead';
import { Order, isSelected } from '../../../utils/table';
import { convertDateWithTimezone, convertDateToTimeWithTimezone } from '../../../utils/helpers';

import styles from './styles';


type Props = {
  selected: Array<Community['id']>;
  communities: Array<Community>;
  order: Order;
  orderBy: keyof Community | string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: Community['id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: keyof Community) => void;
  onRowClick: (customerId: number) => void;
} & WithStyles<typeof styles>;

const CommunitiesTable : React.FC<Props> = props => {
  const {
    selected,
    communities,
    classes,
    order,
    orderBy,
    onSelect,
    onRowClick,
    onSelectAll,
    onChangeOrder,
  } = props;

  const handleRequestSort = (
    event: React.SyntheticEvent,
    property: keyof Community,
  ) => {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  };

  const tableContainer=useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
     <CustomTableScroll getTableContainer={()=>tableContainer} columns={10}/>
    <MatTable className={classes.tableRoot}>
      <TableHead
        numSelected={selected.length}
        order={order}
        orderBy={orderBy}
        rowCount={communities.length}
        onRequestSort={handleRequestSort}
        onSelectAllClick={onSelectAll}
      />
      <TableBody>
        {communities.map((community, index) => {
          const isItemSelected: boolean = isSelected(
            community.id,
            selected,
          );
          const labelId: string = `enhanced-table-checkbox-${index}`;
          const modifiedOn = community.modified_on ? new Date(community.modified_on) : new Date(community?.created_on ?? '');
          const modificationDate = convertDateWithTimezone(new Date(modifiedOn));
          const modificationTime = convertDateToTimeWithTimezone(new Date(modifiedOn));

          return (
            <TableRow
              hover
              role="checkbox"
              classes={{ root: classes.rowRoot }}
              key={community.id}
              tabIndex={-1}
              aria-checked={isItemSelected}
              selected={isItemSelected}
              onClick={() => onRowClick(community.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={event => {
                    event.stopPropagation();
                    onSelect(event, community.id);
                  }}
                />
              </TableCell>
              <TableCell align="center" id={labelId}>{community.name}</TableCell>
              <TableCell align="center">{community.city}</TableCell>
              <TableCell align="center">{community.state}</TableCell>
              <TableCell align="center">{community.zip}</TableCell>
              <TableCell align="center">{community.external_community_id}</TableCell>
              <TableCell align="center">
                {community.created_on && new Date(community.created_on).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                {' '}
              </TableCell>
              <TableCell align="center">{community.created_by }</TableCell>
              <TableCell align="center">
                <Typography className={classes.date}>{modificationDate}</Typography>
                {modificationTime && <Typography className={classes.date}>{modificationTime}</Typography>}
              </TableCell>
              <TableCell align="center">{community?.builder?.name}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(CommunitiesTable);
