import axios from './axios';
import { Query, buildQuery } from '../utils/query';

export const loadAllVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/stats/allVisits${query}`);
};

export const loadAllProperties = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/stats/allProperties${query}`);
};

export const loadYearlyVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/stats/yearlyVisits${query}`);
};

export const loadMonthlyVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/stats/monthlyVisits${query}`);
};

export const loadWeeklyVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/stats/weeklyVisits${query}`);
};
