import React, { useRef } from 'react';
import MatTable from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Checkbox from '../../../components/CustomCheckbox';
import CustomTableScroll from '../../../components/CustomTableScroll';
import TableHead from './TableHead';
import styles from './TroubleTicketsTableStyles';
import { Order, isSelected } from '../../../utils/table';
import TroubleTicket from '../../../types/TroubleTickets';
import Status from '../../../components/LockStatus';
import { formatDate } from '../../../utils/date';


type Props = {
  troubleTickets: Array<TroubleTicket>;
  order: Order;
  orderBy: string;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: TroubleTicket['ticket_number']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: string) => void;
  selected: Array<TroubleTicket['ticket_number']>;
  onRowClick: (ticketNumber: number) => void;
} & WithStyles<typeof styles>;

const TroubleTicketsTable: React.FC<Props> = props => {
  const {
    order,
    troubleTickets,
    orderBy,
    onSelect,
    onRowClick,
    onSelectAll,
    onChangeOrder,
    selected,
    classes,
  } = props;

  function handleRequestSort(
    event: React.SyntheticEvent,
    property: string,
  ) {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  }

  const tableContainer = useRef<HTMLDivElement>(null);

  return (
    <TableContainer ref={tableContainer} className={classes.tableContainer}>
      <CustomTableScroll getTableContainer={() => tableContainer} columns={10} />
      <MatTable className={classes.tableRoot}>
        <TableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          rowCount={troubleTickets.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {troubleTickets.map((troubleTicket, index) => {
            const isItemSelected: boolean = isSelected(
              troubleTicket.ticket_number,
              selected,
            );
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const lockSerial: string = troubleTicket.lock_serial_number || troubleTicket.property?.lock_serial_number;
            const hubSerial = troubleTicket.property?.hub?.serial_number;

            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={troubleTicket.ticket_number}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={() => onRowClick(troubleTicket.ticket_number)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={event => {
                      event.stopPropagation();
                      onSelect(event, troubleTicket.ticket_number);
                    }}
                  />
                </TableCell>
                <TableCell align="justify" id={labelId}>{troubleTicket.ticket_number}</TableCell>
                <TableCell align="justify">
                  {troubleTicket.property_id ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/properties/${troubleTicket.property_id}`}>{troubleTicket.property_id}</Link>
                    : '' }
                </TableCell>
                <TableCell align="justify">
                  {lockSerial ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/inventory/${lockSerial}`}>{lockSerial}</Link>
                    : '' }
                </TableCell>
                <TableCell align="justify">
                  {hubSerial ? <Link className={classes.link} onClick={e => e.stopPropagation()} to={`/devices/${hubSerial}`}>{hubSerial}</Link>
                    : '' }
                </TableCell>
                <TableCell align="justify">{troubleTicket.customer_firstname}</TableCell>
                <TableCell align="justify">{troubleTicket.customer_lastname}</TableCell>
                <TableCell align="justify">{troubleTicket.cell_phone_1}</TableCell>
                <TableCell align="justify">{troubleTicket.client}</TableCell>
                <TableCell align="justify">{formatDate(troubleTicket.open_date)}</TableCell>
                <TableCell align="justify">
                  <Status value={troubleTicket?.property?.lock?.lockStatus ?? ''} />
                </TableCell>
                <TableCell align="justify">{troubleTicket.issue_diagnosis}</TableCell>
                <TableCell align="justify">{troubleTicket.resolution_notes}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </TableContainer>
  );
};

export default withStyles(styles)(TroubleTicketsTable);
