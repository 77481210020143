import { gql } from 'apollo-boost';

export const GET_SHIPMENTS = gql`
  query($serial_number: String!) {
    shipmentsByLockSerialNumber(serial_number: $serial_number) {
      id,
      cost,
      qty,
      carrier,
      date,
      tracking_number,
      builder_id,
      username,
      replacement_lock_number,
      requested_by,
      status,
    }
  }
`;

export const GET_LOCK = gql`
  query($serial_number: String!) {
    lockBySerialNumber(serial_number: $serial_number) {
      version,
      serial_number,
      lockStatusId,
      secret_id,
      manufacturer,
      licensee,
      purchase,
      additional_info_1,
      license_start_date,
      comments,
      email_1,
      email_2,
      shipmentStatus,
      battery_change,
      created_on,
      created_by,
      modified_on,
      modified_by,
      builder_id,
      year,
      batch,
      hardware_version,
      software_version,
      initial_code,
      lock_type_id,
    }
  }
`;

export const VERIFY_IGLOO_LOCKS = gql`
  query($serial_number: String!) {
    verifyIglooLock(serial_number: $serial_number) {
      createdAt
      name
      lockCodes {
        id
        code
        createdAt
        startDate
        endDate
        durationCode
        description
      }
    }
  }
`;

export const GET_LOCK_TYPES = gql`
  query {
    allLockTypes {
      id,
      lock_type
    }
  }
`;

export const UPDATE_LOCK = gql`
  mutation updateLock($lock: UpdateLock!) {
    updateLock(lock: $lock) {
      serial_number
    }
  }
`;

export const UPDATE_SHIPMENTS = gql`
  mutation updateShipments($shipments: [UpdateShipment!]!, $lock_serial_number: String!) {
    updateShipments(shipments: $shipments, lock_serial_number: $lock_serial_number)
  }
`;

export const REMOVE_SHIPMENT = gql(`
     mutation deleteShipment($shipment_id: Int!, $lock_serial_number: String!) {
        deleteShipment(shipment_id: $shipment_id, lock_serial_number: $lock_serial_number) {
          id,
          cost,
          qty,
          carrier,
          date,
          status,
          tracking_number,
          builder_id,
          username,
          replacement_lock_number,
          requested_by,
          }
        }
    `);
export const SEARCH_LOCKS = gql`
  query($sn: String!) {
    locks: searchLocks (serial_number: $sn) {
      serial_number,
      lockStatusId,
    }
  }
`;

export const SEARCH_SHIPMENTS_LOCKS = gql`
  query($sn: String!) {
    availableLocks (serial_number: $sn) {
      serial_number,
      lockStatusId,
    }
  }
`;
