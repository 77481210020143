import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import { Link, RouteComponentProps, useParams } from 'react-router-dom';
import { Grid, Paper } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { useMutation, useQuery } from '@apollo/react-hooks';

import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { transformGraphQlErrorMessage } from '../../utils/helpers';
import CustomButton from '../../components/CustomButton';
import CustomTextField from '../../components/CustomTextField';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import {
  HUB_ACCOUNT_BY_ID,
  UPDATE_HUB_ACCOUNT,
} from '../../graphql/queries/HubAccounts';
import { InputHubAccount } from '../../types/inputTypes/HubAccount';
import { goToZwaveTool } from '../../services/zwave-tool';

type Props = {
  loading?: boolean;
} & RouteComponentProps;

const HubAccountForm: React.FC<Props> = props => {
  const classes = useStyles();
  const [hubAccount, setHubAccount] = useState<InputHubAccount>(
    new InputHubAccount(),
  );

  const { history } = props;
  const { id: hubAccountId } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const handleHubAccountChange = useCallback(
    ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (name) {
        setHubAccount((prevHubAccount: InputHubAccount) => ({
          ...prevHubAccount,
          [name]: value,
        }));
      }
    },
    [],
  );

  const { data } = useQuery<{ getHubAccountById: InputHubAccount }>(
    HUB_ACCOUNT_BY_ID,
    {
      variables: { id: hubAccountId },
      fetchPolicy: 'network-only',
      skip: !hubAccountId,
    },
  );

  const [updateHubAccount] = useMutation<{ updateHubAccount: InputHubAccount }>(
    UPDATE_HUB_ACCOUNT,
    { errorPolicy: 'all' },
  );

  const handleHubAccountSave = () => {
    try {
      updateHubAccount({
        variables: {
          hubAccount,
        },
      })
        .then(() => history.goBack())
        .catch(err => {
          enqueueSnackbar(transformGraphQlErrorMessage(err.message), {
            variant: 'error',
          });
        });
    } catch (errors) {
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data) {
      setHubAccount(data.getHubAccountById);
    }
  }, [data]);

  const handleControlHubs = () => {
    goToZwaveTool(hubAccountId);
  }

  return (
    <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/accounts"
          >
            <ArrowLeftIcon />
            Hub Accounts
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  name="username"
                  label="Username"
                  value={hubAccount.username}
                  onChange={handleHubAccountChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  name="email"
                  label="Email"
                  value={hubAccount.email}
                  onChange={handleHubAccountChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  name="hashpw"
                  label="Password"
                  value={hubAccount.hashpw}
                  onChange={handleHubAccountChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  fullWidth
                  name="adc_group_id"
                  label="ADC Group Id"
                  value={hubAccount.adc_group_id}
                  onChange={handleHubAccountChange}
                />
              </Grid>
              <Grid
                container
                spacing={4}
                justify="center"
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <Grid item>
                  <CustomButton variant="orange" onClick={handleHubAccountSave}>
                    {hubAccountId ? 'Save' : 'Add'}
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton variant="orange" onClick={() => handleControlHubs()}>
                    <DeviceHubIcon /> Control Hubs
                  </CustomButton>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(HubAccountForm);
