import { getAuthToken } from './auth';

export const goToZwaveTool = (hubAccountId: string, hubId?: string) => {
    const token = getAuthToken();
    let url = `${process.env.REACT_APP_ZWAVE_TOOL_URL}/#/login?token=${token}&hubAccountId=${hubAccountId}`;
    if (hubId) {
        url = url + `&hubId=${hubId}`
    }
    window.open(url);
}
