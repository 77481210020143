import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem, TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
  CommandList, CommandModel, InputType, InputParams,
} from './constants';
import CustomButton from '../../components/CustomButton';
import useStyles from './styles';
import RunZWaveScriptComponent from './Components/RunZWaveScriptComponent';
import RunZigbeeScriptComponent from './Components/RunZigbeeScriptComponent';
import Run2GIGScriptComponent from './Components/Run2GIGScriptComponent';
import ListTextInput from './Components/ListTextInput';
import MixTypeInput from './Components/MixTypeInput';
import { buildMessage } from './helper';

const InputBuilder: any = {
  [InputType.Text]: (item: InputParams, register: any) => (
    <TextField
      margin="normal"
      fullWidth
      inputRef={input => register(input, { required: item.required })}
      required={item.required}
      label={item.name}
      key={item.name}
      name={item.name}
    />
  ),
  [InputType.ListText]: (item: InputParams, register: any) => (
    <ListTextInput key={item.name} register={register} name={item.name} label={item.name} />
  ),
  [InputType.MixType]: (item: InputParams, register: any) => (
    <MixTypeInput key={item.name} register={register} name={item.name} label={item.name} />
  ),
  [InputType.Textarea]: (item: InputParams, register: any) => (
    <TextField
      margin="normal"
      fullWidth
      multiline
      rows={5}
      inputRef={input => register(input, { required: item.required })}
      required={item.required}
      label={item.name}
      key={item.name}
      name={item.name}
    />
  ),
  [InputType.Number]: (item: InputParams, register: any) => (
    <TextField
      margin="normal"
      fullWidth
      key={item.name}
      type="number"
      inputRef={input => register(input, { required: item.required, valueAsNumber: true })}
      required={item.required}
      label={item.name}
      name={item.name}
    />
  ),
  [InputType.Checkbox]: (item: InputParams, register: any) => (
    <FormControlLabel
      control={(
        <Checkbox
          inputRef={input => register(input, { required: item.required })}
          color="secondary"
          name={item.name}
          defaultChecked={!!item.default}
        />
      )}
      key={item.name}
      label={item.name}
    />
  ),
  [InputType.CustomRunZWave]: (item: InputParams, register: any) => (
    <RunZWaveScriptComponent key={item.name} register={register} />
  ),
  [InputType.CustomRunZigbee]: (item: InputParams, register: any) => (
    <RunZigbeeScriptComponent key={item.name} register={register} />
  ),
  [InputType.CustomRun2GIG]: (item: InputParams, register: any) => (
    <Run2GIGScriptComponent key={item.name} register={register} />
  ),
};

type Props = {
  onSubmit: (data: object) => void;
};

const EzloCommandComponent : React.FC<Props> = props => {
  const {
    register, watch, handleSubmit, control, formState: { errors },
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [commandMethod, setCommandMethod] = useState<CommandModel>();
  const classes = useStyles();

  const method = watch('method');

  useEffect(() => {
    const item = CommandList.find(c => c.value === method);
    setCommandMethod(item);
  }, [method]);

  const onSubmit = (data: any) => {
    const { method: m, ...params } = data;
    if (m === 'custom') {
      try {
        const payload = JSON.parse(params.payload);
        props.onSubmit(payload);
      } catch (e) {
        enqueueSnackbar('Invalid format. Should be in json format', { variant: 'error' });
      }
      return;
    }
    props.onSubmit(buildMessage(commandMethod?.method || m, params));
  };

  return (
    <>
      <Grid item xs={12} sm={11}>
        <div className={classes.spacer} />
        <h2>Commands</h2>
        <p>
          Please select a command above
          and enter the required params&apos; values if prompted
        </p>
      </Grid>
      <Grid item xs={12} sm={11}>
        <Controller
          render={params => (
            <TextField
              {...params}
              margin="normal"
              fullWidth
              select
              error={!!errors.method}
              label="Method"
              name="method"
            >
              {CommandList.map(item => (
                <MenuItem
                  disabled={item.group}
                  className={item.group ? classes.group : classes.item}
                  key={item.value}
                  value={item.value}
                >
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          defaultValue=""
          name="method"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={11}>
        {
          commandMethod
          && commandMethod.params
          && commandMethod.params.length > 0 && <p>Params: </p>}
        {commandMethod?.params?.map(item => {
          const buildComponent = InputBuilder[item.type];
          return (
            buildComponent && buildComponent(item, register)
          );
        })}
      </Grid>
      {commandMethod
          && (
            <Grid container justify="center" alignItems="center">
              <CustomButton
                variant="orange"
                type="submit"
                onClick={handleSubmit(onSubmit)}
              >
                Send
              </CustomButton>
            </Grid>
          )}
    </>
  );
};

export default EzloCommandComponent;
