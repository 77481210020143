import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  PROPERTIES_REQUEST,
  PROPERTIES_EXPORT_REQUEST,
  PROPERTIES_EXPORT_AND_PRINT_REQUEST,
  PROPERTY_DETAILS_REQUEST,
} from '../types/properties';

import { exportToFile } from '../../services/export';
import { openPrintDialog } from '../../services/print';
import {
  loadProperties,
  loadPropertyById,
  loadPropertiesExport,
} from '../../services/properties';
import {
  propertiesRequestSuccess,
  propertiesRequestFailed,
  propertiesExportSuccess,
  propertiesExportRequestFailed,
  PropertiesRequestActionType,
  PropertiesExportRequestActionType,
  propertyDetailsRequestSuccess,
  propertyDetailsRequestFailed,
  PropertyDetailsRequestActionType,
} from '../actions/properties';

function* loadPropertiesSaga(
  action: PropertiesRequestActionType,
) {
  try {
    const { data } = yield call(loadProperties, action.payload);
    yield put(propertiesRequestSuccess(data));
  } catch (err) {
    yield put(propertiesRequestFailed(err));
  }
}

function* loadPropertyDetailsSaga(
  action: PropertyDetailsRequestActionType,
) {
  try {
    const { data } = yield call(loadPropertyById, action.payload);
    yield put(propertyDetailsRequestSuccess(data));
  } catch (err) {
    yield put(propertyDetailsRequestFailed(err));
  }
}

function* loadPropertiesExportSaga(
  action: PropertiesExportRequestActionType,
) {
  try {
    const { selection, mimeType } = action.payload;
    const { data } = yield call(loadPropertiesExport, selection, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === PROPERTIES_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'properties-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(propertiesExportSuccess());
  } catch (err) {
    yield put(propertiesExportRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(PROPERTIES_REQUEST, loadPropertiesSaga),
  takeLatest<any>(PROPERTY_DETAILS_REQUEST, loadPropertyDetailsSaga),
  takeLatest<any>(
    [PROPERTIES_EXPORT_REQUEST, PROPERTIES_EXPORT_AND_PRINT_REQUEST],
    loadPropertiesExportSaga,
  ),
]);
