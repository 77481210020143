import { createStyles } from '@material-ui/core';

export default () => createStyles({
  communitySelect: {
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});
