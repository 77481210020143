import React from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { TableCellProps } from '@material-ui/core/TableCell';
import useStyles from './styles';
import { generateLabel } from '../../utils/helpers';

type Props<T extends string> = {
  value: T;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  label?: string;
  onSortClick?: (sort: T) => void;
} & TableCellProps;

const TableHeadCell = <T extends string>({
  value, sortBy, sortDirection, label, onSortClick, ...restProps
}: Props<T>) => {
  const text = label || generateLabel(value);
  const handleSort = () => (onSortClick ? onSortClick(value) : undefined);
  const classes = useStyles();
  return (
    <TableCell className={classes.pointer} onClick={handleSort} {...restProps}>
      {text}
      <TableSortLabel
        active={sortBy ? sortBy === value : undefined}
        direction={sortDirection}
        IconComponent={KeyboardArrowDown}
      />
    </TableCell>
  );
};

export default TableHeadCell;
