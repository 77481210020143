import React from 'react';
import {
  Paper, Typography, Grid,
} from '@material-ui/core';
import useStyles from '../CustomerDetails/styles';
import CustomButton from '../CustomButton';
import { CustomerVisit } from '../../types/Visit';
import TimeLine from '../TimeLine';
import TimeLineItem from '../TimeLineItem';
import history from '../../browserHistory';
import { convertDateToTimeWithTimezone, convertDateWithTimezone } from '../../utils/helpers';

type Props = {
  visits: Array<CustomerVisit>,
  match: any;
  location: any;
};

const CustomerVisits: React.FC<Props> = ({ visits }) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Paper className={classes.innerPaper}>
          <TimeLine>
            {visits.map(visit => (
              <TimeLineItem key={visit.customer_visit_id}>
                <div>
                  <Grid item xs={12} sm={12}>
                    <Typography className={classes.timestamp}>
                      { convertDateWithTimezone(
                        new Date(visit.created_on),
                        visit.property?.timezoneCatalog?.catalog_id,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography className={classes.timestamp}>
                      {convertDateToTimeWithTimezone(
                        new Date(visit.created_on),
                      visit.property?.timezoneCatalog?.catalog_id,
                      )}
                      {'  '}
                      {visit.property?.timezoneCatalog?.value}
                    </Typography>
                  </Grid>
                  <Typography>
                    {`Address : ${visit.property?.house_num ?? ''} ${visit.property?.address_1 ?? ''}`}
                  </Typography>
                  <Typography>
                    {`Client : ${visit.property?.builder ?? ''}`}
                  </Typography>
                  <Typography>
                    {`Agent : ${visit.modified_by ?? ''}`}
                  </Typography>
                  <Typography>
                    {`Remarks : ${visit.remarks ?? ''}`}
                  </Typography>
                  <CustomButton variant="orange" style={{ marginTop: 10 }} onClick={() => history.push(`/visits/${visit.customer_visit_id}`)}>
                        Edit
                  </CustomButton>
                </div>
              </TimeLineItem>
            ))}
          </TimeLine>
        </Paper>
      </Grid>
    </>
  );
};

export default React.memo(CustomerVisits);
