import React, { useState } from 'react';

import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField,
} from '@material-ui/core';

import ListTextInput from './ListTextInput';

interface Props {
  register: any;
}

const SCRIPTS = [
  'HUB:zwave/scripts/start_include',
  'HUB:zwave/scripts/stop_include',
  'HUB:zwave/scripts/start_exclude',
  'HUB:zwave/scripts/stop_exclude',
  'HUB:zwave/scripts/start_learn',
  'HUB:zwave/scripts/stop_learn',
  'HUB:zwave/scripts/stop_security_include',
  'HUB:zwave/scripts/reset',
  'HUB:zwave/scripts/set_authentication_mode',
  'HUB:zwave/scripts/set_device_specific_key',
];

const SCRIPT_SET_AUTHENTICATION_MODE = 'HUB:zwave/scripts/set_authentication_mode';
const SCRIPT_SET_DEVICE_SPECIFIC_KEY = 'HUB:zwave/scripts/set_device_specific_key';

const RunZWaveScriptComponent = ({ register }: Props) => {
  const [script, setScript] = useState<string>();

  return (
    <>
      <FormControl>
        <FormLabel id="radio-buttons-group-label">script</FormLabel>
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          defaultValue=""
          name="script"
          onChange={(event, value) => setScript(value)}
        >
          {SCRIPTS.map(item => (
            <FormControlLabel value={item} control={<Radio inputRef={register} />} label={item} />
          ))}
        </RadioGroup>
      </FormControl>
      {script === SCRIPT_SET_AUTHENTICATION_MODE && (
        <>
          <p>scriptParams</p>
          <ListTextInput placeholder="type in accessControl" label="modes" name="scriptParams.modes" register={register} />
        </>
      )}
      {script === SCRIPT_SET_DEVICE_SPECIFIC_KEY && (
        <>
          <p>scriptParams</p>
          <TextField
            margin="normal"
            fullWidth
            inputRef={input => register(input, { required: true })}
            required
            label="key"
            key="key"
            name="scriptParams.key"
          />
        </>
      )}
    </>
  );
};

export default RunZWaveScriptComponent;
