import React from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { WithStyles } from '@material-ui/styles';
import SearchFilterElement from '../SearchFilterElement';
import styles from './styles';
import useUrlLocation from '../../hooks/useUrlLocation';

function noOP(): void{}

type Props = {
  onChange?: (search: string) => void;
  placeholder?: string;
  defaultSearchValue?: string;
  searchRequest?: () => void;
} & WithStyles<typeof styles>;

const SearchBar: React.FC<Props> = props => {
  const {
    classes, onChange = noOP, placeholder, defaultSearchValue, searchRequest,
  } = props;
  const pathForRenderingSearchFilter = ['visits'];
  const currentPath = useUrlLocation();
  const searchBarRef = React.useRef<HTMLDivElement | null>(null);
  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    onChange(e.target.value);
  }

  return (
    <OutlinedInput
      ref={searchBarRef}
      fullWidth
      labelWidth={0}
      name="searchBar"
      autoComplete="off"
      value={defaultSearchValue}
      placeholder={placeholder}
      classes={{ root: classes.searchBarRoot }}
      onChange={handleChange}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon className={classes.searchBarIcon} />
        </InputAdornment>
)}
      endAdornment={(
        <>
          {
            pathForRenderingSearchFilter.map(path => path === currentPath
              && (
              <SearchFilterElement
                key={path}
                searchRequest={searchRequest}
                searchBarRef={searchBarRef}
              />
              ))
          }
        </>
      )}
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
};

export default React.memo(SearchBar);
