import React, {
  useCallback, useState, useMemo, useEffect, useContext, useRef,
} from 'react';
import {
  Paper, Table, TableHead, TableContainer,
  TableRow, TableCell, TableBody,
  Typography, Grid, TableSortLabel,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
} from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useStyles from '../Clients/styles';
import { setProgressBarStatus as SetProgressBarStatus } from '../../redux/actions/progressBar';
import useDebounce from '../../hooks/useDebounce';
import CustomTablePagination from '../CustomTablePagination';
import SelectedActionsBar from '../SelectedActionsBar';
import ConfirmDelete from '../ConfirmDelete';
import CustomButton from '../CustomButton';
import SearchBar from '../SearchBar';
import { isSelected } from '../../utils/table';
import Checkbox from '../CustomCheckbox';
import CustomTableScroll from '../CustomTableScroll';
import { ALL_USERS, REMOVE_USERS } from '../../graphql/queries/Users';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../RowsPerPageSelect/RowsPerPageSelect';
import { loadExport, loadExportAll } from '../../services/export';
import downloadDocument from '../../utils/files';

export type User = {
  username: string,
  firstname: string,
  lastname: string,
  phone: string,
  email: string,
  builder: {
    name: string
  },
  groups: {
    Label: string
  }[],
};

const searchBarPlaceholder: string = 'Search users';

type Props = {
  setProgressBarStatus: typeof SetProgressBarStatus,
} & RouteComponentProps;

const Users: React.FC<Props> = ({ history, match, setProgressBarStatus }) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy, order, contextSearch, currentPage, rowsPerPageAmount,
  } = state.users;
  const [cursor, setCursor] = useState<{ limit: number, offset: number }>({
    limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
  });
  const [search, setSearch] = useState<string>(contextSearch);
  const { enqueueSnackbar } = useSnackbar();
  const [sort, setSort] = useState<{ sortBy: string, direction: 'asc' | 'desc' }>({ sortBy: orderBy, direction: order });
  const [userToRemove, setUserToRemove] = useState<boolean>(false);
  const [selected, setSelected] = useState<Array<User['username']>>([]);
  const selectedLength = selected.length;
  const debouncedSearch = useDebounce(search, 1000);
  const { loading, error, data } = useQuery<{ userList: Paginated<User> }>(ALL_USERS, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const [removeUsers] = useMutation(REMOVE_USERS, {
    refetchQueries: [{
      query: ALL_USERS,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  useEffect(() => {
    setProgressBarStatus(loading);
  }, [loading, error, setProgressBarStatus]);

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_USER',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_USER',
      payload: {
        rowsPerPageAmount: e.target.value,
        currentPage: 0,
      },
    });
  }, [dispatch]);

  const handleShowAllClick = () => {
    dispatch({ type: 'RESET_STATE' });
    setSort({
      direction: 'asc',
      sortBy: 'username',
    });
    setSearch('');
  };

  const handleClick = (
    event: React.SyntheticEvent,
    id: User['username'],
  ) => {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<User['username']> = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data ? data.userList.list.map(i => i.username) : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleUserDelete = () => {
    if (selectedLength !== 0) {
      removeUsers({
        variables: {
          usernames: selected,
        },
      }).then(() => {
        setSelected([]);
        enqueueSnackbar('Removed', { variant: 'success' });
      })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  };

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'users')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'Users', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('users').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Users', response.data);
    });
  }

  const handlePrint = () => {
    if (selectedLength !== 0) {}
  };
  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const handleSortBy = (property: string) => () => {
    const isDesc = sort.sortBy === property && sort.direction === 'desc';
    const direction = isDesc ? 'asc' : 'desc';
    setSort({
      direction,
      sortBy: property,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_USER',
      payload: {
        orderBy: property,
        order: direction,
        currentPage: 0,
      },
    });
  };

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'users')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_USER',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const tableContainer = useRef<HTMLDivElement>(null);

  if (!data) return null;
  if (error) {
    return (
      <Typography variant="h5">
        Error :( -
        {' '}
        {error}
      </Typography>
    );
  }

  const isStateInitial = JSON.stringify({
    contextSearch: state.users.contextSearch,
    order: state.users.order,
    orderBy: state.users.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.users.contextSearch,
    order: initialState.users.order,
    orderBy: initialState.users.orderBy,
  });

  return (
    <div>
      <Grid container justify="space-between">
        <Grid container spacing={8}>
          <Grid item>
            <CustomButton
              variant="orange"
              color="primary"
              style={{ marginBottom: 25 }}
              onClick={() => history.push('/users/add')}
            >
              Add User
            </CustomButton>
          </Grid>
          {!isStateInitial && (
            <Grid item>
              <CustomButton
                variant="orange"
                color="primary"
                style={{ marginBottom: 25 }}
                onClick={handleShowAllClick}
              >
                Show all
              </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        <SearchBar
          placeholder={searchBarPlaceholder}
          onChange={handleSearch}
          defaultSearchValue={search}
        />
      </Grid>
      <Paper className={classes.paper} style={{ marginTop: 33 }}>
        <SelectedActionsBar
          onDelete={() => { setUserToRemove(true); }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography style={{ marginLeft: '23px' }} variant="h5">Users</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <TableContainer ref={tableContainer} className={classes.tableContainer}>
            <CustomTableScroll getTableContainer={() => tableContainer} columns={7} />
            <Table className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedLength > 0
                      && selectedLength < data.userList.list.length}
                      checked={selectedLength === data.userList.list.length}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'Select all locks' }}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} onClick={handleSortBy('username')}>
                  Username
                    <TableSortLabel
                      active={sort.sortBy === 'username'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} align="right" onClick={handleSortBy('firstname')}>
                  First Name
                    <TableSortLabel
                      active={sort.sortBy === 'firstname'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} align="right" onClick={handleSortBy('lastname')}>
                  Last Name
                    <TableSortLabel
                      active={sort.sortBy === 'lastname'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} onClick={handleSortBy('builder.name')}>
                  Client
                    <TableSortLabel
                      active={sort.sortBy === 'builder.name'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} onClick={handleSortBy('groups.Label')}>
                  User Type
                    <TableSortLabel
                      active={sort.sortBy === 'groups.Label'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} align="right" onClick={handleSortBy('phone')}>
                  Phone
                    <TableSortLabel
                      active={sort.sortBy === 'phone'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                  <TableCell className={classes.pointer} align="right" onClick={handleSortBy('email')}>
                  Email
                    <TableSortLabel
                      active={sort.sortBy === 'email'}
                      direction={sort.direction}
                      IconComponent={KeyboardArrowDown}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.userList.list.map((user, index) => {
                  const isItemSelected: boolean = isSelected(
                    user.username,
                    selected,
                  );
                  const labelId: string = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={user.username}
                      className={classes.pointer}
                      onClick={() => history.push(`${match.path}/${user.username}`)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event => {
                            event.stopPropagation();
                            handleClick(event, user.username);
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.orange} component="th" scope="row">{user.username}</TableCell>
                      <TableCell align="right">{user.firstname}</TableCell>
                      <TableCell align="right">{user.lastname}</TableCell>
                      <TableCell>{user.builder?.name ?? ''}</TableCell>
                      
                      <TableCell align="right">{
                      user.groups.map(group => (<p>{group.Label}</p>))
                      }</TableCell>
                      <TableCell align="right">{user.phone}</TableCell>
                      <TableCell align="right">{user.email}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsTotal={data!.userList.params.total}
            rowsCount={data!.userList.list.length}
            rowsPerPage={cursor.limit}
            currentPage={page}
            onChangePage={n => handleChangePage(undefined, n)}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
          />
        </div>

      </Paper>
      <ConfirmDelete
        isOpen={userToRemove}
        onCancel={() => { setUserToRemove(false); }}
        onConfirm={() => { handleUserDelete(); setUserToRemove(false); }}
      />
    </div>
  );
};

export default React.memo(Users);
