import React, {
    Fragment, useCallback, useEffect, useState,
  } from 'react';
  import { Link, RouteComponentProps } from 'react-router-dom';
  import {Grid, MenuItem} from '@material-ui/core/';
  import Button from '@material-ui/core/Button';
  import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
  import { useMutation, useQuery } from '@apollo/react-hooks';
  import { Paper, TextField } from '@material-ui/core';
  import PropertySearch from '../../../components/PropertySearch/PropertySearch';
  import { gql } from 'apollo-boost';
  import useStyles from './styles';
  import { useSnackbar } from 'notistack';
  import {
    beautifyErrors,
    transformGraphQlErrorMessage,
    ValidationErrors,
  } from '../../../utils/helpers';
  import {UPDATE_DEVICE} from '../../../graphql/queries/Devices'
  import CustomTextField from '../../../components/CustomTextField/CustomTextField';
  import CustomButton from '../../../components/CustomButton';
  import BuilderSearch from '../../../components/BulderSearch/BuilderSearch';
  import Builder from '../../../types/Builder';
  import InputDevice from '../../../types/inputTypes/Device';
  import { deviceCreateSchema } from './DeviceValidation';
  import { BaseProperty as Property } from '../../../types/Property';
  import useProperty from '../../../hooks/useProperty';
  import HubTypeSelect from '../../../components/HubTypeSelect';

  type Props = {
    loading?: boolean;
  } & RouteComponentProps;

  const DeviceForm: React.FC<Props> = props => {

    const {history} = props;
    const classes = useStyles();
    const [validationErrors, setValidationErrors] = useState<ValidationErrors<InputDevice>>({});
    const [device, setDevice] = useState<InputDevice>(new InputDevice());
    const [buildersId, setBuildersId] = useState<{ name: string, id?: number }[]>([]);
    const [HubAccountId, setHubAccountId] = useState<{ id: string, username: string }[]>([]);
    const { property } = useProperty(device.property_id);
    const { enqueueSnackbar } = useSnackbar();

    const GET_BUILDERIDS = gql`
    query {
      builderIds {
        builder_id,
        name
      }
    }
    `;

    const GET_HUBACCOUNT_IDS = gql`
    query {
      hubAccountIds {
        id,
        username
      }
    }
    `;

    const { data: builderData } = useQuery<{ builderIds: Array<{ name: string, id?: number }> }>(
      GET_BUILDERIDS,
    );


    const { data: hubAccountData } = useQuery<{ hubAccountIds: Array<{ id: string, username: string }> }>(
      GET_HUBACCOUNT_IDS,
    );

    useEffect(() => {
      if (hubAccountData) {
        setHubAccountId(hubAccountData.hubAccountIds);
      }
    }, [hubAccountData, HubAccountId]);

    const handleDeviceChange = useCallback(
      ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (name) {
          setDevice((prevDevice: InputDevice) => ({
            ...prevDevice,
            [name]: value,
          }));
        }
      },
      [],
    );

    const handleDeviceSave = () => {
      try{
        const uDevice = deviceCreateSchema.validateSync(device, { abortEarly: false })
        updateDevice({
          variables: {
            device: {
              ...uDevice,
            },
      }}).then(() => history.goBack())
      .catch(err => {
        enqueueSnackbar(transformGraphQlErrorMessage(err.message), {
          variant: 'error',
        });
      });
    } catch (errors) {
      enqueueSnackbar('Error', { variant: 'error' });
      setValidationErrors(beautifyErrors<InputDevice>(errors));
    }
  }

    const handleBuilderChange = (builder: Builder) => {
      setDevice((device: InputDevice) => ({
        ...device,
        property_id: 0,
        builder_id: builder.builder_id,
      }));
    }

    const handlePropertyChange = (property: Property) => {
      setDevice((device: InputDevice) => ({
        ...device,
        builder_id: property.builder_id ?? 0,
        property_id: property.property_id,
      })
      )
    }

    useEffect(() => {
      if (builderData) {
        setBuildersId(builderData.builderIds);
      }
    }, [builderData, buildersId]);

    const [updateDevice] = useMutation<{ updateDevice: InputDevice }>(
      UPDATE_DEVICE,
      { errorPolicy: 'all' }
    );

    const renderEzloFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderSeamFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Device Id"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderADCFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                margin="normal"
                id="adc_customer_id"
                name="adc_customer_id"
                label="ADC Customer ID"
                value={device.adc_customer_id}
                onChange={handleDeviceChange}
                helperText={validationErrors.adc_customer_id || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    const renderKwiksetFields = () => {
      return (
        <Fragment>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="serial_number"
                name="serial_number"
                label="Serial number"
                value={device.serial_number}
                onChange={handleDeviceChange}
                helperText={validationErrors.serial_number || ''}
              />
            </Grid>
        </Fragment>
      );
    }

    return(
      <Fragment>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/devices"
          >
            <ArrowLeftIcon />
            Devices
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid item xs={12} sm={12}>
          <Paper className={classes.paper}>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <HubTypeSelect
              margin="normal"
              fullWidth
              id="hub_type"
              label="Hub Type"
              name="hub_type"
              select
              value={device.hub_type}
              onChange={handleDeviceChange} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="normal"
                fullWidth
                select
                label="Hub Account"
                name="hub_account_id"
                onChange={handleDeviceChange}
                value={device.hub_account_id}
                helperText={validationErrors.hub_account_id}
                >
                {HubAccountId.sort((a, b) => a.username.localeCompare(b.username)).map(HubAcc => (
                  <MenuItem
                    key={HubAcc.id}
                    value={HubAcc.id}
                  >
                    {HubAcc.username}
                  </MenuItem>
                ))}
             </TextField>
            </Grid>
            {(device.hub_type === 'Ezlo Secure') && renderEzloFields()}
            {(device.hub_type === 'Seam') && renderSeamFields()}
            {(device.hub_type === 'ADC') && renderADCFields()}
            {(device.hub_type === 'Kwikset') && renderKwiksetFields()}
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                margin="normal"
                id="imei"
                name="imei"
                label="IMEI"
                value={device.imei}
                onChange={handleDeviceChange}
                helperText={validationErrors.imei || ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BuilderSearch
                margin="normal"
                onBuilderChange={handleBuilderChange}
                label="Builder"
                helperText={validationErrors.builder_id}
                initialValue={device.builder_id ?? 0}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PropertySearch
                style={{ marginTop: '0' }}
                margin="normal"
                fullWidth
                //@ts-ignore
                initialValue={property?.property_id ? property : undefined}
                builderId={device.builder_id}
                onPropertyChange={handlePropertyChange}
             />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                margin="normal"
                fullWidth
                multiline
                rows={4}
                rowsMax={8}
                label="Comments"
                name="comments"
                value={device.comments || ''}
                onChange={handleDeviceChange}
              />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  rowsMax={8}
                  label="Descriprion"
                  name="description"
                  value={device.description || ''}
                  onChange={handleDeviceChange}
                />
              </Grid>
            <Grid container spacing={4} justify="center" style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Grid >
                    <CustomButton variant="orange" onClick={handleDeviceSave}>
                      Add
                    </CustomButton>
                </Grid>
              </Grid>
              </Grid>
          </Paper>
      </Grid>
      </div>
      </Fragment>
    )
  }

  export default React.memo(DeviceForm);
