import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    width: '85%',
    margin: '0 auto',
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    width: '85%',
    justifyContent: 'space-between',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  disabled: {
    color: '#000000',
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  tabsContent: {
    height: '100%',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  timestamp: {
    color: theme.appPalette.orange,
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 15,
    paddingBottom: 5,
  },
  propertyId: {
    fontSize: 25,
  },
  alarmContainer: {
    width: '100%',
    border: '2px solid #DBDDE3',
    borderRadius: '4px',
    padding: '0 10px',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
